import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Tabs, Tab, Grid } from "@material-ui/core";
import WidgetColumnLineChart, {
    WIDGET_CHART_TYPE_COLUMN,
    WIDGET_CHART_TYPE_PIE,
} from "../../components/widgets/WidgetColumnLineChart";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import WidgetBasicTable from "../../components/widgets/WidgetBasicTable";
import FilterCustomWindow from "../../components/widgets/FilterCustomWindow";
import { isProject } from "../../utils";
import { PROJECT_CB } from "../../constants/lang";
import FunnelChart from "../../components/widgets/FunnelChart";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        "&::after": {
            content: "",
            display: "flex",
            flex: "auto",
        },
    },
    item: {
        boxSizing: "border-box",
        width: (props) => props.itemWidth || (props.isDrawerOpen ? 400 : 450),
        height: "350px",
        margin: theme.spacing(1),
        marginBottom: theme.spacing(6),
    },
    tabs: {
        marginBottom: theme.spacing(2),
    },
}));

// widgets filters
const customFiltersWidgetActiveSubscriptionsTotal = [
    {
        type: "check_box",
        name: "exclude_invited",
        title: "Exclude invited (real purchases only)",
        hint: "Invited users are users who got a plan by using invite code or got a plan through dashboard (e.g. AppSumo users)",
    },
];

const customFiltersWidgetAppVsWebUsage = [
    {
        type: "date",
        name: "dateStart",
        title: "Date range start",
    },
    {
        type: "date",
        name: "dateEnd",
        title: "Date range end",
    },
];

const customFiltersWidgetAffiliateStat = [
    {
        type: "date",
        name: "dateStart",
        title: "Date range start",
    },
    {
        type: "date",
        name: "dateEnd",
        title: "Date range end",
    },
];

const DashboardMain = () => {
    const [tab, settab] = React.useState(0);
    const localStorage = window.localStorage;
    const isDrawerOpen =
        localStorage.getItem("drawer_position") === "open" ||
        localStorage.getItem("drawer_position") === null;
    const matches = useMediaQuery("(max-width:1534px)");

    const classes = useStyles({
        itemWidth: matches && "100%",
        isDrawerOpen,
    });
    return (
        <div className={classes.root}>
            <Grid container justify="space-between" alignContent="center">
                <Grid item>
                    <Tabs
                        className={classes.tabs}
                        value={tab}
                        onChange={(e, value) => settab(value)}
                        scrollButtons="on"
                    >
                        <Tab label="Growth" />
                        <Tab label="Campaigns" />
                        <Tab label="Invite codes" />
                        <Tab label="Mastering" />
                        <Tab label="Affiliates" />
                        <Tab label="Invite code email" />
                    </Tabs>
                </Grid>
            </Grid>

            {tab === 0 && (
                <>
                    <Paper className={classes.item}>
                        <WidgetBasicTable
                            widgetName="newUserStatistic"
                            title="Users growth"
                        />
                    </Paper>
                    <Paper className={classes.item}>
                        <WidgetColumnLineChart
                            widgetName="WidgetNewUsersMonthGrowColumn"
                            title="Users month growth"
                            hAxisName={"Date"}
                            vAxisName={"Users"}
                        />
                    </Paper>
                    <Paper className={classes.item}>
                        <WidgetColumnLineChart
                            widgetName="WidgetActiveSubscriptionsTotal"
                            title="Active subscriptions month total"
                            hAxisName={"Date"}
                            vAxisName={"Subscriptions"}
                            FilterCustomComponent={FilterCustomWindow}
                            customfilters={
                                customFiltersWidgetActiveSubscriptionsTotal
                            }
                        />
                    </Paper>
                    <Paper className={classes.item}>
                        <WidgetColumnLineChart
                            useSingleChart={WIDGET_CHART_TYPE_COLUMN}
                            widgetName="WidgetActiveSubscriptionsByPlanTotal"
                            title="Plan + active subscriptions"
                            vAxisName={"Subscriptions"}
                        />
                    </Paper>
                    <Paper className={classes.item}>
                        <WidgetBasicTable
                            widgetName="WidgetPopularSignupSource"
                            title="Popular signup sources"
                        />
                    </Paper>
                    <Paper className={classes.item}>
                        <WidgetBasicTable
                            widgetName="WidgetAcceptedCampaigns"
                            title="Popular campaigns (accepted offer)"
                        />
                    </Paper>
                </>
            )}
            {tab === 1 && (
                <>
                    <Paper className={classes.item}>
                        <WidgetBasicTable
                            widgetName="WidgetCampaignsTotal"
                            title="Campaigns info"
                        />
                    </Paper>
                    <Paper className={classes.item}>
                        <WidgetColumnLineChart
                            widgetName="WidgetCampaignsHookedByTime"
                            title="Total month landed"
                            hAxisName={"Date"}
                            vAxisName={"Landed users (Hooked)"}
                        />
                    </Paper>
                    <Paper className={classes.item}>
                        <WidgetColumnLineChart
                            widgetName="WidgetCampaignsJoinedByTime"
                            title="Total month joined"
                            hAxisName={"Date"}
                            vAxisName={"Joined users"}
                        />
                    </Paper>
                    <Paper className={classes.item}>
                        <WidgetColumnLineChart
                            widgetName="WidgetCampaignsAcceptedByTime"
                            title="Total month accepted"
                            hAxisName={"Date"}
                            vAxisName={"Accepted users (purchase)"}
                        />
                    </Paper>
                    <Paper className={classes.item}>
                        <WidgetColumnLineChart
                            isStacked="percent"
                            useSingleChart={WIDGET_CHART_TYPE_COLUMN}
                            widgetName="WidgetCampaignsTotalJoinedAcceptedFunnel"
                            title="Total landed/joined/accepted funnel"
                        />
                    </Paper>
                </>
            )}
            {tab === 2 && (
                <>
                    <Paper className={classes.item}>
                        <WidgetBasicTable
                            widgetName="inviteCodesInfo"
                            title="Invite codes info"
                        />
                    </Paper>
                    <Paper className={classes.item}>
                        <WidgetColumnLineChart
                            useSingleChart={WIDGET_CHART_TYPE_PIE}
                            widgetName="inviteCodesTotalByComment"
                            title="Total invite codes by comment"
                        />
                    </Paper>
                    <Paper className={classes.item}>
                        <WidgetColumnLineChart
                            useSingleChart={WIDGET_CHART_TYPE_PIE}
                            widgetName="inviteCodesTotalByCondition"
                            title="Total invite codes by condition"
                        />
                    </Paper>
                    <Paper className={classes.item}>
                        <WidgetColumnLineChart
                            widgetName="inviteCodesRedeemedByTime"
                            title="Redeemed codes"
                            hAxisName={"Date"}
                            vAxisName={"Redeemed codes"}
                        />
                    </Paper>
                    <Paper className={classes.item}>
                        <WidgetColumnLineChart
                            isStacked="percent"
                            useSingleChart={WIDGET_CHART_TYPE_COLUMN}
                            widgetName="inviteCodesRedeemedByCondition"
                            title="Total/Redeemed by condition"
                        />
                    </Paper>
                </>
            )}
            {tab === 3 && (
                <>
                    {isProject(PROJECT_CB) && (
                        <Paper className={classes.item}>
                            <WidgetColumnLineChart
                                widgetName="widgetMasteringUsage"
                                title="Masters APP vs WEB"
                                FilterCustomComponent={FilterCustomWindow}
                                customfilters={customFiltersWidgetAppVsWebUsage}
                            />
                        </Paper>
                    )}
                    <Paper className={classes.item}>
                        <WidgetColumnLineChart
                            useSingleChart={WIDGET_CHART_TYPE_COLUMN}
                            widgetName="WidgetAppVersions"
                            title="App versions (7 days)"
                            vAxisName={"Versions"}
                        />
                    </Paper>
                </>
            )}
            {tab === 4 && (
                <>
                    <Paper className={classes.item}>
                        <WidgetBasicTable
                            widgetName="WidgetAffiliateDaily"
                            title="Affiliates traffic"
                        />
                    </Paper>
                    <Paper className={classes.item}>
                        <WidgetBasicTable
                            widgetName="WidgetAffiliateByMonth"
                            title="Affiliates traffic total by month"
                        />
                    </Paper>
                    <Paper className={classes.item}>
                        <WidgetBasicTable
                            widgetName="WidgetAffiliateByMonthWithSub"
                            title="Affiliates traffic with sub by month"
                        />
                    </Paper>
                    <Paper className={classes.item}>
                        <WidgetColumnLineChart
                            widgetName="WidgetAffiliateStat"
                            title="Affiliates stat"
                            FilterCustomComponent={FilterCustomWindow}
                            customfilters={customFiltersWidgetAffiliateStat}
                        />
                    </Paper>
                </>
            )}
            {tab === 5 && (
                <>
                    <Paper className={classes.item}>
                        <WidgetBasicTable
                            widgetName="WidgetPromoFirstEmailTotal"
                            title="First email total"
                        />
                    </Paper>
                    <Paper className={classes.item}>
                        <WidgetColumnLineChart
                            widgetName="WidgetPromoFirstEmail"
                            title="First email sends"
                        />
                    </Paper>

                    <Paper className={classes.item}>
                        <WidgetColumnLineChart
                            widgetName="WidgetPromoFirstOpenStat"
                            title="First email open/click"
                        />
                    </Paper>
                    
                    <Paper className={classes.item}>
                        <FunnelChart
                            widgetName="WidgetInviteCodePromoFunnel"
                            title="Funnel total"
                        />
                    </Paper>
                </>
            )}
        </div>
    );
};

export default DashboardMain;
