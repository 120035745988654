import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ChainsActions from "../../actions/chains";
import Typography from "@material-ui/core/Typography";
import { Grid, Button, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import PageTitle from "../../components/PageTitle";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import CloseIcon from "@material-ui/icons/Close";
import { green } from "@material-ui/core/colors";
import Notification from "../../components/Notification";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import JSONPretty from "react-json-pretty";
import JsonEditorPanel from "../../components/JsonEditorPanel";
import JsonEditor from "../../components/JsonEditor";
import FloatingResultWindow from "../../components/FloatingResultWindow";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        position: "relative"
    },
    formWrapper: {
        padding: 15,
        flexGrow: 1,
        height: "70vh",
        overflow: "auto",
        position: "relative"
    },
    basicWrapper: {
        position: "relative"
    },
    viewJson: {
        position: "absolute",
        right: 0,
        top: 0
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    runButton: {
        backgroundColor: green[500],
        color: "white",
        width: 200
    },
    tabs: {
        marginBottom: 15
    },
    runButtonWraper: {
        display: "flex",
        justifyContent: "flex-end"
    },
    buttonWraper: {
        marginRight: 10
    },
    btnLinks: {
        marginTop: 10,
        "& a": {
            textDecoration: "underline"
        }
    }
}));

const DashBoardTestChainsContainer = ({
    params,
    chains,
    specialProcessors,
    processed,
    processedWithExpressions,
    errors,
    isLoading,
    chainsActions
}) => {
    const classes = useStyles();
    const [tab, settab] = React.useState(0);
    const [error, seterror] = React.useState(false);
    const [showSuccess, setShowSuccess] = React.useState(false);
    const [menuLoadDataAnchorEl, setMenuLoadDataAnchorEl] = React.useState(null);
    const [showParamsJsonPreview, setShowParamsJsonPreview] = React.useState(false);
    const [showChainsJsonPreview, setShowChainsJsonPreview] = React.useState(false);
    const [showJsonError, setShowjsonError] = React.useState(false);
    const [jsonErrorText, setShowJsonErrorText] = React.useState("");
    const [useAdvancedEditor, setUseAdvancedEditor] = React.useState(
        localStorage.getItem("advanced_editor") === "1"
    );
    const [useFloatingResultWindow, setUseFloatingResultWindow] = React.useState(
        localStorage.getItem("floating_result_window") === "1"
    );
    React.useEffect(() => {
        if (Boolean(errors && Object.keys(errors).length > 0) && error === false) {
            seterror(true);
        }
    }, [errors, error]);

    const handleNotificationClose = React.useCallback(() => {
        chainsActions.resetErrors();
        seterror(false);
    }, [chainsActions]);

    const handleJsonErrorNotificationClose = React.useCallback(() => {
        setShowjsonError(false);
        setShowJsonErrorText("");
    }, []);

    const handleRunTestClick = () => {
        chainsActions.testChains(() => {
            setShowSuccess(true);
        });
    };

    const handleNotificationSuccessClose = React.useCallback(() => {
        setShowSuccess(false);
    }, []);

    const handleLoadDataMenuClick = (e) => {
        setMenuLoadDataAnchorEl(e.currentTarget);
    };

    const handleAdvancedEditor = React.useCallback((value) => {
        setUseAdvancedEditor(value);
    }, []);

    const handleUseFloatingResultWindow = React.useCallback((value) => {
        setUseFloatingResultWindow(value);
    }, []);

    return (
        <div className={classes.root}>
            {useFloatingResultWindow && (
                <FloatingResultWindow
                    processed={processed}
                    processedWithExpressions={processedWithExpressions}
                    onRunClick={handleRunTestClick}
                    isLoading={isLoading}
                />
            )}
            <Grid container spacing={0} justify="space-between">
                <Grid item xs={6}>
                    <PageTitle title="Chains tester" />
                    <Typography color="textSecondary">
                        Test new chains here, this will not take affect to application.
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.runButtonWraper}>
                        <div className={classes.buttonWraper}>
                            <Button
                                aria-controls="load-data-menu"
                                aria-haspopup="true"
                                onClick={handleLoadDataMenuClick}
                            >
                                Load data
                                <CloudDownloadIcon className={classes.rightIcon} />
                            </Button>
                        </div>
                        <Menu
                            id="simple-menu"
                            anchorEl={menuLoadDataAnchorEl}
                            keepMounted
                            open={Boolean(menuLoadDataAnchorEl)}
                            onClose={() => setMenuLoadDataAnchorEl(null)}
                        >
                            <MenuItem
                                onClick={() => {
                                    setMenuLoadDataAnchorEl(null);
                                    chainsActions.loadParamsAsMeasuresForTest();
                                }}
                            >
                                Load temporary measures
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setMenuLoadDataAnchorEl(null);
                                    chainsActions.loadCurrentChainsForTest();
                                    chainsActions.loadCurrentSpecialProcessorsForTest();
                                }}
                            >
                                Load current chains and special processors
                            </MenuItem>
                        </Menu>
                        <Button
                            className={classes.runButton}
                            variant="contained"
                            color="secondary"
                            onClick={handleRunTestClick}
                            disabled={isLoading}
                        >
                            Run test
                            <PlayCircleFilledIcon className={classes.rightIcon} />
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <JsonEditorPanel
                showFloatingSwithcer={true}
                onUseAdvancedEditorChange={handleAdvancedEditor}
                onUseFloatingResultWindow={handleUseFloatingResultWindow}
            />
            <Tabs className={classes.tabs} value={tab} onChange={(e, value) => settab(value)}>
                <Tab label="Measures" />
                <Tab label="Chains" />
                <Tab label="Special processors" />
                <Tab label="Result" />
            </Tabs>

            <Paper>
                {tab === 0 && (
                    <Grid container spacing={1}>
                        <Grid
                            className={classes.formWrapper}
                            item
                            xs={showParamsJsonPreview ? 6 : 12}
                        >
                            {useAdvancedEditor && (
                                <JsonEditor
                                    name="params"
                                    onChange={(name, value) => chainsActions.setParams(value)}
                                    jsonText={params}
                                    useAdvancedEditor={useAdvancedEditor}
                                    placeholder={`{"measures":[{"name": "0.0"}]}`}
                                />
                            )}
                            {!useAdvancedEditor && (
                                <TextField
                                    placeholder={`{"measures":[{"name": "0.0"}]}`}
                                    value={params}
                                    fullWidth
                                    multiline
                                    onChange={(e) => chainsActions.setParams(e.target.value)}
                                />
                            )}
                            {!showParamsJsonPreview && !useAdvancedEditor && (
                                <div className={classes.viewJson}>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            setShowParamsJsonPreview(true);
                                        }}
                                    >
                                        <RemoveRedEyeIcon />
                                    </IconButton>
                                </div>
                            )}
                        </Grid>
                        {showParamsJsonPreview && (
                            <Grid item xs={6}>
                                <Paper className={classes.formWrapper}>
                                    <JSONPretty
                                        theme={{
                                            main:
                                                "line-height:1.3;color:#66d9ef;background:#fff;overflow:auto;",
                                            key: "color:#f92672;",
                                            string: "color:#fd971f;",
                                            value: "color:#a6e22e;",
                                            boolean: "color:#ac81fe;"
                                        }}
                                        id="json-pretty"
                                        data={params}
                                        onError={(e) => {
                                            if (chains && tab === 0) {
                                                setShowJsonErrorText(e.message);
                                                setShowjsonError(true);
                                            }
                                        }}
                                    />
                                    {showParamsJsonPreview && (
                                        <div className={classes.viewJson}>
                                            <IconButton
                                                color="primary"
                                                onClick={() => {
                                                    setShowParamsJsonPreview(false);
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                    )}
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                )}

                {tab === 1 && (
                    <Grid container spacing={1}>
                        <Grid
                            className={classes.formWrapper}
                            item
                            xs={showChainsJsonPreview ? 6 : 12}
                        >
                            {useAdvancedEditor && (
                                <JsonEditor
                                    name="chains"
                                    onChange={(name, value) => chainsActions.setChains(value)}
                                    jsonText={chains}
                                    useAdvancedEditor={useAdvancedEditor}
                                />
                            )}
                            {!useAdvancedEditor && (
                                <TextField
                                    value={chains}
                                    fullWidth
                                    multiline
                                    onChange={(e) => chainsActions.setChains(e.target.value)}
                                />
                            )}
                            {!showChainsJsonPreview && !useAdvancedEditor && (
                                <div className={classes.viewJson}>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            setShowChainsJsonPreview(true);
                                        }}
                                    >
                                        <RemoveRedEyeIcon />
                                    </IconButton>
                                </div>
                            )}
                        </Grid>
                        {showChainsJsonPreview && (
                            <Grid item xs={6}>
                                <Paper className={classes.formWrapper}>
                                    <JSONPretty
                                        theme={{
                                            main:
                                                "line-height:1.3;color:#66d9ef;background:#fff;overflow:auto;",
                                            key: "color:#f92672;",
                                            string: "color:#fd971f;",
                                            value: "color:#a6e22e;",
                                            boolean: "color:#ac81fe;"
                                        }}
                                        id="json-pretty"
                                        data={chains}
                                        onError={(e) => {
                                            if (chains && tab === 1) {
                                                setShowJsonErrorText(e.message);
                                                setShowjsonError(true);
                                            }
                                        }}
                                    />
                                    {showChainsJsonPreview && (
                                        <div className={classes.viewJson}>
                                            <IconButton
                                                color="primary"
                                                onClick={() => {
                                                    setShowChainsJsonPreview(false);
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                    )}
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                )}
                {tab === 2 && (
                    <Grid container spacing={1}>
                        <Grid
                            className={classes.formWrapper}
                            item
                            xs={showChainsJsonPreview ? 6 : 12}
                        >
                            {useAdvancedEditor && (
                                <JsonEditor
                                    name="specialProcessors"
                                    onChange={(name, value) =>
                                        chainsActions.setSpecialProcessors(value)
                                    }
                                    jsonText={specialProcessors}
                                    useAdvancedEditor={useAdvancedEditor}
                                />
                            )}
                            {!useAdvancedEditor && (
                                <TextField
                                    value={specialProcessors}
                                    fullWidth
                                    multiline
                                    onChange={(e) =>
                                        chainsActions.setSpecialProcessors(e.target.value)
                                    }
                                />
                            )}
                            {!showChainsJsonPreview && !useAdvancedEditor && (
                                <div className={classes.viewJson}>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            setShowChainsJsonPreview(true);
                                        }}
                                    >
                                        <RemoveRedEyeIcon />
                                    </IconButton>
                                </div>
                            )}
                        </Grid>
                        {showChainsJsonPreview && (
                            <Grid item xs={6}>
                                <Paper className={classes.formWrapper}>
                                    <JSONPretty
                                        theme={{
                                            main:
                                                "line-height:1.3;color:#66d9ef;background:#fff;overflow:auto;",
                                            key: "color:#f92672;",
                                            string: "color:#fd971f;",
                                            value: "color:#a6e22e;",
                                            boolean: "color:#ac81fe;"
                                        }}
                                        id="json-pretty"
                                        data={specialProcessors}
                                        onError={(e) => {
                                            if (chains && tab === 1) {
                                                setShowJsonErrorText(e.message);
                                                setShowjsonError(true);
                                            }
                                        }}
                                    />
                                    {showChainsJsonPreview && (
                                        <div className={classes.viewJson}>
                                            <IconButton
                                                color="primary"
                                                onClick={() => {
                                                    setShowChainsJsonPreview(false);
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                    )}
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                )}
            </Paper>
            {tab === 3 && (
                <Paper className={classes.formWrapper}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Typography component="p" variant="subtitle1" color="textSecondary">
                                Processed
                            </Typography>
                            <JSONPretty
                                theme={{
                                    main:
                                        "line-height:1.3;color:#66d9ef;background:#fff;overflow:auto;",
                                    key: "color:#f92672;",
                                    string: "color:#fd971f;",
                                    value: "color:#a6e22e;",
                                    boolean: "color:#ac81fe;"
                                }}
                                id="json-pretty"
                                data={processed}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component="p" variant="subtitle1" color="textSecondary">
                                Processed with expressions
                            </Typography>
                            <JSONPretty
                                theme={{
                                    main:
                                        "line-height:1.3;color:#66d9ef;background:#fff;overflow:auto;",
                                    key: "color:#f92672;",
                                    string: "color:#fd971f;",
                                    value: "color:#a6e22e;",
                                    boolean: "color:#ac81fe;"
                                }}
                                id="json-pretty"
                                data={processedWithExpressions}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            )}
            <div className={classes.btnLinks}>
                <a href="http://www.fixjson.com/" target="blank">
                    Fixjson
                </a>
                <br />
                <a href="https://jsonformatter.curiousconcept.com/" target="blank">
                    jsonformatter
                </a>
            </div>
            <Notification
                variant="error"
                message={
                    errors &&
                    Object.keys(errors)
                        .map((field) => errors[field])
                        .join(", ")
                }
                open={error}
                onClose={handleNotificationClose}
            />
            <Notification
                autoHideDuration={1000}
                variant="success"
                message={"Done"}
                open={showSuccess}
                onClose={handleNotificationSuccessClose}
            />
            <Notification
                autoHideDuration={2000}
                variant="warning"
                message={jsonErrorText}
                open={showJsonError}
                onClose={handleJsonErrorNotificationClose}
            />
        </div>
    );
};

function mapStateToProps({ testChains, state }) {
    return {
        isLoading: state.testChainsFetching,
        chains: testChains.chains,
        specialProcessors: testChains.special_processors,
        params: testChains.params,
        processed: testChains.result.processed,
        processedWithExpressions: testChains.result.processed_with_expressions,
        errors: testChains.errors
    };
}
function mapDispatchToProps(dispatch) {
    return {
        chainsActions: bindActionCreators(ChainsActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardTestChainsContainer);
