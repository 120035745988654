import { RESET_CAMPAIGN_FILTER, SET_CAMPAIGN_FILTER } from "../constants/campaign";

const initialState = {
    name: "",
    hideExpired: true,
};

export default function campaignFilter(state = initialState, action) {
    switch (action.type) {
        case SET_CAMPAIGN_FILTER:
            const { field, value } = action.payload;

            return { ...state, [field]: value };
        case RESET_CAMPAIGN_FILTER:
            return {
                name: "",
                hideExpired: true,
            };
        default:
            return state;
    }
}
