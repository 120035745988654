import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as usersActions from "../../actions/users";
import {
    Grid,
    Paper,
    IconButton,
    Tab,
    Tabs,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    Popper,
    Grow,
    MenuList,
    MenuItem,
    Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageTitle from "../../components/PageTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { green, red, grey, yellow } from "@material-ui/core/colors";
import UserEditForm from "../../components/UserEditForm";
import SaveIcon from "@material-ui/icons/Save";
import { usePrevious } from "../../utils/hooks";
import SubscriptionList from "../../components/SubscriptionList";
import DeviceList from "../../components/DeviceList";
import InviteCodeList from "../../components/InviteCodeList";
import BrainTreeInfo from "../../components/BrainTreeInfo";
import MandrillSearch from "../../components/MandrillSearch";
import { PAID_PLANS, PLAN_GOD } from "../../constants/payments";
import { getHumanPlanName } from "../../utils";
import UserLoginList from "../../components/UserLoginList";
import EventsList from "../../components/EventsList";
import SubscriptionActive from "../../components/SubscriptionActive";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
        width: "100%",
        flexDirection: "column"
    },
    title: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },
    subtitle: {
        fontSize: 20,
        marginBottom: theme.spacing(2)
    },
    content: {
        padding: theme.spacing(2)
    },
    spinnerWrapper: {
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: "2"
    },
    header: {
        marginBottom: 15
    },
    runButton: {
        backgroundColor: green[500],
        color: "white",
        width: 200
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    addNewBtnWrapper: {
        display: "flex",
        justifyContent: "flex-end"
    },
    tabs: {
        marginBottom: theme.spacing(4),
        width: "100%",
        flexGrow: 1
    },
    saveBtn: {
        color: green[500]
    },
    editForm: {
        padding: theme.spacing(4)
    },
    subscriptionsRoot: {
        width: "100%",
        overflowX: "auto"
    },
    subscriptionsTable: {
        width: "100%"
    },
    subscriptionsAction: {
        padding: theme.spacing(2)
    },
    subscriptionsCancelBtn: {
        backgroundColor: red[500],
        color: "white",
        "&:hover": {
            backgroundColor: red[800]
        }
    },
    subscriptionsAddBtn: {
        backgroundColor: green[500],
        color: "white",
        width: 200,
        "&:hover": {
            backgroundColor: green[800]
        }
    },
    bottomSpace: {
        marginBottom: theme.spacing(4),
        padding: theme.spacing(2)
    },
    userPlanLabel: {
        lineHeight: "1.6",
        fontSize: 16,
        display: "inline-block",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        padding: theme.spacing(1),
        borderRadius: 5,
        color: (props) => (props.isMiniPlan ? "black" : props.isPaidPlan ? "white" : "black"),
        backgroundColor: (props) => (props.isMiniPlan ? yellow[600] : props.isPaidPlan ? green[400] : grey[400])
    },
    generatePasswordDialogBody: {
        padding: theme.spacing(3),
        textAlign: "center"
    }
}));

const bc = [{ title: "Users", url: "/dashboard/users" }];

const DashBoardUsersEdit = ({
    match,
    isLoadUserFetching,
    isAddNewUserFetching,
    isUpdateUserFetching,
    isCancelSubscriptionFetching,
    isAddSubscriptionFetching,
    isResetDeviceFetching,
    isSyncSubscriptionFetching,
    isMigrateFromBrainTreeFetching,
    userForm,
    usersActions
}) => {
    const [tab, settab] = React.useState(0);
    
    const classes = useStyles({
        isPaidPlan: PAID_PLANS.includes(userForm.plan),
        isMiniPlan: Boolean(userForm.isMini)
    });
    const [isEditMode, setIsEditMode] = React.useState(true);
    const [generatePasswordResultDialogOpen, setGeneratePasswordResultDialogOpen] =
        React.useState(false);
    const [generatePasswordResultDialogContent, setGeneratePasswordResultDialogContent] =
        React.useState("");
    const prevMatchUserId = usePrevious(match.params.id);

    React.useEffect(() => {
        if (match.params.id && prevMatchUserId !== match.params.id) {
            window.scrollTo(0, 0);
            usersActions.resetMandrill();
            usersActions.resetBrainTreeInfo();

            if (match.params.id === "add") {
                setIsEditMode(false);
            } else {
                setIsEditMode(true);
                usersActions.loadUser(match.params.id);
            }
        }
    }, [match, usersActions, prevMatchUserId]);

    React.useEffect(() => {
        settab(isEditMode ? 0 : 7);
    }, [isEditMode]);

    const handleUserFieldChange = React.useCallback(
        (field, value) => {
            usersActions.userFormSetField(field, value);
        },
        [usersActions]
    );

    const handleSubmit = React.useCallback(() => {
        if (isEditMode) {
            usersActions.updateUser();
        } else {
            usersActions.addNewUser();
        }
    }, [usersActions, isEditMode]);

    const handleAddSubscription = React.useCallback(
        (newPlan) => {
            /*eslint-disable*/
            if (confirm(`Add "${newPlan}" subscription?`)) {
                /*eslint-enable*/
                usersActions.addSubscription(newPlan, () => {
                    usersActions.loadUser(match.params.id);
                });
            }
        },
        [usersActions, match]
    );

    const handleMigrateFromBrainTree = React.useCallback(
        (braintreeId) => {
            /*eslint-disable*/
            if (
                confirm(
                    `Check carefully, does this BrainTree ID really belong to this user on the web CB?! User will get a subscription according BrainTree plan. You won't be able to reset the plan without unsubscribing. Continue?`
                )
            ) {
                /*eslint-enable*/
                usersActions.migrateFormBrainTree(braintreeId, match.params.id, () => {
                    usersActions.loadUser(match.params.id);
                });
            }
        },
        [usersActions, match]
    );

    const handleSyncSubscription = React.useCallback(
        (braintreeId) => {
            /*eslint-disable*/
            if (
                confirm(
                    `User subscription will be synced with "${braintreeId}" subscription. Continue?`
                )
            ) {
                /*eslint-enable*/
                usersActions.syncSubscription(braintreeId, () => {
                    usersActions.loadUser(match.params.id);
                });
            }
        },
        [usersActions, match]
    );

    const handleCancelSubscription = React.useCallback(
        (shouldResetUserPlan, resetToTrial = false) => {
            /*eslint-disable*/
            if (confirm("Cancel active subscription?")) {
                /*eslint-enable*/
                usersActions.cancelSubscription(shouldResetUserPlan, resetToTrial, () => {
                    usersActions.loadUser(match.params.id);
                });
            }
        },
        [usersActions, match]
    );

    const handleResetDevice = React.useCallback(
        (deivce) => {
            /*eslint-disable*/
            if (confirm("Reset hardware ID?")) {
                /*eslint-enable*/
                usersActions.resetDevice(match.params.id, deivce, () => {
                    usersActions.loadUser(match.params.id);
                });
            }
        },
        [usersActions, match]
    );

    const UserActions = ({ usersActions, userId }) => {
        const [open, setOpen] = React.useState(false);
        const anchorRef = React.useRef(null);

        const handleToggle = () => {
            setOpen((prevOpen) => !prevOpen);
        };

        const handleClose = (event) => {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
                return;
            }

            setOpen(false);
        };

        function handleListKeyDown(event) {
            if (event.key === "Tab") {
                event.preventDefault();
                setOpen(false);
            }
        }

        const handleSyncPlanClick = () => {
            if (!userId) {
                alert("User ID is required");
            }
            if (usersActions && userId) {
                usersActions.syncPlan(userId, () => {
                    usersActions.loadUser(userId);
                });
            }
        };

        const handleResetCacheClick = () => {
            if (!userId) {
                alert("User ID is required");
            }
            if (usersActions && userId) {
                usersActions.resetCache(userId, () => {
                    usersActions.loadUser(userId);
                });
            }
        };

        return (
            <div>
                <Tooltip title="Use 'Sync plan' when you see gray badge (user' plan will be synced with his last active subscription). Click 'Reset cache' to reset user' internal cache (sometimes it may help)">
                    <IconButton
                        ref={anchorRef}
                        aria-controls={open ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                <Popper
                    style={{
                        zIndex: 100
                    }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom"
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        <MenuItem onClick={handleSyncPlanClick}>Sync plan</MenuItem>
                                        <MenuItem onClick={handleResetCacheClick}>Reset cache</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    };

    const handleGeneratePassword = React.useCallback(
        (newPlan) => {
            /*eslint-disable*/
            if (
                confirm(
                    `User password will be reset. You will see a new one. Email will not be sent to user. Confirm?`
                )
            ) {
                /*eslint-enable*/
                usersActions.resetAndGeneratePassword(match.params.id, (data) => {
                    setGeneratePasswordResultDialogContent(data.message || "no password");
                    setGeneratePasswordResultDialogOpen(true);
                });
            }
        },
        [usersActions, match]
    );

    const isLoading =
        isLoadUserFetching ||
        isAddNewUserFetching ||
        isUpdateUserFetching ||
        isSyncSubscriptionFetching;
    const editTitle =
        isLoadUserFetching === false ? (
            <div className={classes.title}>
                {userForm.email}
                {userForm.plan && (
                    <>
                        <span className={classes.userPlanLabel}>
                            {getHumanPlanName(userForm.isGod ? PLAN_GOD : userForm.plan, true, userForm.isMini) ||
                                ""}{userForm.isBraintreeTrial ? " TRIAL" : ""}
                        </span>
                        <span>
                            <UserActions usersActions={usersActions} userId={match.params.id} />
                        </span>
                    </>
                )}
            </div>
        ) : (
            "Loading..."
        );

    return (
        <div className={classes.root}>
            {isLoading && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <Grid
                container
                spacing={0}
                justify="space-between"
                className={classes.header}
                alignContent="center"
            >
                <Grid item>
                    <PageTitle
                        title={!isEditMode ? "Add new user" : editTitle}
                        breadcrumbs={[
                            ...bc,
                            ...[isEditMode ? { title: "Edit" } : { title: "New user" }]
                        ]}
                    />
                </Grid>
            </Grid>
            <div className={classes.tabs} position="static">
                {isEditMode && (
                    <Tabs
                        variant="scrollable"
                        value={tab}
                        scrollButtons="on"
                        onChange={(e, value) => settab(value)}
                    >
                        <Tab label="Subscriptions" />
                        <Tab label="Devices" />
                        <Tab label="Invite codes" />
                        <Tab label="BrainTree" />
                        <Tab label="Outbound emails" />
                        <Tab label="Logins" />
                        <Tab label="Events" />
                        <Tab label="Edit" />
                    </Tabs>
                )}
            </div>
            <Grid container spacing={4} alignContent="stretch" direction="column">
                <Dialog
                    onClose={() => {
                        setGeneratePasswordResultDialogOpen(false);
                    }}
                    open={generatePasswordResultDialogOpen}
                >
                    <DialogTitle>New temporary password</DialogTitle>
                    <div className={classes.generatePasswordDialogBody}>
                        {generatePasswordResultDialogContent || ""}
                    </div>
                </Dialog>
                {tab === 0 && (
                    <Grid
                        alignItems="stretch"
                        container
                        direction="column"
                        className={classes.bottomSpace}
                    >
                        <Grid item>
                            <SubscriptionActive
                                subscriptions={userForm.subscriptions || []}
                                currentPlan={userForm.plan}
                                isLoading={isLoadUserFetching}
                                isAddSubscriptionFetching={isAddSubscriptionFetching}
                                isMigrateFromBrainTreeFetching={isMigrateFromBrainTreeFetching}
                                isCancelSubscriptionFetching={isCancelSubscriptionFetching}
                                onAddSubscription={handleAddSubscription}
                                onCancelSubscription={handleCancelSubscription}
                                onMigrateFromBrainTree={handleMigrateFromBrainTree}
                            />
                        </Grid>
                        {isLoadUserFetching === false && (
                            <Grid item>
                                <Typography
                                    className={classes.subtitle}
                                    color="inherit"
                                    variant="subtitle1"
                                    component="div"
                                >
                                    {userForm.subscriptions && userForm.subscriptions.length > 0
                                        ? "History"
                                        : "No subscriptions"}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item>
                            <SubscriptionList
                                subscriptions={userForm.subscriptions || []}
                                isLoading={isLoadUserFetching || isSyncSubscriptionFetching}
                                onSyncSubscription={handleSyncSubscription}
                            />
                        </Grid>
                    </Grid>
                )}
                {tab === 1 && (
                    <DeviceList
                        isLoading={isLoading || isResetDeviceFetching}
                        devices={userForm.devices || []}
                        onReset={handleResetDevice}
                    />
                )}
                {tab === 2 && (
                    <>
                        <Grid container direction="column" className={classes.bottomSpace}>
                            <Grid item>
                                <Typography
                                    className={classes.subtitle}
                                    color="inherit"
                                    variant="subtitle1"
                                    component="div"
                                >
                                    Invite Сodes
                                </Typography>
                            </Grid>

                            <Grid item>
                                <InviteCodeList
                                    currentUserId={userForm.id}
                                    isLoading={isLoading}
                                    items={userForm.codes || []}
                                />
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Typography
                                className={classes.subtitle}
                                color="inherit"
                                variant="subtitle1"
                                component="div"
                            >
                                Applied Invite Сodes
                            </Typography>
                            <InviteCodeList
                                currentUserId={userForm.id}
                                isLoading={isLoading}
                                items={userForm.used_codes || []}
                            />
                        </Grid>
                    </>
                )}
                {tab === 3 && <BrainTreeInfo customerId={userForm.customer_id} />}
                {tab === 4 && <MandrillSearch />}
                {tab === 5 && <UserLoginList items={userForm.logins || []} />}
                {tab === 6 && <EventsList items={userForm.events || []} />}
                {tab === 7 && (
                    <>
                        <Grid item>
                            <Paper className={classes.editForm}>
                                <UserEditForm
                                    editMode={isEditMode}
                                    isLoading={isAddNewUserFetching}
                                    {...userForm}
                                    onChange={handleUserFieldChange}
                                />
                            </Paper>
                        </Grid>

                        <Grid container justify="space-between" alignItems="center">
                            {isEditMode && (
                                <Grid item>
                                    <Button onClick={handleGeneratePassword}>
                                        Generate temporary password
                                    </Button>
                                </Grid>
                            )}
                            <Grid item>
                                <IconButton
                                    className={classes.saveBtn}
                                    onClick={handleSubmit}
                                    disabled={isAddNewUserFetching}
                                >
                                    <SaveIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
};

function mapStateToProps({ userForm, state }) {
    return {
        userForm: userForm,
        isAddNewUserFetching: state.addNewUserFetching,
        isUpdateUserFetching: state.updateUserFetching,
        isLoadUserFetching: state.loadUserFetching,
        isMigrateFromBrainTreeFetching: state.migrateFormBrainTreeFetching,
        isSyncSubscriptionFetching: state.syncSubscriptionFetching,
        isCancelSubscriptionFetching: state.cancelSubscriptionFetching,
        isAddSubscriptionFetching: state.addSubscriptionFetching,
        isResetDeviceFetching: state.resetDeviceFetching
    };
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(usersActions, dispatch),
        push: (url) => {
            dispatch(push(url));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashBoardUsersEdit));
