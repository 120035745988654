import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as codesActions from "../../actions/codes";
import { Paper, Button, Grid, Input, Typography, InputLabel, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const stepsDescription = {
    1: " 1: Check invite codes presence in the database.",
    2: " 2: Select invite code condition and generate them.",
    3: " 3: Done."
};

const CodesGenerateFromList = ({
    codesActions,
    isCheckCodesPresenceFetching,
    isGenerateCodesFromListFetching
}) => {
    const classes = useStyles();
    const [codes, setcodes] = React.useState("");
    const [presenceResponse, setPresenceResponse] = React.useState({
        absentCodes: [],
        existingCodes: [],
        existingCodesCount: 0,
        inputCodesCount: 0
    });
    const [step, setStep] = React.useState(1);
    const [comment, setComment] = React.useState("");
    const [conditionId, setConditionId] = React.useState();
    const [expireDays, setExpireDays] = React.useState();

    const handleCheckClick = () => {
        codesActions.checkCodesPresence(codes, (err, presenceResponseData) => {
            if (!err) {
                setStep(2);
                setPresenceResponse(presenceResponseData);
            }
        });
    };

    const handleGenerateClick = () => {
        codesActions.generateCodesFromList(
            presenceResponse.absentCodes.join("\n"),
            conditionId,
            comment,
            expireDays,
            (err) => {
                if (!err) {
                    setStep(3);
                }
            }
        );
    };

    return (
        <Paper className={classes.root}>
            <Grid container spacing={2} direction="column" justify="flex-start">
                <Grid spacing={4} item>
                    <Typography variant="h5">Step{stepsDescription[step]}</Typography>
                </Grid>
                {step === 1 && (
                    <>
                        <Grid spacing={4} item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCheckClick}
                                disabled={!codes || isCheckCodesPresenceFetching}
                            >
                                Check codes presence
                            </Button>
                        </Grid>
                        <Grid item>
                            <Input
                                value={codes}
                                onChange={(e) => setcodes(e.target.value)}
                                multiline
                                placeholder="Insert 1 code per line"
                            />
                        </Grid>
                    </>
                )}
                {step === 2 && (
                    <>
                        <Grid spacing={2} item>
                            <Box mb={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleGenerateClick}
                                    disabled={isGenerateCodesFromListFetching}
                                >
                                    Generate codes
                                </Button>
                            </Box>
                        </Grid>
                        <Grid container>
                            <Box mb={2}>
                                <Grid item>
                                    Total codes passed: {presenceResponse.inputCodesCount}
                                </Grid>
                                <Grid item>
                                    Present in the database: {presenceResponse.existingCodesCount}
                                </Grid>
                            </Box>
                        </Grid>
                        <Box mb={2}>
                            <Grid container spacing={4}>
                                <Grid item>
                                    <Input
                                        required={true}
                                        value={conditionId}
                                        placeholder="Invite code condition ID"
                                        onChange={(e) => setConditionId(e.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Input
                                        required={true}
                                        value={comment}
                                        placeholder="Comment"
                                        onChange={(e) => setComment(e.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Input
                                        value={expireDays}
                                        placeholder="Expire in days"
                                        onChange={(e) => setExpireDays(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item>
                                <InputLabel htmlFor="codes-to-generate">
                                    Codes to generate
                                </InputLabel>
                                <Input
                                    value={presenceResponse.absentCodes.join("\n")}
                                    multiline
                                    inputProps={{
                                        id: "codes-to-generate",
                                        name: "codes-to-generate"
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <InputLabel htmlFor="existing-codes">
                                    Existing codes (won't be used)
                                </InputLabel>
                                <Input
                                    value={presenceResponse.existingCodes.join("\n")}
                                    multiline
                                    inputProps={{
                                        id: "existing-codes",
                                        name: "existing-codes"
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </Paper>
    );
};

function mapStateToProps({ state }) {
    return {
        isCheckCodesPresenceFetching: state.checkCodesPresenceFetching,
        isGenerateCodesFromListFetching: state.generateCodesFromListFetching
    };
}

function mapDispatchToProps(dispatch) {
    return {
        codesActions: bindActionCreators(codesActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CodesGenerateFromList);
