import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import JSONPretty from "react-json-pretty";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import CloseIcon from "@material-ui/icons/Close";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    formWrapper: {
        padding: 15,
        flexGrow: 1,
        height: "70vh",
        overflow: "auto",
        position: "relative"
    },
    viewJson: {
        position: "absolute",
        right: 0,
        top: 0
    },
    editor: {
        width: "100%"
    }
}));

const JsonEditor = ({ name, placeholder = "", jsonText, onChange, onError, useAdvancedEditor = false }) => {
    const classes = useStyles();
    const [showJsonPreview, setShowParamsJsonPreview] = React.useState(false);

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={showJsonPreview ? 6 : 12}>
                    <Paper className={classes.formWrapper}>
                        {useAdvancedEditor && (
                            <AceEditor
                                placeholder={placeholder}
                                width="100%"
                                height="100%"
                                mode="json"
                                theme="github"
                                value={jsonText}
                                onChange={(value) => {
                                    if (onChange) {
                                        onChange(name, value);
                                    }
                                }}
                                highlightActiveLine={true}
                                name={`editor_${name}`}
                                editorProps={{ $blockScrolling: true }}
                                setOptions={{
                                    useWorker: false
                                }}
                            />
                        )}
                        {!useAdvancedEditor && (
                            <TextField
                                value={jsonText}
                                fullWidth
                                multiline
                                onChange={(e) => {
                                    if (onChange) {
                                        onChange(name, e.target.value);
                                    }
                                }}
                            />
                        )}
                        {!showJsonPreview && !useAdvancedEditor && (
                            <div className={classes.viewJson}>
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        setShowParamsJsonPreview(true);
                                    }}
                                >
                                    <RemoveRedEyeIcon />
                                </IconButton>
                            </div>
                        )}
                    </Paper>
                </Grid>
                {showJsonPreview && (
                    <Grid item xs={6}>
                        <Paper className={classes.formWrapper}>
                            <JSONPretty
                                theme={{
                                    main:
                                        "line-height:1.3;color:#66d9ef;background:#fff;overflow:auto;",
                                    key: "color:#f92672;",
                                    string: "color:#fd971f;",
                                    value: "color:#a6e22e;",
                                    boolean: "color:#ac81fe;"
                                }}
                                id="json-pretty"
                                data={jsonText}
                                onError={(e) => {
                                    if (jsonText && onError) {
                                        onError(name, e.message);
                                    }
                                }}
                            />
                            {showJsonPreview && (
                                <div className={classes.viewJson}>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            setShowParamsJsonPreview(false);
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            )}
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default JsonEditor;
