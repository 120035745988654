import {
    CAMPAIGN_REPORT_UNIQUE_VALUES_SET,
    CAMPAIGN_REPORT_CHART_SET,
    CAMPAIGN_REPORT_CAMPAIGN_SET,
    CAMPAIGN_REPORT_FUNNEL_SET,
    CAMPAIGN_REPORT_FUNNEL_TOTAL_SET,
    CAMPAIGN_REPORT_SUBS_TOTAL_SET,
} from "../constants/campaign";

const initialState = {
    unique_values: {},
    charts: {},
    funnel: [],
    funnel_total: [],
};

export default function campaign(state = initialState, action) {
    switch (action.type) {
        case CAMPAIGN_REPORT_UNIQUE_VALUES_SET:
            return { ...state, unique_values: action.payload };
        case CAMPAIGN_REPORT_CAMPAIGN_SET:
            return { ...state, campaign: action.payload };
        case CAMPAIGN_REPORT_CHART_SET:
            return {
                ...state,
                charts: {
                    ...state.charts,
                    [action.payload.name]: [...action.payload.data],
                },
            };
        case CAMPAIGN_REPORT_FUNNEL_SET:
            return { ...state, funnel: action.payload };
        case CAMPAIGN_REPORT_FUNNEL_TOTAL_SET:
            return { ...state, funnel_total: action.payload };
        case CAMPAIGN_REPORT_SUBS_TOTAL_SET:
            return { ...state, subscriptions_total: action.payload };
        default:
            return state;
    }
}
