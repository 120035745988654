import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as usersActions from "../../actions/users";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, TextField, Button, Grid } from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterList";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        position: "relative",
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    tableWrapper: {
        overflow: "auto"
    },
    spinnerWrapper: {
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "60vh",
        zIndex: "2"
    },
    red: {
        color: "red"
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    }
}));

const UsersFilter = ({ usersFilter, usersActions }) => {
    const classes = useStyles();

    const handleFilterChange = React.useCallback(
        (field, value) => {
            usersActions.setUsersFilter(field, value);
        },
        [usersActions]
    );

    const handleApplyFilterClick = React.useCallback(
        (e) => {
            e.preventDefault();
            usersActions.loadUsers();
        },
        [usersActions]
    );

    const handleResetFilterClick = React.useCallback(
        (e) => {
            e.preventDefault();
            usersActions.resetUsersFilter();
            usersActions.loadUsers();
        },
        [usersActions]
    );

    return (
        <div>
            <Paper className={classes.root}>
                <form onSubmit={handleApplyFilterClick}>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item>
                            <TextField
                                type="search"
                                value={usersFilter.email}
                                fullWidth
                                label="Email"
                                placeholder="user@example.fi"
                                onChange={(e) => {
                                    handleFilterChange("email", e.target.value.trim());
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                type="search"
                                value={usersFilter.affiliate_id}
                                fullWidth
                                label="Affiliate ID"
                                placeholder="3500"
                                onChange={(e) => {
                                    handleFilterChange("affiliate_id", e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                type="search"
                                value={usersFilter.code}
                                fullWidth
                                label="Last used invite code"
                                placeholder="jf8oe9e0"
                                onChange={(e) => {
                                    handleFilterChange("code", e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                type="search"
                                value={usersFilter.brainTreeId}
                                fullWidth
                                label="Subscription ID"
                                placeholder="h8h4j"
                                onChange={(e) => {
                                    handleFilterChange("brainTreeId", e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                type="search"
                                value={usersFilter.customerId}
                                fullWidth
                                label="Customer ID"
                                placeholder="404150229"
                                onChange={(e) => {
                                    handleFilterChange("customerId", e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item container spacing="2">
                            <Grid item>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleApplyFilterClick}
                                >
                                    Apply filter
                                    <FilterIcon className={classes.rightIcon} />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" onClick={handleResetFilterClick}>Reset filter</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </div>
    );
};

function mapStateToProps({ usersFilter, state }) {
    return {
        usersFilter: usersFilter
    };
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(usersActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersFilter);
