import { SET_USERS } from "../constants/user";

const initialState = {
    items: {},
    total: 0
};

export default function user(state = initialState, action) {
    switch (action.type) {
        case SET_USERS:
            return { ...state, items: action.payload.items, total: action.payload.total };
        default:
            return state;
    }
}
