import { SET_STATE, SET_STATE_WITH_KEY } from "../constants/state";

export const setState = (key, value) => {
    return {
        type: SET_STATE,
        payload: {
            key,
            value
        }
    };
};

export const setStateWithKey = (key, innerKey, value) => {
    return {
        type: SET_STATE_WITH_KEY,
        payload: {
            key,
            innerKey,
            value
        }
    };
};
