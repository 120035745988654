import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as codesActions from "../../actions/codes";
import { Paper, Button, Grid, Input, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DiscountOfferInfo from "../DiscountOfferInfo";
import { PROJECT_BC } from "../../constants/lang";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    codeForm: {
        marginBottom: theme.spacing(4)
    }
}));

const CodesCheckInviteCode = ({ codesActions, isCodeCheckFetching }) => {
    const classes = useStyles();
    const [code, setcode] = React.useState("");
    const [result, setresult] = React.useState({});

    const handleSubmit = () => {
        /*eslint-disable*/
        codesActions.checkCode(code, (err, data) => {
            if (!err) {
                setresult(data);
            }
        });
        /*eslint-enable*/
    };

    return (
        <Paper className={classes.root}>
            <Grid
                className={classes.codeForm}
                container
                spacing={2}
                direction="row"
                justify="flex-start"
            >
                <Grid item>
                    <Input
                        title="invite"
                        value={code}
                        onChange={(e) => setcode(e.target.value)}
                        placeholder="sdjkhf3"
                        disabled={isCodeCheckFetching}

                    />
                </Grid>
                <Grid spacing={4} item>
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isCodeCheckFetching}>
                        Check code
                    </Button>
                </Grid>
            </Grid>
            {result && result.id && (
                <Grid container spacing={2} direction="column" justify="flex-start">
                    <Grid spacing={4} item>
                        <Typography variant="h5">Code: {result.code}</Typography>
                    </Grid>

                    <Grid spacing={4} item>
                        <Typography variant="body1">Comment: {result.comment}</Typography>
                    </Grid>

                    <Grid spacing={4} item>
                        <Typography variant="body1">
                            Is redeemed: {result.is_redeemed ? "Yes" : "No"}
                        </Typography>
                    </Grid>
                    {result.redeemed_at && (
                        <Grid spacing={4} item>
                            <Typography variant="body1">
                                Redeemed at: {result.redeemed_at}
                            </Typography>
                        </Grid>
                    )}
                    {result.expires_at && (
                        <Grid spacing={4} item>
                            <Typography variant="body1">Expires at: {result.expires_at}</Typography>
                        </Grid>
                    )}
                    {result.is_redeemed && (
                        <Grid spacing={4} item>
                            <Typography variant="body1">
                                Redeemed by:{" "}
                                <a
                                    href={`https://${
                                        process.env.REACT_APP_PROJECT_NAME === PROJECT_BC
                                            ? "dashboard.bouncecast.app"
                                            : "dashboard.cloudbounce.com"
                                    }/dashboard/users/edit/${result.user_id}`}
                                >
                                    {result.email}
                                </a>
                            </Typography>
                        </Grid>
                    )}
                    {result.discount_condition && (
                        <Grid spacing={4} item>
                            <DiscountOfferInfo offer={result.discount_condition.offer} />
                        </Grid>
                    )}
                </Grid>
            )}
        </Paper>
    );
};

function mapStateToProps({ state }) {
    return {
        isCodeCheckFetching: state.checkCodesFetching
    };
}

function mapDispatchToProps(dispatch) {
    return {
        codesActions: bindActionCreators(codesActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CodesCheckInviteCode);
