import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as emailActions from "../../actions/email";
import { Paper, Button, Grid, Input, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    codeForm: {
        marginBottom: theme.spacing(4),
    },
}));

const CheckRenewEmail = ({ emailActions, isFetching }) => {
    const classes = useStyles();
    const [email, setemail] = React.useState("");
    const [result, setresult] = React.useState({});

    const handleSubmit = () => {
        /*eslint-disable*/
        emailActions.checkRenewEmail(email, (err, data) => {
            if (!err) {
                setresult(data);
            }
        });
        /*eslint-enable*/
    };

    return (
        <Paper className={classes.root}>
            <Grid
                className={classes.codeForm}
                container
                spacing={2}
                direction="row"
                justify="flex-start"
            >
                <Grid item>
                    <Input
                        title="email"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                        placeholder="example@gmail.com"
                        disabled={isFetching}
                    />
                </Grid>
                <Grid spacing={4} item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={isFetching}
                    >
                        Check email
                    </Button>
                </Grid>
            </Grid>
            {result && result.length === 0 && (
                <Typography variant="h5">Not found any records</Typography>
            )}
            {result && result.length > 0 && (
                <Grid
                    container
                    spacing={2}
                    direction="column"
                    justify="flex-start"
                >
                    {result.map((record, i) => (
                        <Grid key={i} spacing={4} item>
                            <Typography variant="h5">
                                {record.created_at} {"next bill amount: "}
                                {record.data.nextBillAmount}$
                                {"; subscription ID: "}
                                {record.data.subscriptionId}
                                {"; next billing date: "}
                                {record.data.nextBillingDate}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Paper>
    );
};

function mapStateToProps({ state }) {
    return {
        isFetching: state.checkRenewEmailFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        emailActions: bindActionCreators(emailActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckRenewEmail);
