import React from "react";
import Link from "@material-ui/icons/FileCopy";
import { IconButton, Tooltip } from "@material-ui/core";
import { triggerMessage } from "../../utils/events";

export default function Clipboard({
    className,
    title = "",
    successMessage = "Copied to clipboard",
    value = ""
}) {
    const handleClick = React.useCallback(() => {
        navigator.clipboard.writeText(value).then(
            () => {
                triggerMessage(successMessage, "success");
            },
            (err) => {
                triggerMessage("Ooops! not copied!", "error");
            }
        );
    }, [successMessage, value]);

    return (
            <Tooltip title={title || "Copy to clipboard"} className={className}>
                <IconButton onClick={handleClick}>
                    <Link style={{ cursor: "pointer" }} />
                </IconButton>
            </Tooltip>
    );
}
