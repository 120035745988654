import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DoneIcon from "@material-ui/icons/Done";
import { Fab, Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { shallowCompare } from "../../../utils";

const useCustomFilterWindowStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 100,
        backgroundColor: "rgba(255, 255, 255, .8)",
        filter: "none",
        borderRadius: "5px"
    },
    body: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        width: "100%",
        height: "100%",
        borderRadius: "5px",
        marginTop: theme.spacing(6)
    },
    filterItem: {
        fontSize: 12
    },
    formControl: {
        margin: theme.spacing(3)
    },
    close: {
        position: "absolute",
        zIndex: 2,
        backgroundColor: "green",
        right: "16px",
        top: "-40px"
    },
    help: {
        position: "absolute"
    },
    filterLabel: {
        marginRight: theme.spacing(1)
    },
    dateInput: {
        marginRight: theme.spacing(1)
    }
}));

// filter window
const FilterCustomWindow = React.memo(({ filterName, items, onClose }) => {
    const styles = useCustomFilterWindowStyles();

    const [filterDataString, setFilterDataString] = React.useState("{}");
    const [filterDataJson, setfilterDataJson] = React.useState(JSON.parse(filterDataString));

    React.useEffect(() => {
        const localStorage = window.localStorage;
        setFilterDataString(localStorage.getItem(filterName) || "{}");
    }, [filterName]);

    React.useEffect(() => {
        const updatedFilterDataJson = { ...filterDataJson };
        const localStorage = window.localStorage;
        for (const filterItem of items) {
            if (!filterDataJson[filterItem.name]) {
                // there is no item. add that
                if (filterItem.type === "check_box") {
                    updatedFilterDataJson[filterItem.name] = "0";
                }
                if (filterItem.type === "date") {
                    updatedFilterDataJson[filterItem.name] = new Date().toISOString().substr(0, 10);
                }
            }
        }

        // remove outdated saved filters
        for (const savedFilterName of Object.keys(filterDataJson)) {
            if (!items.find((item) => item.name === savedFilterName)) {
                delete updatedFilterDataJson[savedFilterName];
            }
        }

        localStorage.setItem(filterName, JSON.stringify(updatedFilterDataJson));
        if (!shallowCompare(filterDataJson, updatedFilterDataJson)) {
            setfilterDataJson(updatedFilterDataJson);
        }
    }, [items, filterName, filterDataJson]);

    const handleCheckBoxFilterClick = React.useCallback(
        (currentFilterName) => {
            const localStorage = window.localStorage;
            if (filterDataJson[currentFilterName]) {
                localStorage.setItem(filterName, JSON.stringify(filterDataJson));
                setfilterDataJson({
                    ...filterDataJson,
                    [currentFilterName]: filterDataJson[currentFilterName] === "1" ? "0" : "1"
                });
            }
        },
        [filterDataJson, filterName]
    );

    const handleDateRangeFilterClick = React.useCallback(
        (currentFilterName, value) => {
            const localStorage = window.localStorage;
            if (filterDataJson[currentFilterName]) {
                localStorage.setItem(filterName, JSON.stringify(filterDataJson));
                try {
                    setfilterDataJson({
                        ...filterDataJson,
                        [currentFilterName]:
                            !value || value === "0"
                                ? new Date().toISOString().substr(0, 10)
                                : new Date(value).toISOString().substr(0, 10)
                    });
                } catch (e) {
                    // TODO
                }
            }
        },
        [filterDataJson, filterName]
    );

    // map filter names onto object
    const itemsMap = React.useMemo(
        () =>
            items.reduce((acc, row) => {
                acc[row.name] = row;

                return acc;
            }, {}),
        [items]
    );

    return (
        <div className={styles.root}>
            <div className={styles.body}>
                <Fab
                    className={styles.close}
                    size="small"
                    variant="round"
                    color="primary"
                    onClick={(e) => {
                        if (onClose) {
                            onClose(e);
                        }
                    }}
                >
                    <DoneIcon />
                </Fab>
                <FormControl component="fieldset" className={styles.formControl}>
                    <FormGroup>
                        {Object.keys(filterDataJson).map((filterName) => (
                            <>
                                {itemsMap[filterName] && (
                                    <FormControlLabel
                                        key={itemsMap[filterName].name}
                                        control={
                                            <>
                                                {itemsMap[filterName].type === "check_box" && (
                                                    <Checkbox
                                                        checked={filterDataJson[filterName] === "1"}
                                                        onChange={() =>
                                                            handleCheckBoxFilterClick(
                                                                itemsMap[filterName].name
                                                            )
                                                        }
                                                        name={itemsMap[filterName].name}
                                                    />
                                                )}
                                                {itemsMap[filterName].type === "date" && (
                                                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                                                        <KeyboardDatePicker
                                                            contentEditable={false}
                                                            className={styles.dateInput}
                                                            disableToolbar
                                                            variant="inline"
                                                            format="MM/dd/yyyy"
                                                            margin="normal"
                                                            id="date-picker-inline"
                                                            label={itemsMap[filterName].title}
                                                            value={
                                                                filterDataJson[filterName]
                                                                    ? new Date(
                                                                          filterDataJson[filterName]
                                                                      )
                                                                    : new Date()
                                                            }
                                                            onChange={(value) => {
                                                                handleDateRangeFilterClick(
                                                                    itemsMap[filterName].name,
                                                                    value
                                                                );
                                                            }}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date"
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                )}
                                            </>
                                        }
                                        label={
                                            <>
                                                {!itemsMap[filterName].hideLabel && (
                                                    <span className={styles.filterLabel}>
                                                        {itemsMap[filterName].title}
                                                    </span>
                                                )}
                                                {itemsMap[filterName].hint && (
                                                    <Tooltip
                                                        title={itemsMap[filterName].hint}
                                                        aria-label={`Hint for ${itemsMap[filterName].title}`}
                                                        className={styles.help}
                                                    >
                                                        <HelpIcon />
                                                    </Tooltip>
                                                )}
                                            </>
                                        }
                                    />
                                )}
                            </>
                        ))}
                    </FormGroup>
                </FormControl>
            </div>
        </div>
    );
});

export default FilterCustomWindow;
