import React from "react";
import { Grid, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import JSONPretty from "react-json-pretty";

const useStyles = makeStyles((theme) => ({
    eventsTable: {
        width: "100%"
    },
    eventsAction: {
        padding: theme.spacing(2)
    },
    eventsCancelBtn: {
        backgroundColor: red[500],
        color: "white",
        "&:hover": {
            backgroundColor: red[800]
        }
    },
    eventsAddBtn: {
        backgroundColor: green[500],
        color: "white",
        width: 200,
        "&:hover": {
            backgroundColor: green[800]
        }
    }
}));

export default function EventsList({ items = [] }) {
    const classes = useStyles();
    return (
        <>
            <Grid container spacing={0} alignItems="stretch" direction="column">
                <Grid item>
                    <div className={classes.eventsAction}>
                        <Grid container spacing={1} justify="flex-end">
                            <Grid item md={2}></Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item>
                    <div className={classes.eventsAction}>
                        Events are tracking since July 10 2020
                    </div>
                </Grid>
                {items && items.length > 0 && (
                    <>
                        <Grid item>
                            <Paper className={classes.eventsRoot}>
                                <Table aria-label="events" className={classes.eventsTable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Name</TableCell>
                                            <TableCell align="center">Data</TableCell>
                                            <TableCell align="center">Created at</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((row, i) => (
                                            <TableRow key={`device${i}`}>
                                                <TableCell align="center">
                                                    {row.name || ""}
                                                </TableCell>
                                                <TableCell>
                                                    <JSONPretty
                                                        theme={{
                                                            main:
                                                                "line-height:1.3;color:#66d9ef;background:#fff;overflow:auto;",
                                                            key: "color:#f92672;",
                                                            string: "color:#fd971f;",
                                                            value: "color:#a6e22e;",
                                                            boolean: "color:#ac81fe;"
                                                        }}
                                                        id="json-pretty"
                                                        data={row.data || {}}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.created_at}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
}
