export const SET_DISCOUNTS = "SET_DISCOUNTS";
export const DISCOUNT_FORM_SET = "DISCOUNT_FORM_SET";
export const DISCOUNT_FORM_SET_INVITE_CODE_CONDITION = "DISCOUNT_FORM_SET_INVITE_CODE_CONDITION";
export const DISCOUNT_FORM_RESET = "DISCOUNT_FORM_RESET";
export const DISCOUNT_FROM_SET_FIELD = "DISCOUNT_FROM_SET_FIELD";
export const DISCOUNT_FROM_RESET = "DISCOUNT_FROM_RESET";

export const DISCOUNTS_PAGE_LIMIT = 25;

// discount types
export const DISCOUNT_TYPE_GIFT = "gift";
export const DISCOUNT_TYPE_DISCOUNT_SUBSCRIPTION = "discount-subscription";
export const DISCOUNT_TYPE_DISCOUNT_ONE_TIME_PAYMENT = "discount-one-time-payment";
