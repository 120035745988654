import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumbs, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: 15
    }
}));

export default function PageTitle({ title, variant = "h4", breadcrumbs = [] }) {
    const classes = useStyles();

    const handleBreadcrumbsClick = React.useCallback(() => {}, []);

    return (
        <Grid>
            <Grid>
                {" "}
                <Typography
                    className={classes.title}
                    component="h1"
                    variant={variant}
                    color="inherit"
                    noWrap
                >
                    {title}
                </Typography>
            </Grid>
            <Grid>
                {" "}
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    {breadcrumbs.map((data, i) => {
                        return data.url ? (
                            <Link
                                key={i}
                                color="inherit"
                                to={data.url || "/"}
                                onClick={handleBreadcrumbsClick}
                            >
                                {data.title || "-"}
                            </Link>
                        ) : (
                            <Typography key={i} color="textPrimary">{data.title || "-"}</Typography>
                        );
                    })}
                </Breadcrumbs>
            </Grid>
        </Grid>
    );
}
