import Api, { resetInstance } from "../services/api";
import { setState } from "../actions/state";
import {
    TEST_CHAINS_SET_PARAMS,
    TEST_CHAINS_SET_ERRORS,
    TEST_CHAINS_SET_CHAINS,
    TEST_CHAINS_SET_RESULT,
    TEST_CHAINS_SET_SPECIAL_PROCESSORS
} from "../constants/testChains";

export const resetErrors = () => async (dispatch, getState) => {
    return dispatch({
        type: TEST_CHAINS_SET_ERRORS,
        payload: {}
    });
};

export const loadCurrentChainsForTest = () => async (dispatch, getState) => {
    try {
        dispatch(setState("loadCurrentChainsFetching", true));
        const { data: apiResponse } = await Api().get(`/admin/data/chains`);
        dispatch(setState("loadCurrentChainsFetching", false));

        if (apiResponse.data.chains) {
            dispatch({
                type: TEST_CHAINS_SET_CHAINS,
                payload: JSON.stringify(apiResponse.data, null, 4)
            });
        }
    } catch (e) {
        dispatch(setState("loadCurrentChainsFetching", false));
    }
};

export const loadCurrentSpecialProcessorsForTest = () => async (dispatch, getState) => {
    try {
        dispatch(setState("loadCurrentSpecialProcessorsFetching", true));
        const { data: apiResponse } = await Api().get(`/admin/data/special_processors`);
        dispatch(setState("loadCurrentSpecialProcessorsFetching", false));

        if (apiResponse.data.special_processors) {
            dispatch({
                type: TEST_CHAINS_SET_SPECIAL_PROCESSORS,
                payload: JSON.stringify(apiResponse.data, null, 4)
            });
        }
    } catch (e) {
        dispatch(setState("loadCurrentSpecialProcessorsFetching", false));
    }
};

export const loadParamsAsMeasuresForTest = () => async (dispatch, getState) => {
    try {
        dispatch(setState("loadParamsAsMeasuresForTestFetching", true));
        const { data: apiResponse } = await Api().get(`/admin/data/params`);
        dispatch(setState("loadParamsAsMeasuresForTestFetching", false));

        if (apiResponse.data.params) {
            let result = [];
            for (const param of apiResponse.data.params) {
                const o = {};
                o[param] = "0.0";
                result.push(o);
            }

            dispatch({
                type: TEST_CHAINS_SET_PARAMS,
                payload: JSON.stringify({ measures: result }, null, 4)
            });
        }
    } catch (e) {
        dispatch(setState("loadParamsAsMeasuresForTestFetching", false));
    }
};

export const testChains = (onSuccess) => async (dispatch, getState) => {
    let paramsObj = "";
    let chainsObj = "";
    let specailProcessorsObj = "";

    const parsingErrors = {};

    try {
        paramsObj = JSON.parse(getState().testChains.params);
        if (!paramsObj.measures) {
            throw new Error("No mesures prop!");
        }
    } catch (e) {
        parsingErrors["params"] = `Params parsing error: ${e.message}`;
    }

    try {
        chainsObj = JSON.parse(getState().testChains.chains);
        if (!chainsObj.chains) {
            throw new Error("No chains prop!");
        }
    } catch (e) {
        parsingErrors["chains"] = `Chains parsing error: ${e.message}`;
    }

    try {
        specailProcessorsObj = JSON.parse(getState().testChains.special_processors);
        if (!specailProcessorsObj.special_processors) {
            throw new Error("No special_processors prop!");
        }
    } catch (e) {
        parsingErrors["special_processors"] = `Special processors parsing error: ${e.message}`;
    }

    if (Object.keys(parsingErrors).length > 0) {
        dispatch({
            type: TEST_CHAINS_SET_ERRORS,
            payload: parsingErrors
        });
        return;
    }

    dispatch(setState("testChainsFetching", true));
    try {
        const requestData = {
            measures: paramsObj.measures,
            chains: chainsObj.chains,
            special_processors: specailProcessorsObj.special_processors
        };
        const requestBody = JSON.stringify(requestData);

        resetInstance();

        const { data: apiResponse } = await Api({
            headers: {
                "Content-Type": "text/plain"
            }
        }).post("/admin/chains/check", requestBody);
        dispatch(setState("testChainsFetching", false));
        dispatch({
            type: TEST_CHAINS_SET_RESULT,
            payload: {
                processed: JSON.stringify({
                    chains: apiResponse.data.processed_chains,
                    special_processors: apiResponse.data.processed_special_processors
                }),
                processed_with_expressions: JSON.stringify({
                    chains: apiResponse.data.processed_chains_with_expressions,
                    special_processors:
                        apiResponse.data.processed_special_processors_with_expressions
                })
            }
        });
        if (typeof onSuccess === "function") {
            onSuccess();
        }
    } catch (e) {
        dispatch(setState("testChainsFetching", false));
        const errors = (e.response && e.response.data && e.response.data.data) || "";
        dispatch({
            type: TEST_CHAINS_SET_ERRORS,
            payload: { params: errors || e.message }
        });
    }
};

export const setParams = params => {
    return {
        type: TEST_CHAINS_SET_PARAMS,
        payload: params
    };
};

export const setChains = chains => {
    return {
        type: TEST_CHAINS_SET_CHAINS,
        payload: chains
    };
};

export const setSpecialProcessors = processors => {
    return {
        type: TEST_CHAINS_SET_SPECIAL_PROCESSORS,
        payload: processors
    };
};
