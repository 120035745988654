import React from "react";
import { Grid, FormControlLabel, Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        padding: "10px 0"
    }
}));

const JsonEditorPanel = ({
    name,
    jsonText,
    onChange,
    onError,
    onUseAdvancedEditorChange,
    onUseFloatingResultWindow,
    showFloatingSwithcer = false
}) => {
    const classes = useStyles();
    const localStorage = window.localStorage;
    const [useAdvancedEditor, setUseAdvancedEditor] = React.useState(
        localStorage.getItem("advanced_editor") === "1"
    );
    const [useFloatingResultWindow, setUseFloatingResultWindow] = React.useState(
        localStorage.getItem("floating_result_window") === "1"
    );

    const handleUseAdvancedEditor = (value) => {
        if (value) {
            localStorage.setItem("advanced_editor", "1");
            setUseAdvancedEditor(true);
            if (onUseAdvancedEditorChange) {
                onUseAdvancedEditorChange(true);
            }
        } else {
            localStorage.removeItem("advanced_editor");
            setUseAdvancedEditor(false);
            if (onUseAdvancedEditorChange) {
                onUseAdvancedEditorChange(false);
            }
        }
    };

    const handleUseFloatingResultWindow = (value) => {
        if (value) {
            localStorage.setItem("floating_result_window", "1");
            setUseFloatingResultWindow(true);
            if (onUseFloatingResultWindow) {
                onUseFloatingResultWindow(true);
            }
        } else {
            localStorage.removeItem("floating_result_window");
            setUseFloatingResultWindow(false);
            if (onUseFloatingResultWindow) {
                onUseFloatingResultWindow(false);
            }
        }
    };

    return (
        <Grid spacing={1} className={classes.root} container>
            <Grid>
                <FormControlLabel
                    control={
                        <Switch
                            checked={useAdvancedEditor}
                            onChange={(e) => {
                                handleUseAdvancedEditor(e.target.checked);
                            }}
                            name="advancedEditor"
                        />
                    }
                    label="Use advanced editor"
                />
            </Grid>
            {showFloatingSwithcer && (
                <Grid>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={useFloatingResultWindow}
                                name="floatingResultWindow"
                                onChange={(e) => {
                                    handleUseFloatingResultWindow(e.target.checked);
                                }}
                            />
                        }
                        label="Floating result window"
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default JsonEditorPanel;
