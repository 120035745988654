import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as usersActions from "../../actions/users";
import { Grid, Paper, Typography, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import InfoTable from "../InfoTable";
import { camelCaseToWords } from "../../utils";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    inviteCodesAction: {
        padding: theme.spacing(2)
    },
    link: {
        textDecoration: "underline"
    },
    loadButton: {
        backgroundColor: green[500],
        color: "white"
    },
    infoTable: {
        marginBottom: theme.spacing(2)
    },
    subPaper: {
        padding: theme.spacing(4)
    },
    scrollable: {
        overflowX: "scroll",
        height: 400,
        border: `1px solid ${green[500]}`,
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        borderRadius: 5
    },
    subTitle: {
        padding: theme.spacing(2),
        fontWeight: 700,
        fontSize: 18
    },
    spinnerWrapper: {
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80vh",
        zIndex: "2"
    }
}));

const makeRows = (data, header) => {
    let result = [];
    for (const prop in data) {
        if (typeof data[prop] === "string") {
            result.push([camelCaseToWords(prop), data[prop]]);
        }
    }

    return {
        header: header,
        rows: result
    };
};

const RenderPaymentMethods = ({ paymentMethods }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.subPaper}>
            <Typography className={classes.subTitle} variant="h3" component="h3">
                Payment methods:
            </Typography>
            {paymentMethods.map((pm, pmi) => {
                return (
                    <Paper key={`pm_${pmi}`} className={classes.subPaper}>
                        <Typography className={classes.subTitle} component="div">
                            Method: {pmi + 1}
                        </Typography>

                        <InfoTable title="Payment method data" data={makeRows(pm, ["", ""])} />

                        {pm.subscriptions && pm.subscriptions.length > 0 && (
                            <div className={classes.scrollable}>
                                <Typography
                                    className={classes.subTitle}
                                    variant="h4"
                                    component="h4"
                                >
                                    Subscriptions:
                                </Typography>
                                {pm.subscriptions.map((sub, i) => {
                                    return (
                                        <div key={`sub_${i}`} className={classes.infoTable}>
                                            <InfoTable
                                                title={`Subscription ID: ${sub.id}`}
                                                data={makeRows(sub, ["", ""])}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </Paper>
                );
            })}
        </Paper>
    );
};

const BrainTreeInfo = ({ isLoading, customerId, usersActions, data = {} }) => {
    React.useEffect(() => {
        if (customerId) {
            usersActions.loadBrainTreeInfo(customerId);
        }
    }, [usersActions, customerId]);

    const classes = useStyles();
    return (
        <>
            <Grid container spacing={0} alignItems="stretch" direction="column">
                <Grid item>
                    {isLoading && (
                        <div className={classes.spinnerWrapper}>
                            <CircularProgress size={60} />
                        </div>
                    )}
                </Grid>
                {customerId && data.id && (
                    <Grid container spacing={0} alignItems="stretch" direction="column">
                        <Grid item className={classes.infoTable}>
                            <InfoTable
                                title="Common BrainTree data"
                                data={makeRows(data, ["", ""])}
                            />
                        </Grid>
                        {data.customFields && (
                            <Grid item className={classes.infoTable}>
                                <InfoTable
                                    title="Custom fields"
                                    data={makeRows(data.customFields, ["", ""])}
                                />
                            </Grid>
                        )}
                        {data.paymentMethods && data.paymentMethods.length > 0 && (
                            <Grid item className={classes.infoTable}>
                                <RenderPaymentMethods paymentMethods={data.paymentMethods} />
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        </>
    );
};

function mapStateToProps({ braintree, state }) {
    return {
        data: braintree.data,
        isLoading: state.loadBrainTree
    };
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(usersActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BrainTreeInfo);
