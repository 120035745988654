import { DATA_SET, DATA_SET_ERRORS } from "../constants/data";

const defaultState = {
    params: "",
    chains: "",
    messages: "",
    soundcheck: "",
    soundcheck_clap: "",
    special_processors: "",
    errors: {}
};

export default function data(state = defaultState, action) {
    switch (action.type) {
        case DATA_SET:
            if (action.payload.name) {
                return { ...state, [action.payload.name]: action.payload.value };
            }

            return { ...state };
        case DATA_SET_ERRORS:
            return { ...state, errors: action.payload };
        default:
            return state;
    }
}
