import {
    QUIZ_OPTION_ADD_EMPTY,
    QUIZ_OPTION_REMOVE,
    QUIZ_OPTION_UPDATE,
    QUIZ_RESET,
    QUIZ_SET,
    QUIZ_UPDATE_FIELD
} from "../constants/quiz";

const initialState = {
    name: "",
    is_active: false,
    vote_once: false,
    title: { en: "" },
    type: "basic",
    QuizOptions: []
};

export default function quiz(state = initialState, action) {
    switch (action.type) {
        case QUIZ_SET:
            return { ...state, ...action.payload };
        case QUIZ_OPTION_UPDATE:
            return {
                ...state,
                QuizOptions: [
                    ...state.QuizOptions.map((option) => {
                        if (option.id === action.payload.optionId) {
                            return { ...option, [action.payload.fieldName]: action.payload.value };
                        } else {
                            return option;
                        }
                    })
                ]
            };
        case QUIZ_UPDATE_FIELD:
            return { ...state, [action.payload.fieldName]: action.payload.value };
        case QUIZ_OPTION_ADD_EMPTY:
            return {
                ...state,
                QuizOptions: [
                    ...state.QuizOptions,
                    {
                        id: action.payload.optionId,
                        is_active: false,
                        is_custom: false,
                        text: { en: "" }
                    }
                ]
            };
        case QUIZ_OPTION_REMOVE: {
            if (action.payload.optionId) {
                return {
                    ...state,
                    QuizOptions: [
                        ...state.QuizOptions.map((option) => {
                            if (option.id === action.payload.optionId) {
                                return { ...option, delete_submit: true };
                            } else {
                                return option;
                            }
                        })
                    ]
                };
            }

            return state;
        }
        case QUIZ_RESET:
            return {
                name: "",
                is_active: false,
                vote_once: false,
                title: { en: "" },
                type: "basic",
                QuizOptions: []
            };
        default:
            return state;
    }
}
