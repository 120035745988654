import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as usersActions from "../../actions/users";
import {
    Grid,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Button,
    Tooltip,
    CircularProgress,
    Modal,
    DialogTitle,
    Fab
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green, red, grey } from "@material-ui/core/colors";
import CalendarIcon from "@material-ui/icons/Event";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    mandrillTable: {
        width: "100%"
    },
    mandrillAction: {
        padding: theme.spacing(2)
    },
    loadButton: {
        backgroundColor: green[500],
        color: "white",
        width: 200,
        "&:hover": {
            backgroundColor: green[800]
        }
    },
    stateRejected: {
        display: "inline-block",
        width: 20,
        height: 20,
        borderRadius: 50,
        backgroundColor: red[500],
        boxShadow: "0 3px 6px 1px rgba(0, 0, 0, 0.46)"
    },
    stateSent: {
        display: "inline-block",
        width: 20,
        height: 20,
        borderRadius: 50,
        backgroundColor: green[500],
        boxShadow: "0 3px 6px 1px rgba(0, 0, 0, 0.46)"
    },
    stateDefault: {
        display: "inline-block",
        width: 20,
        height: 20,
        borderRadius: 50,
        backgroundColor: grey[500],
        boxShadow: "0 3px 6px 1px rgba(0, 0, 0, 0.46)"
    },
    spinnerWrapper: {
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80vh",
        zIndex: "2"
    },
    modal: {
        height: "100%",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    modalBody: {
        height: "90%",
        width: "90%",
        padding: theme.spacing(3),
        overflowX: "scroll",
        position: "relative"
    },
    modalCloseBtn: {
        position: "absolute",
        right: 10,
        top: 10,
        zIndex: 2
    },
    modalBodyEmail: {
        maxWidth: 800,
        wordBreak: "break-all",
        "& div": {
            marginBottom: theme.spacing(1)
        }
    }
}));

const getStateClass = (state) => {
    switch (state) {
        case "sent":
            return "stateSent";
        case "rejected":
            return "stateRejected";
        default:
            return "stateDefault";
    }
};

const MandrillSearch = ({
    mandrill = {},
    isSearchLoading,
    isContentLoading,
    email,
    usersActions
}) => {
    const classes = useStyles();
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const handleLoad = React.useCallback(() => {
        usersActions.loadMandrillSearch(email);
    }, [usersActions, email]);

    const handleLoadContent = React.useCallback(
        (id) => {
            usersActions.resetMandrillContent();
            setIsModalOpen(true);
            usersActions.loadMandrillContent(id);
        },
        [usersActions]
    );

    return (
        <>
            <Grid container spacing={0} alignItems="stretch" direction="column">
                <Grid item>
                    <div className={classes.mandrillAction}>
                        <Grid container spacing={1} justify="flex-start" alignItems="center">
                            <Grid item>
                                <Button
                                    className={classes.loadButton}
                                    onClick={handleLoad}
                                    disabled={isSearchLoading}
                                >
                                    Load from Mandrill
                                </Button>
                            </Grid>
                            <Grid item>
                                Will load data for the last 7 days. You can load Mandrill data 20
                                times per minute
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                {isSearchLoading && (
                    <Grid item>
                        <div className={classes.spinnerWrapper}>
                            <CircularProgress size={60} />
                        </div>
                    </Grid>
                )}
                {isSearchLoading === false && mandrill.search && mandrill.search.length > 0 && (
                    <>
                        <Grid item>
                            <Paper className={classes.mandrillRoot}>
                                <Table aria-label="mandrill" className={classes.mandrillTable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Date</TableCell>
                                            <TableCell align="center">Subject</TableCell>
                                            <TableCell align="center">Email</TableCell>
                                            <TableCell align="center">State</TableCell>
                                            <TableCell align="center">Reject reason</TableCell>
                                            <TableCell align="center">Sender</TableCell>
                                            <TableCell align="center">Opens</TableCell>
                                            <TableCell align="center">Clicks</TableCell>
                                            <TableCell align="center"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {mandrill.search.map((row, i) => (
                                            <TableRow key={`mndr${i}`}>
                                                <TableCell align="center">
                                                    <Tooltip
                                                        title={String(new Date(row.ts * 1000))}
                                                    >
                                                        <CalendarIcon />
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.subject || ""}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.email || ""}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title={row.state}>
                                                        <div
                                                            className={
                                                                classes[getStateClass(row.state)]
                                                            }
                                                        ></div>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.reject && row.reject.reason
                                                        ? `${row.reject.reason}${
                                                              row.reject.last_event_at
                                                                  ? `(${row.reject.last_event_at})`
                                                                  : ""
                                                          }`
                                                        : ""}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.sender || ""}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.opens || ""}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.clicks || ""}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton
                                                        onClick={() => {
                                                            handleLoadContent(row._id);
                                                        }}
                                                    >
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </>
                )}
            </Grid>
            <Modal
                className={classes.modal}
                open={isModalOpen}
                aria-labelledby="Discounts"
                aria-describedby="Discounts"
            >
                <Paper className={classes.modalBody}>
                    <Grid container direction="column">
                        <Grid item>
                            <DialogTitle className={classes.modalTitle}>Mail content</DialogTitle>
                            <Fab
                                className={classes.modalCloseBtn}
                                variant="round"
                                color="primary"
                                onClick={() => {
                                    setIsModalOpen(false);
                                }}
                            >
                                <CloseIcon />
                            </Fab>
                        </Grid>
                        {isContentLoading && (
                            <Grid item>
                                <div className={classes.spinnerWrapper}>
                                    <CircularProgress size={60} />
                                </div>
                            </Grid>
                        )}
                        {isContentLoading === false && (
                            <Grid item >
                                <Grid container direction="column" className={classes.modalBodyEmail}>
                                    <Grid item>Date: {String(new Date())}</Grid>
                                    <Grid item>To: {(mandrill.content.to && mandrill.content.to.email) || ""}</Grid>
                                    <Grid item>From email: {mandrill.content.from_email || ""}</Grid>
                                    <Grid item>Subject: {mandrill.content.subject || ""}</Grid>
                                    <Grid item>Text: {mandrill.content.text || ""}</Grid>
                                    <Grid item>html: {mandrill.content.html || ""}</Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Modal>
        </>
    );
};

function mapStateToProps({ userForm, state, mandrill }) {
    return {
        mandrill,
        email: userForm.email,
        isSearchLoading: state.loadMandrillSearch,
        isContentLoading: state.loadMandrillContent
    };
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(usersActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MandrillSearch);
