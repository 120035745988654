import { HUMAN_PLAN_NAMES } from "../constants/payments";

export const camelCaseToWords = (str) => {
    if (!str) {
        return "";
    }

    const spaceStr = str.replace(/([A-Z])/g, " $1");

    return spaceStr.charAt(0).toUpperCase() + spaceStr.slice(1);
};

export const getHumanPlanName = (planName, upperCase = false, isMini = false) => {
    let humanPlanName = HUMAN_PLAN_NAMES[getProjectName()][planName] || "unknown";
    if (isMini) {
        humanPlanName = humanPlanName + " mini";
    }

    return upperCase ? humanPlanName.toUpperCase() : humanPlanName;
};

export const getProjectName = () => {
    return process.env.REACT_APP_PROJECT_NAME;
};

/**
 *
 * @param {string} projectName
 * @returns {bool}
 */
export const isProject = (projectName) => {
    return process.env.REACT_APP_PROJECT_NAME === projectName;
};

export const shallowCompare = (obj1, obj2) =>
    Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).every((key) => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]);
