import {
    SETTINGS_SET,
    SETTINGS_UPDATE_ITEM,
    SETTINGS_SET_APP_VERSIONS
} from "../constants/settings";

const initialState = {
    items: {},
    app: {
        versions: {},
        current: {}
    }
};

export default function ticker(state = initialState, action) {
    switch (action.type) {
        case SETTINGS_SET:
            return { ...state, items: action.payload };
        case SETTINGS_SET_APP_VERSIONS:
            return { ...state, app: action.payload };
        case SETTINGS_UPDATE_ITEM:
            const { group, name, value } = action.payload;

            return {
                ...state,
                items: {
                    ...state["items"],
                    [group]: {
                        ...state["items"][group],
                        [name]: {
                            ...state["items"][group][name],
                            value
                        }
                    }
                }
            };
        default:
            return state;
    }
}
