import Api from "../services/api";
import { setState } from "../actions/state";
import {
    CAMPAIGN_REPORT_UNIQUE_VALUES_SET,
    CAMPAIGN_REPORT_CHART_SET,
    CAMPAIGN_REPORT_CAMPAIGN_SET,
    CAMPAIGN_REPORT_FUNNEL_SET,
    CAMPAIGN_REPORT_FUNNEL_TOTAL_SET,
    CAMPAIGN_REPORT_SUBS_TOTAL_SET,
} from "../constants/campaign";

export const loadCampaignReportData =
    (campaignId) => async (dispatch, getState) => {
        try {
            dispatch(setState("reportDataFetching", true));
            const { data: apiResponse } = await Api().get(
                `admin/campaign/data/${campaignId}`
            );
            dispatch(setState("reportDataFetching", false));

            if (apiResponse.message === "ok") {
                dispatch({
                    type: CAMPAIGN_REPORT_UNIQUE_VALUES_SET,
                    payload: apiResponse.data["unique_values"],
                });
                dispatch({
                    type: CAMPAIGN_REPORT_CAMPAIGN_SET,
                    payload: apiResponse.data["campaign"],
                });
            }
        } catch (e) {
            dispatch(setState("reportDataFetching", false));
        }
    };

export const loadCampaignChart =
    (
        campaignId,
        startDate,
        endDate,
        series,
        field = "utm_campaign",
        whereCampaignFields = "",
        whereSourceFields = "",
        whereMediumFields = "",
        name = "total"
    ) =>
    async (dispatch, getState) => {
        try {
            dispatch(setState("reportFetching", true));
            const { data: apiResponse } = await Api().get(
                `admin/campaign/report/${campaignId}?startDate=${startDate}&endDate=${endDate}&name=${name}&series=${series}&field=${field}&campaign=${whereCampaignFields}&source=${whereSourceFields}&medium=${whereMediumFields}`
            );
            dispatch(setState("reportFetching", false));

            if (apiResponse.message === "ok") {
                dispatch({
                    type: CAMPAIGN_REPORT_CHART_SET,
                    payload: {
                        name,
                        data: apiResponse.data.chart.data,
                    },
                });
                dispatch({
                    type: CAMPAIGN_REPORT_FUNNEL_SET,
                    payload: apiResponse.data.funnel,
                });
                dispatch({
                    type: CAMPAIGN_REPORT_FUNNEL_TOTAL_SET,
                    payload: apiResponse.data.funnel_total,
                });
                dispatch({
                    type: CAMPAIGN_REPORT_SUBS_TOTAL_SET,
                    payload: apiResponse.data.subscriptions_total,
                });
            }
        } catch (e) {
            dispatch(setState("reportFetching", false));
        }
    };
