import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingsActions from "../../actions/settings";
import Typography from "@material-ui/core/Typography";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageTitle from "../../components/PageTitle";
import SaveIcon from "@material-ui/icons/Save";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import SettingsItemGroup from "../../components/SettingsItemGroup";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        position: "relative"
    },
    spinnerWrapper: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: "2"
    },
    header: {
        marginBottom: 15
    },
    formWrapper: {
        padding: 15,
        flexGrow: 1,
        height: "70vh",
        overflow: "auto",
        position: "relative"
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    saveButton: {
        backgroundColor: green[500],
        color: "white",
        width: 200
    },
    saveButtonWraper: {
        display: "flex",
        justifyContent: "flex-end"
    }
}));

const groupHumanNames = {
    version: "App versions",
    promocode: "Promo codes",
    invite_code_charger_script: "Promo code script",
    mail: "Mailing",
    trial: "Trial plan",
    payments: "Payments",
    personal_invite_code_first_mail: "Personalised invite codes. (First email \"small discount\")",
    personal_invite_code_personal_mail: "Personalised invite codes. (Personal email \"bigger discount\")",
    mailing: "Mailer",
    crypto: "Crypto",
};

const DashBoardSettings = ({ items = {}, isFetching, settingsActions }) => {
    const classes = useStyles();

    React.useEffect(() => {
        settingsActions.loadSettings();
    }, [settingsActions]);

    const handleChange = React.useCallback(
        (group, name, value) => {
            settingsActions.updateItem(group, name, value);
        },
        [settingsActions]
    );

    const handleSave = React.useCallback(() => {
        settingsActions.save();
    }, [settingsActions]);

    return (
        <div className={classes.root}>
            {isFetching === true && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <Grid container spacing={0} justify="space-between" className={classes.header}>
                <Grid item xs={6}>
                    <PageTitle title="Settings" />
                    <Typography color="textSecondary">Application settings</Typography>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.saveButtonWraper}>
                        <Button
                            className={classes.saveButton}
                            variant="contained"
                            color="secondary"
                            /* eslint-disable */
                            onClick={() => {
                                if (confirm("Update settings?")) {
                                    handleSave();
                                }
                            }}
                            /* eslint-enable */
                        >
                            Save all
                            <SaveIcon className={classes.rightIcon} />
                        </Button>
                    </div>
                </Grid>
            </Grid>
            {Object.keys(groupHumanNames).map((key, i) => (
                <SettingsItemGroup
                    groupName={groupHumanNames[key] || key}
                    items={items[key]}
                    key={i}
                    onChange={handleChange}
                />
            ))}
        </div>
    );
};

DashBoardSettings.propTypes = {
    items: PropTypes.object,
    isFetching: PropTypes.bool
};

function mapStateToProps({ settings, state }) {
    return {
        isFetching: state.settingsFetching || state.settingsSaveFetching,
        items: settings.items
    };
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashBoardSettings);
