import * as React from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import LoginContainer from "./containers/LoginContainer";
import DashBoardContainer from "./containers/DashBoardContainer";
import Auth from "./services/auth";
import AuthCheckScreen from "./components/AuthCheckScreen";
import Notification from "./components/Notification";

const PrivateRoute = ({
    component: Component,
    isTokenFetching,
    waitingComponent: WaitingComponent,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (isTokenFetching === true || isTokenFetching === undefined) {
                    return WaitingComponent ? (
                        <WaitingComponent />
                    ) : (
                        <div>Checking authorization...</div>
                    );
                } else {
                    return Auth.isAuthenticated ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    );
                }
            }}
        />
    );
};

const Routes = ({ isTokenFetching }) => {
    const [message, setMessage] = React.useState("");
    const [type, setType] = React.useState("info");

    const handleOnMessage = React.useCallback(
        e => {
            if (e && e.detail) {
                setMessage(e.detail.message || "");
                setType(e.detail.type || "info");
            }
        },
        [setType, setMessage]
    );

    React.useEffect(() => {
        document.addEventListener && document.addEventListener("appMessage", handleOnMessage);

        return () => {
            document.removeEventListener &&
                document.removeEventListener("appMessage", handleOnMessage);
        };
    }, [handleOnMessage]);

    return (
        <>
            <Switch>
                <Route exact path="/login" component={LoginContainer} />
                <PrivateRoute
                    path="/dashboard/:page?"
                    isTokenFetching={isTokenFetching}
                    component={DashBoardContainer}
                    waitingComponent={AuthCheckScreen}
                />
                <Redirect from="/" to="/dashboard" />
            </Switch>
            <Notification
                open={Boolean(message)}
                message={message}
                variant={type}
                onClose={() => {
                    setMessage("");
                }}
            />
        </>
    );
};

const mapStateToProps = ({ state }) => ({
    isTokenFetching: state.tokenFetching
});

export default connect(mapStateToProps)(Routes);
