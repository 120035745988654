import Api from "../services/api";
import { setState } from "../actions/state";
import { TICKER_SET, TICKER_ADD_EMPTY, TICKER_UPDATE } from "../constants/ticker";
import { triggerMessage } from "../utils/events";

export const loadTickers = () => async (dispatch, getState) => {
    try {
        dispatch(setState("tickerFetching", true));
        const { data: apiResponse } = await Api().get(`/admin/ticker`);
        dispatch(setState("tickerFetching", false));

        if (apiResponse.data) {
            dispatch({
                type: TICKER_SET,
                payload: apiResponse.data
            });
        }
    } catch (e) {
        dispatch(setState("tickerFetching", false));
    }
};

export const save = id => async (dispatch, getState) => {
    const item = getState().ticker.items[id];

    if (!item) {
        return;
    }

    if (!item.key_text) {
        triggerMessage("Key is required", "error");
        
        return;
    }

    try {
        dispatch(setState("tickerSaveFetching", true));
        if (id === "0") {
            await Api().post(`/admin/ticker`, {
                key_text: item.key_text,
                text: item.text,
                lang: item.lang
            });
            triggerMessage("Ticker has been added", "success");
        } else {
            await Api().put(`/admin/ticker/${id}`, {
                key_text: item.key_text,
                text: item.text,
                lang: item.lang
            });
            triggerMessage("Ticker has been updated", "success");
        }
        dispatch(loadTickers()); // TODO: update item directly
        dispatch(setState("tickerSaveFetching", false));
    } catch (e) {
        dispatch(setState("tickerSaveFetching", false));
    }
};

export const remove = id => async (dispatch, getState) => {
    try {
        dispatch(setState("tickerDeleteFetching", true));
        await Api().delete(`/admin/ticker/${id}`);
        triggerMessage("Ticker has been removed");
        dispatch(setState("tickerDeleteFetching", false));
        dispatch(loadTickers()); // TODO: update item directly
    } catch (e) {
        dispatch(setState("tickerDeleteFetching", false));
    }
};

export const addEmpty = () => async (dispatch, getState) => {
    dispatch({
        type: TICKER_ADD_EMPTY
    });
};

export const updateItem = (id, field, value) => async (dispatch, getState) => {
    dispatch({
        type: TICKER_UPDATE,
        payload: {
            id,
            field,
            value
        }
    });
};
