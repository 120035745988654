import { PROJECT_CB, PROJECT_BC } from "./lang.js";

export const PLAN_NAME_UNLIMITED = "unlimited";
export const PLAN_NAME_UNLIMITED_MONTHLY = "unlimited-monthly";
export const PLAN_NAME_TRIAL = "trial";
export const PLAN_NAME_NO = "no";
export const PLAN_GOD = "god";
export const PLAN_NAME_PAY_AS_YOU_GO = "payg";
export const PAID_PLANS = [PLAN_NAME_UNLIMITED, PLAN_NAME_UNLIMITED_MONTHLY];

export const HUMAN_PLAN_NAMES = {
    [PROJECT_BC]: {
        [PLAN_NAME_NO]: "free",
        [PLAN_NAME_UNLIMITED]: "Premium Annual",
        [PLAN_NAME_UNLIMITED_MONTHLY]: "Premium Monthly",
        [PLAN_NAME_TRIAL]: "trial",
        [PLAN_GOD]: "Premium LIFETIME",
        [PLAN_NAME_PAY_AS_YOU_GO]: "Pay as you go",
    },
    [PROJECT_CB]: {
        [PLAN_NAME_NO]: "no",
        [PLAN_NAME_UNLIMITED]: "Infinity Annual",
        [PLAN_NAME_UNLIMITED_MONTHLY]: "Infinity Monthly",
        [PLAN_NAME_TRIAL]: "trial",
        [PLAN_GOD]: "Premium LIFETIME",
        [PLAN_NAME_PAY_AS_YOU_GO]: "Pay as you go",
    }
};
