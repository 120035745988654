import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, IconButton, Tooltip, Badge } from "@material-ui/core";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import FilterListIcon from "@material-ui/icons/FilterList";

export const widgetStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative"
    },
    body: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: 1,
        padding: theme.spacing(2),
        overflowX: "scroll",
        filter: (props) => props.isFilterWindowOpened ? "blur(6px)" : ""
    },
    spinnerWrapper: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "70%",
        zIndex: "2"
    }
}));

export const styles = makeStyles((theme) => ({
    header: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        color: "white",
        flexGrow: 0,
        padding: theme.spacing(2)
    },
    headerRefreshBtn: {
        color: "white",
        position: "absolute",
        right: 17,
        top: 3
    }
}));

export const filterButtonStyles = makeStyles((theme) => ({
    root: {
        position: "relative"
    }
}));

export const WidgetHeader = ({ text, onRefreshClick }) => {
    const classes = styles();
    return (
        <div className={classes.header}>
            <Typography>{text}</Typography>
            <IconButton
                onClick={() => {
                    if (onRefreshClick) {
                        onRefreshClick();
                    }
                }}
                color="secondary"
                className={classes.headerRefreshBtn}
            >
                <AutorenewIcon />
            </IconButton>
        </div>
    );
};

export const FilterButton = ({ filterName, text, onClick, availableFilterItems = [] }) => {
    const classes = filterButtonStyles();
    const localStorage = window.localStorage;

    const getAmountOfAppliedFilters = () => {
        const filterDataString = localStorage.getItem(filterName) || "{}";

        const filterJson = JSON.parse(filterDataString);
        return Object.keys(filterJson).reduce((acc, filterName) => {
            if (filterJson[filterName] && filterJson[filterName] !== "0") {
                ++acc;
            }

            return acc;
        }, 0);
    };

    const getToolTipMessage = () => {
        const appliedFiltersAmount = getAmountOfAppliedFilters();
        if (!appliedFiltersAmount) {
            return "You can apply some filters to get more desired result"
        } else {
            const filterDataString = localStorage.getItem(filterName) || "{}";

            const filterJson = JSON.parse(filterDataString);
            const filterTitles =  Object.keys(filterJson).reduce((acc, filterName) => {
                if (filterJson[filterName] === "1") {
                    const availableFilterItem = availableFilterItems.find((availableFilter) => availableFilter.name === filterName);
                    if (availableFilterItem) {
                        acc.push(availableFilterItem.title)
                    }
                }
    
                return acc;
            }, []);

            return `Applied filters (${appliedFiltersAmount}): ${filterTitles.join(", ")}`;
        }
    }

    return (
        <div className={classes.root}>
            <Tooltip
                title={getToolTipMessage()}
                aria-label="open filter list"
            >
                <IconButton size="medium" onClick={onClick}>
                    <Badge badgeContent={getAmountOfAppliedFilters()} color="secondary">
                        <FilterListIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
        </div>
    );
};
