import Api, { resetInstance } from "../services/api";
import { setState } from "../actions/state";
import { triggerMessage } from "../utils/events";

export const get = (cb) => async (dispatch) => {

    try {
        dispatch(setState("popupMessageGetMessageFetching", true));
        const { data: apiResponse } = await Api().get(`/admin/popup-message`);

        if (apiResponse.message === "ok") {
            if (typeof cb === "function") {
                cb(null, apiResponse.data);
            }
        } else {
            if (typeof cb === "function") {
                cb(apiResponse.message, null);
            }
        }

        dispatch(setState("popupMessageGetMessageFetching", false));
    } catch (e) {
        dispatch(setState("popupMessageGetMessageFetching", false));
    }
}

export const remove = (cb) => async (dispatch) => {

    try {
        dispatch(setState("popupMessageRemoveMessageFetching", true));
        const { data: apiResponse } = await Api().delete(`/admin/popup-message`);

        if (apiResponse.message === "ok") {
            if (typeof cb === "function") {
                cb(null, apiResponse.data);
            }
        } else {
            if (typeof cb === "function") {
                cb(apiResponse.message, null);
            }
        }

        dispatch(setState("popupMessageRemoveMessageFetching", false));
    } catch (e) {
        dispatch(setState("popupMessageRemoveMessageFetching", false));
    }
}

export const create = (data, cb) => async (dispatch) => {
    if (Number.isNaN(Number(data.showInDays))) {
        triggerMessage("Show in days should be a number", "error");

        return;
    }
    
    if (!data.campaignId) {
        triggerMessage("Campaign is required", "error");

        return;
    }

    try {
        dispatch(setState("popupMessageCreateMessageFetching", true));
        const { data: apiResponse } = await Api().post(`/admin/popup-message`, data);

        if (apiResponse.message === "ok") {
            if (typeof cb === "function") {
                cb(null, apiResponse.data);
            }
        } else {
            if (typeof cb === "function") {
                cb(apiResponse.message, null);
            }
        }

        dispatch(setState("popupMessageCreateMessageFetching", false));
    } catch (e) {
        dispatch(setState("popupMessageCreateMessageFetching", false));
    }
};


export const uploadImage = (data, cb) => async (dispatch, getState) => {
    resetInstance();
    dispatch(setState(`popupBgImageFetching`, true));
    try {
        const { data: apiResponse } = await Api({
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).post("/admin/popup-message/upload-bg", data);

        dispatch(setState(`popupBgImageFetching`, false));

        if (apiResponse.message === "ok") {
            if (typeof cb === "function") {
                cb(null, apiResponse);
            }
            triggerMessage("Image has been uploaded", "success");
        }
    } catch (e) {
        if (typeof cb === "function") {
            cb("Upload error, try to reduce image size or use another image format", null);
        }
        dispatch(setState(`popupBgImageFetching`, false));
        triggerMessage("Upload error, plz check file format and size.", "error");
    }
};