export const SET_USER = "SET_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_USER_ERRORS = "SET_USER_ERRORS";

export const languages = {
    en: "English",
    fi: "Finnish",
    de: "German",
    ru: "Russian"
};
export const USERS_PAGE_LIMIT = 25;
// bouncecast users
export const SET_USERS = "SET_USERS";

// users filter
export const SET_USERS_FILTER = "SET_USERS_FILTER";
export const RESET_USERS_FILTER = "RESET_USERS_FILTER";

// user form
export const USER_FROM_SET_FIELD = "USER_FROM_SET_FIELD";
export const USER_FROM_RESET = "USER_FROM_RESET";
export const USER_FROM_SET = "USER_FROM_SET";
