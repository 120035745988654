import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as scriptReportActions from "../../actions/scriptReport";
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    spinnerWrapper: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: "2"
    },
}));

const ReportTable = ({ headerRows = [], rows = [], title = "" }) => {
    return (
        <Table>
            {headerRows && headerRows.length > 0 && (
                <TableHead>
                    <TableRow>
                        {headerRows.map((headerTitle, i) => {
                            return <TableCell key={`header_${i}`}>{headerTitle}</TableCell>;
                        })}
                    </TableRow>
                </TableHead>
            )}
            <TableBody>
                {rows &&
                    rows.length > 0 &&
                    rows.map((row, i) => (
                        <TableRow key={`row_${i}`}>
                            {row &&
                                row.map((value, i) => {
                                    return (
                                        <TableCell key={`th_${i}`} component="th" scope="row">
                                            {value}
                                        </TableCell>
                                    );
                                })}
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
};

const ScriptReport = ({ isLoading, scriptReportActions, rows = [] }) => {
    const classes = useStyles();

    React.useEffect(() => {
        scriptReportActions.loadScriptReports();
    }, [scriptReportActions]);

    return (
        <div className={classes.root}>
            {isLoading === true && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <Paper>
                <ReportTable rows={rows} headerRows={["Report", "Name", "Date"]} />
            </Paper>
        </div>
    );
};

function mapStateToProps({ state, scriptReport }) {
    return {
        isLoading: state.scriptReportFetching,
        rows: scriptReport.items
            ? Object.keys(scriptReport.items)
                  .map((key) => [
                      scriptReport.items[key].report,
                      scriptReport.items[key].script_name,
                      scriptReport.items[key].created_at
                  ])
                  .sort((a, b) => {
                      return new Date(b[2]) - new Date(a[2]);
                  })
            : []
    };
}

function mapDispatchToProps(dispatch) {
    return {
        scriptReportActions: bindActionCreators(scriptReportActions, dispatch),
        push: (url) => {
            dispatch(push(url));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScriptReport));
