import { CAMPAIGNS_SET } from "../constants/campaign";

const initialState = {
    items: {},
    total: 0
};

export default function campaigns(state = initialState, action) {
    switch (action.type) {
        case CAMPAIGNS_SET:
            return { ...state, items: action.payload.items, total: action.payload.total };
        default:
            return state;
    }
}
