import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as codesActions from "../../actions/codes";
import { Paper, Button, Grid, Input, Typography, FormLabel, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DiscountSelect from "../DiscountSelect";
import DiscountOfferInfo from "../DiscountOfferInfo";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    ctaBtn: {
        backgroundColor: green[500],
        color: "white",
        width: 200
    },
}));

const CodesCreate = ({isFetching, codesActions}) => {
    const classes = useStyles();
    const [comment, setComment] = React.useState("");
    const [amount, setAmount] = React.useState(1);
    const [condition, setCondition] = React.useState();
    const [codes, setCodes] = React.useState([]);

    const handleDiscountSelect = React.useCallback((discountCondition) => {
        setCondition(discountCondition)
    }, [])

    const handleSubmit = React.useCallback(() => {
        codesActions.createCodes(condition.id, amount, comment, (err, codes) => {
            if (!err) {
                setCodes(codes);
            }
        });
    }, [codesActions, amount, comment, condition])

    return (
        <Paper className={classes.root}>
            <Grid container spacing={2} direction="column">
                <Grid md={6} item>
                    <Typography variant="h5">
                        Please fill in the fields below to generate invite codes
                    </Typography>
                </Grid>

                <Grid item>
                    <FormControl component="fieldset">
                        <FormLabel htmlFor="comment">
                            Unique comment for the batch
                        </FormLabel>
                        <Input
                            inputProps={{
                                name: "comment",
                                id: "comment",
                            }}
                            title="Comment"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder="2022 Sep 5 codes for parther Name"
                        />
                    </FormControl>
                </Grid>

                <Grid item>
                    <FormControl component="fieldset">
                        <FormLabel htmlFor="amount">Codes amount</FormLabel>
                        <Input
                            type="number"
                            inputProps={{
                                name: "amount",
                                id: "amount",
                            }}
                            title="Amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="1"
                        />
                    </FormControl>
                </Grid>

                {condition && (
                    <Grid item>
                        <Typography variant="div">
                            User will get this condition 👇
                        </Typography>
                    </Grid>
                )}

                {condition && (
                    <Grid item>
                        <DiscountOfferInfo
                            offer={condition.discount_condition.offer}
                        />
                    </Grid>
                )}

                <Grid item>
                    <DiscountSelect
                        title="Select a discount condition"
                        buttonText="select a discount condition"
                        onSelect={handleDiscountSelect}
                    />
                </Grid>

                <Grid item>
                    <Button
                        disabled={!condition || isFetching}
                        className={classes.ctaBtn}
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmit}
                    >
                        {isFetching ? "Wait..." : "Create codes"}
                    </Button>
                </Grid>

                {!!codes.length && <Grid item>
                    <FormControl component="fieldset">
                        <FormLabel htmlFor="amount">Codes</FormLabel>
                        <Input multiline={true} value={codes.join("\n")} />
                    </FormControl>
                </Grid>}
            </Grid>
        </Paper>
    );
}

function mapStateToProps({ state }) {
    return {
        isFetching: state.createCodesFetching
    };
}

function mapDispatchToProps(dispatch) {
    return {
        codesActions: bindActionCreators(codesActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CodesCreate);