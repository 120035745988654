import Api, { resetInstance } from "../services/api";
import { setState } from "../actions/state";
import { triggerMessage } from "../utils/events";

export const uploadApp = (data, os, version, cb = null) => async (dispatch, getState) => {
    resetInstance();
    dispatch(setState(`appUpload_${os}_fetching`, true));
    try {
        const { data: apiResponse } = await Api({
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).post(`/admin/settings/upload-app/${version}/${os}`, data);

        if (apiResponse.message === "ok") {
            triggerMessage("File has been uploaded", "success");
            if (typeof cb === "function") {
                cb();
            }
        }

        dispatch(setState(`appUpload_${os}_fetching`, false));
    } catch (e) {
        dispatch(setState(`appUpload_${os}_fetching`, false));
        triggerMessage("Upload error, plz check file format and size.", "error");
    }
};
