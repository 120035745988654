import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import Routes from "./routes";
import Auth from "./services/auth";
import "./global.css";

function App({ history }) {
    React.useEffect(() => {
        Auth.checkToken();
    }, []);

    return (
        <div>
            <Router>
                <ConnectedRouter history={history}>
                    <Routes />
                </ConnectedRouter>
            </Router>
        </div>
    );
}

export default App;
