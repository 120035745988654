import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import JSONPretty from "react-json-pretty";
import { Grid, Typography, Box, IconButton, Tooltip } from "@material-ui/core";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PlusIcon from "@material-ui/icons/AddOutlined";
import MinusIcon from "@material-ui/icons/RemoveOutlined";
import { green, purple } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        width: "800px",
        height: "600px",
        backgroundColor: "white",
        zIndex: "100",
        right: 20,
        bottom: 200,
        borderRadius: 5,
        border: "1px solid black"
    },
    body: {
        fontSize: 11,
        padding: theme.spacing(1),
        marginTop: 70
    },
    bodyWrapper: {
        overflowY: "scroll",
        height: "100%"
    },
    headerWrapper: {
        position: "relative"
    },
    header: {
        position: "absolute",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        color: "white",
        flexGrow: 0,
        padding: theme.spacing(2)
    },
    headerTitle: {
        fontSize: 22
    },
    runButton: {
        backgroundColor: green[500],
        color: "white"
    },
    controlButton: {
        backgroundColor: purple[500],
        color: "white"
    },

    sizeControls: {
        display: "flex"
    },
    sizeControlFont: {
        posittion: "relative",
        marginRight: theme.spacing(3)
    },
    sizeControlWidth: {
        posittion: "relative"
    },
    controlLabel: {
        position: "absoulte",
        color: "white",
        fontSize: 9,
        textAlign: "center"
    }
}));

export default function FloatingResultWindow({
    onRunClick,
    processed = {},
    processedWithExpressions = {},
    isLoading = false
}) {
    const classes = useStyles();
    const localStorage = window.localStorage;
    const [fontSize, setfontSize] = React.useState(
        Number(localStorage.getItem("floating_reslut_window_font_size")) || 11
    );
    const [windowWidth, setwindowWidth] = React.useState(
        Number(localStorage.getItem("floating_reslut_window_width")) || 800
    );

    return (
        <Box boxShadow={5} className={classes.root} style={{ width: windowWidth }}>
            <div className={classes.headerWrapper}>
                <div className={classes.header}>
                    <Tooltip title="Run">
                        <IconButton
                            size="small"
                            className={classes.runButton}
                            disabled={isLoading}
                            onClick={(e) => {
                                if (onRunClick) {
                                    onRunClick(e);
                                }
                            }}
                        >
                            <PlayCircleFilledIcon />
                        </IconButton>
                    </Tooltip>
                    <div className={classes.headerTitle}>RESULT</div>
                    <div className={classes.sizeControls}>
                        <div className={classes.sizeControlFont}>
                            <div className={classes.controlLabel}>Font size</div>
                            <Tooltip title="Increase font">
                                <IconButton
                                    style={{ marginRight: 6 }}
                                    size="small"
                                    className={classes.controlButton}
                                    onClick={() => {
                                        setfontSize(Number(fontSize) + 1);
                                        localStorage.setItem(
                                            "floating_reslut_window_font_size",
                                            Number(fontSize) + 1
                                        );
                                    }}
                                >
                                    <PlusIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Decrease font">
                                <IconButton
                                    size="small"
                                    className={classes.controlButton}
                                    onClick={() => {
                                        if (!(fontSize <= 7)) {
                                            setfontSize(Number(fontSize) - 1);
                                            localStorage.setItem(
                                                "floating_reslut_window_font_size",
                                                Number(fontSize) - 1
                                            );
                                        }
                                    }}
                                >
                                    <MinusIcon />
                                </IconButton>
                            </Tooltip>
                        </div>

                        <div className={classes.sizeControlWidth}>
                            <div className={classes.controlLabel}>Width</div>
                            <Tooltip title="Increase width">
                                <IconButton
                                    style={{ marginRight: 6 }}
                                    size="small"
                                    className={classes.controlButton}
                                    onClick={() => {
                                        setwindowWidth(Number(windowWidth) + 20);
                                        localStorage.setItem(
                                            "floating_reslut_window_width",
                                            Number(windowWidth) + 20
                                        );
                                    }}
                                >
                                    <PlusIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Decrease width">
                                <IconButton
                                    size="small"
                                    className={classes.controlButton}
                                    onClick={() => {
                                        if (!(windowWidth <= 200)) {
                                            setwindowWidth(Number(windowWidth) - 20);
                                            localStorage.setItem(
                                                "floating_reslut_window_width",
                                                Number(windowWidth) - 20
                                            );
                                        }
                                    }}
                                >
                                    <MinusIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.bodyWrapper}>
                <div className={classes.body} style={{ fontSize: fontSize }}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Typography component="p" variant="subtitle1" color="textSecondary">
                                Processed
                            </Typography>
                            <JSONPretty
                                theme={{
                                    main:
                                        "line-height:1.3;color:#66d9ef;background:#fff;overflow:auto;",
                                    key: "color:#f92672;",
                                    string: "color:#fd971f;",
                                    value: "color:#a6e22e;",
                                    boolean: "color:#ac81fe;"
                                }}
                                id="json-pretty"
                                data={processed}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component="p" variant="subtitle1" color="textSecondary">
                                Processed with expressions
                            </Typography>
                            <JSONPretty
                                theme={{
                                    main:
                                        "line-height:1.3;color:#66d9ef;background:#fff;overflow:auto;",
                                    key: "color:#f92672;",
                                    string: "color:#fd971f;",
                                    value: "color:#a6e22e;",
                                    boolean: "color:#ac81fe;"
                                }}
                                id="json-pretty"
                                data={processedWithExpressions}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Box>
    );
}
