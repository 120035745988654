import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appTranslationActions from "../../actions/appTranslation";
import { CircularProgress, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LanguageTextField from "../LanguageTextField";
import Notification from "../../components/Notification";
import SaveIcon from "@material-ui/icons/Save";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative"
    },
    saveTabBtn: {
        position: "absolute",
        right: 0,
        bottom: -5,
        color: green[500],
        zIndex: 10
    },
    spinnerWrapper: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: "2"
    }
}));

function AppTranslation({ items, appTranslationActions, errors = [], isLoading, isSaving }) {
    const [showJsonError, setShowjsonError] = React.useState(false);
    const [jsonErrorText, setShowJsonErrorText] = React.useState("");
    const [showSuccess, setShowSuccess] = React.useState(false);

    const classes = useStyles();

    React.useEffect(() => {
        appTranslationActions.loadLanguages();
    }, [appTranslationActions]);

    const handleTranslationChange = React.useCallback(
        (lang, value) => {
            appTranslationActions.update(lang, value);
        },
        [appTranslationActions]
    );

    const handleNotificationClose = React.useCallback(() => {
        appTranslationActions.resetErrors();
    }, [appTranslationActions]);

    const handleJsonErrorNotificationClose = React.useCallback(() => {
        setShowjsonError(false);
        setShowJsonErrorText("");
    }, []);

    const handleNotificationSuccessClose = React.useCallback(() => {
        setShowSuccess(false);
    }, []);

    const handleTranslationSave = React.useCallback(() => {
        appTranslationActions.saveLanguages();
    }, [appTranslationActions]);

    return (
        <div className={classes.root}>
            {isLoading === true && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <LanguageTextField
                placeholder={`{ "somekey1": "translation 1", "somekey2": "translation 2" }`}
                value={items}
                jsonMode={true}
                onChange={handleTranslationChange}
            />
            <Notification
                variant="error"
                message={errors.length && errors.join(", ")}
                open={errors.length > 0}
                onClose={handleNotificationClose}
            />
            <IconButton
                disabled={isSaving}
                className={classes.saveTabBtn}
                onClick={handleTranslationSave}
            >
                <SaveIcon />
            </IconButton>
            <Notification
                autoHideDuration={1000}
                variant="success"
                message={"Done"}
                open={showSuccess}
                onClose={handleNotificationSuccessClose}
            />
            <Notification
                autoHideDuration={2000}
                variant="warning"
                message={jsonErrorText}
                open={showJsonError}
                onClose={handleJsonErrorNotificationClose}
            />
        </div>
    );
}

function mapStateToProps({ state, appTranslation }) {
    return {
        items: appTranslation.items,
        errors: appTranslation.errors,
        isLoading: state.appTranslationLoadLanguagesFetching,
        isSaving: state.appTranslationSaveLanguagesFetching
    };
}

function mapDispatchToProps(dispatch) {
    return {
        appTranslationActions: bindActionCreators(appTranslationActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppTranslation);
