import React from "react";
import { Button, Paper, DialogTitle, Grid, Fab } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import DiscountsList from "../DiscountsList";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const styles = makeStyles(theme => ({
    modal: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    modalBody: {
        position: "relative",
        height: "90%",
        width: "90%",
        padding: theme.spacing(3),
        overflowX: "scroll"
    },
    bottom: {
        padding: theme.spacing(2)
    },
    modalCloseBtn: {
        position: "absolute",
        right: 10,
        top: 10,
        zIndex: 2
    },
}));

const DiscountSelect = React.memo(
    ({
        title = "Select a condition",
        buttonText = "Set",
        discount = {},
        onSelect,
    }) => {
        const classes = styles();

        const [open, setopen] = React.useState(false);
        return (
            <div>
                <Button
                    color="secondary"
                    onClick={() => {
                        setopen(true);
                    }}
                >
                    {buttonText}
                </Button>

                <Modal
                    className={classes.modal}
                    open={open}
                    aria-labelledby="Discounts"
                    aria-describedby="Discounts"
                >
                    <Paper className={classes.modalBody}>
                        <Grid
                            className={classes.bottom}
                            container
                            direction="row"
                            alignItems="center"
                            justify="space-between"
                        >
                            <Grid item>
                                <DialogTitle>{title}</DialogTitle>
                            </Grid>
                            <Grid item>
                                <Fab
                                    className={classes.modalCloseBtn}
                                    variant="round"
                                    color="primary"
                                    onClick={() => {
                                        setopen(false);
                                    }}
                                >
                                    <CloseIcon />
                                </Fab>
                            </Grid>
                        </Grid>
                        <DiscountsList
                            selectMode={true}
                            onSelect={(data) => {
                                if (onSelect) {
                                    onSelect(data);
                                    setopen(false);
                                }
                            }}
                        />
                    </Paper>
                </Modal>
            </div>
        );
    }
);

export default DiscountSelect;
