import Api from "../services/api";
import { setState } from "../actions/state";
import { SET_SCRIPT_REPORT } from "../constants/scriptReport";

export const loadScriptReports = (page = 0, limit = 200) => async (dispatch, getState) => {
    try {
        dispatch(setState("scriptReportFetching", true));
        const { data: apiResponse } = await Api().get(`/admin/script-report?limit=${limit}&page=${page}`);
        dispatch(setState("scriptReportFetching", false));

        if (apiResponse.message === "ok") {
            dispatch({
                type: SET_SCRIPT_REPORT,
                payload: apiResponse.data
            });
        }
    } catch (e) {
        dispatch(setState("scriptReportFetching", false));
    }
};
