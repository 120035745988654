import React from "react";
import {
    Grid,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControlLabel,
    Checkbox,
    Input,
    IconButton
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
    DISCOUNT_TYPE_GIFT,
    DISCOUNT_TYPE_DISCOUNT_SUBSCRIPTION,
    DISCOUNT_TYPE_DISCOUNT_ONE_TIME_PAYMENT
} from "../../constants/discount";
import {
    PLAN_NAME_UNLIMITED_MONTHLY,
    PLAN_NAME_UNLIMITED,
    PLAN_NAME_NO,
    PLAN_GOD,
    PLAN_NAME_PAY_AS_YOU_GO
} from "../../constants/payments";
import DiscountSelect from "../DiscountSelect";
import DiscountOfferInfo from "../DiscountOfferInfo";
import { getHumanPlanName, isProject } from "../../utils";
import { PROJECT_CB } from "../../constants/lang";

const useStyles = makeStyles((theme) => ({
    root: {},
    section: {
        marginBottom: theme.spacing(3)
    },
    subSection: {
        marginBottom: theme.spacing(2)
    },
    formRoot: {
        border: "1px dashed",
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    saveBtn: {
        color: green[500]
    }
}));

const PlanSelect = ({ plan = "", onChange }) => {
    return (
        <>
            <InputLabel htmlFor="remaining_message">Plan</InputLabel>
            <Select
                fullWidth
                label="Plan"
                value={plan}
                onChange={(e) => {
                    if (onChange) {
                        onChange("subject", e.target.value || "");
                    }
                }}
                inputProps={{
                    name: "plan",
                    id: "plan"
                }}
            >
                <MenuItem value={"no"}>{getHumanPlanName(PLAN_NAME_NO)}</MenuItem>
                <MenuItem value={"unlimited-monthly"}>
                    {getHumanPlanName(PLAN_NAME_UNLIMITED_MONTHLY)}
                </MenuItem>
                <MenuItem value={"unlimited"}>{getHumanPlanName(PLAN_NAME_UNLIMITED)}</MenuItem>
            </Select>
        </>
    );
};

const DiscountTypeSelect = ({ type, onChange }) => {
    return (
        <>
            <InputLabel htmlFor="remaining_message">Discount offer type</InputLabel>
            <Select
                fullWidth
                label="Offer type"
                value={type}
                onChange={(e) => {
                    if (onChange) {
                        onChange("type", e.target.value || "");
                    }
                }}
                inputProps={{
                    name: "type",
                    id: "type"
                }}
            >
                <MenuItem value={DISCOUNT_TYPE_GIFT}>
                    <span role="img" aria-label="discount gift">
                        🎁
                    </span>{" "}
                    GIFT (user will get the plan immediately)
                </MenuItem>
                <MenuItem value={DISCOUNT_TYPE_DISCOUNT_SUBSCRIPTION}>
                    <span role="img" aria-label="discount subscription">
                        💲
                    </span>{" "}
                    SUBSCRIPTION DISCOUNT (user will get subscription discount)
                </MenuItem>
                <MenuItem value={DISCOUNT_TYPE_DISCOUNT_ONE_TIME_PAYMENT}>
                    <span role="img" aria-label="discount one time payment">
                        💲
                    </span>{" "}
                    ONE TIME PAYMENT DISCOUNT (user will get a discount for one time payment)
                </MenuItem>
            </Select>
        </>
    );
};

const GodCheckBox = ({ isGod = false, onChange, title = "" }) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={Boolean(isGod)}
                    onChange={(e) => {
                        if (onChange) {
                            onChange("isGod", e.target.checked ? "1" : false);
                            onChange("extraDurationValue", ""); // reset extra duration
                        }
                    }}
                    value={Boolean(isGod)}
                />
            }
            label={title}
        />
    );
};

const TrialCheckBox = ({ isTrial = false, onChange, title = "" }) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={Boolean(isTrial)}
                    onChange={(e) => {
                        if (onChange) {
                            onChange(
                                "trial",
                                e.target.checked
                                    ? { trialDuration: "1", trialDurationUnit: "day" }
                                    : false
                            );
                        }
                    }}
                    value={Boolean(isTrial)}
                />
            }
            label={title}
        />
    );
};

const MiniCheckBox = ({ isMini = false, onChange, title = "" }) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={Boolean(isMini)}
                    onChange={(e) => {
                        if (onChange) {
                            onChange(
                                "mini",
                                e.target.checked ? { isMini: "1", upgradePrice: "0" } : false
                            );
                        }
                    }}
                    value={Boolean(isMini)}
                />
            }
            label={title}
        />
    );
};

const StackableCheckBox = ({ isGod = false, onChange }) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={Boolean(isGod)}
                    onChange={(e) => {
                        if (onChange) {
                            onChange("stackable", e.target.checked ? "1" : false);
                        }
                    }}
                    value={Boolean(isGod)}
                />
            }
            label="is stackable (on apply user gets additional license/seats)"
        />
    );
};

const ExtendDuration = ({ disable = false, extraDurationValue, plan, onChange }) => {
    return (
        <>
            <InputLabel htmlFor="duration">
                Add extra duration for "{getHumanPlanName(plan)}"" plan (1 unit equal to 1mo/1year
                depend on plan). E.g. monthly plan + duration 2 will give 3 months in total.
            </InputLabel>
            <Input
                inputProps={{
                    name: "duration",
                    id: "duration"
                }}
                type="number"
                placeholder="0"
                value={!disable ? extraDurationValue || "" : ""}
                disable={disable}
                onChange={(e) => {
                    if (onChange) {
                        onChange("extraDurationValue", e.target.value);
                    }
                }}
            />
        </>
    );
};

const GiftForm = ({ offer, onChange }) => {
    const classes = useStyles();

    return (
        <>
            <Grid container spacing={2} direction="column" className={classes.section}>
                <Grid item md={5} sm={12} xs={12}>
                    <PlanSelect plan={offer.subject} onChange={onChange} />
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="column" className={classes.section}>
                <Grid item md={5} sm={12} xs={12}>
                    <GodCheckBox isGod={offer.isGod} onChange={onChange} title="GOD subscription" />
                </Grid>
            </Grid>
            {isProject(PROJECT_CB) && (
                <Grid container spacing={2} direction="column" className={classes.section}>
                    <Grid item md={5} sm={12} xs={12}>
                        <MiniCheckBox
                            isMini={offer.mini}
                            onChange={onChange}
                            title="CloudBounce MINI"
                        />
                    </Grid>
                </Grid>
            )}
            {offer.mini && (
                <Grid container spacing={2} direction="column" className={classes.section}>
                    <Grid item md={5} sm={12} xs={12}>
                        <InputLabel htmlFor="upgradePrice">Upgrade price</InputLabel>
                        <Input
                            inputProps={{
                                name: "upgradePrice",
                                id: "upgradePrice"
                            }}
                            label="upgradePrice"
                            placeholder="1"
                            value={offer.mini.upgradePrice}
                            onChange={(e) => {
                                if (onChange) {
                                    onChange("mini.upgradePrice", e.target.value);
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            )}
            <Grid container spacing={2} direction="column" className={classes.section}>
                <Grid item md={5} sm={12} xs={12}>
                    <StackableCheckBox isGod={offer.stackable} onChange={onChange} />
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="column" className={classes.section}>
                <Grid item md={5} sm={12} xs={12}>
                    <ExtendDuration
                        disable={Boolean(offer.isGod)}
                        extraDurationValue={Boolean(offer.isGod) ? "" : offer.extraDurationValue}
                        plan={offer.subject}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
        </>
    );
};

// the form uses one item of offer.subjects
const DiscountForm = ({
    plan = "",
    enabled = false,
    subject = {},
    isLoading = false,
    onChange,
    onDisable
}) => {
    const classes = useStyles();

    return (
        <>
            <Grid
                container
                spacing={2}
                direction="column"
                alignContent="stretch"
                className={classes.section}
            >
                <Grid item md={12} sm={12} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Boolean(enabled)}
                                onChange={(e) => {
                                    if (onDisable) {
                                        onDisable(plan, e.target.checked);
                                    }
                                }}
                                value={Boolean(enabled)}
                            />
                        }
                        label={`Use discount for "${getHumanPlanName(plan)}" plan`}
                    />
                </Grid>
            </Grid>
            {enabled && (
                <>
                    <Grid container spacing={2} direction="column" className={classes.section}>
                        <Grid item md={5} sm={12} xs={12}>
                            <InputLabel htmlFor="finalPriceCrypto">
                                Final CRYPTO price for "{getHumanPlanName(plan)}"" plan
                            </InputLabel>
                            <Input
                                inputProps={{
                                    name: "finalPriceCrypto",
                                    id: "finalPriceCrypto"
                                }}
                                placeholder="0"
                                value={subject.finalPriceCrypto || ""}
                                onChange={(e) => {
                                    if (onChange) {
                                        onChange("finalPriceCrypto", e.target.value);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={5} sm={12} xs={12}>
                            <InputLabel htmlFor="discount">
                                Discount for "{getHumanPlanName(plan)}"" plan
                            </InputLabel>
                            <Input
                                inputProps={{
                                    name: "discount",
                                    id: "discount"
                                }}
                                placeholder="0"
                                value={subject.amount || ""}
                                onChange={(e) => {
                                    if (onChange) {
                                        onChange("amount", e.target.value);
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="column" className={classes.section}>
                        <Grid item md={5} sm={12} xs={12}>
                            <InputLabel htmlFor="cycles">Cycles</InputLabel>
                            <Input
                                inputProps={{
                                    name: "cycles",
                                    id: "cycles"
                                }}
                                placeholder="1"
                                value={subject.isGod ? "" : subject.cycles || ""}
                                disabled={Boolean(subject.isGod)}
                                onChange={(e) => {
                                    if (onChange) {
                                        onChange("cycles", e.target.value);
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="column" className={classes.section}>
                        <Grid item md={5} sm={12} xs={12}>
                            <GodCheckBox
                                isGod={subject.isGod}
                                onChange={onChange}
                                title="DISCOUNT NEVER EXPIRES"
                            />
                        </Grid>
                    </Grid>
                    {isProject(PROJECT_CB) && (
                        <Grid container spacing={2} direction="column" className={classes.section}>
                            <Grid item md={5} sm={12} xs={12}>
                                <MiniCheckBox
                                    isMini={subject.mini}
                                    onChange={onChange}
                                    title="CloudBounce MINI"
                                />
                            </Grid>
                        </Grid>
                    )}
                    {subject.mini && (
                        <Grid container spacing={2} direction="column" className={classes.section}>
                            <Grid item md={5} sm={12} xs={12}>
                                <InputLabel htmlFor="upgradePrice">Upgrade price</InputLabel>
                                <Input
                                    inputProps={{
                                        name: "upgradePrice",
                                        id: "upgradePrice"
                                    }}
                                    label="duration"
                                    placeholder="1"
                                    value={subject.mini.upgradePrice}
                                    onChange={(e) => {
                                        if (onChange) {
                                            onChange("mini.upgradePrice", e.target.value);
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={2} direction="column" className={classes.section}>
                        <Grid item md={5} sm={12} xs={12}>
                            <TrialCheckBox
                                isTrial={subject.trial}
                                onChange={onChange}
                                title="ENABLE TRIAL MODE (Braintree)"
                            />
                        </Grid>
                    </Grid>

                    {subject.trial && (
                        <>
                            <Grid
                                container
                                spacing={2}
                                direction="column"
                                className={classes.section}
                            >
                                <Grid item md={5} sm={12} xs={12}>
                                    <InputLabel htmlFor="trialDurationUnit">
                                        Trial Duration Unit
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        label="Trial duration unit"
                                        value={subject.trial.trialDurationUnit}
                                        onChange={(e) => {
                                            if (onChange) {
                                                onChange(
                                                    "trial.trialDurationUnit",
                                                    e.target.value || "day"
                                                );
                                            }
                                        }}
                                        inputProps={{
                                            name: "trialDurationUnit",
                                            id: "trialDurationUnit"
                                        }}
                                    >
                                        <MenuItem value="day">DAY</MenuItem>
                                        <MenuItem value="month">MONTH</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={2}
                                direction="column"
                                className={classes.section}
                            >
                                <Grid item md={5} sm={12} xs={12}>
                                    <InputLabel htmlFor="trialDuration">Trial duration</InputLabel>
                                    <Input
                                        inputProps={{
                                            name: "trialDuration",
                                            id: "trialDuration"
                                        }}
                                        label="duration"
                                        placeholder="1"
                                        value={subject.trial.trialDuration}
                                        onChange={(e) => {
                                            if (onChange) {
                                                onChange("trial.trialDuration", e.target.value);
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                </>
            )}
        </>
    );
};

const DiscountFormOneTimePayment = ({
    plan = "",
    enabled = false,
    subject = {},
    isLoading = false,
    hideMiniMode = false,
    onChange,
    onDisable
}) => {
    const classes = useStyles();

    return (
        <>
            <Grid
                container
                spacing={2}
                direction="column"
                alignContent="stretch"
                className={classes.section}
            >
                <Grid item md={12} sm={12} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Boolean(enabled)}
                                onChange={(e) => {
                                    if (onDisable) {
                                        onDisable(plan, e.target.checked);
                                    }
                                }}
                                value={Boolean(enabled)}
                            />
                        }
                        label={`Set a final price for "${plan.toUpperCase()}" plan`}
                    />
                </Grid>
            </Grid>
            {enabled && (
                <>
                    <Grid container spacing={2} direction="column" className={classes.section}>
                    <Grid item md={5} sm={12} xs={12}>
                            <InputLabel htmlFor="discount">
                                Final CRYPTO price for "{plan.toUpperCase()}""
                            </InputLabel>
                            <Input
                                inputProps={{
                                    name: "discount",
                                    id: "discount"
                                }}
                                placeholder="0"
                                value={subject.finalPriceCrypto || ""}
                                onChange={(e) => {
                                    if (onChange) {
                                        onChange("finalPriceCrypto", e.target.value);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={5} sm={12} xs={12}>
                            <InputLabel htmlFor="discount">
                                Final price for "{plan.toUpperCase()}""
                            </InputLabel>
                            <Input
                                inputProps={{
                                    name: "discount",
                                    id: "discount"
                                }}
                                placeholder="0"
                                value={subject.finalPrice || ""}
                                onChange={(e) => {
                                    if (onChange) {
                                        onChange("finalPrice", e.target.value);
                                    }
                                }}
                            />
                        </Grid>
                        {!hideMiniMode && (
                            <>
                                {isProject(PROJECT_CB) && (
                                    <Grid item md={5} sm={12} xs={12}>
                                        <MiniCheckBox
                                            isMini={subject.mini}
                                            onChange={onChange}
                                            title="Enable CloudBounce MINI mode"
                                        />
                                    </Grid>
                                )}
                                {subject.mini && (
                                    <Grid item md={5} sm={12} xs={12}>
                                        <InputLabel htmlFor="upgradePrice">
                                            Upgrade price
                                        </InputLabel>
                                        <Input
                                            inputProps={{
                                                name: "upgradePrice",
                                                id: "upgradePrice"
                                            }}
                                            label="duration"
                                            placeholder="1"
                                            value={subject.mini.upgradePrice}
                                            onChange={(e) => {
                                                if (onChange) {
                                                    onChange("mini.upgradePrice", e.target.value);
                                                }
                                            }}
                                        />
                                    </Grid>
                                )}
                            </>
                        )}
                    </Grid>
                </>
            )}
        </>
    );
};

export default function DiscountEditForm({
    isLoading,
    form = { offer: { type: "", subjects: {} } },
    discountFormInviteCodeCondition = { offer: { type: "", subjects: {} } },
    onChange,
    onInviteCodeConditionSelect, // set a condition
    onSave
}) {
    const classes = useStyles();

    const handleDiscountSubscriptionFormOnChange = React.useCallback(
        (plan, form) => (propName, value) => {
            if (onChange) {
                let offer = form.offer;
                if (offer && offer.subjects && offer.subjects[plan]) {
                    if (propName === "trial.trialDuration") {
                        // handle "trial.trialDuration" trial case change
                        offer.subjects[plan]["trial"] = {
                            ...offer.subjects[plan]["trial"],
                            trialDuration: value
                        };
                    } else if (propName === "trial.trialDurationUnit") {
                        // handle "trial.trialDurationUnit" trial case change
                        offer.subjects[plan]["trial"] = {
                            ...offer.subjects[plan]["trial"],
                            trialDurationUnit: value
                        };
                    } else if (propName === "mini.isMini") {
                        offer.subjects[plan]["mini"] = {
                            ...offer.subjects[plan]["mini"],
                            isMini: value
                        };
                    } else if (propName === "mini.upgradePrice") {
                        offer.subjects[plan]["mini"] = {
                            ...offer.subjects[plan]["mini"],
                            upgradePrice: value
                        };
                    } else {
                        if (value) {
                            // default (root values)
                            offer.subjects[plan][propName] = value;
                            // let's check isGod/cycles case. when pick isGod cycles are not allowed
                            if (propName === "isGod" && value) {
                                // remove cycles
                                delete offer.subjects[plan]["cycles"];
                            }
                        } else {
                            delete offer.subjects[plan][propName]; // don't use empty values (remove prop)
                        }
                    }

                    onChange("offer", { ...offer });
                }
            }
        },
        [onChange]
    );

    const handleOnDisableDiscount = React.useCallback(
        (form) => (plan, value) => {
            let offer = form.offer;
            if (offer && offer.subjects) {
                if (value) {
                    offer.subjects[plan] = {
                        subject: plan
                    };
                } else {
                    delete offer.subjects[plan];
                }
            }
            onChange("offer", { ...offer });
        },
        [onChange]
    );

    return (
        <Grid container spacing={2} direction="column" alignContent="stretch">
            <Grid className={classes.section}>
                <Grid item md={5} sm={12} xs={12}>
                    <TextField
                        value={form.comment}
                        fullWidth
                        label="Comment"
                        placeholder=""
                        onChange={(e) => {
                            onChange("comment", e.target.value);
                        }}
                        disabled={isLoading}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} direction="column" className={classes.section}>
                <Grid item md={5} sm={12} xs={12}>
                    <DiscountTypeSelect
                        type={form.offer.type}
                        onChange={(name, value) => {
                            if (onChange) {
                                let offer = {
                                    type: value
                                };

                                switch (value) {
                                    case DISCOUNT_TYPE_DISCOUNT_SUBSCRIPTION:
                                    case DISCOUNT_TYPE_DISCOUNT_ONE_TIME_PAYMENT:
                                        offer["subjects"] = {};
                                        break;
                                    case DISCOUNT_TYPE_GIFT:
                                        offer["subject"] = "";
                                        break;
                                    default:
                                }

                                onChange("offer", { ...offer });
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} direction="column" className={classes.section}>
                <Grid item md={5} sm={12} xs={12}>
                    {!form.user_invite_code_condition_id && (
                        <InputLabel className={classes.subSection}>
                            User invite code condition ID is not set *
                        </InputLabel>
                    )}
                    {discountFormInviteCodeCondition && discountFormInviteCodeCondition.id && (
                        <Grid container spacing={2} direction="column">
                            <Grid item md={12} sm={12} xs={12}>
                                <InputLabel className={classes.subSection}>
                                    This condition will be applied for user invite codes:
                                </InputLabel>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                                <div className={classes.subSection}>
                                    {discountFormInviteCodeCondition.comment}
                                </div>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12} className={classes.subSection}>
                                <DiscountOfferInfo offer={discountFormInviteCodeCondition.offer} />
                            </Grid>
                        </Grid>
                    )}
                    <Grid item md={12} sm={12} xs={12}>
                        <DiscountSelect
                            title="Select a discount condition for user invite codes"
                            buttonText={`${
                                discountFormInviteCodeCondition.id ? "Update" : "Add"
                            } invite code offer`}
                            discount={discountFormInviteCodeCondition}
                            onSelect={(discount) => {
                                onChange(
                                    "user_invite_code_condition_id",
                                    discount.discount_condition.id
                                );
                                if (onInviteCodeConditionSelect) {
                                    onInviteCodeConditionSelect(discount.discount_condition);
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2} direction="column" className={classes.section}>
                <Grid item md={12} sm={12} xs={12}>
                    {form.offer.type === DISCOUNT_TYPE_GIFT && (
                        <div className={classes.formRoot}>
                            <GiftForm
                                offer={form.offer}
                                onChange={(name, value) => {
                                    console.log(name, value);
                                    if (onChange) {
                                        // update offer object
                                        let offer = form.offer;
                                        if (name === "mini.isMini") {
                                            offer["mini"] = {
                                                ...offer["mini"],
                                                isMini: value
                                            };
                                        } else if (name === "mini.upgradePrice") {
                                            offer["mini"] = {
                                                ...offer["mini"],
                                                upgradePrice: value
                                            };
                                        } else {
                                            if (value) {
                                                offer[name] = value;
                                            } else {
                                                delete offer[name]; // don't use empty values (remove prop)
                                            }
                                        }
                                        onChange("offer", { ...offer });
                                    }
                                }}
                            />
                        </div>
                    )}
                    {form && form.offer && form.offer.type === DISCOUNT_TYPE_DISCOUNT_SUBSCRIPTION && (
                        <>
                            <div className={classes.formRoot}>
                                <DiscountForm
                                    plan={PLAN_NAME_UNLIMITED_MONTHLY}
                                    enabled={Boolean(
                                        form.offer.subjects[PLAN_NAME_UNLIMITED_MONTHLY]
                                    )}
                                    subject={
                                        form.offer.subjects
                                            ? form.offer.subjects[PLAN_NAME_UNLIMITED_MONTHLY]
                                            : {}
                                    }
                                    onChange={handleDiscountSubscriptionFormOnChange(
                                        PLAN_NAME_UNLIMITED_MONTHLY,
                                        form
                                    )}
                                    onDisable={handleOnDisableDiscount(form)}
                                />
                            </div>
                            <div className={classes.formRoot}>
                                <DiscountForm
                                    plan={PLAN_NAME_UNLIMITED}
                                    enabled={Boolean(form.offer.subjects[PLAN_NAME_UNLIMITED])}
                                    subject={
                                        form.offer.subjects
                                            ? form.offer.subjects[PLAN_NAME_UNLIMITED]
                                            : {}
                                    }
                                    onChange={handleDiscountSubscriptionFormOnChange(
                                        PLAN_NAME_UNLIMITED,
                                        form
                                    )}
                                    onDisable={handleOnDisableDiscount(form)}
                                />
                            </div>
                        </>
                    )}
                    {form &&
                        form.offer &&
                        form.offer.type === DISCOUNT_TYPE_DISCOUNT_ONE_TIME_PAYMENT && (
                            <>
                                <div className={classes.formRoot}>
                                    <DiscountFormOneTimePayment
                                        hideMiniMode={false}
                                        plan={PLAN_GOD}
                                        enabled={Boolean(form.offer.subjects[PLAN_GOD])}
                                        subject={
                                            form.offer.subjects ? form.offer.subjects[PLAN_GOD] : {}
                                        }
                                        onChange={handleDiscountSubscriptionFormOnChange(
                                            PLAN_GOD,
                                            form
                                        )}
                                        onDisable={handleOnDisableDiscount(form)}
                                    />
                                </div>
                                <div className={classes.formRoot}>
                                    <DiscountFormOneTimePayment
                                        hideMiniMode={true}
                                        plan={PLAN_NAME_PAY_AS_YOU_GO}
                                        enabled={Boolean(
                                            form.offer.subjects[PLAN_NAME_PAY_AS_YOU_GO]
                                        )}
                                        subject={
                                            form.offer.subjects
                                                ? form.offer.subjects[PLAN_NAME_PAY_AS_YOU_GO]
                                                : {}
                                        }
                                        onChange={handleDiscountSubscriptionFormOnChange(
                                            PLAN_NAME_PAY_AS_YOU_GO,
                                            form
                                        )}
                                        onDisable={handleOnDisableDiscount(form)}
                                    />
                                </div>
                            </>
                        )}
                </Grid>
                <Grid container justify="flex-end">
                    <IconButton className={classes.saveBtn} onClick={onSave} disabled={isLoading}>
                        <SaveIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
}
