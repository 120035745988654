import Api from "../services/api";
import { setState } from "../actions/state";
import { triggerMessage } from "../utils/events";

export const revokeCodes = (codes) => async (dispatch, getState) => {
    dispatch(setState("revokeCodesFetching", true));
    try {
        const { data: apiResponse } = await Api().post("/admin/codes/revoke", {
            codes
        });
        dispatch(setState("revokeCodesFetching", false));
        if (apiResponse.message === "ok") {
            triggerMessage(apiResponse.data.message, "success");
        } else {
            triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
        }
    } catch (e) {
        dispatch(setState("revokeCodesFetching", false));
        const error = (e.response && e.response.data && e.response.data.message) || "";
        triggerMessage(error, "error");
    }
};

export const checkCodesPresence = (codes, cb) => async (dispatch, getState) => {
    dispatch(setState("checkCodesPresenceFetching", true));
    try {
        const { data: apiResponse } = await Api().post("/admin/codes/check-codes-presence", {
            codes
        });
        dispatch(setState("checkCodesPresenceFetching", false));
        if (apiResponse.message === "ok") {
            triggerMessage(apiResponse.data.message, "success");
            if (typeof cb === "function") {
                cb(null, apiResponse.data);
            }
        } else {
            triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
        }
    } catch (e) {
        dispatch(setState("checkCodesPresenceFetching", false));
        const error = (e.response && e.response.data && e.response.data.message) || "";
        triggerMessage(error, "error");
    }
};

export const generateCodesFromList =
    (codes, conditionId, comment, expireInDays, cb) => async (dispatch, getState) => {
        dispatch(setState("generateCodesFromListFetching", true));
        try {
            const { data: apiResponse } = await Api().post(
                "/admin/codes/generate-codes-from-list",
                {
                    codes,
                    conditionId,
                    comment,
                    expireInDays
                }
            );
            dispatch(setState("generateCodesFromListFetching", false));
            if (apiResponse.message === "ok") {
                triggerMessage(apiResponse.data.message, "success");
                if (typeof cb === "function") {
                    cb(null, apiResponse.data);
                }
            } else {
                triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
            }
        } catch (e) {
            dispatch(setState("generateCodesFromListFetching", false));
            const error = (e.response && e.response.data && e.response.data.message) || "";
            triggerMessage(error, "error");
        }
    };

export const checkCode = (code, cb) => async (dispatch, getState) => {
    dispatch(setState("checkCodesFetching", true));
    try {
        const { data: apiResponse } = await Api().post("/admin/codes/check", {
            code
        });
        dispatch(setState("checkCodesFetching", false));
        if (apiResponse.message === "ok") {
            triggerMessage(apiResponse.data.message, "success");
            if (typeof cb === "function") {
                cb(null, apiResponse.data);
            }
        } else {
            triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
            if (typeof cb === "function") {
                cb(apiResponse.message, null);
            }
        }
    } catch (e) {
        dispatch(setState("checkCodesFetching", false));
        const error = (e.response && e.response.data && e.response.data.message) || "";
        triggerMessage(error, "error");
    }
};

export const createCodes = (conditionId, amount, comment, cb) => async (dispatch, getState) => {
    dispatch(setState("createCodesFetching", true));
    try {
        const { data: apiResponse } = await Api().post("/admin/codes/create", {
            conditionId, comment, amount
        });
        dispatch(setState("createCodesFetching", false));
        if (apiResponse.message === "ok") {
            triggerMessage("Done", "success");
            if (typeof cb === "function") {
                cb(null, apiResponse.data);
            }
        } else {
            triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
            if (typeof cb === "function") {
                cb(apiResponse.message, null);
            }
        }
    } catch (e) {
        dispatch(setState("createCodesFetching", false));
        const error = (e.response && e.response.data && e.response.data.message) || "";
        triggerMessage(error, "error");
    }
};