import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as discountActions from "../../actions/discount";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageTitle from "../../components/PageTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { usePrevious } from "../../utils/hooks";
import DiscountEditForm from "../../components/DiscountEditForm";

const useRootStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    header: {
        marginBottom: 15
    },
    editForm: {
        padding: theme.spacing(4)
    },
    spinnerWrapper: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: "2"
    },
}));

const bc = [{ title: "Disount Conditions", url: "/dashboard/discount-conditions" }];

const DashBoardDiscountConditionsEdit = ({
    match,
    push,
    discountForm,
    discountFormInviteCodeCondition,
    discountActions,
    isDiscountFetching,
    isSaveDiscountFetching
}) => {
    const [isEditMode, setIsEditMode] = React.useState(false);
    const prevMatchDiscountId = usePrevious(match.params.id);
    const classes = useRootStyles();

    React.useEffect(() => {
        if (match.params.id && prevMatchDiscountId !== match.params.id) {
            discountActions.loadDiscount(match.params.id);
            setIsEditMode(true);
        }
    }, [match, discountActions, prevMatchDiscountId]);

    const handleChange = React.useCallback(
        (fieldName, value) => {
            discountActions.discountFormSetField(fieldName, value);
        },
        [discountActions]
    );

    const handleSave = React.useCallback(() => {
        if (isEditMode) {
            discountActions.updateDiscount();
        } else {
            discountActions.addDiscount();
        }
    }, [discountActions, isEditMode]);

    const handleInviteCodeConditionSelect = React.useCallback((discount) => {
        discountActions.setInviteCodeDiscountCondition(discount)
    }, [discountActions])

    const isLoading = isDiscountFetching || isSaveDiscountFetching;
    const editTitle =
        isDiscountFetching === false ? `Discount conditon ID: ${discountForm.id}` : "Loading...";
    return (
        <div className={classes.root}>
            {isDiscountFetching && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <Grid container spacing={0} justify="space-between" className={classes.header}>
                <Grid item xs={6}>
                    <PageTitle
                        title={!isEditMode ? "New discount condition" : editTitle}
                        breadcrumbs={[
                            ...bc,
                            ...[
                                isEditMode ? { title: "Edit" } : { title: "New discount condition" }
                            ]
                        ]}
                    />
                </Grid>
                <Grid item xs={6}></Grid>
            </Grid>
            {!isDiscountFetching && isEditMode && (
                <Grid container alignContent="stretch" direction="column">
                    <Paper className={classes.editForm}>
                        <DiscountEditForm
                            isLoading={isLoading}
                            form={discountForm}
                            discountFormInviteCodeCondition={discountFormInviteCodeCondition}
                            onChange={handleChange}
                            onSave={handleSave}
                            onInviteCodeConditionSelect={handleInviteCodeConditionSelect}
                        />
                    </Paper>
                </Grid>
            )}
            {!isEditMode && (
                <Grid container alignContent="stretch" direction="column">
                    <Paper className={classes.editForm}>
                        <DiscountEditForm
                            isLoading={isLoading}
                            form={discountForm}
                            discountFormInviteCodeCondition={discountFormInviteCodeCondition}
                            onChange={handleChange}
                            onSave={handleSave}
                            onInviteCodeConditionSelect={handleInviteCodeConditionSelect}
                        />
                    </Paper>
                </Grid>
            )}
        </div>
    );
};

function mapStateToProps({ state, discountForm }) {
    return {
        discountForm: discountForm.data,
        discountFormInviteCodeCondition: discountForm.inviteCodeCondtion,
        isDiscountFetching: state.loadDiscountFetching,
        isSaveDiscountFetching: state.saveDiscountFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        discountActions: bindActionCreators(discountActions, dispatch),
        push: url => {
            dispatch(push(url));
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DashBoardDiscountConditionsEdit));
