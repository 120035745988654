import Auth from "../services/auth";
import Api from "../services/api";
import { eraseCookie, createCookie } from "../services/cookie";
import { setState } from "../actions/state";
import { push } from "connected-react-router";
import { SET_USER_ERRORS, SET_USER } from "../constants/user";

export const tokenRequest = () => async (dispatch, getState) => {
    try {
        dispatch(setState("tokenFetching", true));
        const { data: apiResponse } = await Api().get("/user/current");
        if (apiResponse.data && apiResponse.data.id && apiResponse.data.is_admin) {
            Auth.isAuthenticated = true;
            dispatch(setState("tokenFetching", false));
            dispatch({
                type: SET_USER,
                payload: apiResponse.data
            });
        } else {
            dispatch(setState("tokenFetching", false));
            dispatch({
                type: SET_USER_ERRORS,
                payload: {
                    message: "You don't have access"
                }
            });
        }
    } catch (e) {
        dispatch(setState("tokenFetching", false));
    }
};

export const auth = (email, password) => async (dispatch, getState) => {
    try {
        dispatch(setState("authLoading", true));
        const { data: apiResponse } = await Api().post("/auth/login", {
            email,
            password
        });

        dispatch(setState("authLoading", false));
        if (apiResponse.data && apiResponse.data.token && apiResponse.data.is_admin) {
            Auth.isAuthenticated = true;
            createCookie("token", apiResponse.data.token);
            dispatch({
                type: SET_USER,
                payload: apiResponse.data
            });
            dispatch(push("/"));
        } else {
            Auth.isAuthenticated = false;
            eraseCookie("token");
            dispatch({
                type: SET_USER_ERRORS,
                payload: {
                    message: "You don't have access"
                }
            });
        }
    } catch (e) {
        const errors = (e.response && e.response.data && e.response.data.data) || {};
        dispatch({ type: SET_USER_ERRORS, payload: errors });
        dispatch(setState("authLoading", false));
    }
};
