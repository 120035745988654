import { SET_BRAINTREE, RESET_BRAINTREE } from "../constants/braintree";

const defaultState = {
    data: {}
};

export default function braintree(state = defaultState, action) {
    switch (action.type) {
        case SET_BRAINTREE:
            return { ...state, data: {...state.data, ...action.payload } };
        case RESET_BRAINTREE:
            return {
                data: {}
            };
        default:
            return state;
    }
}
