import { SET_WIDGET } from "../constants/widgets";

const defaultState = {
    data: {}
};

export default function widgets(state = defaultState, action) {
    switch (action.type) {
        case SET_WIDGET:
            return {
                ...state,
                data: { ...state["data"], [action.payload.name]: action.payload.data }
            };
        default:
            return state;
    }
}
