import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import {
    IconButton,
    TextField,
    FormControlLabel,
    CircularProgress,
    Typography,
    Grid,
    FormLabel
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { green, red } from "@material-ui/core/colors";
import LuxonUtils from "@date-io/luxon";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import LanguageTextField from "../LanguageTextField";
import Checkbox from "@material-ui/core/Checkbox";
import FileUpload from "../FileUpload";
import DiscountOfferInfo from "../DiscountOfferInfo";
import DiscountSelect from "../DiscountSelect";
import { DISCOUNT_TYPE_GIFT } from "../../constants/discount";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        position: "relative"
    },
    paper: {
        padding: theme.spacing(4)
    },
    removeBtn: {
        color: red[500]
    },
    saveBtn: {
        color: green[500]
    },
    pager: {
        padding: theme.spacing(2)
    },
    form: {
        "&>div": {
            marginBottom: theme.spacing(3)
        }
    },
    bottom: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        height: 10
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    spinnerWrapper: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "60vh",
        zIndex: "2"
    },
    userType: {
        marginBottom: theme.spacing(2)
    },
    borderWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        border: "1px solid rgba(0, 0, 0, 0.54)",
        borderRadius: "5px",
        padding: theme.spacing(2)
    },
    subItem: {
        marginBottom: theme.spacing(2)
    }
}));

// TODO: rework this
// const calculateDiscount = (finalPrice, currentPrice) => {
//     let result = {
//         new_price: "",
//         discount: "",
//         money_saved: "",
//         money_saved_in_percent: "",
//         money_saved_in_percent_short: ""
//     };

//     if (!finalPrice || isNaN(finalPrice)) {
//         return result;
//     }

//     if (Number(currentPrice) - Number(finalPrice) <= 0) {
//         triggerMessage("The final should not exceed the original price", "warning");

//         return result;
//     }

//     if (finalPrice) {
//         const newPrice = Number(finalPrice).toFixed(2);
//         const discount = (Number(currentPrice) - Number(finalPrice)).toFixed(2);
//         const priceSave = (Number(currentPrice) - Number(finalPrice)).toFixed(2);
//         result = {
//             new_price: newPrice,
//             discount,
//             money_saved: priceSave,
//             money_saved_in_percent: (100 - (newPrice / currentPrice) * 100).toFixed(2),
//             money_saved_in_percent_short: (100 - (newPrice / currentPrice) * 100).toFixed(0)
//         };

//         return result;
//     }
// };

const getDiscountOrGiftMessage = offer => {
    return offer && offer.type === DISCOUNT_TYPE_GIFT ? "gift" : "discount";
};

export default function CampaingAddForm({
    isEditMode = false,
    id,
    affiliate_id,
    disabled,
    background_translucency = true,
    name = "",
    cookie_days_lifetime = "",
    title = {},
    subtitle = {},
    text = {},
    button_text = {},
    buy_button_text = {},
    call_to_action = {},
    background_url = "",
    partner_logo_url = "",
    social_background_url = "",
    social_title = "",
    social_text = "",
    template_id = "",
    quota = "",
    plan = "no",
    discount = {},
    discount_id = "",
    user_type = "",
    remaining_message = "time",
    expired_at = "",
    condition_id,
    conditions = {}, // condition and invite code condition objects
    isLoading,
    isBackgroundUrlFetching,
    isPartnerLogoFetching,
    isSocialBackgroundUrlFetching,
    onChange,
    onLanguageFieldChange,
    onSubmit,
    onDelete,
    onUpload,
    onDiscountConditionSelect
}) {
    const classes = useStyles();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={classes.root}>
            {isLoading === true && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <Paper className={classes.paper}>
                <form className={classes.form} noValidate>
                    {id && (
                        <div>
                            <Typography color="secondary">Campaign ID: {id}</Typography>
                        </div>
                    )}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Boolean(disabled)}
                                onChange={e => {
                                    onChange("disabled", e.target.checked);
                                }}
                                value={Boolean(disabled)}
                            />
                        }
                        label="Disabled (if checked campaign will be disabled for users)"
                    />
                    <TextField
                        value={name}
                        fullWidth
                        required
                        label="Name (https://bouncecast.app/c/campaign-name-here)"
                        placeholder="textwithoutspaces"
                        onChange={e => {
                            onChange("name", e.target.value);
                        }}
                        disabled={isLoading}
                    />
                    <TextField
                        value={affiliate_id}
                        fullWidth
                        label="affiliate ID (won't be overwritten by idev param)"
                        placeholder="00000"
                        onChange={e => {
                            onChange("affiliate_id", e.target.value);
                        }}
                        disabled={isLoading}
                    />
                    <TextField
                        value={quota}
                        fullWidth
                        label="Offer quota left (-1 is unlimited)"
                        placeholder=""
                        onChange={e => {
                            onChange("quota", e.target.value);
                        }}
                        type="number"
                        disabled={isLoading}
                    />
                    <TextField
                        value={cookie_days_lifetime}
                        fullWidth
                        required
                        label="Cookie lifetime in days (when will expire user won't be hooked on campaign)"
                        placeholder=""
                        onChange={e => {
                            onChange("cookie_days_lifetime", e.target.value);
                        }}
                        type="number"
                        disabled={isLoading}
                    />
                    <div className={classes.userType}>
                        <FormLabel htmlFor="user_type">
                            Who can apply on the campaign offer
                        </FormLabel>
                        <Select
                            fullWidth
                            label="User type"
                            value={user_type}
                            onChange={e => {
                                onChange("user_type", e.target.value);
                            }}
                            inputProps={{
                                name: "user_type",
                                id: "user_type"
                            }}
                        >
                            <MenuItem value={"all"}>For all users</MenuItem>
                            <MenuItem value={"new"}>Only new users</MenuItem>
                            <MenuItem value={"existing"}>Only existing users</MenuItem>
                        </Select>
                    </div>
                    <Grid container className={classes.borderWrapper} direction="column">
                        <Grid item className={classes.subItem}>
                            <FormLabel htmlFor="discount_condtion">Discount condition</FormLabel>
                        </Grid>

                        <Grid item className={classes.subItem}>
                            {conditions.condition && conditions.condition.comment && (
                                <>Comment: {conditions.condition.comment}</>
                            )}
                        </Grid>
                        {conditions.condition && (
                            <Grid item className={classes.subItem}>
                                <FormLabel>
                                    User will get this{" "}
                                    {getDiscountOrGiftMessage(conditions.condition.offer)} on apply:
                                </FormLabel>
                            </Grid>
                        )}
                        <Grid item>
                            {conditions.condition && (
                                <DiscountOfferInfo offer={conditions.condition.offer} />
                            )}
                        </Grid>
                        {conditions.inviteCodeCondition && (
                            <Grid item className={classes.subItem}>
                                <FormLabel>
                                    User will receive invite codes with this{" "}
                                    {getDiscountOrGiftMessage(conditions.inviteCodeCondition.offer)}
                                    :
                                </FormLabel>
                            </Grid>
                        )}
                        <Grid item className={classes.subItem}>
                            {conditions.inviteCodeCondition && (
                                <DiscountOfferInfo offer={conditions.inviteCodeCondition.offer} />
                            )}
                        </Grid>
                        <Grid item>
                            <DiscountSelect
                                title="Select a discount condition"
                                buttonText="select a discount condition"
                                onSelect={discount => {
                                    onChange("condition_id", discount.discount_condition.id);
                                    if (onDiscountConditionSelect) {
                                        onDiscountConditionSelect(discount);
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>

                    <div>
                        <InputLabel htmlFor="remaining_message">
                            Remaining message (show expiration date or offer quota left)
                        </InputLabel>
                        <Select
                            fullWidth
                            label="Remaining message (show expiration date or licenses left)"
                            value={remaining_message}
                            onChange={e => {
                                onChange("remaining_message", e.target.value);
                            }}
                            inputProps={{
                                name: "remaining_message",
                                id: "remaining_message"
                            }}
                        >
                            <MenuItem value={"offer"}>Offers left</MenuItem>
                            <MenuItem value={"time"}>Time to campaign end</MenuItem>
                            <MenuItem value={"offer-time"}>Both (offers/time)</MenuItem>
                            <MenuItem value={"nothing"}>Nothing</MenuItem>
                        </Select>
                    </div>

                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Expiration date"
                            format="dd.MM.yyyy"
                            value={!expired_at ? new Date() : new Date(expired_at)}
                            onChange={dateTime => {
                                onChange("expired_at", dateTime.toISO());
                            }}
                            KeyboardButtonProps={{
                                "aria-label": "change date"
                            }}
                        />
                    </MuiPickersUtilsProvider>

                    <FileUpload
                        fileUrl={partner_logo_url}
                        label="Partner logo (.png or .jpeg)"
                        name="partner_logo_url"
                        buttonText="Upload logo image"
                        onUpload={onUpload}
                        isLoading={isPartnerLogoFetching}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Boolean(background_translucency)}
                                onChange={e => {
                                    onChange("background_translucency", e.target.checked);
                                }}
                                value={Boolean(background_translucency)}
                            />
                        }
                        label="Background translucency (adds translucency on to campaign hero block)"
                    />
                    <FileUpload
                        fileUrl={background_url}
                        label="Background image (.png or .jpeg)"
                        name="background_url"
                        buttonText="Upload background image"
                        onUpload={onUpload}
                        isLoading={isBackgroundUrlFetching}
                    />
                    <LanguageTextField
                        name="title"
                        title="Campaign title"
                        value={title}
                        onChange={(lang, value) => {
                            onLanguageFieldChange("title", lang, value);
                        }}
                    />
                    <LanguageTextField
                        name="subtitle"
                        title="Campaign subtitle"
                        value={subtitle}
                        onChange={(lang, value) => {
                            onLanguageFieldChange("subtitle", lang, value);
                        }}
                    />
                    <LanguageTextField
                        name="text"
                        multiline={true}
                        title="Campaign main text"
                        value={text}
                        onChange={(lang, value) => {
                            onLanguageFieldChange("text", lang, value);
                        }}
                    />
                    <LanguageTextField
                        name="text"
                        multiline={true}
                        title="Apply button text (btn on campaign page)"
                        value={button_text}
                        onChange={(lang, value) => {
                            onLanguageFieldChange("button_text", lang, value);
                        }}
                    />
                    <LanguageTextField
                        name="text"
                        multiline={true}
                        title="Buy button text (btn on the main page)"
                        value={buy_button_text}
                        onChange={(lang, value) => {
                            onLanguageFieldChange("buy_button_text", lang, value);
                        }}
                    />
                    <LanguageTextField
                        name="text"
                        multiline={true}
                        title="Call to action text (will be displayed on the main page)"
                        value={call_to_action}
                        onChange={(lang, value) => {
                            onLanguageFieldChange("call_to_action", lang, value);
                        }}
                    />
                    <div className={classes.borderWrapper}>
                        <TextField
                            fullWidth
                            value={social_title}
                            label="Social title"
                            placeholder=""
                            onChange={e => {
                                onChange("social_title", e.target.value);
                            }}
                            disabled={isLoading}
                        />
                        <TextField
                            fullWidth
                            value={social_text}
                            label="Social text"
                            placeholder=""
                            onChange={e => {
                                onChange("social_text", e.target.value);
                            }}
                            disabled={isLoading}
                        />
                        <br />
                        <FileUpload
                            fileUrl={social_background_url}
                            label="Social background image (.png or .jpeg)"
                            name="social_background_url"
                            buttonText="Upload background image"
                            onUpload={onUpload}
                            isLoading={isSocialBackgroundUrlFetching}
                        />
                    </div>
                </form>
                <div className={classes.bottom}>
                    <div>{!id && "Not saved (new campaign)"}</div>
                    <IconButton
                        className={classes.saveBtn}
                        onClick={() => {
                            onSubmit(id);
                        }}
                        disabled={isLoading}
                    >
                        <SaveIcon />
                    </IconButton>
                </div>
            </Paper>
        </div>
    );
}

CampaingAddForm.propsTypes = {
    id: PropTypes.string,
    keyText: PropTypes.string,
    text: PropTypes.string,
    lang: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onTextInput: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};
