import { TICKER_ADD_EMPTY, TICKER_UPDATE, TICKER_SET } from "../constants/ticker";

const initialState = {
    items: {}
};

export default function ticker(state = initialState, action) {
    switch (action.type) {
        case TICKER_SET:
            return { ...state, items: action.payload };
        case TICKER_ADD_EMPTY:
            return {
                ...state,
                items: { "0": { id: "0", key_text: "", text: "", lang: "en" }, ...state["items"] }
            };
        case TICKER_UPDATE:
            if (action.payload.id === undefined) {
                return state;
            }
            const item = state.items[action.payload.id] || {};
            item[action.payload.field] = action.payload.value;

            return { ...state, items: { ...state.items, [action.payload.id]: item } };
        default:
            return state;
    }
}
