import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as campaignActions from "../../actions/campaign";
import Typography from "@material-ui/core/Typography";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageTitle from "../../components/PageTitle";
import AddIcon from "@material-ui/icons/Add";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import CampaingAddForm from "../../components/CampaingAddForm";
import CampaignList from "../../components/CampaignList";
import { Route, Switch } from "react-router-dom";
import { usePrevious } from "../../utils/hooks";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        position: "relative"
    },
    spinnerWrapper: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: "2"
    },
    header: {
        marginBottom: 15
    },
    formWrapper: {
        padding: 15,
        flexGrow: 1,
        height: "70vh",
        overflow: "auto",
        position: "relative"
    },
    saveTabBtn: {
        position: "absolute",
        right: 0,
        bottom: 0,
        color: green[500]
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    runButton: {
        backgroundColor: green[500],
        color: "white",
        width: 200
    },
    tabs: {
        marginBottom: 15
    },
    runButtonWraper: {
        display: "flex",
        justifyContent: "flex-end"
    },
    buttonWraper: {
        marginRight: 10
    },
    btnLinks: {
        marginTop: 10,
        "& a": {
            textDecoration: "underline"
        }
    }
}));

const DashBoardCampaigns = ({
    match,
    campaign = {},
    conditions = {},
    items,
    push,
    isCampaignFetching,
    isSavingFetching,
    isBackgroundUrlFetching,
    isPartnerLogoFetching,
    isSocialBackgroundUrlFetching,
    campaignActions
}) => {
    const classes = useStyles();
    const prevMatchAction = usePrevious(match.params.action);

    React.useEffect(() => {
        if (match.params.action === "edit" && prevMatchAction !== "edit") {
            campaignActions.loadCampaign(match.params.id);
        }
    }, [match, campaignActions, prevMatchAction]);

    const handleInput = React.useCallback(
        (field, value) => {
            if (field === "name") {
                value = value.replace(/([\s])+/g, "");
            }
            campaignActions.updateItemField(field, value);
        },
        [campaignActions]
    );

    const handleLanguageInput = React.useCallback(
        (field, lang, value) => {
            campaignActions.updateItemLanguageField(field, lang, value);
        },
        [campaignActions]
    );

    const handleSubmit = React.useCallback(
        id => {
            campaignActions.save(id);
        },
        [campaignActions]
    );

    const handleUpload = React.useCallback(
        (name, data) => {
            campaignActions.uploadImage(data, name);
        },
        [campaignActions]
    );

    const handleDiscountConditionSelect = React.useCallback(
        (discount) => {
            // update discount condition object
            campaignActions.setDiscountCondition(discount.discount_condition);
            // update invite code discount condition object
            campaignActions.setInviteCodeDiscountCondition(discount.invite_code_discount_condition);
        },
        [campaignActions]
    );

    return (
        <div className={classes.root}>
            {isSavingFetching === true && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <Grid container spacing={0} justify="space-between" className={classes.header}>
                <Grid item xs={6}>
                    <PageTitle title="Campaigns" />
                    <Typography color="textSecondary">Campaigns manager</Typography>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.runButtonWraper}>
                        <Button
                            className={classes.runButton}
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                campaignActions.campaignResetForm();
                                push("/dashboard/campaigns/new");
                            }}
                        >
                            Add new
                            <AddIcon className={classes.rightIcon} />
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <Switch>
                <Route
                    path={"/dashboard/campaigns/new"}
                    render={() => {
                        return (
                            <CampaingAddForm
                                {...campaign}
                                isEditMode={false}
                                conditions={conditions}
                                onChange={handleInput}
                                onLanguageFieldChange={handleLanguageInput}
                                onUpload={handleUpload}
                                isBackgroundUrlFetching={isBackgroundUrlFetching}
                                isPartnerLogoFetching={isPartnerLogoFetching}
                                onSubmit={handleSubmit}
                                onDiscountConditionSelect={handleDiscountConditionSelect}
                            />
                        );
                    }}
                />
                <Route
                    path={"/dashboard/campaigns/edit/:id?"}
                    render={({ match }) => {
                        return (
                            <CampaingAddForm
                                {...campaign}
                                isEditMode={true}
                                conditions={conditions}
                                onChange={handleInput}
                                onLanguageFieldChange={handleLanguageInput}
                                onUpload={handleUpload}
                                isLoading={isCampaignFetching}
                                isBackgroundUrlFetching={isBackgroundUrlFetching}
                                isPartnerLogoFetching={isPartnerLogoFetching}
                                isSocialBackgroundUrlFetching={isSocialBackgroundUrlFetching}
                                onSubmit={handleSubmit}
                                onDiscountConditionSelect={handleDiscountConditionSelect}
                            />
                        );
                    }}
                />
                <Route exact path={"/dashboard/campaigns"} component={CampaignList} />
            </Switch>
        </div>
    );
};

function mapStateToProps({ campaign, campaigns, state }) {
    return {
        campaign: campaign.data,
        conditions: {
            condition: campaign.condition,
            inviteCodeCondition: campaign.inviteCodeCondition
        },
        items: campaigns.items,
        isSavingFetching: state.campaignSaveFetching,
        isCampaignFetching: state.campaignFetching,
        isBackgroundUrlFetching: state.campaignbackground_urlFetching, // prop name built on the fly campaign{field}Fetching
        isPartnerLogoFetching: state.campaignpartner_logo_urlFetching, // prop name built on the fly campaign{field}Fetching
        isSocialBackgroundUrlFetching: state.campaignsocial_background_urlFetching // prop name built on the fly campaign{field}Fetching
    };
}

function mapDispatchToProps(dispatch) {
    return {
        campaignActions: bindActionCreators(campaignActions, dispatch),
        push: url => {
            dispatch(push(url));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashBoardCampaigns));
