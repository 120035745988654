import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reportsActions from "../../actions/reports";
import Typography from "@material-ui/core/Typography";
import {
    Grid,
    ButtonGroup,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Toolbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageTitle from "../../components/PageTitle";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import Chart from "react-google-charts";
import CampaignReprotToolBar from "../../components/CampaignReprotToolBar";
import { FunnelChart as FunnelPipeLineChart } from "react-funnel-pipeline";
import { getHumanPlanName } from "../../utils";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },
    spinnerWrapper: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: "2",
    },
    header: {
        marginBottom: 15,
    },
    formWrapper: {
        padding: 15,
        flexGrow: 1,
        height: "70vh",
        overflow: "auto",
        position: "relative",
    },
    saveTabBtn: {
        position: "absolute",
        right: 0,
        bottom: 0,
        color: green[500],
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    runButton: {
        backgroundColor: green[500],
        color: "white",
        width: 200,
    },
    tabs: {
        marginBottom: 15,
    },
    runButtonWraper: {
        display: "flex",
        justifyContent: "flex-end",
    },
    buttonWraper: {
        marginRight: 10,
    },
    btnLinks: {
        marginTop: 10,
        "& a": {
            textDecoration: "underline",
        },
    },
}));

const DashBoardCampaignsReport = ({
    match,
    campaign = {},
    uniqueValues = {},
    charts,
    funnel,
    funnelTotal,
    subscriptionsTotal,
    items,
    push,
    isLoading,
    reportsActions,
}) => {
    const classes = useStyles();
    const [chartType, setchartType] = React.useState("AreaChart");
    const [dateRangeTitle, setDateRangeTitle] = React.useState("");

    const handleSubmit = React.useCallback(({ dateRangeTitle }) => {
        setDateRangeTitle(dateRangeTitle);
    }, []);
    console.log(subscriptionsTotal);
    return (
        <div className={classes.root}>
            {isLoading === true && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <Grid
                container
                spacing={0}
                justify="space-between"
                className={classes.header}
            >
                <Grid item xs={12}>
                    <PageTitle title={`Report: ${campaign && campaign.name}`} />
                    <Typography color="textSecondary">
                        Campaign reports, statistic
                    </Typography>
                </Grid>
            </Grid>
            <div className={classes.header}>
                <CampaignReprotToolBar onSubmit={handleSubmit} />
            </div>

            <Grid container spacing={2} alignItems="center">
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Toolbar>
                        <Typography
                            sx={{ flex: "1 1 100%" }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            Plan types for the entire period (since the creation of the campaign)
                        </Typography>
                    </Toolbar>
                    <TableContainer>
                        <Table sx={{ minWidth: 350 }} title="fdfdf">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Plan</TableCell>
                                    <TableCell>Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subscriptionsTotal.map((subsTotalRow) => (
                                    <TableRow key={subsTotalRow.plan}>
                                        <TableCell align="left">
                                            {getHumanPlanName(
                                                subsTotalRow.plan
                                            )}
                                        </TableCell>
                                        <TableCell align="left">
                                            {subsTotalRow.cnt}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={1} />
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FunnelPipeLineChart
                        title="Total since the creation of the campaign"
                        chartHeight="100%"
                        heightRelativeToValue={true}
                        showNames={true}
                        showValues={true}
                        style={{
                            marginTop: 100,
                        }}
                        data={funnelTotal}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FunnelPipeLineChart
                        title={`Date range: ${dateRangeTitle}`}
                        chartHeight="100%"
                        heightRelativeToValue={true}
                        showNames={true}
                        showValues={true}
                        style={{
                            marginTop: 100,
                        }}
                        data={funnel}
                    />
                </Grid>
            </Grid>

            <div>
                <Chart
                    width={"100%"}
                    height={"70vh"}
                    chartType={chartType}
                    loader={<div>Loading Chart</div>}
                    data={charts["total"]}
                    options={{
                        title: `Date range: ${dateRangeTitle}`,
                        legend: { position: "bottom", alignment: "start" },
                        animation: {
                            startup: true,
                            easing: "linear",
                            duration: 500,
                        },
                        enableInteractivity: true,
                        backgroundColor: "#fafafa",
                        chart: {
                            title: "",
                            subtitle: "",
                        },
                        hAxis: {
                            title: "Date",
                        },
                        vAxis: {
                            title: "Events",
                        },
                    }}
                    rootProps={{ "data-testid": "2" }}
                />
                <Grid
                    className={classes.chartTypeButtons}
                    container
                    direction="row"
                    justify="center"
                >
                    <Grid item>
                        <ButtonGroup size="small" aria-label="Chart type">
                            <Button
                                disabled={chartType === "AreaChart"}
                                onClick={() => setchartType("AreaChart")}
                            >
                                Area
                            </Button>
                            <Button
                                disabled={chartType === "ColumnChart"}
                                onClick={() => setchartType("ColumnChart")}
                            >
                                Column
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

function mapStateToProps({ campaignReport }) {
    return {
        charts: campaignReport.charts,
        funnel: campaignReport.funnel,
        funnelTotal: campaignReport.funnel_total,
        subscriptionsTotal: campaignReport.subscriptions_total || [],
        campaign: campaignReport.campaign,
        uniqueValues: campaignReport.unique_values,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        reportsActions: bindActionCreators(reportsActions, dispatch),
        push: (url) => {
            dispatch(push(url));
        },
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DashBoardCampaignsReport));
