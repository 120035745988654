import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as codesActions from "../../actions/codes";
import { Paper, Button, Grid, Input } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const CodesRevoke = ({ codesActions }) => {
    const classes = useStyles();
    const [codes, setcodes] = React.useState("");

    const handleSubmit = () => {
        /*eslint-disable*/
        if (confirm("All users who have applied these codes will get NO plan. Continue?")) {
            codesActions.revokeCodes(codes);
        }
        /*eslint-enable*/
    };

    return (
        <Paper className={classes.root}>
            <Grid container spacing={2} direction="column" justify="flex-start">
                <Grid spacing={4} item>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Revoke codes
                    </Button>
                </Grid>
                <Grid item>
                    <Input
                        value={codes}
                        onChange={(e) => setcodes(e.target.value)}
                        multiline
                        placeholder="Insert 1 code per line"
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

function mapStateToProps({ state }) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        codesActions: bindActionCreators(codesActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CodesRevoke);
