import React from "react";
import {
    DISCOUNT_TYPE_GIFT,
    DISCOUNT_TYPE_DISCOUNT_SUBSCRIPTION,
    DISCOUNT_TYPE_DISCOUNT_ONE_TIME_PAYMENT
} from "../../constants/discount";
import { makeStyles } from "@material-ui/core/styles";
import { green, yellow } from "@material-ui/core/colors";
import { getHumanPlanName } from "../../utils";
import { PLAN_GOD } from "../../constants/payments";

const useDiscountOfferInfoStyles = makeStyles({
    root: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        position: "relative"
    }
});

const useDisplayStyles = makeStyles((theme) => ({
    root: {
        border: "1px dashed",
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2),
        position: "relative",
        "&::before": {
            content: (props) => `'${props.typeIcon || ""}'`,
            top: "-16px",
            left: "-16px",
            display: "block",
            position: "absolute",
            fontSize: "28px"
        }
    },
    isGod: {
        color: green[700]
    },
    stackable: {
        color: green[700]
    },
    isMini: {
        color: yellow[700]
    },
    gift: {}
}));

const DisplayGiftType = ({ offer = {} }) => {
    const classes = useDisplayStyles({ typeIcon: "🎁" });

    return (
        <div className={classes.root}>
            <div>Plan: {getHumanPlanName(offer.isGod ? PLAN_GOD : offer.subject)}</div>
            {offer.isGod && (
                <div className={classes.isGod}>
                    GOD{" "}
                    <span role="img" aria-label="is GOD">
                        🧙🏻‍♂
                    </span>
                </div>
            )}
            {offer.mini && (
                <div className={classes.isMini}>
                    CloudBounce MINI{" "}
                    {offer.mini.upgradePrice && <span>[${offer.mini.upgradePrice}] </span>}
                    <span role="img" aria-label="is mini">
                        ✂️
                    </span>
                </div>
            )}
            {offer.stackable && (
                <div className={classes.stackable}>
                    Stackable{" "}
                    <span role="img" aria-label="Stackable">
                        🧩
                    </span>
                </div>
            )}
            {offer.extraDurationValue && (
                <div className={classes.stackable}>
                    Extra duration [{offer.extraDurationValue}]{" "}
                    <span role="img" aria-label="Stackable">
                        🥉
                    </span>
                </div>
            )}
        </div>
    );
};
const DisplayDiscountType = ({ offer = {} }) => {
    const classes = useDisplayStyles({ typeIcon: "💲" });

    return (
        <div>
            {offer.subjects &&
                Object.keys(offer.subjects).map((plan, i) => {
                    return (
                        <div className={classes.root} key={`DisplayDiscountType_${i}`}>
                            <div>Plan: {getHumanPlanName(offer.subjects[plan].subject)}</div>
                            <div>Discount: {offer.subjects[plan].amount}$</div>
                            {offer.subjects[plan].coinbase && (
                                <div>
                                    Coinbase final price:{" "}
                                    {offer.subjects[plan].coinbase.local_price.amount}{" "}
                                    {offer.subjects[plan].coinbase.local_price.currency}
                                </div>
                            )}
                            {offer.subjects[plan].mini && (
                                <div className={classes.isMini}>
                                    CloudBounce MINI{" "}
                                    {offer.subjects[plan].mini.upgradePrice && (
                                        <span>[${offer.subjects[plan].mini.upgradePrice}] </span>
                                    )}
                                    <span role="img" aria-label="is mini">
                                        ✂️
                                    </span>
                                </div>
                            )}
                            {offer.subjects[plan].isGod && (
                                <div className={classes.isGod}>
                                    DISCOUNT NEVER EXPIRES{" "}
                                    <span role="img" aria-label="is GOD">
                                        🧙🏻‍♂
                                    </span>
                                </div>
                            )}
                            {offer.subjects[plan].stackable && (
                                <div className={classes.stackable}>
                                    Stackable{" "}
                                    <span role="img" aria-label="Stackable">
                                        🧩
                                    </span>
                                </div>
                            )}
                            {offer.subjects[plan].cycles && (
                                <div>Cycles: {offer.subjects[plan].cycles}</div>
                            )}
                            {offer.subjects[plan].trial && (
                                <div className={classes.trial}>
                                    Trial: {offer.subjects[plan].trial.trialDuration}{" "}
                                    {offer.subjects[plan].trial.trialDurationUnit.toUpperCase()}{" "}
                                    <span role="img" aria-label="Trial">
                                        ⏳
                                    </span>
                                </div>
                            )}
                        </div>
                    );
                })}
        </div>
    );
};

const DisplayDiscountTypeOneTimePayment = ({ offer = {} }) => {
    const classes = useDisplayStyles({ typeIcon: "💲" });

    return (
        <div>
            {offer.subjects &&
                Object.keys(offer.subjects).map((plan, i) => {
                    return (
                        <div className={classes.root} key={`DisplayDiscountType_${i}`}>
                            <div>Plan: {getHumanPlanName(plan)}</div>
                            <div>Final price: {offer.subjects[plan].finalPrice}$</div>
                            {offer.subjects[plan].coinbase && (
                                <div>
                                    Coinbase final price:{" "}
                                    {offer.subjects[plan].coinbase.local_price.amount}{" "}
                                    {offer.subjects[plan].coinbase.local_price.currency}
                                </div>
                            )}
                            {offer.subjects[plan].mini && (
                                <div className={classes.isMini}>
                                    CloudBounce MINI{" "}
                                    {offer.subjects[plan].mini.upgradePrice && (
                                        <span>[${offer.subjects[plan].mini.upgradePrice}] </span>
                                    )}
                                    <span role="img" aria-label="is mini">
                                        ✂️
                                    </span>
                                </div>
                            )}
                            {plan === PLAN_GOD && (
                                <div className={classes.isGod}>
                                    GOD{" "}
                                    <span role="img" aria-label="is GOD">
                                        🧙🏻‍♂
                                    </span>
                                </div>
                            )}
                        </div>
                    );
                })}
        </div>
    );
};

const getDisplayComponent = (type) => {
    let Component = null;

    switch (type) {
        case DISCOUNT_TYPE_GIFT:
            Component = DisplayGiftType;
            break;
        case DISCOUNT_TYPE_DISCOUNT_SUBSCRIPTION:
            Component = DisplayDiscountType;
            break;
        case DISCOUNT_TYPE_DISCOUNT_ONE_TIME_PAYMENT:
            Component = DisplayDiscountTypeOneTimePayment;
            break;
        default:
            Component = () => <div>No discount</div>;
    }

    return Component;
};

const DiscountOfferInfo = ({ offer = {} }) => {
    const classes = useDiscountOfferInfoStyles();
    const DisplayComponent = getDisplayComponent(offer.type);

    return (
        <div className={classes.root}>
            <DisplayComponent offer={offer} />
        </div>
    );
};

export default DiscountOfferInfo;
