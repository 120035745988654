import { SET_USER, LOGOUT_USER, SET_USER_ERRORS } from "../constants/user";

const initialState = {
    id: null,
    errors: []
};

export default function user(state = initialState, action) {
    switch (action.type) {
        case SET_USER:
            return setUser(state, action);
        case LOGOUT_USER:
            return {
                ...state,
                id: null,
                errors: []
            };
        case SET_USER_ERRORS:
            return {...state, errors: action.payload}
        default:
            return state;
    }
}

function setUser(state, action) {
    return {
        ...state,
        id: action.payload.id,
        firstname: action.payload.firstname || "",
        lastname: action.payload.lastname || "",
        email: action.payload.email || "",
        errors: []
    };
}
