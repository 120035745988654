import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as quizActions from "../../actions/quiz";
import Typography from "@material-ui/core/Typography";
import {
    Grid,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    Paper,
    IconButton,
    Modal,
    Fab,
    DialogTitle
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageTitle from "../../components/PageTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { green, red } from "@material-ui/core/colors";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { usePrevious } from "../../utils/hooks";
import LanguageTextField from "../../components/LanguageTextField";
import Chart from "react-google-charts";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    content: {
        padding: theme.spacing(2)
    },
    spinnerWrapper: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: "2"
    },
    header: {
        marginBottom: 15
    },
    add: {
        backgroundColor: green[500],
        color: "white",
        width: 200
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    answer: {
        marginBottom: theme.spacing(1)
    },
    answers: {
        width: "100%",
        padding: theme.spacing(2)
    }
}));

const QuizResult = ({ match, quizActions, isLoading }) => {
    const classes = useStyles();
    const [quizResult, setQuizResult] = React.useState();
    const prevMatchUserId = usePrevious(match.params.id);

    React.useEffect(() => {
        if (match.params.id && prevMatchUserId !== match.params.id) {
            quizActions.loadQuizResult(match.params.id, (err, data) => {
                if (!err) {
                    setQuizResult(data);
                }
            });
        }
    }, [prevMatchUserId, quizActions, match, isLoading]);

    return (
        <div className={classes.root}>
            {isLoading && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            {isLoading === false && quizResult && (
                <>
                    <Grid container spacing={0} justify="space-between" className={classes.header}>
                        <Grid item xs={6}>
                            <PageTitle
                                title={
                                    isLoading
                                        ? "Loading..."
                                        : `${(quizResult && quizResult.name) || ""}`
                                }
                            />
                            <Typography color="textSecondary">
                                {(quizResult && quizResult.title) || ""}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="center">
                        <Grid item xs={12}>
                            <Chart
                                width={"100%"}
                                height={"70vh"}
                                chartType="PieChart"
                                loader={
                                    <div>
                                        <CircularProgress size={20} />
                                    </div>
                                }
                                data={quizResult.data}
                                options={{
                                    legend: { position: "bottom", alignment: "start" },
                                    animation: {
                                        startup: true,
                                        easing: "linear",
                                        duration: 500
                                    },
                                    bars: "vertical",
                                    enableInteractivity: true,
                                    backgroundColor: "#fafafa",
                                    curveType: "function",
                                    hAxis: {
                                        title: "Votes"
                                    },
                                    vAxis: {
                                        title: quizResult.title || ""
                                    }
                                }}
                            />
                        </Grid>
                        {quizResult.custom_answers && quizResult.custom_answers.length > 0 && (
                            <Grid container>
                                <Paper className={classes.answers}>
                                    <Grid item xs={6}>
                                        <Typography h2 color="textSecondary">
                                            Answers:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {quizResult.custom_answers.map((answ, i) => (
                                            <div className={classes.answer} key={`${i}an`}>{answ}</div>
                                        ))}
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                </>
            )}
        </div>
    );
};

function mapStateToProps({ state }) {
    return {
        isLoading: state.quizResultFetching
    };
}

function mapDispatchToProps(dispatch) {
    return {
        quizActions: bindActionCreators(quizActions, dispatch),
        push: (url) => {
            dispatch(push(url));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuizResult));
