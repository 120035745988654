import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as quizActions from "../../actions/quiz";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { push } from "connected-react-router";
import EditIcon from "@material-ui/icons/Edit";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import { IconButton, CircularProgress } from "@material-ui/core";

const columns = [
    { id: "id", label: "ID" },
    { id: "isActive", label: "Active", align: "center" },
    { id: "voteOnce", label: "Vote once", align: "center" },
    { id: "name", label: "Name" },
    { id: "type", label: "Type", align: "center" },
    { id: "created_at", label: "Created at", align: "center" },
    {
        id: "edit",
        label: "",
        align: "right"
    }
];

const useStyles = makeStyles({
    root: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        position: "relative"
    },
    tableWrapper: {
        overflow: "auto"
    },
    spinnerWrapper: {
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "60vh",
        zIndex: "2"
    },
    red: {
        color: "red"
    }
});

function QuizTable({ rows = [], actions = {}, onRowClick }) {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.id}
                                    onClick={() => {
                                        if (onRowClick) {
                                            onRowClick();
                                        }
                                    }}
                                >
                                    {columns.map((column) => {
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {row[column.id]}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        </Paper>
    );
}

const makeRowsData = (items, onEditClick, onShowReslut) => {
    let rows = [];

    for (const id of Object.keys(items)) {
        rows.push({
            id,
            name: items[id].name,
            isActive: items[id].is_active ? "Yes" : "No",
            voteOnce: items[id].vote_once ? "Yes, restrictions by IP" : "No, no IP restrictions",
            type: items[id].type,
            created_at: items[id].created_at,
            edit: (
                <>
                    <IconButton
                        onClick={() => {
                            if (onShowReslut) {
                                onShowReslut(id);
                            }
                        }}
                    >
                        <ShowChartIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            if (onEditClick) {
                                onEditClick(id);
                            }
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                </>
            )
        });
    }

    return rows.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
    });
};

const QuizList = ({ items = {}, isQuizFetching, quizActions, goToPage }) => {
    const classes = useStyles();

    React.useEffect(() => {
        quizActions.loadQuizzes();
    }, [quizActions]);

    const handleEditClick = React.useCallback(
        (id) => {
            goToPage(`/dashboard/quiz/edit/${id}`);
        },
        [goToPage]
    );

    const handleResultClick = React.useCallback(
        (id) => {
            goToPage(`/dashboard/quiz/result/${id}`);
        },
        [goToPage]
    );

    return (
        <div className={classes.root}>
            {isQuizFetching === true && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <QuizTable
                rows={makeRowsData(items, handleEditClick, handleResultClick)}
                actions={quizActions}
            />
        </div>
    );
};

function mapStateToProps({ quizzes, state }) {
    return {
        items: quizzes.items,
        isQuizFetching: state.quizFetching
    };
}

function mapDispatchToProps(dispatch) {
    return {
        quizActions: bindActionCreators(quizActions, dispatch),
        goToPage: (url) => {
            dispatch(push(url));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuizList);
