import React from "react";
import { widgetStyles, WidgetHeader, FilterButton } from "../common";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as widgetsActions from "../../../actions/widgets";
import { CircularProgress, IconButton, Paper, Modal, DialogTitle, Grid } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import { green, grey } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";
import Chart from "react-google-charts";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { CUSTOM_FILTER_PREFIX } from "../../../constants/widgets";

export const styles = makeStyles((theme) => ({
    body: {
        position: "relative",
        width: "100%",
        height: "100%",
        fontSize: 21
    },
    grow: {
        color: green[400]
    },
    label: {
        color: grey[500]
    },
    zoomIcon: {
        position: "absolute",
        right: 0,
        top: 0,
        zIndex: 12
    },
    filterIcon: {
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 12
    },
    modal: {
        height: "100%",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(3),
        boxSizing: "border-box"
    },
    modalBody: {
        height: "90vh",
        width: "100%",
        overflowX: "scroll",
        position: "relative"
    },
    modalBodyContent: {
        padding: theme.spacing(3),
        height: "90vh",
        width: "100%",
        filter: (props) => (props.isFilterWindowOpenedWithinModal ? "blur(6px)" : "")
    },
    bottom: {
        padding: theme.spacing(2)
    },
    modalCloseBtn: {
        position: "absolute",
        right: 16,
        top: 10,
        zIndex: 2
    },
    modalTitle: {
        position: "absolute",
        left: 10,
        top: 10,
        zIndex: 2,
        textAlign: "center",
        width: "100%"
    },
    chartTypeButtons: {
        width: "100%",
        marginTop: theme.spacing(1)
    },
    chartBody: {
        flexGrow: "1"
    },
    chartBodyInner: {
        height: "223px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    chartBodyInnerModal: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    contentBody: {
        height: "100%"
    }
}));

const WidgetChartColumn = React.memo(
    ({ data, isStacked = "", height = 100, hAxisName = "", vAxisName = "" }) => {
        const options = {
            legend: { position: "bottom", alignment: "start" },
            animation: {
                startup: true,
                easing: "linear",
                duration: 500
            },
            bars: "vertical",
            enableInteractivity: true,
            backgroundColor: "white"
        };
        if (hAxisName) {
            options["hAxis"] = { title: hAxisName };
        }
        if (vAxisName) {
            options["vAxis"] = { title: vAxisName };
        }
        options["isStacked"] = isStacked;
        return (
            <Chart
                width={"100%"}
                height={`${height}%`}
                chartType="ColumnChart"
                isStacked={isStacked}
                loader={
                    <div>
                        <CircularProgress size={20} />
                    </div>
                }
                data={data}
                options={options}
                rootProps={{ "data-testid": "2" }}
            />
        );
    }
);

const WidgetChartLines = React.memo(({ data, height = 100, hAxisName = "", vAxisName = "" }) => {
    return (
        <Chart
            width={"100%"}
            height={`${height}%`}
            chartType="LineChart"
            loader={
                <div>
                    <CircularProgress size={20} />
                </div>
            }
            data={data}
            options={{
                legend: { position: "bottom", alignment: "start" },
                animation: {
                    startup: true,
                    easing: "linear",
                    duration: 500
                },
                bars: "vertical",
                enableInteractivity: true,
                backgroundColor: "white",
                curveType: "function",
                hAxis: {
                    title: hAxisName
                },
                vAxis: {
                    title: vAxisName
                }
            }}
        />
    );
});

const WidgetChartArea = React.memo(({ data, height = 100, hAxisName = "", vAxisName = "" }) => {
    return (
        <Chart
            width={"100%"}
            height={`${height}%`}
            chartType="AreaChart"
            loader={
                <div>
                    <CircularProgress size={20} />
                </div>
            }
            data={data}
            options={{
                legend: { position: "bottom", alignment: "start" },
                animation: {
                    startup: true,
                    easing: "linear",
                    duration: 500
                },
                bars: "vertical",
                enableInteractivity: true,
                backgroundColor: "white",
                curveType: "function",
                hAxis: {
                    title: hAxisName
                },
                vAxis: {
                    title: vAxisName
                }
            }}
        />
    );
});

const WidgetChartPie = React.memo(({ data, height = 100, hAxisName = "", vAxisName = "" }) => {
    return (
        <Chart
            width={"100%"}
            height={`${height}%`}
            chartType="PieChart"
            loader={
                <div>
                    <CircularProgress size={20} />
                </div>
            }
            data={data}
            options={{
                legend: { position: "bottom", alignment: "start" },
                animation: {
                    startup: true,
                    easing: "linear",
                    duration: 500
                },
                bars: "vertical",
                enableInteractivity: true,
                backgroundColor: "white",
                curveType: "function",
                hAxis: {
                    title: hAxisName
                },
                vAxis: {
                    title: vAxisName
                }
            }}
        />
    );
});

export const WIDGET_CHART_TYPE_COLUMN = "column";
export const WIDGET_CHART_TYPE_LINES = "lines";
export const WIDGET_CHART_TYPE_AREA = "area";
export const WIDGET_CHART_TYPE_PIE = "pie";

const WidgetColumnLineChart = React.memo(
    ({
        widgetsData = {},
        widgetsActions,
        widgetsLoading,
        title,
        widgetName,
        hAxisName = "",
        vAxisName = "",
        isStacked = "", // column
        useSingleChart,
        FilterCustomComponent, // filter
        customfilters = []
    }) => {
        const localStorage = window.localStorage;
        const lsWidgetName = `widget_${widgetName}_chart_type`;
        let storedChartType = localStorage.getItem(lsWidgetName)
            ? localStorage.getItem(lsWidgetName)
            : WIDGET_CHART_TYPE_AREA;

        if (useSingleChart) {
            // force rewrite
            storedChartType = useSingleChart;
        }

        const [isModalOpen, setIsModalOpen] = React.useState(false);
        const [chartType, setChartType] = React.useState(storedChartType);
        const [isCustomFilterOpen, setIsCustomFilterOpen] = React.useState(false);
        const [isCustomFilterOpenWithinModal, setIsCustomFilterOpenWithinModal] = React.useState(
            false
        );
        const isLoading = widgetsLoading[widgetName];
        const data = widgetsData[widgetName] || [];
        const widgetClasses = widgetStyles({
            isFilterWindowOpened: isCustomFilterOpen
        });
        const classes = styles({
            isFilterWindowOpenedWithinModal: isCustomFilterOpenWithinModal
        });

        React.useEffect(() => {
            widgetsActions.loadWidget(widgetName);
        }, [widgetsActions, widgetName]);

        const handleChartTypeChange = React.useCallback(
            (type) => {
                setChartType(type);
                if (!useSingleChart) {
                    localStorage.setItem(lsWidgetName, type);
                }
            },
            [localStorage, lsWidgetName, useSingleChart]
        );

        const handleRefresh = React.useCallback(
            (type) => {
                widgetsActions.loadWidget(widgetName);
            },
            [widgetName, widgetsActions]
        );

        const handleCustomFilterOpen = React.useCallback(() => {
            setIsCustomFilterOpen(true);
        }, []);

        const handleCustomFilterClose = React.useCallback(() => {
            setIsCustomFilterOpen(false);
            widgetsActions.loadWidget(widgetName);
        }, [widgetsActions, widgetName]);

        const handleCustomFilterOpenOnModal = React.useCallback(() => {
            setIsCustomFilterOpenWithinModal(true);
        }, []);

        const handleCustomFilterCloseOnModal = React.useCallback(() => {
            setIsCustomFilterOpenWithinModal(false);
            widgetsActions.loadWidget(widgetName);
        }, [widgetsActions, widgetName]);

        const renderContent = (modalWindowMode = false) => {
            return (
                <div className={classes.body}>
                    <Grid
                        container
                        direction="column"
                        wrap="nowrap"
                        justify="space-between"
                        className={classes.contentBody}
                    >
                        <Grid item>
                            {!modalWindowMode && (
                                <IconButton
                                    className={classes.zoomIcon}
                                    onClick={() => {
                                        setIsModalOpen(true);
                                    }}
                                >
                                    <ZoomInIcon />
                                </IconButton>
                            )}
                            {FilterCustomComponent && customfilters && (
                                <div className={classes.filterIcon}>
                                    <FilterButton
                                        filterName={`${CUSTOM_FILTER_PREFIX}${widgetName}`}
                                        onClick={
                                            modalWindowMode
                                                ? handleCustomFilterOpenOnModal
                                                : handleCustomFilterOpen
                                        }
                                        availableFilterItems={customfilters}
                                    />
                                </div>
                            )}
                        </Grid>
                        <Grid item className={classes.chartBody}>
                            <div
                                className={
                                    !modalWindowMode
                                        ? classes.chartBodyInner
                                        : classes.chartBodyInnerModal
                                }
                            >
                                {isLoading && (
                                    <div className={widgetClasses.spinnerWrapper}>
                                        <CircularProgress size={60} />
                                    </div>
                                )}
                                {isLoading === false && (
                                    <>
                                        {chartType === WIDGET_CHART_TYPE_AREA &&
                                            (useSingleChart === WIDGET_CHART_TYPE_AREA ||
                                                !useSingleChart) && (
                                                <WidgetChartArea
                                                    hAxisName={hAxisName}
                                                    vAxisName={vAxisName}
                                                    data={data}
                                                />
                                            )}
                                        {chartType === WIDGET_CHART_TYPE_LINES &&
                                            (useSingleChart === WIDGET_CHART_TYPE_LINES ||
                                                !useSingleChart) && (
                                                <WidgetChartLines
                                                    hAxisName={hAxisName}
                                                    vAxisName={vAxisName}
                                                    data={data}
                                                />
                                            )}
                                        {chartType === WIDGET_CHART_TYPE_COLUMN &&
                                            (useSingleChart === WIDGET_CHART_TYPE_COLUMN ||
                                                !useSingleChart) && (
                                                <WidgetChartColumn
                                                    hAxisName={hAxisName}
                                                    vAxisName={vAxisName}
                                                    isStacked={isStacked}
                                                    data={data}
                                                />
                                            )}
                                        {chartType === WIDGET_CHART_TYPE_PIE &&
                                            (useSingleChart === WIDGET_CHART_TYPE_PIE ||
                                                !useSingleChart) && (
                                                <WidgetChartPie
                                                    hAxisName={hAxisName}
                                                    vAxisName={vAxisName}
                                                    data={data}
                                                />
                                            )}
                                    </>
                                )}
                            </div>
                        </Grid>
                        {isLoading === false && (
                            <Grid
                                className={classes.chartTypeButtons}
                                container
                                direction="row"
                                justify="center"
                            >
                                <Grid item>
                                    {!useSingleChart && !isCustomFilterOpen && (
                                        <ButtonGroup size="small" aria-label="Chart type">
                                            <Button
                                                disabled={chartType === WIDGET_CHART_TYPE_AREA}
                                                onClick={() =>
                                                    handleChartTypeChange(WIDGET_CHART_TYPE_AREA)
                                                }
                                            >
                                                Area
                                            </Button>
                                            <Button
                                                disabled={chartType === WIDGET_CHART_TYPE_COLUMN}
                                                onClick={() =>
                                                    handleChartTypeChange(WIDGET_CHART_TYPE_COLUMN)
                                                }
                                            >
                                                Column
                                            </Button>
                                            <Button
                                                disabled={chartType === WIDGET_CHART_TYPE_LINES}
                                                onClick={() =>
                                                    handleChartTypeChange(WIDGET_CHART_TYPE_LINES)
                                                }
                                            >
                                                Lines
                                            </Button>
                                            <Button
                                                disabled={chartType === WIDGET_CHART_TYPE_PIE}
                                                onClick={() =>
                                                    handleChartTypeChange(WIDGET_CHART_TYPE_PIE)
                                                }
                                            >
                                                Pie
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </div>
            );
        };

        return (
            <div className={widgetClasses.root}>
                <WidgetHeader text={title} onRefreshClick={handleRefresh} />
                {isCustomFilterOpen && FilterCustomComponent && (
                    <FilterCustomComponent
                        filterName={`${CUSTOM_FILTER_PREFIX}${widgetName}`}
                        items={customfilters}
                        onClose={handleCustomFilterClose}
                    />
                )}
                <div className={widgetClasses.body}>
                    {renderContent()}
                    <Modal
                        className={classes.modal}
                        open={isModalOpen}
                        aria-labelledby="Discounts"
                        aria-describedby="Discounts"
                    >
                        <>
                            <Paper className={classes.modalBody}>
                                {isCustomFilterOpenWithinModal && FilterCustomComponent && (
                                    <FilterCustomComponent
                                        filterName={`${CUSTOM_FILTER_PREFIX}${widgetName}`}
                                        items={customfilters}
                                        onClose={handleCustomFilterCloseOnModal}
                                    />
                                )}
                                <div className={classes.modalBodyContent}>
                                    <DialogTitle className={classes.modalTitle}>
                                        {title}
                                    </DialogTitle>
                                    <Fab
                                        className={classes.modalCloseBtn}
                                        variant="round"
                                        color="primary"
                                        size="small"
                                        onClick={() => {
                                            setIsModalOpen(false);
                                        }}
                                    >
                                        <CloseIcon />
                                    </Fab>
                                    {renderContent(true)}
                                </div>
                            </Paper>
                        </>
                    </Modal>
                </div>
            </div>
        );
    }
);

function mapStateToProps({ widgets, state }) {
    return {
        widgetsData: widgets.data,
        widgetsLoading: state.widgets
    };
}

function mapDispatchToProps(dispatch) {
    return {
        widgetsActions: bindActionCreators(widgetsActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetColumnLineChart);
