import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as tickerActions from "../../actions/ticker";
import Typography from "@material-ui/core/Typography";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageTitle from "../../components/PageTitle";
import AddIcon from "@material-ui/icons/Add";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import TickerRecordForm from "../../components/TickerRecordForm";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        position: "relative"
    },
    spinnerWrapper: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: "2"
    },
    header: {
        marginBottom: 15
    },
    formWrapper: {
        padding: 15,
        flexGrow: 1,
        height: "70vh",
        overflow: "auto",
        position: "relative"
    },
    saveTabBtn: {
        position: "absolute",
        right: 0,
        bottom: 0,
        color: green[500]
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    runButton: {
        backgroundColor: green[500],
        color: "white",
        width: 200
    },
    tabs: {
        marginBottom: 15
    },
    runButtonWraper: {
        display: "flex",
        justifyContent: "flex-end"
    },
    buttonWraper: {
        marginRight: 10
    },
    btnLinks: {
        marginTop: 10,
        "& a": {
            textDecoration: "underline"
        }
    }
}));

const DashBoardTicker = ({ items, isTickerFetching, isTickerSaveFetching, tickerActions }) => {
    const classes = useStyles();

    React.useEffect(() => {
        tickerActions.loadTickers();
    }, [tickerActions]);

    const handleInput = React.useCallback(
        (id, field, value) => {
            if (field === "key_text") {
                value = value.replace(/[\W\s]+/g, "");
            }
            tickerActions.updateItem(id, field, value);
        },
        [tickerActions]
    );

    const handleSubmit = React.useCallback(
        id => {
            tickerActions.save(id);
        },
        [tickerActions]
    );

    const handleDelete = React.useCallback(
        id => {
            /* eslint-disable */
            if (confirm("Are you sure?")) {
                tickerActions.remove(id);
            }
            /* eslint-enable */
        },
        [tickerActions]
    );

    return (
        <div className={classes.root}>
            {isTickerFetching === true && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <Grid container spacing={0} justify="space-between" className={classes.header}>
                <Grid item xs={6}>
                    <PageTitle title="Tickers" />
                    <Typography color="textSecondary">
                        Tickers – hints which you see in the app during data analysis{" "}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.runButtonWraper}>
                        <Button
                            className={classes.runButton}
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                tickerActions.addEmpty();
                            }}
                        >
                            Add new
                            <AddIcon className={classes.rightIcon} />
                        </Button>
                    </div>
                </Grid>
            </Grid>
            {Object.keys(items).map((id, i) => (
                <TickerRecordForm
                    isLoading={isTickerSaveFetching}
                    id={items[id]["id"]}
                    keyText={items[id]["key_text"]}
                    text={items[id]["text"]}
                    lang={items[id]["lang"]}
                    onKeyInput={handleInput}
                    onTextInput={handleInput}
                    key={`ticker_item_${i}`}
                    onSubmit={handleSubmit}
                    onDelete={handleDelete}
                />
            ))}
        </div>
    );
};

function mapStateToProps({ ticker, state }) {
    return {
        items: ticker.items,
        isTickerSaveFetching: state.tickerSaveFetching,
        isTickerFetching: state.tickerFetching
    };
}

function mapDispatchToProps(dispatch) {
    return {
        tickerActions: bindActionCreators(tickerActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashBoardTicker);
