import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as campaignActions from "../../actions/campaign";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { push } from "connected-react-router";
import EditIcon from "@material-ui/icons/Edit";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import { IconButton, CircularProgress } from "@material-ui/core";
import DiscountOfferInfo from "../DiscountOfferInfo";
import Clipboard from "../Clipboard";
import { PROJECT_BC } from "../../constants/lang";
import CampaignFilter from "../CampaignFilter";
import { CAMPAIGN_PAGE_LIMIT } from "../../constants/campaign";

const columns = [
    { id: "name", label: "Name", minWidth: 200 },
    { id: "disabled", label: "Disabled", minWidth: 100 },
    {
        id: "quota",
        label: "Quota left",
        align: "right"
    },
    {
        id: "user_type",
        label: "Users",
        align: "right"
    },
    {
        id: "offer",
        label: "Offer",
        align: "center"
    },
    {
        id: "expired",
        label: "Expired at",
        align: "center"
    },
    {
        id: "created",
        label: "Created at",
        align: "center"
    },
    {
        id: "edit",
        label: "",
        align: "right"
    }
];

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        position: "relative"
    },
    tableWrapper: {
        overflow: "auto"
    },
    spinnerWrapper: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "60vh",
        zIndex: "2"
    },
    nameColumn: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    campaignName: {
        marginRight: theme.spacing(2)
    },
    red: {
        color: "red"
    }
}));

const RenderCellValue = ({ column, value }) => {
    const classes = useStyles();
    const useDefaultRender = !["expired", "name"].includes(column.id);

    return (
        <>
            {useDefaultRender && <div>{value}</div>}
            {column.id === "expired" && <div>{"expired" ? `💀 ${value}` : ""}</div>}
            {column.id === "name" && (
                <div className={classes.nameColumn}>
                    <Clipboard
                        title={`Copy link to clipboard https://${
                            process.env.REACT_APP_PROJECT_NAME === PROJECT_BC
                                ? "bouncecast.app"
                                : "app.cloudbounce.com"
                        }/c/${value}`}
                        value={`https://${
                            process.env.REACT_APP_PROJECT_NAME === PROJECT_BC
                                ? "bouncecast.app"
                                : "app.cloudbounce.com"
                        }/c/${value}`}
                        successMessage={
                            "Copied to your clipboard " +
                            `https://${
                                process.env.REACT_APP_PROJECT_NAME === PROJECT_BC
                                    ? "bouncecast.app"
                                    : "app.cloudbounce.com"
                            }/c/${value}`
                        }
                        className={classes.campaignName}
                    />
                    {value}
                </div>
            )}
        </>
    );
};

function CampaignTable({ rows = [], total = 0, actions = {}, onRowClick }) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(CAMPAIGN_PAGE_LIMIT);

    const handleChangePage = React.useCallback(
        (e, newPage) => {
            actions.loadCampaigns(newPage, rowsPerPage);
            setPage(newPage);
        },
        [actions, rowsPerPage]
    );

    const handleChangeRowsPerPage = React.useCallback(
        (e) => {
            setRowsPerPage(+e.target.value);
            setPage(0);
            actions.loadCampaigns(0, e.target.value);
        },
        [actions]
    );

    return (
        <>
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}
                                        onClick={() => {
                                            if (onRowClick) {
                                                onRowClick();
                                            }
                                        }}
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            const isExpired =
                                                column.id === "expired" &&
                                                new Date(row["expired_at"]).getTime() -
                                                    new Date().getTime() <
                                                    0;
                                            const className = isExpired ? classes.red : "";

                                            return (
                                                <TableCell
                                                    className={className}
                                                    key={column.id}
                                                    align={column.align}
                                                >
                                                    <RenderCellValue
                                                        column={column}
                                                        value={value}
                                                    />
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[CAMPAIGN_PAGE_LIMIT, 50, 100]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        "aria-label": "previous page"
                    }}
                    nextIconButtonProps={{
                        "aria-label": "next page"
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}

const makeRowsData = (items, onEditClick, onChartClick) => {
    let rows = [];

    for (const id of Object.keys(items)) {
        rows.push({
            id,
            name: items[id].name,
            disabled: items[id].disabled === true ? "🛑 Yes" : "No",
            cookie_days_lifetime: items[id].cookie_days_lifetime,
            quota: items[id].quota < 0 ? "∞" : items[id].quota,
            user_type: items[id].user_type,
            offer:
                items[id].discount_condition && items[id].discount_condition.id ? (
                    <DiscountOfferInfo offer={items[id].discount_condition.offer} />
                ) : (
                    "No"
                ),
            expired: items[id].expired,
            created: items[id].created,
            expired_at: items[id].expired_at,
            created_at: items[id].created_at,
            edit: (
                <>
                    <IconButton
                        onClick={() => {
                            if (onChartClick) {
                                onChartClick(id);
                            }
                        }}
                    >
                        <ShowChartIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            if (onEditClick) {
                                onEditClick(id);
                            }
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                </>
            )
        });
    }

    return rows.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
    });
};

const CampaignList = ({ items = {}, total, isCampaignsFetching, campaignActions, goToPage }) => {
    const classes = useStyles();

    React.useEffect(() => {
        campaignActions.loadCampaigns(0, CAMPAIGN_PAGE_LIMIT);
    }, [campaignActions]);

    const handleEditClick = React.useCallback(
        (id) => {
            goToPage(`/dashboard/campaigns/edit/${id}`);
        },
        [goToPage]
    );

    const handleChartClick = React.useCallback(
        (id) => {
            goToPage(`/dashboard/campaigns-report/${id}`);
        },
        [goToPage]
    );

    return (
        <div className={classes.root}>
            {isCampaignsFetching === true && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <CampaignFilter />
            <CampaignTable
                rows={makeRowsData(items, handleEditClick, handleChartClick)}
                total={total}
                actions={campaignActions}
            />
        </div>
    );
};

function mapStateToProps({ campaign, campaigns, state }) {
    return {
        items: campaigns.items,
        total: campaigns.total,
        isCampaignsFetching: state.campaignsFetching
    };
}

function mapDispatchToProps(dispatch) {
    return {
        campaignActions: bindActionCreators(campaignActions, dispatch),
        goToPage: (url) => {
            dispatch(push(url));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);
