import { QUIZZES_SET } from "../constants/quiz";

const initialState = {
    items: {}
};

export default function quizzes(state = initialState, action) {
    switch (action.type) {
        case QUIZZES_SET:
            return { ...state, items: action.payload };
        default:
            return state;
    }
}
