import React from "react";
import { Grid, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    loginsTable: {
        width: "100%"
    }
}));

export default function UserLoginList({ items = [] }) {
    const classes = useStyles();

    return (
        <>
            <Grid container spacing={0} alignItems="stretch" direction="column">
                {items && items.length > 0 && (
                    <>
                        <Grid item>
                            <Paper className={classes.loginsRoot}>
                                <Table aria-label="logins" className={classes.loginsTable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Type</TableCell>
                                            <TableCell align="center">IP</TableCell>
                                            <TableCell align="center">Client version</TableCell>
                                            <TableCell align="center">Device</TableCell>
                                            <TableCell align="center">Operating system</TableCell>
                                            <TableCell align="center">Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((row, i) => (
                                            <TableRow key={`logins_${i}`}>
                                                <TableCell align="center">
                                                    {row.type || ""}
                                                </TableCell>
                                                <TableCell align="center">{row.ip}</TableCell>
                                                <TableCell align="center">
                                                    {row.client_version}
                                                </TableCell>
                                                <TableCell align="center">{row.device}</TableCell>
                                                <TableCell align="center">
                                                    {row.operating_system}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.created_at}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
}
