import {
    SET_MANDRILL_SEARCH,
    RESET_MANDRILL,
    SET_MANDRILL_CONTENT,
    RESET_MANDRILL_CONTENT
} from "../constants/mandrill";

const defaultState = {
    search: [],
    content: {}
};

export default function mandrill(state = defaultState, action) {
    switch (action.type) {
        case SET_MANDRILL_SEARCH:
            return { ...state, search: action.payload };
        case SET_MANDRILL_CONTENT:
            return { ...state, content: action.payload };
        case RESET_MANDRILL:
            return {
                search: [],
                content: {}
            };
        case RESET_MANDRILL_CONTENT:
            return {
                ...state,
                content: {}
            };
        default:
            return state;
    }
}
