import React from "react";
import { Table, TableHead, TableRow, TableCell, TableBody, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    title: {
        padding: theme.spacing(2),
        fontWeight: 700,
        fontSize: 18
    }
}));

const InfoTable = ({ data, title = "" }) => {
    const classes = useStyles();
    return (
        <Paper>
            <Typography className={classes.title}>{title}</Typography>
            <Table>
                {data && data.header && Object.keys(data.header).length > 0 && (
                    <TableHead>
                        <TableRow>
                            {data.header.map((headerTitle, i) => {
                                return <TableCell key={`header_${i}`}>{headerTitle}</TableCell>;
                            })}
                        </TableRow>
                    </TableHead>
                )}
                <TableBody>
                    {data &&
                        data.rows &&
                        data.rows.map((row, i) => (
                            <TableRow key={`row_${i}`}>
                                {row &&
                                    row.map((value, i) => {
                                        return (
                                            <TableCell key={`th_${i}`} component="th" scope="row">
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

export default InfoTable;
