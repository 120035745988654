import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as codesActions from "../../actions/codes";
import { Grid, Tab, Tabs, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageTitle from "../../components/PageTitle";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import CodesRevok from "../../components/CodesRevoke";
import CodesGenerateFromList from "../../components/CodesGenerateFromList";
import CodesCheckInviteCode from "../../components/CodesCheckInviteCode";
import CodesCreate from "../../components/CodesCreate";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    title: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },
    subtitle: {
        fontSize: 20
    },
    header: {
        marginBottom: 15
    },
    tabs: {
        paddingBottom: 15
    }
}));

const DashBoardCodes = () => {
    const [tab, settab] = React.useState(0);
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={0}
                justify="space-between"
                className={classes.header}
                alignContent="center"
            >
                <Grid item>
                    <PageTitle title="Invite codes" />
                </Grid>
            </Grid>

            <Grid container justify="space-between" alignContent="center">
                <Grid item>
                    <Tabs
                        className={classes.tabs}
                        value={tab}
                        onChange={(e, value) => settab(value)}
                    >
                        <Tab label="Check code" />
                        <Tab label="Revoke" />
                        <Tab label="Generate from list" />
                        <Tab label="Create code" />
                    </Tabs>
                </Grid>
            </Grid>

            <Paper>{tab === 0 && <CodesCheckInviteCode />}</Paper>
            <Paper>{tab === 1 && <CodesRevok />}</Paper>
            <Paper>{tab === 2 && <CodesGenerateFromList />}</Paper>
            <Paper>{tab === 3 && <CodesCreate />}</Paper>
        </div>
    );
};

function mapStateToProps({ state }) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        codesActions: bindActionCreators(codesActions, dispatch),
        push: (url) => {
            dispatch(push(url));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashBoardCodes));
