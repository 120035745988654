import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import user from "./user";
import state from "./state";
import testChains from "./testChains";
import data from "./data";
import ticker from "./ticker";
import settings from "./settings";
import campaign from "./campaign";
import campaigns from "./campaigns";
import campaignFilter from "./campaignFilter";
import campaignReport from "./campaignReport";
import users from "./users";
import usersFilter from "./usersFilter";
import userForm from "./userForm";
import discounts from "./discounts";
import discountForm from "./discountForm";
import widgets from "./widgets";
import braintree from "./braintree";
import mandrill from "./mandrill";
import scriptReport from "./scriptReport";
import appTranslation from "./appTranslation";
import accountantReport from "./accountantReport";
import quiz from "./quiz";
import quizzes from "./quizzes";

const createRootReducer = (history) => {
    return combineReducers({
        router: connectRouter(history),
        user,
        state,
        testChains,
        data,
        ticker,
        settings,
        campaign,
        campaigns,
        campaignFilter,
        campaignReport,
        users,
        usersFilter,
        userForm,
        discounts,
        discountForm,
        widgets,
        braintree,
        mandrill,
        scriptReport,
        appTranslation,
        accountantReport,
        quiz,
        quizzes
    });
};

export default createRootReducer;
