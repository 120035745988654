import {
    TEST_CHAINS_SET_PARAMS,
    TEST_CHAINS_SET_ERRORS,
    TEST_CHAINS_SET_CHAINS,
    TEST_CHAINS_SET_SPECIAL_PROCESSORS,
    TEST_CHAINS_SET_RESULT
} from "../constants/testChains";

const defaultState = {
    chains: "",
    special_processors: "",
    params: "",
    result: {
        processed: {},
        processed_with_expressions: {}
    },
    errors: {}
};

export default function testChains(state = defaultState, action) {
    switch (action.type) {
        case TEST_CHAINS_SET_PARAMS:
            return { ...state, params: action.payload };
        case TEST_CHAINS_SET_CHAINS:
            return { ...state, chains: action.payload };
        case TEST_CHAINS_SET_SPECIAL_PROCESSORS:
            return { ...state, special_processors: action.payload };
        case TEST_CHAINS_SET_RESULT:
            return { ...state, result: action.payload };
        case TEST_CHAINS_SET_ERRORS:
            return { ...state, errors: action.payload };
        default:
            return state;
    }
}
