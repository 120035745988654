import Api from "../services/api";
import { setState } from "../actions/state";
import {
    ACCOUNTANT_REPORT_REMOVE_ITEM_BY_EMAIL,
    ACCOUNTANT_REPORT_SET_ITEMS,
    ACCOUNTANT_REPORT_SET_STATUS
} from "../constants/accountantReport";

export const loadStatus = (cb) => async (dispatch) => {
    try {
        dispatch(setState("accountantReportStatusFetching", true));
        const { data: apiResponse } = await Api().get(`/admin/accountant-report/status`);
        dispatch(setState("accountantReportStatusFetching", false));

        if (apiResponse.message === "ok") {
            dispatch({
                type: ACCOUNTANT_REPORT_SET_STATUS,
                payload: apiResponse.data
            });
            if (typeof cb === "function") {
                cb();
            }
        }
    } catch (e) {
        dispatch(setState("accountantReportStatusFetching", false));
    }
};

export const start = (startDate, endDate, cb) => async (dispatch) => {
    try {
        dispatch(setState("accountantReportStartFetching", true));
        await Api().get(`/admin/accountant-report/start?startDate=${startDate}&endDate=${endDate}`);
        dispatch(setState("accountantReportStartFetching", false));

        if (typeof cb === "function") {
            cb();
        }
    } catch (e) {
        dispatch(setState("accountantReportStartFetching", false));
    }
};

export const loadUnhandledItems = (cb) => async (dispatch) => {
    try {
        dispatch(setState("accountantReportItemsFetching", true));
        const { data: apiResponse } = await Api().get(`/admin/accountant-report/unhandled-items`);
        dispatch(setState("accountantReportItemsFetching", false));

        if (apiResponse.message === "ok") {
            dispatch({
                type: ACCOUNTANT_REPORT_SET_ITEMS,
                payload: apiResponse.data.items
            });
            if (typeof cb === "function") {
                cb();
            }
        }
    } catch (e) {
        dispatch(setState("accountantReportItemsFetching", false));
    }
};

export const reset = (cb) => async (dispatch) => {
    try {
        dispatch(setState("accountantReportResetFetching", true));
        await Api().post(`/admin/accountant-report/reset`);
        dispatch(setState("accountantReportResetFetching", false));
    } catch (e) {
        dispatch(setState("accountantReportResetFetching", false));
    }
};

export const removeItemByEmail = (email) => async (dispatch) => {
    dispatch({
        type: ACCOUNTANT_REPORT_REMOVE_ITEM_BY_EMAIL,
        payload: email
    });
};

export const download = (fileNamePostfix = "") => async () => {
    const response = await Api().get(
        `${process.env.REACT_APP_API_HOST}/admin/accountant-report/download`
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
        "download",
        `accountant_report${fileNamePostfix ? "_" + fileNamePostfix : ""}.csv`
    ); //or any other extension
    document.body.appendChild(link);
    link.click();
};

export const getCrispProfile = (email, cb) => async (dispatch) => {
    try {
        dispatch(setState("accountantReportStatusFetching", true));
        const { data: apiResponse } = await Api().get(
            `/admin/accountant-report/get-crisp-profile?email=${email}`
        );
        dispatch(setState("accountantReportStatusFetching", false));

        if (apiResponse.message === "ok") {
            if (typeof cb === "function") {
                cb(null, apiResponse.data);
            }
        }
    } catch (e) {
        dispatch(setState("accountantReportStatusFetching", false));
        let message =
            (e.response && e.response.data && e.response.data.data && e.response.data.message) ||
            "";
        cb(message, null);
    }
};
