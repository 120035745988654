import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles(theme => ({
    message: {
        color: blue[700],
        fontSize: 34,
        width: "100%"
    },
    progress: {
        color: blue[900],
        marginTop: 25
    }
}));

export default function AuthCheckScreen() {
    const classes = useStyles();

    return (
        <Container component="main" fixed>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: "100vh" }}
            >
                <Grid item xs={12}>
                    <div className={classes.message}>Checking your authorization...</div>
                </Grid>
                <Grid item xs={12}>
                    <CircularProgress size={100} className={classes.progress} />
                </Grid>
            </Grid>
        </Container>
    );
}
