import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reportsActions from "../../actions/reports";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { withRouter } from "react-router-dom";
import { usePrevious } from "../../utils/hooks";
import { push } from "connected-react-router";
import { Button, CircularProgress, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        flexFlow: "column",
        borderRadius: "5px",
        padding: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    topLine: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
    bottomLine: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(4),
    },
    bottomLineBlock: {
        backgroundColor: "white",
        borderRadius: "10px",
        position: "relative",
        marginRight: theme.spacing(2),
    },
    bottomLineBlockLabel: {
        position: "absolute",
        left: "7px",
        top: "-18px",
    },
    spinnerWrapper: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: "2",
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CampaignReprotToolBar = ({
    match,
    campaign,
    reportsActions,
    uniqueValues,
    isReportDataFetching,
    isReportFetching,
    onSubmit
}) => {
    const classes = useStyles();
    const campaignId = campaign && campaign.id;
    const prevCampaignMatchId = usePrevious(match.params.id);
    const prevCampaignId = usePrevious(campaignId);
    const [selectedCampaign, setSelectedCampaign] = React.useState([]);
    const [selectedSource, setSelectedSource] = React.useState([]);
    const [selectedMedium, setSelectedMedium] = React.useState([]);
    const [field, setField] = React.useState("utm_campaign");
    const [chartName, setChartName] = React.useState("total");
    const now = new Date();
    const [startDate, setStartDate] = React.useState(
        new Date(now.getFullYear(), now.getMonth(), 1)
            .toLocaleString("en-US")
            .split(",")[0]
    );

    const [endDate, setEndDate] = React.useState(
        new Date(now.getFullYear(), now.getMonth() + 1, 0)
            .toLocaleString("en-US")
            .split(",")[0]
    );

    React.useEffect(() => {
        if (prevCampaignMatchId !== match.params.id) {
            reportsActions.loadCampaignReportData(match.params.id);
        }
    }, [match, reportsActions, prevCampaignMatchId]);

    const handleSubmit = React.useCallback(() => {
        let series = "";
        let whereCampaignFields = "";
        let whereSourceFields = "";
        let whereMediumFields = "";

        switch (field) {
            case "utm_campaign":
                series = uniqueValues["utm_campaign"].join(",");
                whereSourceFields = selectedSource.join(",");
                whereMediumFields = selectedMedium.join(",");
                break;
            case "utm_source":
                series = uniqueValues["utm_source"].join(",");
                whereCampaignFields = selectedCampaign.join(",");
                whereMediumFields = selectedMedium.join(",");
                break;
            case "utm_medium":
                series = uniqueValues["utm_medium"].join(",");
                whereCampaignFields = selectedCampaign.join(",");
                whereSourceFields = selectedSource.join(",");
                break;
            default:
        }

        if (onSubmit) {
            onSubmit({
                dateRangeTitle: `${startDate} – ${endDate}`
            })
        }

        reportsActions.loadCampaignChart(
            campaignId,
            startDate,
            endDate,
            series,
            field,
            whereCampaignFields,
            whereSourceFields,
            whereMediumFields,
            chartName
        );
    }, [
        startDate,
        endDate,
        reportsActions,
        campaignId,
        field,
        selectedCampaign,
        selectedSource,
        selectedMedium,
        chartName,
        uniqueValues,
        onSubmit
    ]);

    React.useEffect(() => {
        if (prevCampaignId !== campaignId) {
            handleSubmit();
        }
    }, [
        match,
        reportsActions,
        prevCampaignId,
        campaignId,
        uniqueValues,
        handleSubmit,
    ]);

    const handleChangeCampaign = React.useCallback((e) => {
        setSelectedCampaign(e.target.value);
    }, []);

    const handleChangeSource = React.useCallback((e) => {
        setSelectedSource(e.target.value);
    }, []);

    const handleChangeMedium = React.useCallback((e) => {
        setSelectedMedium(e.target.value);
    }, []);

    const utmCampaigns = uniqueValues["utm_campaign"] || [];
    const utmSource = uniqueValues["utm_source"] || [];
    const utmMedium = uniqueValues["utm_medium"] || [];

    return (
        <Paper className={classes.root}>
            {(isReportDataFetching === true || isReportFetching === true) && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <div className={classes.topLine}>
                <FormControl className={classes.formControl}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <KeyboardDatePicker
                            margin="normal"
                            views={["date"]}
                            openTo="date"
                            id="date-picker-dialog"
                            label="Start date"
                            format="MM/dd/yyyy"
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                            value={startDate}
                            onChange={(dateTime) => {
                                setStartDate(
                                    dateTime
                                        .toJSDate()
                                        .toLocaleString("en-US")
                                        .split(",")[0]
                                );
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <KeyboardDatePicker
                            margin="normal"
                            views={["date"]}
                            openTo="date"
                            id="date-picker-dialog"
                            label="End date"
                            format="MM/dd/yyyy"
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                            value={endDate}
                            onChange={(dateTime) => {
                                setEndDate(
                                    dateTime
                                        .toJSDate()
                                        .toLocaleString("en-US")
                                        .split(",")[0]
                                );
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </FormControl>
            </div>
            <div className={classes.bottomLine}>
                <div className={classes.bottomLineBlock}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="field">Search field</InputLabel>
                        <Select
                            value={field}
                            onChange={(e) => {
                                setField(e.target.value);
                            }}
                            input={<Input id="field" />}
                            renderValue={(selected) => selected}
                            MenuProps={MenuProps}
                        >
                            {["utm_campaign", "utm_source", "utm_medium"].map(
                                (name) => (
                                    <MenuItem key={name} value={name}>
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="report_type">
                            Report type
                        </InputLabel>
                        <Select
                            value={chartName}
                            onChange={(e) => {
                                setChartName(e.target.value);
                            }}
                            input={<Input id="report_type" />}
                            renderValue={(selected) => selected}
                            MenuProps={MenuProps}
                        >
                            {["total"].map((name) => (
                                <MenuItem key={name} value={name}>
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.bottomLineBlock}>
                    <span className={classes.bottomLineBlockLabel}>
                        Condition params:{" "}
                    </span>

                    {field !== "utm_campaign" && (
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="utm_campaign">
                                utm_campaign
                            </InputLabel>
                            <Select
                                multiple
                                value={selectedCampaign}
                                onChange={handleChangeCampaign}
                                input={<Input id="utm_campaign" />}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                            >
                                {utmCampaigns.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox
                                            checked={
                                                selectedCampaign.indexOf(name) >
                                                -1
                                            }
                                        />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    {field !== "utm_source" && (
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="utm_source">
                                utm_source
                            </InputLabel>
                            <Select
                                multiple
                                value={selectedSource}
                                onChange={handleChangeSource}
                                input={<Input id="utm_source" />}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                            >
                                {utmSource.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox
                                            checked={
                                                selectedSource.indexOf(name) >
                                                -1
                                            }
                                        />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    {field !== "utm_medium" && (
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="utm_medium">
                                utm_medium
                            </InputLabel>
                            <Select
                                multiple
                                value={selectedMedium}
                                onChange={handleChangeMedium}
                                input={<Input id="utm_medium" />}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                            >
                                {utmMedium.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox
                                            checked={
                                                selectedMedium.indexOf(name) >
                                                -1
                                            }
                                        />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </div>
            </div>
            <FormControl className={classes.formControl}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isReportDataFetching}
                >
                    Build report
                </Button>
            </FormControl>
        </Paper>
    );
};

function mapStateToProps({ campaignReport, campaigns, state }) {
    return {
        charts: campaignReport.charts,
        campaign: campaignReport.campaign,
        uniqueValues: campaignReport.unique_values,
        isReportDataFetching: state.reportDataFetching,
        isReportFetching: state.reportFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        reportsActions: bindActionCreators(reportsActions, dispatch),
        push: (url) => {
            dispatch(push(url));
        },
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CampaignReprotToolBar));
