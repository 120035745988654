import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as usersActions from "../../actions/users";
import Typography from "@material-ui/core/Typography";
import { Grid, Button, Modal, Paper, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageTitle from "../../components/PageTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { green } from "@material-ui/core/colors";
import UserList from "../../components/UserList";
import UsersFilter from "../../components/UsersFilter";
import AddIcon from "@material-ui/icons/PersonAdd";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    content: {
        padding: theme.spacing(2)
    },
    spinnerWrapper: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: "2"
    },
    header: {
        marginBottom: 15
    },
    runButton: {
        backgroundColor: green[500],
        color: "white",
        width: 200
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    addNewBtnWrapper: {
        display: "flex",
        justifyContent: "flex-end"
    },
    tabs: {
        marginBottom: 15
    },
    userInfoModalWrapper: {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}));

const useUserInfoModalStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        width: "400px",
        height: "420px",
        padding: theme.spacing(2)
    },
    label: {
        fontWeight: "600"
    }
}));

const UserInfoModal = ({
    email,
    id,
    firstname,
    lastname,
    company,
    plan,
    invited,
    usedInviteCode,
    is_admin,
    affiliate_id,
    created,
    blocked,
    confirmed,
    trial_days,
    signup_source = "",
    PaymentDetail = {},
    onEditClick
}) => {
    const classes = useUserInfoModalStyles();

    return (
        <Paper className={classes.root}>
            <Grid container spacing={1} direction="column">
                <Grid>
                    <span className={classes.label}>User ID</span>: {id || "-"}
                </Grid>
                <Grid>
                    <span className={classes.label}>Signup source</span>: {signup_source || "-"}
                </Grid>
                <Grid>
                    <span className={classes.label}>Email</span>: {email || ""}
                </Grid>
                <Grid>
                    <span className={classes.label}>Customer ID</span>:{" "}
                    {PaymentDetail && PaymentDetail.customer_id}
                </Grid>
                <Grid>
                    <span className={classes.label}>Customer country</span>:{" "}
                    {(PaymentDetail && PaymentDetail.customer_country) || ""}
                </Grid>
                <Grid>
                    <span className={classes.label}>Customer VAT</span>:{" "}
                    {(PaymentDetail && PaymentDetail.sale_vat) || ""}
                </Grid>
                <Grid>
                    <span className={classes.label}>Blocked</span>: {blocked || "No"}
                </Grid>
                <Grid>
                    <span className={classes.label}>Account confirmed</span>:{" "}
                    {confirmed ? "✅" : "No"}
                </Grid>
                <Grid>
                    <span className={classes.label}>Firstname</span>: {firstname || ""}
                </Grid>
                <Grid>
                    <span className={classes.label}>Lastname</span>: {lastname || ""}
                </Grid>
                <Grid>
                    <span className={classes.label}>Company</span>: {company || ""}
                </Grid>
                <Grid>
                    <span className={classes.label}>Plan</span>: {plan || ""}
                </Grid>
                <Grid>
                    <span className={classes.label}>Used invite code</span>:{" "}
                    {usedInviteCode ? "✅" : "No"}
                </Grid>
                <Grid>
                    <span className={classes.label}>User type</span>:{" "}
                    {is_admin ? "👮 admin" : "👤 user"}
                </Grid>
                <Grid>
                    <span className={classes.label}>Affiliate ID</span>: {affiliate_id || ""}
                </Grid>
                <Grid>
                    <span className={classes.label}>Trial days</span>: {trial_days || ""}
                </Grid>
                <Grid>
                    <span className={classes.label}>Created at</span>: {created || ""}
                </Grid>
                <Grid container justify="flex-end">
                    <IconButton
                        onClick={() => {
                            if (onEditClick) {
                                onEditClick(id);
                            }
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Paper>
    );
};

const DashBoardUsers = ({ push, usersActions }) => {
    const classes = useStyles();
    const [userInfoModalOpen, setUserInfoModalOpen] = React.useState(false);
    const [userInfo, setUserInfo] = React.useState({});

    const handleCloseUserInfoModal = React.useCallback(() => {
        setUserInfoModalOpen(false);
    }, []);

    const handleShowUserInfo = React.useCallback(userInfoObj => {
        setUserInfo(userInfoObj);
        setUserInfoModalOpen(true);
    }, []);

    const handleModalEditClick = React.useCallback(
        userId => {
            push(`/dashboard/users/edit/${userId}`);
            setUserInfoModalOpen(false);
        },
        [push]
    );

    return (
        <div className={classes.root}>
            {false && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <Grid container spacing={0} justify="space-between" className={classes.header}>
                <Grid item xs={6}>
                    <PageTitle title="Users" />
                    <Typography color="textSecondary">{process.env.REACT_APP_PROJECT_NAME} users</Typography>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.addNewBtnWrapper}>
                        <Button
                            className={classes.runButton}
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                usersActions.userFormReset();
                                push("/dashboard/users/edit/add");
                            }}
                        >
                            Add new user
                            <AddIcon className={classes.rightIcon} />
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <>
                <UsersFilter /> <UserList onShowInfoClick={handleShowUserInfo} />
            </>
            <Modal
                aria-labelledby="user-info"
                aria-describedby="show-user-info"
                open={userInfoModalOpen}
                onClose={handleCloseUserInfoModal}
                onEscapeKeyDown={handleCloseUserInfoModal}
                onBackdropClick={handleCloseUserInfoModal}
                className={classes.userInfoModalWrapper}
            >
                <div>
                    <UserInfoModal {...userInfo} onEditClick={handleModalEditClick} />
                </div>
            </Modal>
        </div>
    );
};

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(usersActions, dispatch),
        push: url => {
            dispatch(push(url));
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DashBoardUsers));
