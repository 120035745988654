import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../actions/user";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import Notification from "../components/Notification/";
import BackgroundBcImage from "../assets/svg/background-bc.svg";
import BackgroundCbImage from "../assets/svg/background-cb.svg";
import LogoBc from "../assets/svg/logo-bc.svg";
import LogoCb from "../assets/svg/logo-cb.svg";
import { push } from "connected-react-router";
import { isProject } from "../utils";
import { PROJECT_BC } from "../constants/lang";

const useStyles = makeStyles(theme => ({
    "@global": {
        body: {
            backgroundColor: theme.palette.common.white
        }
    },
    logo: {
        position: "relative",
        background: `url("${isProject(PROJECT_BC) ? LogoBc : LogoCb}") center`,
        backgroundRepeat: "no-repeat",
        width: 300,
        height: 140
    },
    dashboardLabel: {
        position: "absolute",
        right: -39,
        top: 30,
        color: "white",
    },
    container: {
        height: "100vh",
        background: `url("${isProject(PROJECT_BC) ? BackgroundBcImage : BackgroundCbImage}") center`,
        backgroundColor: `${isProject(PROJECT_BC) ? "#401f53" : "#22262a"}`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white",
        padding: theme.spacing(4),
        marginBottom: 150
    },
    form: {
        width: "100%"
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    wrapper: {
        position: "relative"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}));

const LoginContainer = ({ userActions, redirectToRoot, userId, errors = {}, isAuthLoading }) => {
    const classes = useStyles();
    const [email, setemail] = React.useState("");
    const [password, setpassword] = React.useState("");
    const [error, seterror] = React.useState(false);

    React.useEffect(() => {
        if (userId) {
            redirectToRoot();
        }
    }, [userId, redirectToRoot]);

    React.useEffect(() => {
        if (Boolean(errors && Object.keys(errors).length > 0)) {
            seterror(true);
        }
    }, [errors]);

    const handleNotificationClose = React.useCallback(() => {
        seterror(false);
    }, []);

    const handleSubmit = React.useCallback(
        e => {
            e.preventDefault();
            userActions.auth(email, password);
        },
        [email, password, userActions]
    );

    return (
        <div className={classes.container}>
            <CssBaseline />
            <div className={classes.logo}>
                <Typography className={classes.dashboardLabel} component="div">
                    DashBoard
                </Typography>
            </div>
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Authorization
                </Typography>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        value={email}
                        onChange={e => setemail(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={"Email"}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        error={Boolean(errors && errors["email"])}
                        disabled={isAuthLoading}
                    />
                    <TextField
                        value={password}
                        onChange={e => setpassword(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        error={Boolean(errors && errors["password"])}
                        disabled={isAuthLoading}
                    />
                    <div className={classes.wrapper}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isAuthLoading}
                        >
                            Sign In
                        </Button>
                        {isAuthLoading && (
                            <CircularProgress size={29} className={classes.buttonProgress} />
                        )}
                    </div>
                </form>
                <Notification
                    variant="error"
                    message={Object.keys(errors)
                        .map(field => errors[field])
                        .join(", ")}
                    open={error}
                    onClose={handleNotificationClose}
                />
            </div>
        </div>
    );
};

function mapStateToProps({ user, state }) {
    return {
        errors: user.errors,
        userId: user.id,
        isAuthLoading: state.authLoading
    };
}
function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        redirectToRoot: () => dispatch(push("/"))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginContainer);
