import Api, { resetInstance } from "../services/api";
import { setState } from "../actions/state";
import { DATA_SET, DATA_SET_ERRORS } from "../constants/data";
import { triggerMessage } from "../utils/events";

export const openDialog = value => dispatch => {
    dispatch(setState("editDataDialogOpened", value));
};

export const cleanErrors = () => dispatch => {
    return dispatch({
        type: DATA_SET_ERRORS,
        payload: {}
    });
};

export const loadAllData = () => async (dispatch, getState) => {
    try {
        dispatch(setState("loadAllDataFetching", true));
        const { data: apiResponse } = await Api().get(`/admin/data`);
        dispatch(setState("loadAllDataFetching", false));

        if (apiResponse.data && Object.keys(apiResponse.data).length > 0) {
            for (const name in apiResponse.data) {
                dispatch({
                    type: DATA_SET,
                    payload: {
                        name,
                        value: JSON.stringify(JSON.parse(apiResponse.data[name]), null, 4)
                    }
                });
            }
        }
    } catch (e) {
        dispatch(setState("loadAllDataFetching", false));
    }
};

export const saveJsonText = name => async (dispatch, getState) => {
    const errors = {};
    let body = "";
    console.log(name)
    dispatch({
        type: DATA_SET_ERRORS,
        payload: {}
    });
    // test json before save
    if (name === "all") {
        const names = ["params", "chains", "messages", "soundcheck", "soundcheck_clap", "special_processors", "special_processors_mini", "references", "references_mini"];
        const resultObj = {};
        for (const name of names) {
            try {
                const jsonObj = JSON.parse(getState().data[name]);
                console.log(jsonObj)
                if (!jsonObj[name]) {
                    throw new Error(`Prop ${name} not found!`);
                }
                resultObj[name] = jsonObj[name];
            } catch (e) {
                errors[name] = `${name} JSON parsing error: ${e.message}`;
            }
        }
        body = JSON.stringify(resultObj);
    } else {
        try {
            const jsonObj = JSON.parse(getState().data[name]);
            if (!jsonObj[name]) {
                throw new Error(`Prop ${name} not found!`);
            }
            body = getState().data[name];
        } catch (e) {
            errors[name] = `${name} JSON parsing error: ${e.message}`;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch({
            type: DATA_SET_ERRORS,
            payload: errors
        });

        return;
    }

    try {
        dispatch(setState("saveDataFetching", true));
        resetInstance();
        let url = `/admin/data/${name}`;
        if (name === "all") {
            url = `/admin/data`;
        }
        await Api({
            headers: {
                "Content-Type": "text/plain"
            }
        }).post(url, body);

        dispatch(setState("saveDataFetching", false));
        dispatch(openDialog(false));
        triggerMessage("Saved", "success");
    } catch (e) {
        dispatch(setState("saveDataFetching", false));
        const respErrorMessage = (e.response && e.response.data && e.response.data.message) || "";
        dispatch({
            type: DATA_SET_ERRORS,
            payload: { response: respErrorMessage }
        });
    }
};

export const updateJsonText = (name, changedJsonText) => dispatch => {
    dispatch({
        type: DATA_SET,
        payload: {
            name,
            value: changedJsonText
        }
    });
};
