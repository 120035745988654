import React from "react";
import { Grid, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DiscountOfferInfo from "../DiscountOfferInfo";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    inviteCodesTable: {
        width: "100%"
    },
    inviteCodesAction: {
        padding: theme.spacing(2)
    },
    link: {
        textDecoration: "underline"
    }
}));

export default function InviteCodeList({ currentUserId = "", items = [], isLoading }) {
    const classes = useStyles();
    return (
        <>
            <Grid container spacing={0} alignItems="stretch" direction="column">
                <Grid item>
                    <div className={classes.inviteCodesAction}>
                        <Grid container spacing={1} justify="flex-end">
                            <Grid item md={2}></Grid>
                        </Grid>
                    </div>
                </Grid>
                {items && items.length > 0 && (
                    <>
                        <Grid item>
                            <Paper className={classes.devicesRoot}>
                                <Table aria-label="items" className={classes.inviteCodesTable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Code</TableCell>
                                            <TableCell align="center">Owner</TableCell>
                                            <TableCell align="center">Invited friend</TableCell>
                                            <TableCell align="center">Is redeemed</TableCell>
                                            <TableCell align="center">Redeemed at</TableCell>
                                            <TableCell align="center">Created at</TableCell>
                                            <TableCell align="center">Discount condition</TableCell>
                                            <TableCell align="center">Comment</TableCell>
                                            <TableCell align="center">Expires at</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((row, i) => (
                                            <TableRow key={`device${i}`}>
                                                <TableCell align="center">
                                                    {row.code || ""}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.user_id === currentUserId ? (
                                                        "This user"
                                                    ) : row.invited_user_id ? (
                                                        <Link
                                                            className={classes.link}
                                                            to={`/dashboard/users/edit/${row.user_id}`}
                                                        >{`User ID: ${row.user_id}`}</Link>
                                                    ) : (
                                                        "Generated"
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.invited_user_id === currentUserId ? (
                                                        "This user"
                                                    ) : row.invited_user_id ? (
                                                        <Link
                                                            className={classes.link}
                                                            to={`/dashboard/users/edit/${row.invited_user_id}`}
                                                        >{`User ID: ${row.invited_user_id}`}</Link>
                                                    ) : (
                                                        ""
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.is_redeemed ? "YES" : "NO"}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.redeemed_at || ""}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.created_at || ""}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.invite_code_condition.offer && (
                                                        <DiscountOfferInfo
                                                            offer={row.invite_code_condition.offer}
                                                        />
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.comment || ""}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.expires_at || ""}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
}
