import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as campaignActions from "../../actions/campaign";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, TextField, Button, Grid, FormControlLabel, Checkbox } from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterList";
import { CAMPAIGN_PAGE_LIMIT } from "../../constants/campaign";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        position: "relative",
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    tableWrapper: {
        overflow: "auto"
    },
    spinnerWrapper: {
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "60vh",
        zIndex: "2"
    },
    red: {
        color: "red"
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    }
}));

const CampaignFilter = ({ campaignFilter, campaignActions }) => {
    const classes = useStyles();

    const handleFilterChange = React.useCallback(
        (field, value) => {
            campaignActions.setCampaignFilter(field, value);
        },
        [campaignActions]
    );

    const handleApplyFilterClick = React.useCallback(
        (e) => {
            e.preventDefault();
            campaignActions.loadCampaigns(0, CAMPAIGN_PAGE_LIMIT);
        },
        [campaignActions]
    );

    const handleResetFilterClick = React.useCallback(
        (e) => {
            e.preventDefault();
            campaignActions.resetCampaignFilter();
            campaignActions.loadCampaigns(0, CAMPAIGN_PAGE_LIMIT);
        },
        [campaignActions]
    );

    return (
        <div>
            <Paper className={classes.root}>
                <form onSubmit={handleApplyFilterClick}>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item>
                            <TextField
                                type="search"
                                value={campaignFilter.name}
                                fullWidth
                                label="Name"
                                placeholder="Music love"
                                onChange={(e) => {
                                    handleFilterChange("name", e.target.value.trim());
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(campaignFilter.hideExpired)}
                                        onChange={(e) => {
                                            handleFilterChange("hideExpired", e.target.checked);
                                        }}
                                        value={Boolean(campaignFilter.hideExpired)}
                                    />
                                }
                                label={"Hide expired"}
                            />
                        </Grid>
                        <Grid item container spacing="2">
                            <Grid item>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleApplyFilterClick}
                                >
                                    Apply filter
                                    <FilterIcon className={classes.rightIcon} />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" onClick={handleResetFilterClick}>
                                    Reset filter
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </div>
    );
};

function mapStateToProps({ campaignFilter, state }) {
    return {
        campaignFilter: campaignFilter
    };
}

function mapDispatchToProps(dispatch) {
    return {
        campaignActions: bindActionCreators(campaignActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignFilter);
