import Api from "../services/api";
import { setState } from "../actions/state";
import {
    APP_TRANSLATION_SET,
    APP_TRANSLATION_UPDATE,
    APP_TRANSLATION_RESET_ERRORS,
    APP_TRANSLATION_SET_ERRORS
} from "../constants/lang";
import { triggerMessage } from "../utils/events";

export const loadLanguages = () => async (dispatch) => {
    try {
        dispatch(setState("appTranslationLoadLanguagesFetching", true));
        const { data: apiResponse } = await Api().get(`/admin/app-translation`);
        dispatch(setState("appTranslationLoadLanguagesFetching", false));

        if (apiResponse.message === "ok") {
            dispatch({
                type: APP_TRANSLATION_SET,
                payload: apiResponse.data
            });
        }
    } catch (e) {
        dispatch(setState("appTranslationLoadLanguagesFetching", false));
    }
};

export const saveLanguages = () => async (dispatch, getState) => {
    const languages = Object.keys(getState().appTranslation.items);
    const errors = [];

    for (const lang of languages) {
        if (getState().appTranslation.items[lang]) {
            try {
                JSON.parse(getState().appTranslation.items[lang]);
            } catch (e) {
                errors.push(`${lang}: ${e.message}`);
            }
        }
    }

    if (errors.length > 0) {
        dispatch({
            type: APP_TRANSLATION_SET_ERRORS,
            payload: errors
        });

        return;
    }

    try {
        dispatch(setState("appTranslationSaveLanguagesFetching", true));
        const { data: apiResponse } = await Api().post(
            `/admin/app-translation`,
            getState().appTranslation.items
        );
        dispatch(setState("appTranslationSaveLanguagesFetching", false));

        if (apiResponse.message === "ok") {
            dispatch({
                type: APP_TRANSLATION_RESET_ERRORS
            });
            triggerMessage("Translation has been updated", "success");
        }
    } catch (e) {
        dispatch({
            type: APP_TRANSLATION_SET_ERRORS,
            payload: [(e.response && e.response.data && e.response.data.message) || "Unknown error"]
        });
        dispatch(setState("appTranslationSaveLanguagesFetching", false));
    }
};

export const update = (lang, value) => async (dispatch) => {
    dispatch({
        type: APP_TRANSLATION_UPDATE,
        payload: {
            lang,
            value
        }
    });
};

export const resetErrors = () => async (dispatch) => {
    return dispatch({
        type: APP_TRANSLATION_RESET_ERRORS
    });
};
