import {
    APP_TRANSLATION_SET,
    APP_TRANSLATION_UPDATE,
    APP_TRANSLATION_RESET_ERRORS,
    APP_TRANSLATION_SET_ERRORS
} from "../constants/lang";

const initialState = {
    items: {},
    errors: []
};

export default function appTranslation(state = initialState, action) {
    switch (action.type) {
        case APP_TRANSLATION_SET:
            return { ...state, items: { ...action.payload.items } };
        case APP_TRANSLATION_UPDATE:
            return {
                ...state,
                items: { ...state.items, [action.payload.lang]: action.payload.value }
            };
        case APP_TRANSLATION_RESET_ERRORS:
            return { ...state, errors: [] };
        case APP_TRANSLATION_SET_ERRORS:
            return { ...state, errors: action.payload };
        default:
            return state;
    }
}
