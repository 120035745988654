import axios from "axios";
import { readCookie } from "./cookie";
import Auth from "./auth";

export const apiHost = process.env.REACT_APP_API_HOST;

let instance = null;

const getInstance = (config = { headers: {} }) => {
    if (instance) {
        return instance;
    } else {
        const token = readCookie("token");
        const defaultHeaders = {
            baseURL: apiHost,
            crossDomain: true,
            headers: {
                Authorization: token || "",
                "Content-Type": "application/json",
                ...config["headers"]
            }
        };
        const instance = axios.create({
            ...defaultHeaders,
            headers: { ...defaultHeaders["headers"], ...config["headers"] }
        });
        instance.interceptors.response.use(
            function(response) {
                return response;
            },
            function(error) {
                if (
                    error.response &&
                    (error.response.status === 401 || error.response.data.no_token === true)
                ) {
                    Auth.logout();
                }
                return Promise.reject(error);
            }
        );
        return instance;
    }
};

export const resetInstance = () => {
    instance = null;
};

export default getInstance;
