import { Button, Grid } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as accountantReportActions from "../../actions/accountantReport";
import { makeStyles } from "@material-ui/core/styles";
import Api from "../../services/api";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { green } from "@material-ui/core/colors";
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        padding: theme.spacing(2)
    },
    startBtn: {
        backgroundColor: green[500],
        color: "white"
    },
    info: {
        marginTop: theme.spacing(2)
    }
}));

let crispRequestInterval = null;

function AccountantReport({ emails = [], status = {}, accountantReportActions, isStartLoading }) {
    const classes = useStyles();
    const [ready, setReady] = React.useState(false);
    const [startDate, setStartDate] = React.useState(new Date().toISOString());
    const [endDate, setEndDate] = React.useState(new Date().toISOString());
    const [inCrispProgress, setInCrispProgress] = React.useState(false);
    const [isCrispHandlingRow, setIsCrispHandlingRow] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const crispRequestIntervalRef = React.useRef(null);

    React.useEffect(() => {
        accountantReportActions.loadStatus(() => {
            accountantReportActions.loadUnhandledItems(() => {
                setReady(true);
            });
        });
    }, [accountantReportActions]);

    React.useEffect(() => {
        const statusInterval = setInterval(() => {
            accountantReportActions.loadStatus();
        }, 5000);
        return () => {
            clearInterval(statusInterval);
        };
    }, [accountantReportActions]);

    const handleInterval = React.useCallback(
        async function () {
            if (inCrispProgress && !isCrispHandlingRow) {
                setIsCrispHandlingRow(true);
                const currentEmail = emails.pop();
                if (currentEmail) {
                    console.log("Start processing " + currentEmail);
                    try {
                        const { data: apiResponse } = await Api().get(
                            `/admin/accountant-report/get-crisp-profile?email=${currentEmail}`
                        );
                        console.log("End processing " + currentEmail);
                        if (apiResponse.message === "ok") {
                            // next
                            accountantReportActions.removeItemByEmail(currentEmail);
                        }
                        setIsCrispHandlingRow(false);
                    } catch (e) {
                        let message =
                            (e.response &&
                                e.response.data &&
                                e.response.data.data &&
                                e.response.data.message) ||
                            "";
                        setInCrispProgress(() => false);
                        setIsCrispHandlingRow(() => false);
                        setMessage(message);
                    }
                } else {
                    setInCrispProgress(() => false);
                    setIsCrispHandlingRow(() => false);
                    setMessage("Done");
                }
            }
        },
        [inCrispProgress, isCrispHandlingRow, emails, accountantReportActions]
    );

    React.useEffect(() => {
        if (crispRequestIntervalRef.current) {
            clearInterval(crispRequestIntervalRef.current);
        }

        crispRequestIntervalRef.current = setInterval(handleInterval, 1000);
    }, [handleInterval]);

    const handleStartClick = React.useCallback(() => {
        accountantReportActions.start(startDate, endDate, () => {
            accountantReportActions.loadStatus(() => {
                accountantReportActions.loadUnhandledItems(() => {
                    setReady(true);
                });
            });
        });
    }, [accountantReportActions, startDate, endDate]);

    const handleRestClick = React.useCallback(() => {
        /*eslint-disable*/
        if (confirm("Reset report?")) {
            accountantReportActions.reset(() => {
                accountantReportActions.loadStatus(() => {
                    accountantReportActions.loadUnhandledItems(() => {
                        setInCrispProgress(false);
                        setIsCrispHandlingRow(false);
                        setMessage("");
                    });
                });
            });
        }
        /*eslint-enable*/
    }, [accountantReportActions]);

    const handleCrispExtractClick = React.useCallback(() => {
        setInCrispProgress(!inCrispProgress);
        if (inCrispProgress) {
            clearInterval(crispRequestInterval);
        } else {
            setMessage("");
        }
    }, [inCrispProgress]);

    const handleDownloadClick = React.useCallback(() => {
        accountantReportActions.download();
    }, [accountantReportActions]);

    return (
        <div className={classes.root}>
            {!status.launched && (
                <Grid container direction="column">
                    <Grid item>
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="start-date-picker-dialog"
                                label="Start date"
                                format="dd.MM.yyyy"
                                value={!startDate ? new Date() : new Date(startDate)}
                                onChange={(dateTime) => {
                                    setStartDate(dateTime.toISODate());
                                }}
                                KeyboardButtonProps={{
                                    "aria-label": "start date"
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="end-date-picker-dialog"
                                label="End date"
                                format="dd.MM.yyyy"
                                value={!endDate ? new Date() : new Date(endDate)}
                                onChange={(dateTime) => {
                                    setEndDate(dateTime.toISODate());
                                }}
                                KeyboardButtonProps={{
                                    "aria-label": "end date"
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <Button
                            className={classes.startBtn}
                            disabled={isStartLoading}
                            onClick={handleStartClick}
                        >
                            {isStartLoading ? "Working..." : "Start"}
                        </Button>
                    </Grid>
                </Grid>
            )}
            {status.launched && (
                <Grid container direction="column">
                    <Grid container direction="column">
                        <Grid item>Total items: {status.total}</Grid>
                        <Grid item>Processed: {status.processedCount}</Grid>
                        <Grid item>Message: {message}</Grid>
                    </Grid>

                    <Grid container>
                        <Grid item>
                            <Button disabled={!ready} onClick={handleCrispExtractClick}>
                                {inCrispProgress
                                    ? "Stop Crisp extraction"
                                    : "Start Crisp extraction"}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button disabled={!ready} onClick={handleRestClick}>
                                Reset
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button disabled={!ready} onClick={handleDownloadClick}>
                                Download
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Alert className={classes.info} severity="info">
                <div>1: Pick the date range (better to pick the first day of the desired month and the first day of the next month)</div>
                <div>2: Wait for a BrainTree response</div>
                <div>3: Start Crisp processing (it might take a while), don't close this page and don't switch the tab</div>
                <div>4: Download the report in CSV format. Open the report via Numbers or MS Excel and export to xls format. Do not forget to remove extra rows of the next month from the report if they got there by some reason</div>
                <div>5: Reset the current report if you wanna create a new one</div>
                <div>Note: When the process is over, you can see that some data is still pending. Run the Crisp process several times, in some cases you will be able extract missing records. If it doesn't work out, send those records to hell and find missing data manually. </div>
            </Alert>
        </div>
    );
}

function mapStateToProps({ state, accountantReport }) {
    return {
        status: accountantReport.status,
        isStartLoading: state.accountantReportStartFetching,
        emails: accountantReport.items
    };
}

function mapDispatchToProps(dispatch) {
    return {
        accountantReportActions: bindActionCreators(accountantReportActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountantReport);
