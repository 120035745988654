export const cookieDomain = process.env.COOKIE_DOMAIN;

export function createCookie(name, value, days) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }

    let domain = "";
    if (cookieDomain) {
        domain = `domain=${cookieDomain}`;
    }

    document.cookie = name + "=" + value + expires + `; path=/; ${domain}`;
}

export function readCookie(name) {
    let nameEq = name + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEq) === 0) return c.substring(nameEq.length, c.length);
    }

    return null;
}

export function eraseCookie(name) {
    createCookie(name, "", -1);
}

export function parseCookies(req) {
    const result = {};
    const cookieString = req.headers.cookie;

    cookieString &&
        cookieString.split(";").forEach(function(cookie) {
            let chunk = cookie.split("=");
            result[chunk.shift().trim()] = decodeURI(chunk.join("="));
        });

    return result;
}
