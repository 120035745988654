import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import { languages } from "../../constants/user";
import JsonEditor from "../JsonEditor";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(4)
    }
}));

const flagIconMap = {
    en: "🇬🇧",
    ru: "🇷🇺",
    fi: "🇫🇮",
    de: "🇩🇪"
};

export default function LanguageTextField({
    multiline = false,
    jsonMode = false,
    name = "",
    title = "",
    onChange,
    isLoading,
    value = "",
    placeholder = ""
}) {
    const [lang, setLang] = React.useState("en");
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <InputLabel htmlFor={name}>{title}</InputLabel>
            <Tabs className={classes.tabs} value={lang} onChange={(e, value) => setLang(value)}>
                {Object.keys(languages).map((key, i) => (
                    <Tab
                        icon={flagIconMap[key] || null}
                        value={key}
                        key={`${i}_title`}
                        label={languages[key]}
                    />
                ))}
            </Tabs>
            {jsonMode === false && (
                <TextField
                    value={value[lang] || ""}
                    inputProps={{
                        name: { name },
                        id: { name }
                    }}
                    multiline={multiline}
                    fullWidth
                    required
                    label={name}
                    placeholder={placeholder}
                    onChange={(e) => {
                        if (onChange) {
                            onChange(lang, e.target.value);
                        }
                    }}
                    disabled={isLoading}
                />
            )}
            {jsonMode === true && (
                <JsonEditor
                    name={lang}
                    useAdvancedEditor={true}
                    jsonText={value[lang] || ""}
                    onChange={(name, val) => {
                        if (onChange) {
                            onChange(name, val);
                        }
                    }}
                    placeholder={placeholder}
                />
            )}
        </Paper>
    );
}
