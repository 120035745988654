import React from "react";
import { widgetStyles, WidgetHeader } from "../common";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as widgetsActions from "../../../actions/widgets";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green, grey } from "@material-ui/core/colors";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import HelpIcon from "@material-ui/icons/Help";

export const styles = makeStyles((theme) => ({
    body: {
        width: "100%",
        height: "100%",
        fontSize: 21
    },
    grow: {
        color: green[400]
    },
    label: {
        color: grey[500]
    },
    itemTitleCell: {
        display: "flex",
        alignItems: "center"
    },
    helpIcon: {
        marginLeft: theme.spacing(1)
    }
}));

const WidgetBasicTable = React.memo(
    ({ widgetsData = {}, widgetsActions, widgetsLoading, title, widgetName }) => {
        const widgetClasses = widgetStyles();
        const classes = styles();

        const isLoading = widgetsLoading[widgetName];
        const data = widgetsData[widgetName] || [];

        React.useEffect(() => {
            widgetsActions.loadWidget(widgetName);
        }, [widgetsActions, widgetName]);

        const handleRefresh = React.useCallback(
            (type) => {
                widgetsActions.loadWidget(widgetName);
            },
            [widgetName, widgetsActions]
        );

        return (
            <div className={widgetClasses.root}>
                <WidgetHeader text={title} onRefreshClick={handleRefresh} />
                <div className={widgetClasses.body}>
                    {isLoading && (
                        <div className={widgetClasses.spinnerWrapper}>
                            <CircularProgress size={60} />
                        </div>
                    )}
                    {isLoading === false && (
                        <div className={classes.body}>
                            <Table className={classes.table} aria-label={title}>
                                {data && data.header && Object.keys(data.header).length > 0 && (
                                    <TableHead>
                                        <TableRow>
                                            {data.header.map((headerTitle, i) => {
                                                return (
                                                    <TableCell key={`header_${i}`}>
                                                        {headerTitle}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </TableHead>
                                )}
                                <TableBody>
                                    {data &&
                                        data.items &&
                                        data.items.map((row, i) => (
                                            <TableRow key={`row_${i}`}>
                                                {row &&
                                                    row.row &&
                                                    row.row.map((value, i) => {
                                                        return (
                                                            <TableCell
                                                                key={`th_${i}`}
                                                                component="th"
                                                                scope="row"
                                                                className={i === 0 && classes.itemTitleCell}
                                                            >
                                                                <>
                                                                    {row.colored ? (
                                                                        <span
                                                                            className={
                                                                                value > 0
                                                                                    ? classes.grow
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            {value}
                                                                        </span>
                                                                    ) : (
                                                                        <span>{value}</span>
                                                                    )}
                                                                    {row.hint && i === 0 && (
                                                                        <Tooltip title={row.hint}>
                                                                            <HelpIcon className={classes.helpIcon} />
                                                                        </Tooltip>
                                                                    )}
                                                                </>
                                                            </TableCell>
                                                        );
                                                    })}
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

function mapStateToProps({ widgets, state }) {
    return {
        widgetsData: widgets.data,
        widgetsLoading: state.widgets
    };
}

function mapDispatchToProps(dispatch) {
    return {
        widgetsActions: bindActionCreators(widgetsActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetBasicTable);
