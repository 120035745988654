import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as uploadActions from "../../actions/upload";
import * as settingsActions from "../../actions/settings";
import Typography from "@material-ui/core/Typography";
import { Grid, TextField, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageTitle from "../../components/PageTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import FileUpload from "../../components/FileUpload";
import { triggerMessage } from "../../utils/events";
import { APP_S3_FOLDER_NAME_MACOS, APP_S3_FOLDER_NAME_WINDOWS } from "../../constants/settings";
import { green } from "@material-ui/core/colors";
import CheckIcon from "@material-ui/icons/Check";

import { ReactComponent as AppleIcon } from "../../assets/svg/apple.svg";
import { ReactComponent as WindowsIcon } from "../../assets/svg/windows.svg";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    content: {
        padding: theme.spacing(2)
    },
    spinnerWrapper: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: "2"
    },
    header: {
        marginBottom: 15
    },
    item: {
        marginBottom: theme.spacing(1)
    },
    block: {
        marginBottom: theme.spacing(5)
    },
    step: {
        position: "relative",
        marginBottom: theme.spacing(2)
    },
    step__title: {
        fontSize: "24px",
        paddingLeft: "55px"
    },
    step__count: {
        position: "absolute",
        top: "-2px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.light,
        fontSize: "24px"
    },
    progress: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    appVersionBlock: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: theme.spacing(4),
        padding: theme.spacing(2)
    },
    appVersionBlockVersionWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    appVersionBlockVersion: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "45px",
        height: "45px",
        borderRadius: "50%",
        align: "center",
        backgroundColor: theme.palette.secondary.light,
        color: "white"
    },
    appVersionBlockIcon: {
        width: "24px",
        height: "24px"
    },
    appVersionBlockFiles: {
        paddingLeft: theme.spacing(2)
    },
    appVersionBlockFilesBlock: {
        marginBottom: "15px"
    },
    appVersionFileRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    appVersionFileRowSetCurrentLink: {
        color: theme.palette.secondary.dark,
        marginLeft: theme.spacing(1),
        "&:hover": {
            color: theme.palette.secondary.light
        }
    },
    currentIcon: {
        marginLeft: theme.spacing(1),
        color: green[500]
    }
}));

const Step = ({ count, title }) => {
    const classes = useStyles();

    return (
        <div className={classes.step}>
            <div className={classes.step__count}>{count}</div>
            <div className={classes.step__title}>{title}</div>
        </div>
    );
};

const AppVersionFileRow = ({ url, current, os }) => {
    const classes = useStyles();
    const ctx = React.useContext(AppUploadContext);

    return (
        <div className={classes.appVersionFileRow}>
            <div>{url}</div>
            <div>{current && <CheckIcon className={classes.currentIcon} />}</div>
            <div>
                {!current && (
                    <a
                        href="/"
                        className={classes.appVersionFileRowSetCurrentLink}
                        onClick={e => {
                            e.preventDefault();
                            if (ctx.handleSetCurrent) {
                                ctx.handleSetCurrent(url, os);
                            }
                        }}
                    >
                        Set as a current version
                    </a>
                )}
            </div>
        </div>
    );
};

const AppVersionBlock = ({ data = {}, version = "" }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.appVersionBlock}>
            <div className={classes.appVersionBlockVersionWrapper}>
                <div className={classes.appVersionBlockVersion}>
                    {version.replace(new RegExp("_", "g"), ".")}
                </div>
            </div>
            <div className={classes.appVersionBlockFiles}>
                <div className={classes.appVersionBlockFilesBlock}>
                    <div>
                        <AppleIcon className={classes.appVersionBlockIcon} />
                    </div>
                    <div>
                        {data[APP_S3_FOLDER_NAME_MACOS].map((file, i) => (
                            <AppVersionFileRow {...file} os={APP_S3_FOLDER_NAME_MACOS} />
                        ))}
                    </div>
                </div>
                <div>
                    <div>
                        <WindowsIcon className={classes.appVersionBlockIcon} />
                    </div>
                    <div>
                        {data[APP_S3_FOLDER_NAME_WINDOWS].map((file, i) => (
                            <AppVersionFileRow {...file} os={APP_S3_FOLDER_NAME_WINDOWS} />
                        ))}
                    </div>
                </div>
            </div>
        </Paper>
    );
};

const AppUploadContext = React.createContext({});

const DashBoardAppUpload = ({
    match,
    items,
    push,
    currentVersions,
    versions,
    isMacOsFetching,
    isWindowsFetching,
    isAppVersionFetching,
    uploadActions,
    settingsActions
}) => {
    const classes = useStyles();
    const [version, setVersion] = React.useState("");

    React.useEffect(() => {
        const versionCount = Object.keys(versions).length;
        if (!versionCount) {
            settingsActions.loadAppVersions();
        }
    }, [settingsActions, versions]);

    const handleUpload = React.useCallback(
        (name, data) => {
            if (version) {
                uploadActions.uploadApp(
                    data,
                    name,
                    version.replace(new RegExp("\\.", "g"), "_"),
                    () => {
                        settingsActions.loadAppVersions();
                    }
                );
            } else {
                triggerMessage("Version is required", "warning");
            }
        },
        [uploadActions, version, settingsActions]
    );

    const handleSetCurrent = React.useCallback(
        (url, os) => {
            /*eslint-disable*/
            if (confirm("Are you sure?")) {
                /*eslint-enable*/
                settingsActions.setCurrentAppVersion(url, os);
            }
        },
        [settingsActions]
    );

    return (
        <div className={classes.root}>
            {false && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <Grid container spacing={0} justify="space-between" className={classes.header}>
                <Grid item xs={6}>
                    <PageTitle title="App Upload" />
                    <Typography color="textSecondary">Upload MacOS and Windows app here</Typography>
                </Grid>
                <Grid item xs={6}></Grid>
            </Grid>
            <Paper className={classes.content}>
                <div className={classes.block}>
                    <Step count="1" title="Input app version" />
                    <TextField
                        value={version}
                        fullWidth
                        label="Version"
                        placeholder="0.0"
                        onChange={e => {
                            if (/^[\d+.]+$/g.test(e.target.value) || !e.target.value) {
                                setVersion(e.target.value);
                            }
                        }}
                    />
                </div>
                <div className={classes.block}>
                    <Step count="2" title="Upload app" />
                    <div className={classes.item}>
                        <FileUpload
                            label="MacOS"
                            name="macos"
                            buttonText="Upload for MacOS"
                            onUpload={handleUpload}
                            isLoading={isMacOsFetching}
                        />
                    </div>
                    <div className={classes.item}>
                        <FileUpload
                            label="Windows"
                            name="windows"
                            buttonText="Upload for Windows"
                            onUpload={handleUpload}
                            isLoading={isWindowsFetching}
                        />
                    </div>
                </div>
                <div className={classes.block}>
                    <Step count="3" title="Set current version (optional)" />
                    {isAppVersionFetching && (
                        <div className={classes.progress}>
                            <CircularProgress size={40} />
                        </div>
                    )}
                    <AppUploadContext.Provider value={{ handleSetCurrent }}>
                        {isAppVersionFetching === false &&
                            Object.keys(versions).map((version, i) => (
                                <AppVersionBlock
                                    version={version}
                                    data={versions[version]}
                                    key={i}
                                />
                            ))}
                    </AppUploadContext.Provider>
                </div>
            </Paper>
        </div>
    );
};

function mapStateToProps({ state, settings }) {
    return {
        currentVersions: settings.app.current || {
            [APP_S3_FOLDER_NAME_MACOS]: "",
            [APP_S3_FOLDER_NAME_WINDOWS]: ""
        },
        versions: settings.app.versions,
        isAppVersionFetching: state.appVersionFetching,
        isMacOsFetching: state.appUpload_macos_fetching,
        isWindowsFetching: state.appUpload_windows_fetching
    };
}

function mapDispatchToProps(dispatch) {
    return {
        uploadActions: bindActionCreators(uploadActions, dispatch),
        settingsActions: bindActionCreators(settingsActions, dispatch),
        push: url => {
            dispatch(push(url));
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DashBoardAppUpload));
