import { SET_DISCOUNTS } from "../constants/discount";

const initialState = {
    items: {},
    total: 0
};

export default function discount(state = initialState, action) {
    switch (action.type) {
        case SET_DISCOUNTS:
            return { ...state, items: action.payload.items, total: action.payload.total };
        default:
            return state;
    }
}
