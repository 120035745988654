import React from "react";
import { Grid, TextField, Select, MenuItem } from "@material-ui/core";
import { getHumanPlanName } from "../../utils";
import {
    PLAN_NAME_NO,
    PLAN_NAME_TRIAL,
    PLAN_NAME_UNLIMITED_MONTHLY,
    PLAN_NAME_UNLIMITED
} from "../../constants/payments";

export default function UserEditForm({
    editMode = false,
    isLoading,
    password,
    email,
    firstname,
    lastname,
    company,
    plan,
    onChange
}) {
    return (
        <div>
            <Grid container spacing={2} direction="column">
                <Grid item md={5} sm={12} xs={12}>
                    <TextField
                        value={email}
                        fullWidth
                        required
                        label="Email"
                        placeholder="example@example.fi"
                        onChange={(e) => {
                            onChange("email", e.target.value);
                        }}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item md={5} sm={12} xs={12}>
                    <TextField
                        value={firstname}
                        fullWidth
                        label="Firstname"
                        placeholder=""
                        onChange={(e) => {
                            onChange("firstname", e.target.value);
                        }}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item md={5} sm={12} xs={12}>
                    <TextField
                        value={lastname}
                        fullWidth
                        label="Lastname"
                        placeholder=""
                        onChange={(e) => {
                            onChange("lastname", e.target.value);
                        }}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item md={5} sm={12} xs={12}>
                    <TextField
                        value={company}
                        fullWidth
                        label="Company name"
                        placeholder="Google inc"
                        onChange={(e) => {
                            onChange("company", e.target.value);
                        }}
                        disabled={isLoading}
                    />
                </Grid>
                {!editMode && (
                    <Grid item md={5} sm={12} xs={12}>
                        <TextField
                            value={password}
                            fullWidth
                            label="Password (leave blank for a random password)"
                            placeholder=""
                            onChange={(e) => {
                                onChange("password", e.target.value);
                            }}
                            disabled={isLoading}
                        />
                    </Grid>
                )}
                {!editMode && (
                    <Grid item md={5} sm={12} xs={12}>
                        <Select
                            fullWidth
                            label="Plan"
                            value={plan}
                            onChange={(e) => {
                                onChange("plan", e.target.value);
                            }}
                            inputProps={{
                                name: "plan",
                                id: "plan"
                            }}
                            disabled={isLoading}
                        >
                            <MenuItem value={"no"}>{getHumanPlanName(PLAN_NAME_NO)}</MenuItem>
                            <MenuItem value={"trial"}>{getHumanPlanName(PLAN_NAME_TRIAL)}</MenuItem>
                            <MenuItem value={"unlimited-monthly"}>
                                {getHumanPlanName(PLAN_NAME_UNLIMITED_MONTHLY)}
                            </MenuItem>
                            <MenuItem value={"unlimited"}>
                                {getHumanPlanName(PLAN_NAME_UNLIMITED)}
                            </MenuItem>
                        </Select>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}
