import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { amber, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

const useStyles1 = makeStyles(theme => ({
    root: {
        position: "absolute"
    },
    success: {
        backgroundColor: green[600]
    },
    error: {
        backgroundColor: theme.palette.error.dark
    },
    info: {
        backgroundColor: theme.palette.primary.main
    },
    warning: {
        backgroundColor: amber[700]
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    messageRoot: {
        display: "flex",
        alignItems: "center",
    },
    message: {
        display: "flex",
        alignItems: "right",
        flexDirection: "column"
    },
    errMsg: {}
}));

function SnackbarContentWrapper(props) {
    const classes = useStyles1();
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.messageRoot}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    <div className={classes.message}>
                        {Array.isArray(message)
                            ? message.map((msg, i) => (
                                  <div className={classes.errMsg} key={i}>
                                      {msg}
                                  </div>
                              ))
                            : message}
                    </div>
                </span>
            }
            action={[
                <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>
            ]}
            {...other}
        />
    );
}

const Notification = ({
    message = "",
    variant = "info",
    open = false,
    autoHideDuration = 6000,
    onClose
}) => {
    const classes = useStyles1();

    return (
        <Snackbar
            className={classes.root}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left"
            }}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
        >
            <SnackbarContentWrapper onClose={onClose} variant={variant} message={message} />
        </Snackbar>
    );
};

Notification.propTypes = {
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

export default Notification;
