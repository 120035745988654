import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    Button,
    CircularProgress,
    Grid,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Typography
} from "@material-ui/core";
import * as popupMessageActions from "../../actions/popupMessage";
import * as campaignActions from "../../actions/campaign";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import { triggerMessage } from "../../utils/events";
import Alert from "@material-ui/lab/Alert";
import FileUpload from "../FileUpload";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    section: {
        marginBottom: theme.spacing(3)
    },
    subSection: {
        marginBottom: theme.spacing(2)
    },
    formRoot: {
        border: "1px dashed",
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    saveBtn: {
        backgroundColor: green[500],
        color: "white"
    },
    removeBtn: {
        backgroundColor: red[500],
        marginRight: theme.spacing(2),
        color: "white"
    },
    editBtn: {
        backgroundColor: red[500],
        marginRight: theme.spacing(2),
        color: "white"
    },
    spinnerWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: "2"
    },
    saveButton: {
        backgroundColor: green[500],
        color: "white",
        width: 200
    }
}));

const usePopupSampleStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "red",
        width: 360,
        minHeight: 400,
        position: "absolute",
        top: 0,
        right: 0
    },
    hero: {
        padding: 15,
        width: "100%",
        height: 200,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
    },
    content: {
        minHeight: 200,
        padding: 15,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    title: {
        fontSize: 24
    },
    description: {
        fontSize: 18
    },
    text: {
        fontSize: 18,
        marginBottom: 25
    }
}));

const POPUP_TYPE_CAMPAIGN = "campaign";
const POPUP_TYPE_POPUP = "popup";

// bottom red message
const PopupMessageCampaign = ({
    campaigns,
    campaignId,
    showInDays = 0,
    setShowInDays,
    isLoading,
    onCampaignChange,
    onSubmit,
    onClose
}) => {
    const classes = useStyles();

    return (
        <>
            <Grid item md={12} sm={12} xs={12}>
                <Typography variant="h6">
                    Campaign offer will be shown on the website as a red block at the window bottom.
                </Typography>
            </Grid>
            <Grid item md={4} sm={4} xs={4}>
                <InputLabel htmlFor="campaignId">Campaign</InputLabel>
                <Select
                    fullWidth
                    label="Campaign"
                    value={campaignId}
                    onChange={onCampaignChange}
                    inputProps={{
                        name: "campaignId",
                        id: "campaignId"
                    }}
                >
                    <MenuItem value={""}>Disabled</MenuItem>
                    {Object.keys(campaigns).length &&
                        Object.keys(campaigns).map((cmpId, id) => {
                            return (
                                !campaigns[cmpId].disabled && (
                                    <MenuItem key={`cmp_${id}`} value={campaigns[cmpId].id}>
                                        {campaigns[cmpId].name}
                                    </MenuItem>
                                )
                            );
                        })}
                </Select>
            </Grid>
            <Grid item md={4} sm={4} xs={4}>
                <InputLabel htmlFor="discount">
                    In how many days show pop-up again after closing
                </InputLabel>
                <Input
                    inputProps={{
                        name: "discount",
                        id: "discount"
                    }}
                    placeholder="0"
                    value={showInDays || 0}
                    onChange={(e) => {
                        setShowInDays(Number(e.target.value));
                    }}
                />
            </Grid>
            <Grid container justify="flex-end">
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    label="add"
                    className={classes.saveBtn}
                    onClick={onSubmit}
                    disabled={isLoading}
                    style={{ marginRight: 10 }}
                >
                    Add
                </Button>
                <Button variant="contained" onClick={onClose}>
                    Close
                </Button>
            </Grid>
        </>
    );
};

const PopupSample = ({
    title = "",
    description = "",
    text = "",
    ctatext = "",
    bgcolor = "#ffffff",
    bgcolorg = "",
    titlecolor = "#ffffff",
    textcolor = "#ffffff",
    textareag = "",
    bgsize = "auto",
    backgroundUrl
}) => {
    const classes = usePopupSampleStyles();

    const rootStyle = { backgroundColor: bgcolor, color: titlecolor };
    if (bgcolorg) {
        delete rootStyle["backgroundColor"];
        rootStyle["background"] = bgcolorg;
    }

    return (
        <div className={classes.root} style={rootStyle}>
            <div
                className={classes.hero}
                style={{
                    backgroundImage: `url(${backgroundUrl})`,
                    backgroundSize: bgsize
                }}
            >
                <div className={classes.title}>{title}</div>
                <div className={classes.description}>{description}</div>
            </div>
            <div className={classes.content} style={{ background: textareag || "" }}>
                <div style={{ color: textcolor }} className={classes.text}>
                    {text}
                </div>
                <div>
                    <Button color="primary">{ctatext || "OK"}</Button>
                    <Button color="secondary">Close</Button>
                </div>
            </div>
        </div>
    );
};

const LS_KEY = "popup_data";
const PopupMessagePopup = ({ actions, isBgImageFetching, bgUrl = "", onClose, onSave }) => {
    const classes = useStyles();

    const dataString =
        window.localStorage.getItem(LS_KEY) ||
        `{"campaignId": "n/a", "bgcolor": "#4543c3", "bgsize": "auto", "showInDays": "0", "textareag": "red"}`;

    const [data, setdata] = React.useState(
        dataString
            ? JSON.parse(dataString)
            : {
                  campaignId: "n/a",
                  bgcolor: "#4543c3",
                  textcolor: "#fff",
                  titlecolor: "#fff",
                  bgsize: "auto",
                  showInDays: 0
              }
    );

    React.useEffect(() => {
        window.localStorage.setItem(LS_KEY, JSON.stringify(data));
    }, [data]);

    const handleInput = React.useCallback(
        (key, value) => {
            setdata({ ...data, [key]: value });
        },
        [data]
    );

    const handleRemoveBgClick = React.useCallback(() => {
        setdata({ ...data, backgroundUrl: "" });
    }, [data]);

    const handleUpload = React.useCallback(
        (name, file) => {
            actions.uploadImage(file, (err, res) => {
                if (!err) {
                    if (res && res.data && res.data.file) {
                        setdata({ ...data, backgroundUrl: res.data.file });
                    }
                }
            });
        },
        [actions, data]
    );

    return (
        <>
            <Grid container item direction="column" spacing={4} style={{ position: "relative" }}>
                <Grid item md={6} sm={6} xs={6} spacing={3}>
                    <Typography variant="h6">
                        Will be shown on the website as a pop-up in the middle.
                    </Typography>
                </Grid>
                <Grid item md={4} sm={6} xs={6}>
                    <InputLabel htmlFor="showInDays">Show again after closing (in days)</InputLabel>
                    <Input
                        fullWidth={true}
                        label="show in days"
                        placeholder="0"
                        inputProps={{
                            id: "showInDays",
                            name: "showInDays"
                        }}
                        value={data.showInDays || ""}
                        onChange={(e) => handleInput("showInDays", e.target.value)}
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={6}>
                    <InputLabel htmlFor="title">Title</InputLabel>
                    <Input
                        fullWidth={true}
                        label="Title"
                        placeholder="Some title"
                        inputProps={{
                            id: "title",
                            name: "title"
                        }}
                        value={data.title || ""}
                        onChange={(e) => handleInput("title", e.target.value)}
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={6}>
                    <InputLabel htmlFor="description">Description</InputLabel>
                    <Input
                        fullWidth={true}
                        label="Description"
                        placeholder="Description under the title"
                        inputProps={{
                            id: "description",
                            name: "description"
                        }}
                        value={data.description || ""}
                        onChange={(e) => handleInput("description", e.target.value)}
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={6}>
                    <InputLabel htmlFor="text">Text</InputLabel>
                    <Input
                        fullWidth={true}
                        multiline={true}
                        label="Text"
                        placeholder="Big text description"
                        inputProps={{
                            id: "text",
                            name: "text"
                        }}
                        value={data.text || ""}
                        onChange={(e) => handleInput("text", e.target.value)}
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={6}>
                    <InputLabel htmlFor="ctatext">CTA button label</InputLabel>
                    <Input
                        fullWidth={true}
                        label="ctatext"
                        placeholder="Join now!"
                        inputProps={{
                            id: "ctatext",
                            name: "ctatext"
                        }}
                        value={data.ctatext || ""}
                        onChange={(e) => handleInput("ctatext", e.target.value)}
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={6}>
                    <InputLabel htmlFor="link">Link</InputLabel>
                    <Input
                        fullWidth={true}
                        label="Link"
                        placeholder="https://example.com/"
                        inputProps={{
                            id: "link",
                            name: "link"
                        }}
                        value={data.link || ""}
                        onChange={(e) => handleInput("link", e.target.value)}
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={6}>
                    <InputLabel htmlFor="bgcolor">Background Color</InputLabel>
                    <Input
                        fullWidth={true}
                        label="Background color"
                        placeholder="#FFFFFF"
                        inputProps={{
                            id: "bgcolor",
                            name: "bgcolor"
                        }}
                        value={data.bgcolor}
                        onChange={(e) => handleInput("bgcolor", e.target.value)}
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={6}>
                    <InputLabel htmlFor="bgcolorg">Background gradient</InputLabel>
                    <Input
                        fullWidth={true}
                        label="Background gradient"
                        placeholder="linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(113,9,121,1) 35%, rgba(0,212,255,1) 100%)"
                        inputProps={{
                            id: "bgcolorg",
                            name: "bgcolorg"
                        }}
                        value={data.bgcolorg}
                        onChange={(e) => handleInput("bgcolorg", e.target.value)}
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={6}>
                    <InputLabel htmlFor="titlecolor">Title/Description color</InputLabel>
                    <Input
                        fullWidth={true}
                        label="titlecolor"
                        placeholder="#FFFFFF"
                        inputProps={{
                            id: "titlecolor",
                            name: "titlecolor"
                        }}
                        value={data.titlecolor}
                        onChange={(e) => handleInput("titlecolor", e.target.value)}
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={6}>
                    <InputLabel htmlFor="textcolor">Text color</InputLabel>
                    <Input
                        fullWidth={true}
                        label="textcolor"
                        placeholder="#FFFFFF"
                        inputProps={{
                            id: "textcolor",
                            name: "textcolor"
                        }}
                        value={data.textcolor}
                        onChange={(e) => handleInput("textcolor", e.target.value)}
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={6}>
                    <InputLabel htmlFor="textareag">Text area gradient</InputLabel>
                    <Input
                        fullWidth={true}
                        label="Text area gradient"
                        placeholder="linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(113,9,121,1) 35%, rgba(0,212,255,1) 100%)"
                        inputProps={{
                            id: "textareag",
                            name: "textareag"
                        }}
                        value={data.textareag}
                        onChange={(e) => handleInput("textareag", e.target.value)}
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={6}>
                    <InputLabel htmlFor="field">Background size</InputLabel>
                    <Select
                        fullWidth={true}
                        value={data.bgsize}
                        onChange={(e) => handleInput("bgsize", e.target.value)}
                        renderValue={(selected) => selected}
                    >
                        {["auto", "contain", "cover"].map((name) => (
                            <MenuItem key={name} value={name}>
                                <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                    <FileUpload
                        fileUrl={bgUrl}
                        label="Background image"
                        name="background_img"
                        buttonText="Upload bg image"
                        onUpload={handleUpload}
                        isLoading={isBgImageFetching}
                    />
                    <Button onClick={handleRemoveBgClick}>Remove bg image</Button>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                    <Button
                        className={classes.saveButton}
                        variant="contained"
                        color="success"
                        style={{ marginRight: 10 }}
                        onClick={onSave}
                    >
                        Set pop-up
                    </Button>
                    <Button variant="contained" onClick={onClose}>
                        Close
                    </Button>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                    <Alert severity="info">
                        To set a color use{" "}
                        <a
                            style={{ color: "blue" }}
                            href="https://developer.mozilla.org/en-US/docs/Web/CSS/color_value"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            CSS colors
                        </a>{" "}
                        or gradient values.
                        <br />
                        Gradient generator is{" "}
                        <a
                            style={{ color: "blue" }}
                            href="https://cssgradient.io"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            here
                        </a>
                        <br />
                        External links will be opened in new window e.g.
                        https://example.com/some-url.
                        <br />
                        If you want to use app CloudBounce or BounceCast link don't include domain
                        name, use path only e.g. /redeem or /partner etc.
                    </Alert>
                </Grid>
                <PopupSample {...data} />
            </Grid>
        </>
    );
};

const PopupMessageMain = ({ onSelect }) => {
    return (
        <>
            <Grid item md={12} sm={12} xs={12}>
                <Typography variant="h5">Select pop-up type.</Typography>
            </Grid>
            <Grid container md={6} sm={12} xs={12}>
                <Grid item md={4} sm={4} xs={4}>
                    <Button
                        variant="contained"
                        label="Campaign offer"
                        onClick={() => {
                            if (onSelect) {
                                onSelect(POPUP_TYPE_CAMPAIGN);
                            }
                        }}
                    >
                        Campaign offer
                    </Button>
                </Grid>
                <Grid item md={4} sm={4} xs={4}>
                    <Button
                        variant="contained"
                        lavel="Pop-up in the middle"
                        onClick={() => {
                            if (onSelect) {
                                onSelect(POPUP_TYPE_POPUP);
                            }
                        }}
                    >
                        Pop-up in the middle
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

const PopupMessage = ({
    campaigns = {},
    isMessageFetching,
    isMessageSubmitFetching,
    isMessageRemoveFetching,
    campaignActions,
    isBgImageFetching,
    popupMessageActions
}) => {
    const classes = useStyles();

    const [popUpType, setPopUpType] = React.useState("");
    const [campaignId, setCampaignId] = React.useState("");
    const [showInDays, setShowInDays] = React.useState(0);
    const [messageData, setMessageData] = React.useState(null);

    React.useEffect(() => {
        popupMessageActions.get((err, data) => {
            if (!err && Object.keys(data).length) {
                setMessageData(data);
            }
        });
        if (popUpType === POPUP_TYPE_CAMPAIGN) {
            campaignActions.loadCampaigns(0, 1000);
        }
    }, [campaignActions, popupMessageActions, popUpType]);

    const handleCampaignChange = React.useCallback((e) => {
        setCampaignId(e.target.value);
    }, []);

    const handleSubmit = React.useCallback(
        (e) => {
            popupMessageActions.create({ campaignId, showInDays }, (err, data) => {
                if (err) {
                    triggerMessage(`Something wrong ${err}`, "warning");
                } else {
                    triggerMessage("Pop-up has been updated", "success");
                    setMessageData(data);
                    setPopUpType("");
                }
            });
        },
        [popupMessageActions, campaignId, showInDays]
    );

    const handleRemove = React.useCallback(
        (e) => {
            popupMessageActions.remove((err, data) => {
                if (err) {
                    triggerMessage(`Something wrong ${err}`, "warning");
                } else {
                    triggerMessage("Pop-up has been removed", "success");
                    setMessageData(null);
                }
            });
        },
        [popupMessageActions]
    );

    const handlePopupSave = React.useCallback(
        (e) => {
            const dataString = window.localStorage.getItem(LS_KEY) || "{}";
            popupMessageActions.create(JSON.parse(dataString), (err, data) => {
                if (err) {
                    triggerMessage(`Something wrong ${err}`, "warning");
                } else {
                    triggerMessage("Pop-up has been updated", "success");
                    setMessageData(data);
                    setPopUpType("");
                }
            });
        },
        [popupMessageActions]
    );

    return (
        <Grid container spacing={2} direction="column" className={classes.root}>
            {isMessageFetching === true && (
                <Grid className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </Grid>
            )}
            {isMessageFetching === false && messageData && (
                <>
                    <Grid item md={4} sm={4} xs={4}>
                        <Alert className={classes.info} severity="info">
                            You have an active pop-up window on the website. If you have updated
                            campaign texts plz remove this message and set a new one.
                        </Alert>
                    </Grid>
                    <Grid item md={4} sm={4} xs={4}>
                        <Button
                            variant="contained"
                            startIcon={<RemoveIcon />}
                            label="remove"
                            className={classes.removeBtn}
                            onClick={handleRemove}
                            disabled={isMessageRemoveFetching}
                        >
                            Remove
                        </Button>
                    </Grid>
                </>
            )}
            {isMessageFetching === false && !messageData && (
                <Grid container item direction="column" md="auto" spacing={4}>
                    {!popUpType && <PopupMessageMain onSelect={setPopUpType} />}
                    {popUpType === POPUP_TYPE_CAMPAIGN && (
                        <PopupMessageCampaign
                            campaignId={campaignId}
                            campaigns={campaigns}
                            showInDays={showInDays}
                            setShowInDays={setShowInDays}
                            isLoading={isMessageFetching}
                            onCampaignChange={handleCampaignChange}
                            onSubmit={handleSubmit}
                            onClose={() => {
                                setPopUpType("");
                            }}
                        />
                    )}
                    {popUpType === POPUP_TYPE_POPUP && (
                        <PopupMessagePopup
                            actions={popupMessageActions}
                            isBgImageFetching={isBgImageFetching}
                            onClose={() => {
                                setPopUpType("");
                            }}
                            onSave={handlePopupSave}
                        />
                    )}
                </Grid>
            )}
        </Grid>
    );
};

function mapStateToProps({ state, campaigns }) {
    return {
        campaigns: campaigns.items,
        isMessageFetching: state.popupMessageGetMessageFetching,
        isMessageSubmitFetching: state.popupMessageCreateMessageFetching,
        isMessageRemoveFetching: state.popupMessageRemoveMessageFetching,
        isBgImageFetching: state.popupBgImageFetching
    };
}

function mapDispatchToProps(dispatch) {
    return {
        popupMessageActions: bindActionCreators(popupMessageActions, dispatch),
        campaignActions: bindActionCreators(campaignActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupMessage);
