import Api from "../services/api";
import { setState } from "../actions/state";
import {
    QUIZZES_SET,
    QUIZ_SET,
    QUIZ_OPTION_UPDATE,
    QUIZ_UPDATE_FIELD,
    QUIZ_RESET,
    QUIZ_ADD,
    QUIZ_OPTION_ADD_EMPTY,
    QUIZ_OPTION_REMOVE
} from "../constants/quiz";
import { triggerMessage } from "../utils/events";

export const loadQuizzes = () => async (dispatch, getState) => {
    try {
        dispatch(setState("quizFetching", true));
        const { data: apiResponse } = await Api().get(`/admin/quiz`);
        dispatch(setState("quizFetching", false));

        if (apiResponse.data) {
            dispatch({
                type: QUIZZES_SET,
                payload: apiResponse.data
            });
        }
    } catch (e) {
        dispatch(setState("quizFetching", false));
    }
};

export const loadQuiz = (id) => async (dispatch, getState) => {
    try {
        dispatch(setState("loadquizFetching", true));
        const { data: apiResponse } = await Api().get(`/admin/quiz/${id}`);
        dispatch(setState("loadquizFetching", false));

        if (apiResponse.data) {
            dispatch({
                type: QUIZ_SET,
                payload: apiResponse.data
            });
        }
    } catch (e) {
        dispatch(setState("loadquizFetching", false));
    }
};

export const loadQuizResult = (id, cb) => async (dispatch, getState) => {
    try {
        dispatch(setState("quizResultFetching", true));
        const { data: apiResponse } = await Api().get(`/admin/quiz/result/${id}`);
        dispatch(setState("quizResultFetching", false));

        if (apiResponse.data) {
            if (typeof cb === "function") {
                cb(null, apiResponse.data);
            }
        }
    } catch (e) {
        dispatch(setState("quizResultFetching", false));
        if (typeof cb === "function") {
            cb(true, null);
        }
    }
};

export const updateQuiz = (fieldName, value) => (dispatch) => {
    dispatch({
        type: QUIZ_UPDATE_FIELD,
        payload: {
            fieldName,
            value
        }
    });
};

export const reset = () => (dispatch) => {
    dispatch({
        type: QUIZ_RESET
    });
};

export const submitQuiz =
    (isUpdate = false, cb) =>
    async (dispatch, getState) => {
        const quiz = getState().quiz;

        try {
            dispatch(setState("submitQuizFetching", true));
            const { data: apiResponse } = isUpdate
                ? await Api().put(`/admin/quiz/${quiz.id}`, quiz)
                : await Api().post(`/admin/quiz`, quiz);
            dispatch(setState("submitQuizFetching", false));

            if (apiResponse.data) {
                triggerMessage(`Quiz has been ${isUpdate ? "updated" : "added"}`, "success");
                if (typeof cb === "function") {
                    cb(null, apiResponse.data);
                }
            }
        } catch (e) {
            dispatch(setState("submitQuizFetching", false));
            let errors =
                (e.response &&
                    e.response.data &&
                    e.response.data.data &&
                    e.response.data.data.errors) ||
                "";

            if (!errors) {
                errors = (e.response && e.response.data && e.response.data.message) || e.message;
            }
            triggerMessage(errors, "error");
            if (typeof cb === "function") {
                cb(errors, null);
            }
        }
    };

export const addEmptyOption = (optionId) => (dispatch) => {
    dispatch({
        type: QUIZ_OPTION_ADD_EMPTY,
        payload: {
            optionId
        }
    });
};

export const removeQuizOption = (optionId) => (dispatch) => {
    dispatch({
        type: QUIZ_OPTION_REMOVE,
        payload: {
            optionId
        }
    });
};

export const optionUpdate = (optionId, fieldName, value) => (dispatch) => {
    dispatch({
        type: QUIZ_OPTION_UPDATE,
        payload: {
            optionId,
            fieldName,
            value
        }
    });
};
