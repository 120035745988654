export const CAMPAIGN_SET = "CAMPAIGN_SET";
export const CAMPAIGN_RESET = "CAMPAIGN_RESET";
export const CAMPAIGNS_SET = "CAMPAIGNS_SET";
export const CAMPAIGN_UPDATE_ITEM_FIELD = "CAMPAIGN_UPDATE_ITEM_FIELD";
export const CAMPAIGN_UPDATE_ITEM_LANGUAGE_FIELD = "CAMPAIGN_UPDATE_ITEM_LANGUAGE_FIELD";
export const CAMPAIGN_FORM_SET_DISCOUNT_CONDITION = "CAMPAIGN_FORM_SET_DISCOUNT_CONDITION";
export const CAMPAIGN_FORM_SET_INVITE_CODE_DISCOUNT_CONDITION = "CAMPAIGN_FORM_SET_INVITE_CODE_DISCOUNT_CONDITION";

export const CAMPAIGN_REPORT_UNIQUE_VALUES_SET = "CAMPAIGN_REPORT_UNIQUE_VALUES_SET";
export const CAMPAIGN_REPORT_CAMPAIGN_SET = "CAMPAIGN_REPORT_CAMPAIGN_SET";
export const CAMPAIGN_REPORT_CHART_SET = "CAMPAIGN_REPORT_CHART_SET";
export const CAMPAIGN_REPORT_FUNNEL_SET = "CAMPAIGN_REPORT_FUNNEL_SET";
export const CAMPAIGN_REPORT_FUNNEL_TOTAL_SET = "CAMPAIGN_REPORT_FUNNEL_TOTAL_SET";
export const CAMPAIGN_REPORT_SUBS_TOTAL_SET = "CAMPAIGN_REPORT_SUBS_TOTAL_SET";

// filter
export const SET_CAMPAIGN_FILTER = "SET_CAMPAIGN_FILTER";
export const RESET_CAMPAIGN_FILTER = "RESET_CAMPAIGN_FILTER";

export const CAMPAIGN_PAGE_LIMIT = 25;