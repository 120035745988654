import React from "react";
import {
    Grid,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getHumanPlanName } from "../../utils";
import HelpIcon from "@material-ui/icons/Help";
import SyncIcon from "@material-ui/icons/Sync";
import { green } from "@material-ui/core/colors";
import { PLAN_GOD } from "../../constants/payments";

const useStyles = makeStyles((theme) => ({
    subscriptionsTable: {
        width: "100%"
    },
    renewLabel: {
        position: "relative",
        padding: theme.spacing(1),
        border: "1px dashed",
        marginBottom: theme.spacing(1)
    },
    renewLabelHint: {
        position: "absolute",
        fontSize: 9,
        top: 1,
        left: 3
    },
    smallHelpIcon: {
        fontSize: 12,
        cursor: "pointer"
    },
    outOfSyncBlock: {
        position: "absolute",
        fontSize: 9,
        top: -23,
        right: -23
    },
    outOfSyncBtn: {
        color: green[500]
    },
    historyPlanName: {
        position: "relative"
    },
    migratedLabel: {
        position: "absolute",
        left: 80,
        top: 6,
        fontSize: 14,
        opacity: ".4",
        color: green[500],
        transform: "rotate(90deg)",
        "&:hover": {
            backgroundColor: green[800],
            color: "white",
            opacity: 1
        }
    }
}));

export default function SubscriptionList({
    currentPlan = "",
    isLoading,
    subscriptions,
    onSyncSubscription
}) {
    const classes = useStyles();

    return (
        <>
            <Grid container spacing={0} alignItems="stretch" direction="column">
                {subscriptions && subscriptions.length > 0 && (
                    <>
                        <Grid item>
                            <Paper className={classes.subscriptionsRoot}>
                                <Table
                                    aria-label="subscriptions"
                                    className={classes.subscriptionsTable}
                                >
                                    <TableHead>
                                        <TableRow spacing={1}>
                                            <TableCell>
                                                ID{" "}
                                                <Tooltip title="Braintree ID">
                                                    <HelpIcon className={classes.smallHelpIcon} />
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="center">
                                                Invited{" "}
                                                <Tooltip title="It means user got this subscription as a gift or through dashboard. You can consider this as a local subscription which doesn't have BrainTree ID.">
                                                    <HelpIcon className={classes.smallHelpIcon} />
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="center">
                                                Status{" "}
                                                <Tooltip
                                                    title={
                                                        'Possible statuses: "active", "canceled", "expired", "suspended", "pending", "unknown". \nSuspended happens on unsuccessful charge and will be actual until Braintree won\'t be charged user successfully. Pending status is not in use now.'
                                                    }
                                                >
                                                    <HelpIcon className={classes.smallHelpIcon} />
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="center" style={{ width: 130 }}>
                                                Plan
                                            </TableCell>
                                            <TableCell align="center">Price</TableCell>
                                            <TableCell align="center" style={{ width: 130 }}>
                                                Created at
                                            </TableCell>
                                            <TableCell align="center">
                                                Renew{" "}
                                                <Tooltip title="Next renew date. Local record is the record in our database. Braintree – paid through date. If you see that the dates are too different, then you can try to synchronize the local and the braintree dates.">
                                                    <HelpIcon className={classes.smallHelpIcon} />
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="center">
                                                Charged{" "}
                                                <Tooltip title="Successful charge signal from Braintree was gotten">
                                                    <HelpIcon className={classes.smallHelpIcon} />
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="center">Canceled at</TableCell>
                                            <TableCell align="center">
                                                Canceled by{" "}
                                                <Tooltip
                                                    title={
                                                        'Who cancelled subscription. "user", "system", "braintree", "unknown"'
                                                    }
                                                >
                                                    <HelpIcon className={classes.smallHelpIcon} />
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="center" style={{ width: 200 }}>
                                                Cancel reason
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {subscriptions.map((row, i) => (
                                            <TableRow key={`sub${i}`}>
                                                <TableCell align="center">{row.id || ""}</TableCell>
                                                <TableCell align="center">
                                                    {row.invited ? "✅" : ""}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    style={{
                                                        color:
                                                            row.status === "active"
                                                                ? "green"
                                                                : "initial"
                                                    }}
                                                >
                                                    {row.status}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div className={classes.historyPlanName}>
                                                        {row.is_transferred && <div className={classes.migratedLabel}>Migrated</div>}
                                                        {getHumanPlanName(
                                                            row.isGod ? PLAN_GOD : row.plan, false, row.isMini
                                                        )}
                                                        {row.isTrial && " trial"}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">{row.pric}</TableCell>
                                                <TableCell align="center">
                                                    {row.created_at}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.renew_date ? (
                                                        <div>
                                                            <div className={classes.renewLabel}>
                                                                <div
                                                                    className={
                                                                        classes.renewLabelHint
                                                                    }
                                                                >
                                                                    Local
                                                                </div>
                                                                {row.is_renew_date_out_of_sync &&
                                                                    row.id &&
                                                                    row.status === "active" && (
                                                                        <div
                                                                            className={
                                                                                classes.outOfSyncBlock
                                                                            }
                                                                        >
                                                                            <Tooltip title="Seems to this subscription is out of sync. You can sync it with BrainTree. User's renew date and plan will be updated according to BrainTree subscription. If BrainTree subscription is past due or canceled then this user will get NO plan. Keep calm and sync">
                                                                                <IconButton
                                                                                    className={
                                                                                        classes.outOfSyncBtn
                                                                                    }
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            onSyncSubscription
                                                                                        ) {
                                                                                            onSyncSubscription(
                                                                                                row.id
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <SyncIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </div>
                                                                    )}
                                                                <Tooltip
                                                                    title={`Next renew date (local record)${
                                                                        row.is_renew_date_out_of_sync
                                                                            ? ". It's out of sync now."
                                                                            : ""
                                                                    }`}
                                                                >
                                                                    <div> {row.renew_date}</div>
                                                                </Tooltip>
                                                            </div>
                                                            {row.paid_through_date && (
                                                                <div className={classes.renewLabel}>
                                                                    <div
                                                                        className={
                                                                            classes.renewLabelHint
                                                                        }
                                                                    >
                                                                        Braintree
                                                                    </div>
                                                                    <Tooltip title="Braintree paid through date (source of truth). If you see that the local renew date record lags behind Braintree, then you can synchronize it.">
                                                                        <div>
                                                                            {" "}
                                                                            {row.paid_through_date}
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <span>
                                                            GOD{" "}
                                                            <span role="img" aria-label="is GOD">
                                                                🧙🏻‍♂
                                                            </span>
                                                        </span>
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.charged_successfully && "✅"}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.canceled_at || ""}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.canceled_by || ""}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.canceled_reason || ""}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
}
