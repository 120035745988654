import {
    ACCOUNTANT_REPORT_REMOVE_ITEM_BY_EMAIL,
    ACCOUNTANT_REPORT_SET_ITEMS,
    ACCOUNTANT_REPORT_SET_STATUS
} from "../constants/accountantReport";

const initialState = {
    items: [],
    status: {
        launched: false,
        total: 0,
        processedCount: 0
    },
    progress: {
        message: "",
        email: ""
    }
};

export default function accountantReport(state = initialState, action) {
    switch (action.type) {
        case ACCOUNTANT_REPORT_SET_STATUS:
            return { ...state, status: { ...action.payload } };
        case ACCOUNTANT_REPORT_SET_ITEMS:
            return { ...state, items: [...action.payload] };
        case ACCOUNTANT_REPORT_REMOVE_ITEM_BY_EMAIL:
            return { ...state, items: state.items.filter((email) => email !== action.payload) };
        default:
            return state;
    }
}
