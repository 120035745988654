import React from "react";
import {
    Grid,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import RefreshIcon from "@material-ui/icons/Refresh";

const useStyles = makeStyles(theme => ({
    devicesTable: {
        width: "100%"
    },
    devicesAction: {
        padding: theme.spacing(2)
    },
    devicesCancelBtn: {
        backgroundColor: red[500],
        color: "white",
        "&:hover": {
            backgroundColor: red[800]
        }
    },
    devicesAddBtn: {
        backgroundColor: green[500],
        color: "white",
        width: 200,
        "&:hover": {
            backgroundColor: green[800]
        }
    }
}));

export default function DeviceList({ devices, isLoading, onReset }) {
    const classes = useStyles();
    return (
        <>
            <Grid container spacing={0} alignItems="stretch" direction="column">
                <Grid item>
                    <div className={classes.devicesAction}>
                        <Grid container spacing={1} justify="flex-end">
                            <Grid item md={2}></Grid>
                        </Grid>
                    </div>
                </Grid>
                {devices && devices.length > 0 && (
                    <>
                        <Grid item>
                            <Paper className={classes.devicesRoot}>
                                <Table aria-label="devices" className={classes.devicesTable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Device</TableCell>
                                            <TableCell align="center">Description</TableCell>
                                            <TableCell align="center">
                                                Hardware (System ID)
                                            </TableCell>
                                            <TableCell align="center">Reset</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {devices.map((row, i) => (
                                            <TableRow key={`device${i}`}>
                                                <TableCell align="center">
                                                    {row.device || ""}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.device_description}
                                                </TableCell>
                                                <TableCell align="center">{row.hardware}</TableCell>
                                                <TableCell align="center">
                                                    <IconButton
                                                        disabled={isLoading}
                                                        onClick={() => {
                                                            if (onReset) {
                                                                onReset(row.device);
                                                            }
                                                        }}
                                                    >
                                                        <RefreshIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
}
