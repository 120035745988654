import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as dataActions from "../../actions/data";
import Typography from "@material-ui/core/Typography";
import { Grid, Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageTitle from "../../components/PageTitle";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SaveIcon from "@material-ui/icons/Save";
import { green } from "@material-ui/core/colors";
import Notification from "../../components/Notification";
import JsonEditor from "../../components/JsonEditor";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import JsonEditorPanel from "../../components/JsonEditorPanel";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        position: "relative"
    },
    spinnerWrapper: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: "2"
    },
    tabWrapper: {
        position: "relative"
    },
    saveTabBtn: {
        position: "absolute",
        right: 0,
        bottom: 0,
        color: green[500],
        zIndex: 10
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    runButton: {
        backgroundColor: green[500],
        color: "white",
        width: 200
    },
    tabs: {
        marginBottom: 15
    },
    runButtonWraper: {
        display: "flex",
        justifyContent: "flex-end"
    },
    buttonWraper: {
        marginRight: 10
    },
    btnLinks: {
        marginTop: 10,
        "& a": {
            textDecoration: "underline"
        }
    }
}));

const dataLabels = {
    params: "allowed params",
    chains: "chains",
    messages: "messages",
    soundcheck: "soundcheck",
    soundcheck_clap: "soundcheck clap",
    special_processors: "special processors",
    special_processors_mini: "special processors mini",
    references: "references",
    references_mini: "references_mini",
    all: "all"
};

const DashBoardChainsEdit = ({
    params,
    chains,
    messages,
    soundcheck,
    soundcheckClap,
    specialProcessors,
    specialProcessorsMini,
    references,
    referencesMini,
    errors,
    dataActions,
    isSaveDataFetching,
    isLoadAllDataFetching,
    isEditDataDialogOpened // dialog opened
}) => {
    const classes = useStyles();
    const [tab, settab] = React.useState(0);
    const [jsonError, setJsonError] = React.useState("");
    const [error, setError] = React.useState(false);
    const [useAdvancedEditor, setUseAdvancedEditor] = React.useState(
        localStorage.getItem("advanced_editor") === "1"
    );
    const [dataNameToSave, setDataNameToSave] = React.useState(""); // name of data to save. using when you clicked save in the save dialog

    React.useEffect(() => {
        dataActions.loadAllData();
    }, [dataActions]);

    React.useEffect(() => {
        const errorsCount = Object.keys(errors).length;
        if (errorsCount > 0) {
            setError(true);
        } else {
            setError(false);
        }
    }, [errors, error]);

    const handleJsonTextChange = React.useCallback(
        (name, changedJsonText) => {
            dataActions.updateJsonText(name, changedJsonText);
        },
        [dataActions]
    );

    const handleJsonTextError = React.useCallback((name, message) => {
        setJsonError(`Error ${name}: ${message}`);
    }, []);

    const handleDialogClose = React.useCallback(() => {
        dataActions.openDialog(false);
    }, [dataActions]);

    const handleSaveDataClick = React.useCallback(() => {
        dataActions.saveJsonText(dataNameToSave);
    }, [dataNameToSave, dataActions]);

    const handleErrorClose = React.useCallback(() => {
        dataActions.cleanErrors();
    }, [dataActions]);

    const handleAdvancedEditor = React.useCallback((value) => {
        setUseAdvancedEditor(value);
    }, []);

    return (
        <div className={classes.root}>
            {isLoadAllDataFetching === true && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <Grid container spacing={0} justify="space-between">
                <Grid item xs={6}>
                    <PageTitle title="Edit sound data" />
                    <Typography color="textSecondary">Edit production data here</Typography>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.runButtonWraper}>
                        <Button
                            className={classes.runButton}
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                setDataNameToSave("all");
                                dataActions.openDialog(true);
                            }}
                        >
                            Save all
                            <SaveIcon className={classes.rightIcon} />
                        </Button>
                    </div>
                </Grid>
                <JsonEditorPanel onUseAdvancedEditorChange={handleAdvancedEditor} />
            </Grid>
            <Tabs
                className={classes.tabs}
                variant="scrollable"
                value={tab}
                scrollButtons="on"
                onChange={(e, value) => settab(value)}
            >
                <Tab label="Allowed params" />
                <Tab label="Chains" />
                <Tab label="Messages" />
                <Tab label="Soundcheck" />
                <Tab label="Soundcheck Clap" />
                <Tab label="Special processors" />
                <Tab label="References" />
                <Tab label="Special processors Mini" />
                <Tab label="References Mini" />
            </Tabs>
            {tab === 0 && (
                <div className={classes.tabWrapper}>
                    <JsonEditor
                        name="params"
                        onChange={handleJsonTextChange}
                        onError={handleJsonTextError}
                        jsonText={params}
                        useAdvancedEditor={useAdvancedEditor}
                    />
                    <IconButton
                        className={classes.saveTabBtn}
                        onClick={() => {
                            dataActions.openDialog(true);
                            setDataNameToSave("params");
                        }}
                    >
                        <SaveIcon />
                    </IconButton>
                </div>
            )}
            {tab === 1 && (
                <div className={classes.tabWrapper}>
                    <JsonEditor
                        name="chains"
                        onChange={handleJsonTextChange}
                        onError={handleJsonTextError}
                        jsonText={chains}
                        useAdvancedEditor={useAdvancedEditor}
                    />
                    <IconButton
                        className={classes.saveTabBtn}
                        onClick={() => {
                            dataActions.openDialog(true);
                            setDataNameToSave("chains");
                        }}
                    >
                        <SaveIcon />
                    </IconButton>
                </div>
            )}
            {tab === 2 && (
                <div className={classes.tabWrapper}>
                    <JsonEditor
                        name="messages"
                        onChange={handleJsonTextChange}
                        onError={handleJsonTextError}
                        jsonText={messages}
                        useAdvancedEditor={useAdvancedEditor}
                    />
                    <IconButton
                        className={classes.saveTabBtn}
                        onClick={() => {
                            dataActions.openDialog(true);
                            setDataNameToSave("messages");
                        }}
                    >
                        <SaveIcon />
                    </IconButton>
                </div>
            )}
            {tab === 3 && (
                <div className={classes.tabWrapper}>
                    <JsonEditor
                        name="soundcheck"
                        onChange={handleJsonTextChange}
                        onError={handleJsonTextError}
                        jsonText={soundcheck}
                        useAdvancedEditor={useAdvancedEditor}
                    />
                    <IconButton
                        className={classes.saveTabBtn}
                        onClick={() => {
                            dataActions.openDialog(true);
                            setDataNameToSave("soundcheck");
                        }}
                    >
                        <SaveIcon />
                    </IconButton>
                </div>
            )}
            {tab === 4 && (
                <div className={classes.tabWrapper}>
                    <JsonEditor
                        name="soundcheck_clap"
                        onChange={handleJsonTextChange}
                        onError={handleJsonTextError}
                        jsonText={soundcheckClap}
                        useAdvancedEditor={useAdvancedEditor}
                    />
                    <IconButton
                        className={classes.saveTabBtn}
                        onClick={() => {
                            dataActions.openDialog(true);
                            setDataNameToSave("soundcheck_clap");
                        }}
                    >
                        <SaveIcon />
                    </IconButton>
                </div>
            )}
            {tab === 5 && (
                <div className={classes.tabWrapper}>
                    <JsonEditor
                        name="special_processors"
                        onChange={handleJsonTextChange}
                        onError={handleJsonTextError}
                        jsonText={specialProcessors}
                        useAdvancedEditor={useAdvancedEditor}
                    />
                    <IconButton
                        className={classes.saveTabBtn}
                        onClick={() => {
                            dataActions.openDialog(true);
                            setDataNameToSave("special_processors");
                        }}
                    >
                        <SaveIcon />
                    </IconButton>
                </div>
            )}
            {tab === 6 && (
                <div className={classes.tabWrapper}>
                    <JsonEditor
                        name="references"
                        onChange={handleJsonTextChange}
                        onError={handleJsonTextError}
                        jsonText={references}
                        useAdvancedEditor={useAdvancedEditor}
                    />
                    <IconButton
                        className={classes.saveTabBtn}
                        onClick={() => {
                            dataActions.openDialog(true);
                            setDataNameToSave("references");
                        }}
                    >
                        <SaveIcon />
                    </IconButton>
                </div>
            )}
            {tab === 7 && (
                <div className={classes.tabWrapper}>
                    <JsonEditor
                        name="special_processors_mini"
                        onChange={handleJsonTextChange}
                        onError={handleJsonTextError}
                        jsonText={specialProcessorsMini}
                        useAdvancedEditor={useAdvancedEditor}
                    />
                    <IconButton
                        className={classes.saveTabBtn}
                        onClick={() => {
                            dataActions.openDialog(true);
                            setDataNameToSave("special_processors_mini");
                        }}
                    >
                        <SaveIcon />
                    </IconButton>
                </div>
            )}
            {tab === 8 && (
                <div className={classes.tabWrapper}>
                    <JsonEditor
                        name="references_mini"
                        onChange={handleJsonTextChange}
                        onError={handleJsonTextError}
                        jsonText={referencesMini}
                        useAdvancedEditor={useAdvancedEditor}
                    />
                    <IconButton
                        className={classes.saveTabBtn}
                        onClick={() => {
                            dataActions.openDialog(true);
                            setDataNameToSave("references_mini");
                        }}
                    >
                        <SaveIcon />
                    </IconButton>
                </div>
            )}
            <Notification
                variant="error"
                message={errors && Object.keys(errors).map((key) => errors[key])}
                open={error}
                onClose={handleErrorClose}
            />
            <Notification
                autoHideDuration={2000}
                variant="warning"
                message={jsonError}
                open={Boolean(jsonError)}
                onClose={() => {
                    setJsonError("");
                }}
            />
            <Dialog open={Boolean(isEditDataDialogOpened)} onClose={handleDialogClose}>
                <DialogTitle>{`Save ${dataLabels[dataNameToSave]} production data?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Data will be saved in the production database, app behavior could be
                        changed. Test it before change!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSaveDataClick}
                        color="primary"
                        autoFocus
                        disabled={isSaveDataFetching}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

DashBoardChainsEdit.propTypes = {
    params: PropTypes.string.isRequired,
    chains: PropTypes.string.isRequired,
    messages: PropTypes.string.isRequired,
    soundcheck: PropTypes.string.isRequired,
    soundcheckClap: PropTypes.string.isRequired,
    specialProcessors: PropTypes.string.isRequired,
    references: PropTypes.string.isRequired,
    referencesMini: PropTypes.string.isRequired,
    errors: PropTypes.object,
    dataActions: PropTypes.object.isRequired,
    isSaveDataFetching: PropTypes.bool,
    isLoadAllDataFetching: PropTypes.bool,
    isEditDataDialogOpened: PropTypes.bool
};

function mapStateToProps({ data, state }) {
    return {
        params: data.params,
        chains: data.chains,
        messages: data.messages,
        soundcheck: data.soundcheck,
        soundcheckClap: data.soundcheck_clap,
        specialProcessors: data.special_processors,
        specialProcessorsMini: data.special_processors_mini,
        references: data.references,
        referencesMini: data.references_mini,
        errors: data.errors,
        isSaveDataFetching: state.saveDataFetching,
        isLoadAllDataFetching: state.loadAllDataFetching,
        isEditDataDialogOpened: state.editDataDialogOpened
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dataActions: bindActionCreators(dataActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardChainsEdit);
