import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as discountActions from "../../actions/discount";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { push } from "connected-react-router";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton, CircularProgress } from "@material-ui/core";
import { DISCOUNTS_PAGE_LIMIT } from "../../constants/discount";
import DiscountOfferInfo from "../DiscountOfferInfo";

const columns = [
    { id: "discountId", label: "ID" },
    { id: "comment", label: "Comment", maxWidth: 150 },
    { id: "offer_type", label: "Offer type", align: "center", maxWidth: 100 },
    { id: "offer_info", label: "Offer info", align: "center", maxWidth: 100 },
    {
        id: "invite_code_condition_id",
        label: "Invite code condition ID",
        align: "center",
        maxWidth: 10
    },
    { id: "invite_code_info", label: "Invite code offer info", align: "center", maxWidth: 100 },
    {
        id: "edit",
        label: "",
        align: "right"
    }
];

const useStyles = makeStyles({
    root: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        position: "relative"
    },
    tableWrapper: {
        overflow: "auto"
    },
    spinnerWrapper: {
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "60vh",
        zIndex: "2"
    },
    red: {
        color: "red"
    },
    row: props => ({
        cursor: props.selectMode ? "pointer" : ""
    })
});

function DiscountsTable({ rows = [], total = 0, actions = {}, selectMode = false, onRowClick }) {
    const classes = useStyles({ selectMode });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(DISCOUNTS_PAGE_LIMIT);

    const handleChangePage = React.useCallback(
        (e, newPage) => {
            actions.loadDiscounts(newPage, rowsPerPage);
            setPage(newPage);
        },
        [actions, rowsPerPage]
    );

    const handleChangeRowsPerPage = React.useCallback(
        e => {
            setRowsPerPage(+e.target.value);
            setPage(0);
            actions.loadDiscounts(0, e.target.value);
        },
        [actions]
    );

    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => {
                            return (
                                <TableRow
                                    className={classes.row}
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.id}
                                    onClick={() => {
                                        if (onRowClick) {
                                            onRowClick(row);
                                        }
                                    }}
                                >
                                    {columns.map(column => {
                                        const value = row[column.id];

                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[DISCOUNTS_PAGE_LIMIT, 50, 100]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    "aria-label": "previous page"
                }}
                nextIconButtonProps={{
                    "aria-label": "next page"
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

const makeRowsData = (items, selectMode, onEditClick, onShowInfoClick) => {
    let rows = [];

    for (const id of Object.keys(items)) {
        rows.push({
            id,
            discountId: items[id].id,
            comment: items[id].comment,
            offer_type: items[id].offer ? items[id].offer.type : "",
            offer_info: <DiscountOfferInfo offer={items[id].offer} />,
            invite_code_condition_id: items[id].user_invite_code_condition_id,
            invite_code_discount_condition: items[id].invite_code_discount_condition,
            discount_condition: items[id],
            invite_code_info:
                items[id].user_invite_code_condition_id &&
                items[items[id].user_invite_code_condition_id] ? (
                    <DiscountOfferInfo
                        offer={items[items[id].user_invite_code_condition_id].offer}
                    />
                ) : (
                    "REQUIRED! set a condition for invite codes!"
                ),
            edit: (
                <>
                    {!selectMode && (
                        <IconButton
                            onClick={() => {
                                if (onEditClick) {
                                    onEditClick(id);
                                }
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    )}
                </>
            )
        });
    }

    return rows.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
    });
};

const DiscountsList = ({
    items = {},
    selectMode = false,
    total,
    isLoading,
    discountActions,
    goToPage,
    onShowInfoClick,
    onSelect
}) => {
    const classes = useStyles();

    React.useEffect(() => {
        discountActions.loadDiscounts(0, DISCOUNTS_PAGE_LIMIT);
    }, [discountActions]);

    const handleEditClick = React.useCallback(
        id => {
            goToPage(`/dashboard/discount-conditions/edit/${id}`);
        },
        [goToPage]
    );

    return (
        <div className={classes.root}>
            {isLoading === true && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <DiscountsTable
                rows={makeRowsData(items, selectMode, handleEditClick, onShowInfoClick)}
                total={total}
                actions={discountActions}
                selectMode={selectMode}
                onRowClick={onSelect}
            />
        </div>
    );
};

function mapStateToProps({ discounts, state }) {
    return {
        items: discounts.items,
        total: discounts.total,
        isDiscountsFetching: state.discountsFetching
    };
}

function mapDispatchToProps(dispatch) {
    return {
        discountActions: bindActionCreators(discountActions, dispatch),
        goToPage: url => {
            dispatch(push(url));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscountsList);
