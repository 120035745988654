import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as widgetsActions from "../../../actions/widgets";
import { WidgetHeader, widgetStyles } from "../common";
import { FunnelChart as FunnelPipeLineChart } from "react-funnel-pipeline";
import "react-funnel-pipeline/dist/index.css";
import { CircularProgress } from "@material-ui/core";

const FunnelChart = ({
    title,
    widgetName,
    widgetsData,
    widgetsLoading,
    widgetsActions,
}) => {
    const widgetClasses = widgetStyles({});
    // const classes = styles({
    //     isFilterWindowOpenedWithinModal: isCustomFilterOpenWithinModal
    // });
    const isLoading = widgetsLoading[widgetName];
    const data = widgetsData[widgetName] || [];
    console.log(data);
    console.log(isLoading);
    React.useEffect(() => {
        widgetsActions.loadWidget(widgetName);
    }, [widgetsActions, widgetName]);

    const handleRefresh = React.useCallback(
        (type) => {
            widgetsActions.loadWidget(widgetName);
        },
        [widgetName, widgetsActions]
    );

    return (
        <div className={widgetClasses.root}>
            <WidgetHeader text={title} onRefreshClick={handleRefresh} />
            <div className={widgetClasses.body}>
                {isLoading && (
                    <div className={widgetClasses.spinnerWrapper}>
                        <CircularProgress size={60} />
                    </div>
                )}
                {isLoading === false && (
                    <FunnelPipeLineChart
                        chartHeight="100%"
                        heightRelativeToValue={true}
                        showNames={true}
                        showValues={true}
                        style={{
                            marginTop: 100,
                        }}
                        data={data}
                    />
                )}
            </div>
        </div>
    );
};

function mapStateToProps({ widgets, state }) {
    return {
        widgetsData: widgets.data,
        widgetsLoading: state.widgets,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        widgetsActions: bindActionCreators(widgetsActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunnelChart);
