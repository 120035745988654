import Api from "../services/api";
import { triggerMessage } from "../utils/events";
import { setState } from "./state";

export const checkRenewEmail = (email, cb) => async (dispatch) => {
    try {
        dispatch(setState("checkRenewEmailFetching", true));
        const { data: apiResponse } = await Api().get(
            `/admin/email-notification/renew-email?email=${email}`
        );

        dispatch(setState("checkRenewEmailFetching", false));
        if (apiResponse.data) {
            triggerMessage(`Email: ${email}`, "success");
            if (typeof cb === "function") {
                cb(null, apiResponse.data);
            }
        }
    } catch (e) {
        dispatch(setState("checkRenewEmailFetching", false));
        const errors =
            (e.response && e.response.data && e.response.data.message) || {};
        triggerMessage(errors, "error");
    }
};
