import { SET_STATE, SET_STATE_WITH_KEY } from "../constants/state";
const defaultState = {
    widgets: {}
};
export default function state(state = defaultState, action) {
    switch (action.type) {
        case SET_STATE:
            return applySetState(state, action);
        case SET_STATE_WITH_KEY:
            return applySetStateWithKey(state, action);
        default:
            return state;
    }
}

function applySetState(state, action) {
    const { key, value } = action.payload;
    return { ...state, [key]: value };
}

function applySetStateWithKey(state, action) {
    const { key, innerKey, value } = action.payload;
    return {
        ...state,
        [key]: {
            ...state[key],
            [innerKey]: value
        }
    };
}
