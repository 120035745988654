import React from "react";
import { Paper, InputLabel, Button, CircularProgress, Grid } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(4)
    },
    fileInput: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    wrapper: {
        position: "relative"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    uploadedImage: {
        height: 200
    },
    form: {
        marginRight: theme.spacing(2)
    }
}));

const FileUpload = ({
    name = "",
    label = "",
    fileUrl = "",
    buttonText = "Upload",
    isLoading,
    onUpload
}) => {
    const classes = useStyles();
    const fileInput = React.useRef();

    const handleUploadClick = React.useCallback(() => {
        if (fileInput) {
            let data = new FormData();
            if (
                fileInput &&
                fileInput.current &&
                fileInput.current.files &&
                fileInput.current.files.length > 0
            ) {
                data.append("data", fileInput.current.files[0]);
                if (onUpload) {
                    onUpload(name, data);
                }
            }
        }
    }, [fileInput, name, onUpload]);

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={0} justify="flex-start">
                <Grid item xs={4} md={4} className={classes.form}>
                    <InputLabel htmlFor={name}>{label}</InputLabel>
                    <div className={classes.fileInput}>
                        <input type="file" ref={fileInput} />
                    </div>
                    <div className={classes.wrapper}>
                        <Button
                            fullWidth
                            disabled={isLoading}
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                handleUploadClick();
                            }}
                        >
                            {buttonText}
                            <CloudUploadIcon className={classes.rightIcon} />
                            {isLoading && (
                                <CircularProgress size={29} className={classes.buttonProgress} />
                            )}
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={6} md={6}>
                    <div>
                        {fileUrl && (
                            <img alt={name} className={classes.uploadedImage} src={fileUrl} />
                        )}
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default FileUpload;
