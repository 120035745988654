import Api from "../services/api";
import { setState } from "../actions/state";
import {
    SET_USERS,
    SET_USERS_FILTER,
    USERS_PAGE_LIMIT,
    USER_FROM_SET_FIELD,
    USER_FROM_RESET,
    USER_FROM_SET,
    RESET_USERS_FILTER
} from "../constants/user";
import { triggerMessage } from "../utils/events";
import { push } from "connected-react-router";
import { SET_BRAINTREE, RESET_BRAINTREE } from "../constants/braintree";
import {
    SET_MANDRILL_SEARCH,
    RESET_MANDRILL,
    SET_MANDRILL_CONTENT,
    RESET_MANDRILL_CONTENT
} from "../constants/mandrill";

export const loadUsers =
    (page = 0, limit = USERS_PAGE_LIMIT) =>
    async (dispatch, getState) => {
        const filters = Object.keys(getState().usersFilter).reduce((acc, field) => {
            if (getState().usersFilter[field]) {
                acc.push(`${field}=${getState().usersFilter[field]}`);
            }

            return acc;
        }, []);

        try {
            dispatch(setState("usersFetching", true));
            const { data: apiResponse } = await Api().get(
                `/admin/user?limit=${limit}&page=${page}${
                    filters.length ? `&${filters.join("&")}` : ""
                }`
            );
            dispatch(setState("usersFetching", false));

            if (apiResponse.message === "ok") {
                dispatch({
                    type: SET_USERS,
                    payload: apiResponse.data
                });
            }
        } catch (e) {
            dispatch(setState("usersFetching", false));
        }
    };

export const setUsersFilter = (field, value) => async (dispatch) => {
    dispatch({
        type: SET_USERS_FILTER,
        payload: { field, value }
    });
};

export const resetUsersFilter = () => async (dispatch) => {
    dispatch({
        type: RESET_USERS_FILTER
    });
};

export const userFormSetField = (field, value) => async (dispatch) => {
    dispatch({
        type: USER_FROM_SET_FIELD,
        payload: { field, value }
    });
};

export const userFormReset = () => async (dispatch) => {
    dispatch({
        type: USER_FROM_RESET
    });
};

export const addNewUser = () => async (dispatch, getState) => {
    const userForm = getState().userForm;

    if (!userForm.email) {
        triggerMessage("Email is required", "warning");

        return;
    }

    dispatch(setState("addNewUserFetching", true));
    try {
        const { data: apiResponse } = await Api().post("/admin/user", userForm);
        dispatch(setState("addNewUserFetching", false));
        if (apiResponse.message === "ok") {
            triggerMessage("New user has been added", "success");
            dispatch({
                type: USER_FROM_RESET
            });
            dispatch(push(`/dashboard/users/edit/${apiResponse.data.id}`));
        } else {
            triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
        }
    } catch (e) {
        dispatch(setState("addNewUserFetching", false));
        const error = (e.response && e.response.data && e.response.data.message) || "";
        triggerMessage(error, "error");
    }
};

export const updateUser = () => async (dispatch, getState) => {
    const userForm = getState().userForm;

    if (!userForm.email) {
        triggerMessage("Email is required", "warning");

        return;
    }

    dispatch(setState("updateUserFetching", true));
    try {
        const { data: apiResponse } = await Api().put("/admin/user", userForm);
        dispatch(setState("updateUserFetching", false));
        if (apiResponse.message === "ok") {
            triggerMessage("User has been updated", "success");
        } else {
            triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
        }
    } catch (e) {
        dispatch(setState("updateUserFetching", false));
        const error = (e.response && e.response.data && e.response.data.message) || "";
        triggerMessage(error, "error");
    }
};

export const loadUser = (id) => async (dispatch, getState) => {
    dispatch(setState("loadUserFetching", true));
    try {
        const { data: apiResponse } = await Api().get(`/admin/user/${id}`);
        dispatch(setState("loadUserFetching", false));

        if (apiResponse.message === "ok") {
            dispatch({
                type: USER_FROM_SET,
                payload: apiResponse.data
            });
        } else {
            triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
        }
    } catch (e) {
        dispatch(setState("loadUserFetching", false));
        const error = (e.response && e.response.data && e.response.data.message) || "";
        triggerMessage(error, "error");
    }
};

export const addSubscription =
    (plan, cb = null) =>
    async (dispatch, getState) => {
        dispatch(setState("addSubscriptionFetching", true));
        const userForm = getState().userForm;
        try {
            const { data: apiResponse } = await Api().post(`/admin/user/add-subscription`, {
                plan,
                id: userForm.id
            });
            dispatch(setState("addSubscriptionFetching", false));

            if (apiResponse.message === "ok") {
                triggerMessage("Subscription has been added", "success");
                if (typeof cb === "function") {
                    cb();
                }
            } else {
                triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
            }
        } catch (e) {
            dispatch(setState("addSubscriptionFetching", false));
            const error = (e.response && e.response.data && e.response.data.message) || "";
            triggerMessage(error, "error");
        }
    };

export const syncSubscription =
    (brainTreeId, cb = null) =>
    async (dispatch, getState) => {
        dispatch(setState("syncSubscriptionFetching", true));
        try {
            const { data: apiResponse } = await Api().post(`/admin/user/sync-subscription`, {
                brainTreeId
            });
            dispatch(setState("syncSubscriptionFetching", false));

            if (apiResponse.message === "ok") {
                triggerMessage("Subscription has been synced", "success");
                if (typeof cb === "function") {
                    cb();
                }
            } else {
                triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
            }
        } catch (e) {
            dispatch(setState("syncSubscriptionFetching", false));
            const error = (e.response && e.response.data && e.response.data.message) || "";
            triggerMessage(error, "error");
        }
    };

export const migrateFormBrainTree =
    (brainTreeId, userId, cb = null) =>
    async (dispatch, getState) => {
        dispatch(setState("migrateFormBrainTreeFetching", true));

        try {
            const { data: apiResponse } = await Api().post(
                `/admin/user/migrate-subscription-from-web-cb`,
                {
                    brainTreeId,
                    userId
                }
            );
            dispatch(setState("migrateFormBrainTreeFetching", false));

            if (apiResponse.message === "ok") {
                triggerMessage("Subscription has been migrated", "success");
                if (typeof cb === "function") {
                    cb();
                }
            } else {
                triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
            }
        } catch (e) {
            dispatch(setState("migrateFormBrainTreeFetching", false));
            const error = (e.response && e.response.data && e.response.data.message) || "";
            triggerMessage(error, "error");
        }
    };

export const cancelSubscription =
    (shouldResetUserPlan = false, resetToTrial = false, cb = null) =>
    async (dispatch, getState) => {
        const userForm = getState().userForm;

        dispatch(setState("cancelSubscriptionFetching", true));
        try {
            const { data: apiResponse } = await Api().post(`/admin/user/cancel-subscription`, {
                id: userForm.id,
                shouldResetUserPlan,
                resetToTrial
            });
            dispatch(setState("cancelSubscriptionFetching", false));

            if (apiResponse.message === "ok") {
                triggerMessage("Subscription has been canceled", "success");
                if (typeof cb === "function") {
                    cb();
                }
            } else {
                triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
            }
        } catch (e) {
            dispatch(setState("cancelSubscriptionFetching", false));
            const error = (e.response && e.response.data && e.response.data.message) || "";
            triggerMessage(error, "error");
        }
    };

export const resetDevice =
    (userId, device, cb = null) =>
    async (dispatch, getState) => {
        dispatch(setState("resetDeviceFetching", true));
        try {
            const { data: apiResponse } = await Api().post(`/admin/user/reset-device`, {
                id: userId,
                device
            });
            dispatch(setState("resetDeviceFetching", false));

            if (apiResponse.message === "ok") {
                triggerMessage(`${device} device has been reset`, "success");
                if (typeof cb === "function") {
                    cb();
                }
            } else {
                triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
            }
        } catch (e) {
            dispatch(setState("resetDeviceFetching", false));
            const error = (e.response && e.response.data && e.response.data.message) || "";
            triggerMessage(error, "error");
        }
    };

export const resetAndGeneratePassword =
    (userId, cb = null) =>
    async (dispatch, getState) => {
        dispatch(setState("resetAndGeneratePasswordFetching", true));
        try {
            const { data: apiResponse } = await Api().post(`/admin/user/reset-generate-password`, {
                id: userId
            });
            dispatch(setState("resetAndGeneratePasswordFetching", false));

            if (apiResponse.message === "ok") {
                triggerMessage(`Success`, "success");
                if (typeof cb === "function") {
                    cb(apiResponse.data);
                }
            } else {
                triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
            }
        } catch (e) {
            dispatch(setState("resetAndGeneratePasswordFetching", false));
            const error = (e.response && e.response.data && e.response.data.message) || "";
            triggerMessage(error, "error");
        }
    };

export const loadBrainTreeInfo = (customerId) => async (dispatch, getState) => {
    const btstate = getState().braintree;
    if (btstate && btstate.data && btstate.data.id === customerId) {
        return;
    }
    dispatch(setState("loadBrainTree", true));
    try {
        const { data: apiResponse } = await Api().get(`/admin/user/${customerId}/braintree`);
        dispatch(setState("loadBrainTree", false));

        if (apiResponse.message === "ok") {
            dispatch({
                type: SET_BRAINTREE,
                payload: apiResponse.data
            });
        } else {
            triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
        }
    } catch (e) {
        dispatch(setState("loadBrainTree", false));
        const error = (e.response && e.response.data && e.response.data.message) || "";
        triggerMessage(error, "error");
    }
};

export const resetBrainTreeInfo = () => async (dispatch) => {
    dispatch({
        type: RESET_BRAINTREE
    });
};

export const loadMandrillSearch = (email) => async (dispatch, getState) => {
    dispatch(setState("loadMandrillSearch", true));
    try {
        const { data: apiResponse } = await Api().get(`/admin/user/mandrill/outbound/${email}`);
        dispatch(setState("loadMandrillSearch", false));

        if (apiResponse.message === "ok") {
            dispatch({
                type: SET_MANDRILL_SEARCH,
                payload: apiResponse.data
            });
        } else {
            triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
        }
    } catch (e) {
        dispatch(setState("loadMandrillSearch", false));
        const error = (e.response && e.response.data && e.response.data.message) || "";
        triggerMessage(error, "error");
    }
};

export const loadMandrillContent = (id) => async (dispatch, getState) => {
    dispatch(setState("loadMandrillContent", true));
    try {
        const { data: apiResponse } = await Api().get(`/admin/user/mandrill/content/${id}`);
        dispatch(setState("loadMandrillContent", false));

        if (apiResponse.message === "ok") {
            dispatch({
                type: SET_MANDRILL_CONTENT,
                payload: apiResponse.data
            });
        } else {
            triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
        }
    } catch (e) {
        dispatch(setState("loadMandrillContent", false));
        const error = (e.response && e.response.data && e.response.data.message) || "";
        triggerMessage(error, "error");
    }
};

export const resetMandrill = () => async (dispatch) => {
    dispatch({
        type: RESET_MANDRILL
    });
};

export const resetMandrillContent = () => async (dispatch) => {
    dispatch({
        type: RESET_MANDRILL_CONTENT
    });
};

export const syncPlan = (userId, successCb) => async (dispatch, getState) => {
    dispatch(setState("syncPlan", true));
    try {
        const { data: apiResponse } = await Api().post(`/admin/user/sync-plan`, {
            userId
        });
        dispatch(setState("syncPlan", false));

        if (apiResponse.message === "ok") {
            triggerMessage("Done", "success");
            if (typeof successCb === "function") {
                successCb();
            }
        } else {
            triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
        }
    } catch (e) {
        dispatch(setState("syncPlan", false));
        const error = (e.response && e.response.data && e.response.data.message) || "";
        triggerMessage(error, "error");
    }
};

export const resetCache = (userId, successCb) => async (dispatch, getState) => {
    dispatch(setState("resetCache", true));
    try {
        const { data: apiResponse } = await Api().post(`/admin/user/reset-cache`, {
            userId
        });
        dispatch(setState("resetCache", false));

        if (apiResponse.message === "ok") {
            triggerMessage("Done", "success");
            if (typeof successCb === "function") {
                successCb();
            }
        } else {
            triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
        }
    } catch (e) {
        dispatch(setState("resetCache", false));
        const error = (e.response && e.response.data && e.response.data.message) || "";
        triggerMessage(error, "error");
    }
};
