import React from "react";
import SettingsFieldItem from "../SettingsFieldItem";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    group: {
        // border: "1px solid black",
        padding: theme.spacing(4)
    },
    groupName: {
        marginBottom: theme.spacing(2)
    }
}));

export default function SettingsItemGroup({ groupName = "", items = {}, onChange }) {
    const classes = useStyles();

    return (
        <div className={classes.group}>
            <Typography
                className={classes.groupName}
                component="h2"
                variant="h6"
                color="textPrimary"
            >
                {groupName}
            </Typography>
            {Object.keys(items).map((key, i) => (
                <SettingsFieldItem key={i} {...items[key]} onChange={onChange} />
            ))}
        </div>
    );
}
