import { RESET_USERS_FILTER, SET_USERS_FILTER } from "../constants/user";

const initialState = {
    email: "",
    affiliate_id: "",
    code: "",
};

export default function user(state = initialState, action) {
    switch (action.type) {
        case SET_USERS_FILTER:
            const { field, value } = action.payload;

            return { ...state, [field]: value };
        case RESET_USERS_FILTER:
            return {
                email: "",
                affiliate_id: "",
                code: "",
            }
        default:
            return state;
    }
}
