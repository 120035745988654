import React from "react";
import {
    Grid,
    Paper,
    makeStyles,
    Typography,
    MenuItem,
    Select,
    Button,
    FormControlLabel,
    Tooltip,
    Checkbox,
    Input,
    FormControl,
    InputLabel
} from "@material-ui/core";
import { getHumanPlanName, isProject } from "../../utils";
import { PLAN_NAME_UNLIMITED, PLAN_NAME_UNLIMITED_MONTHLY } from "../../constants/payments";
import { green, red } from "@material-ui/core/colors";
import { PROJECT_CB } from "../../constants/lang";
import HelpIcon from "@material-ui/icons/Help";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
        padding: theme.spacing(2)
    },
    item: {
        marginBottom: theme.spacing(1)
    },
    title: {
        marginBottom: theme.spacing(2)
    },
    subscriptionsAction: {
        padding: theme.spacing(2)
    },
    subscriptionsCancelBtn: {
        backgroundColor: red[500],
        color: "white",
        "&:hover": {
            backgroundColor: red[800]
        }
    },
    subscriptionsAddBtn: {
        backgroundColor: green[500],
        color: "white",
        // width: 200,
        "&:hover": {
            backgroundColor: green[800]
        }
    }
}));

export default function SubscriptionActive({
    subscriptions = [],
    currentPlan = "",
    isLoading,
    isAddSubscriptionFetching,
    isMigrateFromBrainTreeFetching,
    isCancelSubscriptionFetching,
    onAddSubscription,
    onMigrateFromBrainTree,
    onCancelSubscription
}) {
    const classes = useStyles();
    const [newPlan, setNewPlan] = React.useState("unlimited");
    const [brainTreeId, setBrainTreeId] = React.useState("");
    const [removeUserPlan, setRemoveUserPlan] = React.useState(false);
    const [resetToTrial, setResetToTrial] = React.useState(false);
    const lastSubscription = subscriptions && subscriptions[0];

    return (
        <>
            {isLoading === false && (
                <Paper className={classes.root}>
                    <Grid container direction="column">
                        {lastSubscription && (
                            <>
                                <Typography
                                    className={classes.title}
                                    color="inherit"
                                    variant="h6"
                                    component="div"
                                >
                                    {lastSubscription.id
                                        ? `Braintree ${lastSubscription.braintree_status} `
                                        : "Local "}
                                    subscription
                                    {lastSubscription.id ? `: ${lastSubscription.id}` : ""}
                                </Typography>
                                {lastSubscription.subscription_created_at && (
                                    <Grid className={classes.item} item>
                                        Created at: {lastSubscription.subscription_created_at}
                                    </Grid>
                                )}
                                {lastSubscription.braintree_status && (
                                    <Grid className={classes.item} item>
                                        BrainTree status: {lastSubscription.braintree_status}
                                    </Grid>
                                )}
                                {lastSubscription.status && (
                                    <Grid className={classes.item} item>
                                        Local status: {lastSubscription.status}
                                    </Grid>
                                )}
                                {lastSubscription.trial_duration && (
                                    <Grid className={classes.item} item>
                                        Braintree trial duration: {lastSubscription.trial_duration}{" "}
                                        {lastSubscription.trial_duration_unit}
                                    </Grid>
                                )}
                                <Grid className={classes.item} item>
                                    Billing cycle: {lastSubscription.current_billing_cycle}
                                </Grid>
                                {lastSubscription.next_bill_amount && (
                                    <Grid className={classes.item} item>
                                        Next bill amount: {lastSubscription.next_bill_amount}
                                    </Grid>
                                )}
                                {lastSubscription.paid_through_date ? (
                                    <Grid className={classes.item} item>
                                        Paid through date: {lastSubscription.paid_through_date}
                                    </Grid>
                                ) : (
                                    <Grid className={classes.item} item>
                                        Renew date: {lastSubscription.renew_date || "Never (GOD)"}
                                    </Grid>
                                )}
                                {lastSubscription.days_past_due && (
                                    <Grid className={classes.item} item>
                                        Past due: {lastSubscription.days_past_due}
                                    </Grid>
                                )}
                                <Grid className={classes.item} item>
                                    Discount amount: {lastSubscription.discount_amount}
                                </Grid>
                                <Grid className={classes.item} item>
                                    Discount number of billing cycles:{" "}
                                    {lastSubscription.discount_number_of_billing_cycles || "-"}
                                </Grid>
                                <Grid className={classes.item} item>
                                    Discount current billing cycle:{" "}
                                    {lastSubscription.discount_current_billing_cycle}
                                </Grid>
                                {lastSubscription.got_sub_used_campaign && (
                                    <Grid className={classes.item} item>
                                        Gained this subscription when applied on campaign:{" "}
                                        {lastSubscription.got_sub_used_campaign}
                                    </Grid>
                                )}
                                {lastSubscription.got_sub_used_invite_code && (
                                    <Grid className={classes.item} item>
                                        Gained this subscription when applied invite code:{" "}
                                        {lastSubscription.got_sub_used_invite_code}
                                    </Grid>
                                )}
                            </>
                        )}
                        <Grid item>
                            <div className={classes.subscriptionsAction}>
                                <Grid container direction="row" spacing={1} justify="flex-start">
                                    {isLoading === false &&
                                        currentPlan !== PLAN_NAME_UNLIMITED &&
                                        currentPlan !== PLAN_NAME_UNLIMITED_MONTHLY && (
                                            <Grid container direction="column">
                                                <Grid
                                                    container
                                                    direction="row"
                                                    spacing={3}
                                                    style={{ marginBottom: "16px" }}
                                                >
                                                    <Grid item md={2}>
                                                        <Select
                                                            fullWidth
                                                            label="Plan"
                                                            value={newPlan}
                                                            onChange={(e) => {
                                                                setNewPlan(e.target.value);
                                                            }}
                                                            inputProps={{
                                                                name: "newplan",
                                                                id: "newplan"
                                                            }}
                                                            disabled={isLoading}
                                                        >
                                                            <MenuItem value={"unlimited-monthly"}>
                                                                {getHumanPlanName(
                                                                    PLAN_NAME_UNLIMITED_MONTHLY
                                                                )}
                                                            </MenuItem>
                                                            <MenuItem value={"unlimited"}>
                                                                {getHumanPlanName(
                                                                    PLAN_NAME_UNLIMITED
                                                                )}
                                                            </MenuItem>
                                                            <MenuItem value={"god"}>
                                                                LIFETIME
                                                            </MenuItem>
                                                        </Select>
                                                    </Grid>
                                                    <Grid container alignItems="center">
                                                        <Button
                                                            disabled={isAddSubscriptionFetching}
                                                            className={classes.subscriptionsAddBtn}
                                                            onClick={() => {
                                                                if (onAddSubscription) {
                                                                    onAddSubscription(newPlan);
                                                                }
                                                            }}
                                                        >
                                                            Add local plan
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                {isProject(PROJECT_CB) && (
                                                    <Grid container direction="row" spacing={3}>
                                                        <Grid item md={2}>
                                                            <FormControl
                                                                className={classes.formControl}
                                                            >
                                                                <InputLabel htmlFor="BrainTreeID">
                                                                    Subscription ID
                                                                </InputLabel>
                                                                <Input
                                                                    id="BrainTreeID"
                                                                    placeholder="i34hf"
                                                                    fullWidth={true}
                                                                    onChange={(e) => {
                                                                        setBrainTreeId(
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid container alignItems="center">
                                                            <Button
                                                                disabled={
                                                                    isMigrateFromBrainTreeFetching
                                                                }
                                                                className={
                                                                    classes.subscriptionsAddBtn
                                                                }
                                                                onClick={() => {
                                                                    if (onMigrateFromBrainTree) {
                                                                        onMigrateFromBrainTree(
                                                                            brainTreeId
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                Migrate from braintree
                                                            </Button>
                                                            <Tooltip
                                                                title={
                                                                    "You can migrate web CloudBounce subscription using BrainTree Subscripiton ID. Web CloudBounce user will be marked as migrated, the web CB customer ID will be used and the new CB user will get a plan according BrainTree subscription."
                                                                }
                                                            >
                                                                <HelpIcon
                                                                    style={{ marginLeft: 15 }}
                                                                    className={
                                                                        classes.smallHelpIcon
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        )}
                                    <Grid item md={12}>
                                        <Grid container spacing={1} justify="flex-end">
                                            <Grid item>
                                                {lastSubscription &&
                                                    lastSubscription.status === "active" && (
                                                        <FormControlLabel
                                                            control={
                                                                <Tooltip
                                                                    title="If checked will remove user active plan immediately on cancel subscription click (user gets FREE plan). 
                                                        If not checked active plan will be removed by script on expiration date."
                                                                    aria-label="remove user active plan"
                                                                >
                                                                    <Checkbox
                                                                        checked={Boolean(
                                                                            removeUserPlan
                                                                        )}
                                                                        onChange={(e) => {
                                                                            setRemoveUserPlan(
                                                                                e.target.checked
                                                                            );
                                                                            if (!e.target.checked) {
                                                                                setResetToTrial(
                                                                                    false
                                                                                );
                                                                            }
                                                                        }}
                                                                        value={Boolean(
                                                                            removeUserPlan
                                                                        )}
                                                                    />
                                                                </Tooltip>
                                                            }
                                                            label="Remove user active plan on cancel"
                                                        />
                                                    )}
                                                {lastSubscription &&
                                                    lastSubscription.status === "active" && (
                                                        <FormControlLabel
                                                            control={
                                                                <Tooltip
                                                                    title="If checked user will get TRIAL plan on cancel subscription click"
                                                                    aria-label="If checked user will get TRIAL plan on cancel subscription click"
                                                                >
                                                                    <Checkbox
                                                                        checked={Boolean(
                                                                            resetToTrial
                                                                        )}
                                                                        onChange={(e) => {
                                                                            setResetToTrial(
                                                                                e.target.checked
                                                                            );
                                                                        }}
                                                                        value={Boolean(
                                                                            resetToTrial
                                                                        )}
                                                                        disabled={!removeUserPlan}
                                                                    />
                                                                </Tooltip>
                                                            }
                                                            label="Reset to TRIAL"
                                                        />
                                                    )}
                                            </Grid>
                                            <Grid item md={4}>
                                                {lastSubscription &&
                                                    lastSubscription.status === "active" && (
                                                        <>
                                                            <Button
                                                                disabled={
                                                                    isCancelSubscriptionFetching
                                                                }
                                                                fullWidth
                                                                className={
                                                                    classes.subscriptionsCancelBtn
                                                                }
                                                                onClick={() => {
                                                                    if (onCancelSubscription) {
                                                                        onCancelSubscription(
                                                                            removeUserPlan,
                                                                            resetToTrial
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                Cancel active subscription
                                                            </Button>
                                                        </>
                                                    )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            )}
        </>
    );
}
