import { USER_FROM_SET_FIELD, USER_FROM_RESET, USER_FROM_SET } from "../constants/user";

const initialState = {
    id: "",
    email: "",
    firstname: "",
    lastname: "",
    company: "",
    password: "",
    plan: "no"
};

export default function user(state = initialState, action) {
    switch (action.type) {
        case USER_FROM_SET_FIELD:
            const { value, field } = action.payload;

            return { ...state, [field]: value };
        case USER_FROM_SET:
            return { ...state, ...action.payload };
        case USER_FROM_RESET:
            return {
                id: "",
                email: "",
                firstname: "",
                lastname: "",
                company: "",
                password: "",
                plan: "no"
            };
        default:
            return state;
    }
}
