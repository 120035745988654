import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    item: {
        marginBottom: theme.spacing(3)
    }
}));

export default function SettingsFieldItem({
    type,
    name = "",
    value = "",
    description = "",
    group = "",
    onChange
}) {
    const classes = useStyles();

    return (
        <div className={classes.item}>
            {type === "string" && (
                <div>
                    <TextField
                        required
                        fullWidth
                        label={description}
                        multiline={true}
                        value={value}
                        onChange={e => {
                            onChange(group, name, e.target.value);
                        }}
                    />
                </div>
            )}
            {type === "number" && (
                <div>
                    <TextField
                        type="number"
                        required
                        fullWidth
                        label={description}
                        multiline={true}
                        value={value}
                        onChange={e => {
                            onChange(group, name, e.target.value);
                        }}
                    />
                </div>
            )}
            {type === "boolean" && (
                <div>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{description}</FormLabel>
                        <RadioGroup
                            name={name}
                            value={value}
                            defaultValue="0"
                            onChange={e => {
                                onChange(group, name, e.target.value);
                            }}
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Enable" />
                            <FormControlLabel value="0" control={<Radio />} label="Disable" />
                        </RadioGroup>
                    </FormControl>
                </div>
            )}
        </div>
    );
}

SettingsFieldItem.propTypes = {
    onChange: PropTypes.func
};
