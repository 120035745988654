import React from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DoneIcon from "@material-ui/icons/Done";
import PersonIcon from "@material-ui/icons/Person";
import CodeIcon from "@material-ui/icons/Code";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ShortTextIcon from "@material-ui/icons/ShortText";
import DialpadIcon from "@material-ui/icons/Dialpad";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import WebIcon from "@material-ui/icons/Web";
import { Switch, Route, withRouter, Link } from "react-router-dom";
import DashBoardTestChainsContainer from "./dashboard/DashBoardTestChainsContainer";
import DashBoardChainsEdit from "./dashboard/DashBoardChainsEdit";
import Auth from "../services/auth";
import DashBoardTicker from "./dashboard/DashBoardTicker";
import LogoBc from "../assets/svg/logo-bc.svg";
import LogoCb from "../assets/svg/logo-cb.svg";
import DashBoardSettings from "./dashboard/DashBoardSettings";
import DashBoardCampaigns from "./dashboard/DashBoardCampaigns";
import DashBoardCampaignsReport from "./dashboard/DashBoardCampaignsReport";
import DashBoardAppUpload from "./dashboard/DashBoardAppUpload";
import DashBoardUsers from "./dashboard/DashBoardUsers";
import DashBoardUsersEdit from "./dashboard/DashBoardUsersEdit";
import DashBoardQuizzes from "./dashboard/DashBoardQuizzes";
import DashBoardDiscountConditions from "./dashboard/DashBoardDiscountConditions";
import DashBoardDiscountConditionsEdit from "./dashboard/DashBoardDiscountConditionsEdit";
import DashboardMain from "./dashboard/DashBoardMain";
import { isProject } from "../utils";
import { PROJECT_BC } from "../constants/lang";
import DashBoardCodes from "./dashboard/DashBoardCodes";
import DashBoardMisc from "./dashboard/DashBoardMisc";
import DashBoardQuizEdit from "./dashboard/DashBoardQuizEdit";
import DashBoardQuizResult from "./dashboard/DashBoardQuizResult";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex"
    },
    toolbar: {
        paddingRight: 24 // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 36
    },
    menuButtonHidden: {
        display: "none"
    },
    title: {
        flexGrow: 1,
        background: `url("${isProject(PROJECT_BC) ? LogoBc : LogoCb}") left`,
        backgroundRepeat: "no-repeat",
        height: 30,
        paddingLeft: 170
    },
    userName: {
        color: "white"
    },
    logoutBtn: {
        color: "white"
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9)
        }
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflowY: "scroll",
        WebkitOverflowScrolling: "auto"
    },
    container: {
        "-webkit-transform": "translate3d(0, 0, 0)",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column"
    },
    fixedHeight: {
        height: 240
    },
    drawerBottom: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end"
    }
}));

const DashBoardContainer = ({ match, email, firstname, lastname }) => {
    const localStorage = window.localStorage;
    const classes = useStyles();
    const [open, setOpen] = React.useState(
        localStorage.getItem("drawer_position") === "open" ||
            localStorage.getItem("drawer_position") === null
    );

    const handleDrawerOpen = () => {
        localStorage.setItem("drawer_position", "open");
        setOpen(true);
    };

    const handleDrawerClose = () => {
        localStorage.setItem("drawer_position", "close");
        setOpen(false);
    };

    const handleLogoutClick = () => {
        Auth.logout();
    };

    const greatingText = `Hey! ${!firstname && !lastname ? email : `${firstname} ${lastname}`}`;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="absolute"
                className={clsx(classes.appBar, open && classes.appBarShift)}
            >
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        {process.env.REACT_APP_IS_TEST_DASHBOARD
                            ? "(staging server dashboard)"
                            : ""}
                    </Typography>
                    <Typography
                        className={classes.userName}
                        component="span"
                        variant="subtitle2"
                        color="secondary"
                        noWrap
                    >
                        {greatingText}
                    </Typography>
                    <IconButton className={classes.logoutBtn} onClick={handleLogoutClick}>
                        <ExitToAppIcon className={classes.logoutBtn} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <Link to="/dashboard">
                    <ListItem button selected={!match.params.page}>
                        <ListItemIcon>
                            <DashboardIcon color={!match.params.page ? "secondary" : "inherit"} />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                </Link>
                <Link to="/dashboard/test_chains">
                    <ListItem button selected={match.params.page === "test_chains"}>
                        <ListItemIcon>
                            <DoneIcon
                                color={
                                    match.params.page === "test_chains" ? "secondary" : "inherit"
                                }
                            />
                        </ListItemIcon>
                        <ListItemText primary="Chains tester" />
                    </ListItem>
                </Link>
                <Link to="/dashboard/edit_data">
                    <ListItem button selected={match.params.page === "edit_data"}>
                        <ListItemIcon>
                            <CodeIcon
                                color={match.params.page === "edit_data" ? "secondary" : "inherit"}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Edit sound data" />
                    </ListItem>
                </Link>
                <Link to="/dashboard/ticker">
                    <ListItem button selected={match.params.page === "ticker"}>
                        <ListItemIcon>
                            <ShortTextIcon
                                color={match.params.page === "ticker" ? "secondary" : "inherit"}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Tickers" />
                    </ListItem>
                </Link>
                <Link to="/dashboard/campaigns">
                    <ListItem button selected={match.params.page === "campaigns"}>
                        <ListItemIcon>
                            <WebIcon
                                color={match.params.page === "campaigns" ? "secondary" : "inherit"}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Campaigns" />
                    </ListItem>
                </Link>
                <Link to="/dashboard/discount-conditions">
                    <ListItem button selected={match.params.page === "discount-conditions"}>
                        <ListItemIcon>
                            <MoneyOffIcon
                                color={
                                    match.params.page === "discount-conditions"
                                        ? "secondary"
                                        : "inherit"
                                }
                            />
                        </ListItemIcon>
                        <ListItemText primary="Discounts" />
                    </ListItem>
                </Link>
                <Link to="/dashboard/users">
                    <ListItem button selected={match.params.page === "users"}>
                        <ListItemIcon>
                            <PersonIcon
                                color={match.params.page === "users" ? "secondary" : "inherit"}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Users" />
                    </ListItem>
                </Link>
                <Link to="/dashboard/app_upload">
                    <ListItem button selected={match.params.page === "app_upload"}>
                        <ListItemIcon>
                            <CloudUploadIcon
                                color={match.params.page === "app_upload" ? "secondary" : "inherit"}
                            />
                        </ListItemIcon>
                        <ListItemText primary="App upload" />
                    </ListItem>
                </Link>
                <Link to="/dashboard/codes">
                    <ListItem button selected={match.params.page === "codes"}>
                        <ListItemIcon>
                            <LoyaltyIcon
                                color={match.params.page === "codes" ? "secondary" : "inherit"}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Invite codes" />
                    </ListItem>
                </Link>
                <Link to="/dashboard/quiz">
                    <ListItem button selected={match.params.page === "quiz"}>
                        <ListItemIcon>
                            <ThumbsUpDownIcon
                                color={match.params.page === "quiz" ? "secondary" : "inherit"}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Quiz" />
                    </ListItem>
                </Link>
                <Link to="/dashboard/misc">
                    <ListItem button selected={match.params.page === "misc"}>
                        <ListItemIcon>
                            <DialpadIcon
                                color={match.params.page === "misc" ? "secondary" : "inherit"}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Misc" />
                    </ListItem>
                </Link>
                <Divider />
                <div className={classes.drawerBottom}>
                    <Divider />
                    <Link to="/dashboard/settings">
                        <ListItem button selected={match.params.page === "settings"}>
                            <ListItemIcon>
                                <SettingsIcon
                                    color={
                                        match.params.page === "settings" ? "secondary" : "inherit"
                                    }
                                />
                            </ListItemIcon>
                            <ListItemText primary="Settings" />
                        </ListItem>
                    </Link>
                    <Divider />
                </div>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth={false} className={classes.container}>
                    <Switch>
                        <Route exact path={"/dashboard"} component={DashboardMain} />{" "}
                        <Route
                            exact
                            path={match.path + "test_chains"}
                            component={DashBoardTestChainsContainer}
                        />
                        <Route
                            exact
                            path={match.path + "edit_data"}
                            component={DashBoardChainsEdit}
                        />
                        <Route
                            exact
                            path={match.path + "app_upload"}
                            component={DashBoardAppUpload}
                        />
                        <Route exact path={match.path + "ticker"} component={DashBoardTicker} />
                        <Route exact path={match.path + "settings"} component={DashBoardSettings} />
                        <Route
                            path={match.path + "campaigns/:action(new|edit)?/:id?"}
                            component={DashBoardCampaigns}
                        />
                        <Route
                            path={match.path + "campaigns-report/:id?"}
                            component={DashBoardCampaignsReport}
                        />
                        <Route exact path={match.path + "users"} component={DashBoardUsers} />
                        <Route
                            exact
                            path={match.path + "users/edit/:id"}
                            component={DashBoardUsersEdit}
                        />
                        <Route
                            exact
                            path={match.path + "discount-conditions"}
                            component={DashBoardDiscountConditions}
                        />
                        <Route
                            exact
                            path={match.path + "discount-conditions/add"}
                            component={DashBoardDiscountConditionsEdit}
                        />
                        <Route
                            exact
                            path={match.path + "discount-conditions/edit/:id"}
                            component={DashBoardDiscountConditionsEdit}
                        />
                        <Route
                            exact
                            path={match.path + "quiz"}
                            component={DashBoardQuizzes}
                        />
                        <Route
                            exact
                            path={match.path + "quiz/edit/:id"}
                            component={DashBoardQuizEdit}
                        />
                        <Route
                            exact
                            path={match.path + "quiz/result/:id"}
                            component={DashBoardQuizResult}
                        />
                        <Route exact path={match.path + "codes"} component={DashBoardCodes} />
                        <Route exact path={match.path + "misc"} component={DashBoardMisc} />
                    </Switch>
                </Container>
            </main>
        </div>
    );
};

function mapStateToProps({ user }) {
    return {
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email
    };
}

export default connect(mapStateToProps)(withRouter(DashBoardContainer));
