import {
    CAMPAIGN_SET,
    CAMPAIGN_UPDATE_ITEM_LANGUAGE_FIELD,
    CAMPAIGN_UPDATE_ITEM_FIELD,
    CAMPAIGN_RESET,
    CAMPAIGN_FORM_SET_DISCOUNT_CONDITION,
    CAMPAIGN_FORM_SET_INVITE_CODE_DISCOUNT_CONDITION
} from "../constants/campaign";

const initialState = {
    data: {
        id: "",
        affiliate_id: "",
        disabled: false,
        name: "",
        cookie_days_lifetime: 30,
        title: {},
        text: {},
        subtitle: {},
        button_text: {},
        buy_button_text: {},
        call_to_action: {},
        background_url: "",
        partner_logo_url: "",
        social_background_url: "",
        background_translucency: false,
        social_title: "",
        social_text: "",
        template_id: 1,
        quota: -1,
        plan: "no",
        discount: { monthly: "", annually: "" },
        user_type: "all",
        remaining_message: "time",
        expired_at: "",
        condition_id: ""
    },
    condition: { id: "", comment: "", offer: { type: "" }, user_invite_code_condition_id: "" },
    inviteCodeCondition: {
        id: "",
        comment: "",
        offer: { type: "" },
        user_invite_code_condition_id: ""
    }
};

export default function campaign(state = initialState, action) {
    switch (action.type) {
        case CAMPAIGN_SET:
            return {
                ...state,
                data: action.payload.campaign,
                condition: action.payload.condition,
                inviteCodeCondition: action.payload.inviteCodeCondition
            };
        case CAMPAIGN_UPDATE_ITEM_FIELD:
            if (!state.data.hasOwnProperty(action.payload.field)) {
                return state;
            }

            return {
                ...state,
                data: { ...state["data"], [action.payload.field]: action.payload.value }
            };
        case CAMPAIGN_UPDATE_ITEM_LANGUAGE_FIELD:
            if (!state.data.hasOwnProperty(action.payload.field)) {
                return state;
            }

            return {
                ...state,
                data: {
                    ...state["data"],
                    [action.payload.field]: {
                        ...state["data"][action.payload.field],
                        [action.payload.lang]: action.payload.value
                    }
                }
            };
        case CAMPAIGN_FORM_SET_DISCOUNT_CONDITION:
            return { ...state, condition: action.payload };
        case CAMPAIGN_FORM_SET_INVITE_CODE_DISCOUNT_CONDITION:
            return { ...state, inviteCodeCondition: action.payload };
        case CAMPAIGN_RESET:
            return {
                data: {
                    id: "",
                    affiliate_id: "",
                    disabled: false,
                    name: "",
                    cookie_days_lifetime: 30,
                    title: {},
                    text: {},
                    subtitle: {},
                    button_text: {},
                    buy_button_text: {},
                    call_to_action: {},
                    background_url: "",
                    partner_logo_url: "",
                    social_background_url: "",
                    background_translucency: false,
                    social_title: "",
                    social_text: "",
                    template_id: 1,
                    quota: -1,
                    plan: "no",
                    discount: { monthly: "", annually: "" },
                    user_type: "all",
                    remaining_message: "time",
                    expired_at: "",
                    condition_id: ""
                },
                condition: {
                    id: "",
                    comment: "",
                    offer: { type: "" },
                    user_invite_code_condition_id: ""
                },
                inviteCodeCondition: {
                    id: "",
                    comment: "",
                    offer: { type: "" },
                    user_invite_code_condition_id: ""
                }
            };
        default:
            return state;
    }
}
