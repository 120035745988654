import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as discountActions from "../../actions/discount";
import Typography from "@material-ui/core/Typography";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageTitle from "../../components/PageTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { green } from "@material-ui/core/colors";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import DiscountsList from "../../components/DiscountsList";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    content: {
        padding: theme.spacing(2)
    },
    spinnerWrapper: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: "2"
    },
    header: {
        marginBottom: 15
    },
    runButton: {
        backgroundColor: green[500],
        color: "white",
        width: 200
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    addNewBtnWrapper: {
        display: "flex",
        justifyContent: "flex-end"
    },
    tabs: {
        marginBottom: 15
    },
    userInfoModalWrapper: {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    info: {
        marginTop: theme.spacing(4),
    },
    infoField: {
        fontWeight: 500,
        fontSize: 16
    },
    infoStackSpace: {
        marginBottom: theme.spacing(2)
    }
}));

const DashBoardDiscountConditions = ({ discountActions, isDiscountsFetching, push }) => {
    const classes = useStyles();

    const handleAddNew = React.useCallback(() => {
        push("/dashboard/discount-conditions/add");
        discountActions.discountFormReset();
    }, [discountActions, push]);

    return (
        <div className={classes.root}>
            {isDiscountsFetching === true && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <Grid container spacing={0} justify="space-between" className={classes.header}>
                <Grid item md={6} sm={6} xs={6}>
                    <PageTitle title="Discount conditions" />
                    <Typography color="textSecondary">
                        Set of rules for discounts. Used by campaigns and invite codes.
                    </Typography>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                    <div className={classes.addNewBtnWrapper}>
                        <Button
                            fullWidth={true}
                            className={classes.runButton}
                            variant="contained"
                            color="secondary"
                            onClick={handleAddNew}
                        >
                            Add new
                            <MoneyOffIcon className={classes.rightIcon} />
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <DiscountsList />
            <Alert className={classes.info} severity="info">
                <div>Every discount condition can be connected to any campaign.</div>
                <div></div>
                <div>
                    <span className={classes.infoField}>Offer info</span>: this offer will be
                    received by the user. For example, applying campaign deal or applying an invite
                    code.
                </div>
                <div className={classes.infoStackSpace}>
                    <span className={classes.infoField}> Invite code offer info</span>: each user
                    who bought a plan or received a gift plan will receive invite codes. For each
                    discount condition you can add special condition for user invite codes.
                </div>
                <div className={classes.infoStackSpace}>
                    Options{" "}
                    <span role="img" aria-label="options">
                        ⚙
                    </span>
                    :
                </div>
                <div>
                    <span className={classes.infoField}>Plan:</span>
                    plan to which the condition applies
                </div>
                <div>
                    <span className={classes.infoField}>Discount: </span>
                    discount amount
                </div>
                <div>
                    <span className={classes.infoField}>Cycles: </span>
                    how many billing cycles discount will be valid
                </div>
                <div>
                    <span className={classes.infoField}>
                        GOD{" "}
                        <span role="img" aria-label="is GOD">
                            🧙🏻‍♂
                        </span>
                        :
                    </span>
                    for the GIFT offer type user will get unlimited lifetime for free. for the
                    discount-subscription type discount will be applying on each billing cycle.
                </div>{" "}
                <div className={classes.infoStackSpace}>
                    <span className={classes.infoField}>
                        CloudBounce MINI{" "}
                        <span role="img" aria-label="Stackable">
                        ✂️
                        </span>
                    </span>
                    : upgrade price in square brackets [$19]. If the upgrade price is 0 user won't be able to upgrade CB MINI to the regular CB.
                </div>
                <div className={classes.infoStackSpace}>
                    <span className={classes.infoField}>
                        Stackable{" "}
                        <span role="img" aria-label="Stackable">
                            🧩
                        </span>
                    </span>
                    : valid for invite codes. If an invite code has this flag in the discount
                    condition, then using it on special page you can get an additional license.
                </div>
                <div>
                    Conditon ID 1 using by default for all invite codes where the condition is not
                    specified explicitly.
                </div>
            </Alert>
        </div>
    );
};

function mapStateToProps({ state }) {
    return {
        isDiscountsFetching: state.discountsFetching
    };
}

function mapDispatchToProps(dispatch) {
    return {
        discountActions: bindActionCreators(discountActions, dispatch),
        push: url => {
            dispatch(push(url));
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DashBoardDiscountConditions));
