import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as quizActions from "../../actions/quiz";
import Typography from "@material-ui/core/Typography";
import {
    Grid,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    Paper,
    IconButton,
    Modal,
    Fab,
    DialogTitle
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageTitle from "../../components/PageTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { green, red } from "@material-ui/core/colors";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { usePrevious } from "../../utils/hooks";
import LanguageTextField from "../../components/LanguageTextField";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    content: {
        padding: theme.spacing(2)
    },
    spinnerWrapper: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: "2"
    },
    header: {
        marginBottom: 15
    },
    add: {
        backgroundColor: green[500],
        color: "white",
        width: 200
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    addNewBtnWrapper: {
        display: "flex",
        justifyContent: "flex-end"
    },
    option: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    removeOptionBtn: {
        color: red[500]
    },
    saveBtn: {
        color: green[500]
    },
    block: {
        marginBottom: theme.spacing(2)
    },
    modal: {
        height: "100%",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    modalBody: {
        height: "90%",
        width: "90%",
        padding: theme.spacing(3),
        overflowX: "scroll",
        position: "relative"
    },
    modalCloseBtn: {
        position: "absolute",
        right: 10,
        top: 10,
        zIndex: 2
    }
}));

const QuizOptionForm = ({ id, isCustom, isActive, text = {}, onChange, onSubmit }) => {
    const classes = useStyles();

    const onTextChange = React.useCallback(
        (lang, value) => {
            onChange("text", { ...text, [lang]: value });
        },
        [onChange, text]
    );

    return (
        <Grid container spacing={2} direction="column">
            <Grid item>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={Boolean(isActive)}
                            onChange={(e) => {
                                onChange("is_active", e.target.checked);
                            }}
                            value={Boolean(isActive)}
                        />
                    }
                    label="Is active"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={Boolean(isCustom)}
                            onChange={(e) => {
                                onChange("is_custom", e.target.checked);
                            }}
                            value={Boolean(isCustom)}
                        />
                    }
                    label="Is custom (allow user input)"
                />
            </Grid>
            <Grid item>
                <LanguageTextField title="Text" name="text" value={text} onChange={onTextChange} />
            </Grid>
            <Grid container justify="flex-end">
                <Grid item>
                    <IconButton
                        className={classes.saveBtn}
                        onClick={() => {
                            onSubmit(id);
                        }}
                    >
                        <SaveIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

const QuizOption = ({ id, text = {}, isActive, isCustom, onEditClick, onRemoveClick }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.option}>
            <Grid container spacing={2} direction="row">
                <Grid item md={11} sm={11} xs={11}>
                    <div>Text: {text["en"] || ""}</div>
                    <div>Is active: {isActive ? "Yes" : "No"}</div>
                    <div>Is custom: {isCustom ? "Yes" : "No"}</div>
                </Grid>
                <Grid item md={1} sm={1} xs={1}>
                    <IconButton
                        onClick={() => {
                            onEditClick(id);
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        className={classes.removeOptionBtn}
                        onClick={() => {
                            if (onRemoveClick) {
                                onRemoveClick(id);
                            }
                        }}
                    >
                        <RemoveIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Paper>
    );
};

const QuizEditForm = ({
    quiz,
    onChange,
    isLoading,
    onOptionEditClick,
    onOptionAddClick,
    onOptionRemoveClick
}) => {
    const classes = useStyles();

    const onTitleChange = React.useCallback(
        (lang, value) => {
            onChange("title", { ...quiz.title, [lang]: value });
        },
        [onChange, quiz]
    );

    return (
        <div>
            {quiz && (
                <Grid container spacing={2} direction="column">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Boolean(quiz.is_active)}
                                onChange={(e) => {
                                    onChange("is_active", e.target.checked);
                                }}
                                value={Boolean(quiz.is_active)}
                            />
                        }
                        label="Is active"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Boolean(quiz.vote_once)}
                                onChange={(e) => {
                                    onChange("vote_once", e.target.checked);
                                }}
                                value={Boolean(quiz.vote_once)}
                            />
                        }
                        label="Vote once (restrict by IP)"
                    />
                    <Grid item md={5} sm={12} xs={12}>
                        <Select
                            fullWidth
                            label="Type"
                            placeholder="Quiz type"
                            value={quiz.type || "basic"}
                            onChange={(e) => {
                                onChange("type", e.target.value);
                            }}
                            inputProps={{
                                name: "type",
                                id: "type"
                            }}
                            disabled={isLoading}
                        >
                            <MenuItem value={"basic"}>Basic quiz</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item className={classes.block}>
                        <TextField
                            value={quiz.name || ""}
                            required
                            fullWidth
                            label="Name"
                            placeholder="name"
                            onChange={(e) => {
                                onChange("name", e.target.value);
                            }}
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item className={classes.block}>
                        <LanguageTextField
                            title="Title"
                            name="title"
                            placeholder="How are you doing today?"
                            value={quiz.title}
                            onChange={onTitleChange}
                        />
                    </Grid>
                    <Grid container direction="row">
                        <Grid item className={classes.block}>
                            <Button
                                className={classes.saveBtn}
                                onClick={() => {
                                    if (onOptionEditClick) {
                                        onOptionAddClick(
                                            // random id
                                            Math.random().toString(21).substr(2, 5).toLowerCase()
                                        );
                                    }
                                }}
                            >
                                <AddIcon /> Add option
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {quiz &&
                            quiz.QuizOptions &&
                            quiz.QuizOptions.length > 0 &&
                            quiz.QuizOptions.filter(
                                (option) => !option.delete_submit || !("delete_submit" in option)
                            ).map((quizOption, i) => (
                                <QuizOption
                                    id={quizOption.id}
                                    key={`o${i}`}
                                    isActive={quizOption.is_active}
                                    isCustom={quizOption.is_custom}
                                    text={quizOption.text}
                                    onEditClick={onOptionEditClick}
                                    onRemoveClick={onOptionRemoveClick}
                                />
                            ))}
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

const DashBoardQuizEdit = ({ match, push, isLoading, quiz, quizActions }) => {
    const classes = useStyles();
    const prevMatchUserId = usePrevious(match.params.id);
    const [isEditMode, setIsEditMode] = React.useState(true);
    const [activeOptionId, setActiveOptionId] = React.useState(null);
    const [isQuizOptionModalOpen, setIsQuizOptionModalOpen] = React.useState(false);

    React.useEffect(() => {
        if (match.params.id && prevMatchUserId !== match.params.id) {
            window.scrollTo(0, 0);

            if (match.params.id === "add") {
                setIsEditMode(false);
            } else {
                setIsEditMode(true);
                quizActions.loadQuiz(match.params.id);
            }
        }
    }, [match, quizActions, prevMatchUserId]);

    const handleQuizFormChange = React.useCallback(
        (fieldName, value) => {
            quizActions.updateQuiz(fieldName, value);
        },
        [quizActions]
    );

    const handleQuizOptionFormChange = React.useCallback(
        (fieldName, value) => {
            if (activeOptionId) {
                quizActions.optionUpdate(activeOptionId, fieldName, value);
            }
        },
        [activeOptionId, quizActions]
    );

    const handleOptionEditClick = React.useCallback((id) => {
        setIsQuizOptionModalOpen(true);
        setActiveOptionId(id);
    }, []);

    const handleOptionAddClick = React.useCallback(
        (id) => {
            quizActions.addEmptyOption(id);
            setIsQuizOptionModalOpen(true);
            setActiveOptionId(id);
        },
        [quizActions]
    );

    const handleOptionRemoveClick = React.useCallback(
        (id) => {
            quizActions.removeQuizOption(id);
        },
        [quizActions]
    );

    const handleOptionSubmitClick = React.useCallback(
        (id) => {
            setActiveOptionId(null);
            setIsQuizOptionModalOpen(false);
            if (isEditMode) {
            }
        },
        [isEditMode]
    );

    const handleQuizSubmitClick = React.useCallback(() => {
        quizActions.submitQuiz(isEditMode, (err) => {
            if (!err) {
                push("/dashboard/quiz");
            }
        });
    }, [quizActions, isEditMode, push]);

    let acitveOption =
        quiz && quiz.QuizOptions && quiz.QuizOptions.find((option) => option.id === activeOptionId);

    if (!acitveOption && activeOptionId) {
        acitveOption = {
            is_active: false,
            is_custom: false,
            text: { en: "" }
        };
    }

    return (
        <div className={classes.root}>
            {isLoading && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <Grid container spacing={0} justify="space-between" className={classes.header}>
                <Grid item xs={6}>
                    <PageTitle
                        title={isLoading ? "Loading..." : `Edit quiz: ${quiz.name || "New quiz"}`}
                    />
                    <Typography color="textSecondary">
                        {(quiz && quiz.title && quiz.title["en"]) || ""}
                    </Typography>
                </Grid>
            </Grid>
            <QuizEditForm
                quiz={quiz}
                isLoading={isLoading}
                onChange={handleQuizFormChange}
                onOptionEditClick={handleOptionEditClick}
                onOptionAddClick={handleOptionAddClick}
                onOptionRemoveClick={handleOptionRemoveClick}
            />
            <Modal
                className={classes.modal}
                open={isQuizOptionModalOpen}
                aria-labelledby="Discounts"
                aria-describedby="Discounts"
            >
                <Paper className={classes.modalBody}>
                    <Grid container direction="column">
                        <Grid item>
                            <DialogTitle className={classes.modalTitle}>Quiz option</DialogTitle>
                            <Fab
                                className={classes.modalCloseBtn}
                                variant="round"
                                color="primary"
                                onClick={() => {
                                    setIsQuizOptionModalOpen(false);
                                    setActiveOptionId(null);
                                    if (
                                        acitveOption &&
                                        acitveOption.text &&
                                        !acitveOption.text["en"]
                                    ) {
                                        quizActions.removeQuizOption(acitveOption.id);
                                    }
                                }}
                            >
                                <CloseIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                    {acitveOption && (
                        <QuizOptionForm
                            id={acitveOption.id}
                            isActive={acitveOption.is_active}
                            isCustom={acitveOption.is_custom}
                            text={acitveOption.text}
                            onChange={handleQuizOptionFormChange}
                            onSubmit={handleOptionSubmitClick}
                        />
                    )}
                </Paper>
            </Modal>

            <Grid container justify="flex-end">
                <Grid item>
                    <IconButton className={classes.saveBtn} onClick={handleQuizSubmitClick}>
                        <SaveIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    );
};

function mapStateToProps({ quiz, state }) {
    return {
        quiz,
        isLoading: state.loadquizFetching
    };
}

function mapDispatchToProps(dispatch) {
    return {
        quizActions: bindActionCreators(quizActions, dispatch),
        push: (url) => {
            dispatch(push(url));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashBoardQuizEdit));
