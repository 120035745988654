import Api, { resetInstance } from "../services/api";
import { setState } from "../actions/state";
import { triggerMessage } from "../utils/events";
import {
    CAMPAIGN_SET,
    CAMPAIGN_UPDATE_ITEM_FIELD,
    CAMPAIGN_UPDATE_ITEM_LANGUAGE_FIELD,
    CAMPAIGNS_SET,
    CAMPAIGN_RESET,
    CAMPAIGN_FORM_SET_DISCOUNT_CONDITION,
    CAMPAIGN_FORM_SET_INVITE_CODE_DISCOUNT_CONDITION,
    SET_CAMPAIGN_FILTER,
    RESET_CAMPAIGN_FILTER
} from "../constants/campaign";
import { push } from "connected-react-router";

export const updateItemField = (field, value) => async (dispatch, getState) => {
    dispatch({
        type: CAMPAIGN_UPDATE_ITEM_FIELD,
        payload: {
            field,
            value
        }
    });
};

export const updateItemLanguageField = (field, lang, value) => async (dispatch, getState) => {
    dispatch({
        type: CAMPAIGN_UPDATE_ITEM_LANGUAGE_FIELD,
        payload: {
            field,
            lang,
            value
        }
    });
};

export const loadCampaigns =
    (page = 1, limit = 5) =>
    async (dispatch, getState) => {
        const filters = Object.keys(getState().campaignFilter).reduce((acc, field) => {
            if (getState().campaignFilter[field]) {
                acc.push(`${field}=${getState().campaignFilter[field]}`);
            }

            return acc;
        }, []);

        try {
            dispatch(setState("campaignsFetching", true));
            const { data: apiResponse } = await Api().get(
                `/admin/campaign?limit=${limit}&page=${page}${
                    filters.length ? `&${filters.join("&")}` : ""
                }`
            );
            dispatch(setState("campaignsFetching", false));

            if (apiResponse.message === "ok") {
                dispatch({
                    type: CAMPAIGNS_SET,
                    payload: apiResponse.data
                });
            }
        } catch (e) {
            dispatch(setState("campaignsFetching", false));
        }
    };

export const loadCampaign = (id) => async (dispatch, getState) => {
    try {
        dispatch(setState("campaignFetching", true));
        const { data: apiResponse } = await Api().get(`/admin/campaign/${id}`);
        dispatch(setState("campaignFetching", false));

        if (apiResponse.message === "ok") {
            dispatch({
                type: CAMPAIGN_SET,
                payload: apiResponse.data
            });
        }
    } catch (e) {
        dispatch(setState("campaignFetching", false));
    }
};

export const uploadImage =
    (data, field = "image") =>
    async (dispatch, getState) => {
        resetInstance();
        dispatch(setState(`campaign${field}Fetching`, true));
        try {
            const { data: apiResponse } = await Api({
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).post("/admin/campaign/upload-image", data);

            dispatch(setState(`campaign${field}Fetching`, false));

            if (apiResponse.message === "ok") {
                dispatch(updateItemField(field, apiResponse.data.file)); // update field in campaign reducer
                triggerMessage("Image has been uploaded", "success");
            }
        } catch (e) {
            dispatch(setState(`campaign${field}Fetching`, false));
            triggerMessage("Upload error, plz check file format and size.", "error");
        }
    };

export const save = () => async (dispatch, getState) => {
    const data = getState().campaign && getState().campaign.data ? getState().campaign.data : {};

    if (!data.name) {
        triggerMessage("Name is required", "error");

        return;
    }

    if (!data.expired_at) {
        triggerMessage("Choose a more suitable date", "error");

        return;
    }

    if (!data.condition_id) {
        triggerMessage("Set a discount condition", "error");

        return;
    }

    try {
        dispatch(setState("campaignSaveFetching", true));

        if (!data["id"]) {
            await Api().post(`/admin/campaign`, data);
            triggerMessage("Campaign has been added", "success");
            dispatch(push("/dashboard/campaigns"));
        } else {
            await Api().put(`/admin/campaign/${data["id"]}`, data);
            triggerMessage("Campaign has been updated", "success");
            dispatch(push("/dashboard/campaigns"));
        }

        dispatch(setState("campaignSaveFetching", false));
    } catch (e) {
        dispatch(setState("campaignSaveFetching", false));
    }
};

export const campaignResetForm = () => async (dispatch) => {
    dispatch({
        type: CAMPAIGN_RESET
    });
};

export const setDiscountCondition = (discount) => async (dispatch) => {
    dispatch({
        type: CAMPAIGN_FORM_SET_DISCOUNT_CONDITION,
        payload: discount
    });
};

export const setInviteCodeDiscountCondition = (discount) => async (dispatch) => {
    dispatch({
        type: CAMPAIGN_FORM_SET_INVITE_CODE_DISCOUNT_CONDITION,
        payload: discount
    });
};

export const setCampaignFilter = (field, value) => async (dispatch) => {
    dispatch({
        type: SET_CAMPAIGN_FILTER,
        payload: { field, value }
    });
};

export const resetCampaignFilter = () => async (dispatch) => {
    dispatch({
        type: RESET_CAMPAIGN_FILTER
    });
};
