/*eslint-disable*/
import { createStore, compose, applyMiddleware } from "redux";
import createRootReducer from "../reducers";
import { createLogger } from "redux-logger";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";

export default function configureStore(initialState, history) {
    let middleware = [routerMiddleware(history), thunk];
    const isDevelopment = process.env.NODE_ENV !== "production";

    if (isDevelopment) {
        const logger = createLogger();
        middleware = [...middleware, logger];
    }

    const rootReducer = createRootReducer(history);
    const store = createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware))
    );

    if (module.hot) {
        module.hot.accept("../reducers", () => {
            const nextRootReducer = require("../reducers/index");
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}
