import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, TextField, ListItemIcon } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import RemoveIcon from "@material-ui/icons/Delete";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(4)
    },
    item: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    itemInput: {
        // height: "60px"
    },
    removeBtn: {
        color: red[500]
    },
    saveBtn: {
        color: green[500]
    },
    form: {
        marginBottom: theme.spacing(2),
        "&>div": {
            marginBottom: theme.spacing(1)
        }
    },
    bottom: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        height: 10
    }
}));

export default function TickerRecordForm({
    id,
    text = "",
    keyText = "",
    lang = "en",
    isLoading,
    onKeyInput,
    onTextInput,
    onSubmit,
    onDelete
}) {
    const classes = useStyles();

    return (
        <div className={classes.item}>
            <Paper className={classes.paper}>
                <form className={classes.form} noValidate>
                    <div>
                        <InputLabel htmlFor="lang">Language</InputLabel>
                        <Select
                            label="Language"
                            value={lang}
                            onChange={e => {
                                onKeyInput(id, "lang", e.target.value);
                            }}
                            inputProps={{
                                name: "lang",
                                id: "lang"
                            }}
                        >
                            <MenuItem value={"en"}>
                                <ListItemIcon>
                                    <span role="img" aria-label="en">
                                        🇬🇧
                                    </span>
                                </ListItemIcon>
                                English
                            </MenuItem>
                            <MenuItem value={"fi"}>
                                <ListItemIcon>
                                    <span role="img" aria-label="fi">
                                        🇫🇮
                                    </span>
                                </ListItemIcon>
                                Finnish
                            </MenuItem>
                            <MenuItem value={"de"}>
                                <ListItemIcon>
                                    <span role="img" aria-label="de">
                                        🇩🇪
                                    </span>
                                </ListItemIcon>
                                German
                            </MenuItem>
                            <MenuItem value={"ru"}>
                                <ListItemIcon>
                                    <span role="img" aria-label="ru">
                                        🇷🇺
                                    </span>
                                </ListItemIcon>
                                Russian
                            </MenuItem>
                        </Select>
                    </div>

                    <TextField
                        required
                        label="Key"
                        placeholder="textwithoutspaces"
                        multiline={true}
                        value={keyText}
                        onChange={e => {
                            onKeyInput(id, "key_text", e.target.value);
                        }}
                        disabled={isLoading}
                    />
                    <TextField
                        required
                        fullWidth
                        label="Messages"
                        placeholder="Message A|Message B|[DELAY]|Message C"
                        multiline={true}
                        value={text}
                        className={classes.itemInput}
                        onChange={e => {
                            onTextInput(id, "text", e.target.value);
                        }}
                        disabled={isLoading}
                    />
                </form>
                <div className={classes.bottom}>
                    <div>{id === "0" && "Not saved"}</div>
                    {id !== "0" && (
                        <IconButton
                            className={classes.removeBtn}
                            onClick={() => {
                                onDelete(id);
                            }}
                        >
                            <RemoveIcon />
                        </IconButton>
                    )}
                    <IconButton
                        className={classes.saveBtn}
                        onClick={() => {
                            onSubmit(id);
                        }}
                        disabled={isLoading}
                    >
                        <SaveIcon />
                    </IconButton>
                </div>
            </Paper>
        </div>
    );
}

TickerRecordForm.propsTypes = {
    id: PropTypes.string,
    keyText: PropTypes.string,
    text: PropTypes.string,
    lang: PropTypes.string,
    onKeyInput: PropTypes.func.isRequired,
    onTextInput: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};
