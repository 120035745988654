import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import configureStore from "./store/configureStore";
import App from "./App";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Auth from "./services/auth";
import { PROJECT_BC, PROJECT_CB } from "./constants/lang";

const themes = {
    [PROJECT_BC]: {
        palette: {
            primary: {
                light: "#6e4881",
                main: "#401f53",
                dark: "#1c002b"
            },
            secondary: {
                light: "#ff74e3",
                main: "#fc37b1",
                dark: "#c40081"
            }
        },
        typography: {
            useNextVariants: true
        }
    },
    [PROJECT_CB]: {
        palette: {
            primary: {
                light: "#3a4148",
                main: "#22262a",
                dark: "#14181b"
            },
            secondary: {
                light: "#aca9c1",
                main: "#5f2f73",
                dark: "#401f53"
            }
        },
        typography: {
            useNextVariants: true
        }
    }
};

const theme = createMuiTheme(themes[process.env.REACT_APP_PROJECT_NAME || PROJECT_BC]);

const history = createBrowserHistory();
const store = configureStore({}, history);
Auth.init(store.dispatch, store.getState);

ReactDOM.render(
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <App history={history} />
        </MuiThemeProvider>
    </Provider>,
    document.getElementById("root")
);
