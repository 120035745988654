import {
    DISCOUNT_FORM_SET,
    DISCOUNT_FORM_RESET,
    DISCOUNT_FROM_SET_FIELD,
    DISCOUNT_FORM_SET_INVITE_CODE_CONDITION
} from "../constants/discount";

const initialState = {
    data: { id: "", comment: "", offer: { type: "" }, user_invite_code_condition_id: "" },
    inviteCodeCondtion: { id: "", comment: "", offer: { type: "" }, user_invite_code_condition_id: "" }
};

export default function discountForm(state = initialState, action) {
    switch (action.type) {
        case DISCOUNT_FROM_SET_FIELD:
            const { value, field } = action.payload;

            return { ...state, data: { ...state["data"], [field]: value } };
        case DISCOUNT_FORM_SET:
            return {
                ...state,
                data: {
                    id: action.payload.id,
                    comment: action.payload.comment,
                    offer: action.payload.offer,
                    user_invite_code_condition_id: action.payload.user_invite_code_condition_id
                },
                inviteCodeCondtion: action.payload.user_invite_code_condition_condition
            };
        case DISCOUNT_FORM_SET_INVITE_CODE_CONDITION:
            return { ...state, inviteCodeCondtion: action.payload };
        case DISCOUNT_FORM_RESET:
            return {
                data: {
                    id: "",
                    comment: "",
                    offer: { type: "" },
                    user_invite_code_condition_id: ""
                },
                inviteCodeCondtion: {
                    id: "",
                    comment: "",
                    offer: { type: "" },
                    user_invite_code_condition_id: ""
                }
            };
        default:
            return state;
    }
}
