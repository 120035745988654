import Api from "../services/api";
import { setState } from "../actions/state";
import {
    SETTINGS_SET,
    SETTINGS_UPDATE_ITEM,
    SETTINGS_SET_APP_VERSIONS
} from "../constants/settings";
import { triggerMessage } from "../utils/events";

export const loadSettings = () => async (dispatch, getState) => {
    try {
        dispatch(setState("settingsFetching", true));
        const { data: apiResponse } = await Api().get(`/admin/settings`);
        dispatch(setState("settingsFetching", false));

        if (apiResponse.message === "ok") {
            dispatch({
                type: SETTINGS_SET,
                payload: apiResponse.data.items
            });
        }
    } catch (e) {
        dispatch(setState("settingsFetching", false));
    }
};

export const loadAppVersions = () => async (dispatch, getState) => {
    try {
        dispatch(setState("appVersionFetching", true));
        const { data: apiResponse } = await Api().get(`/admin/settings/app-version`);
        dispatch(setState("appVersionFetching", false));

        if (apiResponse.message === "ok") {
            dispatch({
                type: SETTINGS_SET_APP_VERSIONS,
                payload: apiResponse.data
            });
        }
    } catch (e) {
        dispatch(setState("appVersionFetching", false));
    }
};

export const setCurrentAppVersion = (url, os) => async (dispatch, getState) => {
    try {
        dispatch(setState("setCurrentAppVersionFetching", true));
        const { data: apiResponse } = await Api().post(`/admin/settings/update-app-current-version`, {
            url,
            os
        });
        dispatch(setState("setCurrentAppVersionFetching", false));

        if (apiResponse.message === "ok") {
            triggerMessage("Current version has been updated", "success");
            dispatch(loadAppVersions());
        }
    } catch (e) {
        dispatch(setState("setCurrentAppVersionFetching", false));
    }
};

export const save = () => async (dispatch, getState) => {
    const settings = getState().settings;
    const payload = [];

    try {
        for (const group of Object.keys(settings.items)) {
            for (const item of Object.keys(settings.items[group])) {
                const { value, name, description } = settings.items[group][item];

                switch (settings.items[group][item].type) {
                    case "boolean":
                        if (value !== "0" && value !== "1") {
                            throw new Error(
                                `Saving error: some of values are incorrect, should boolean "${description}"`
                            );
                        }
                        break;
                    case "number":
                        if (!/^\d+$/.test(value)) {
                            throw new Error(
                                `Saving error: some of values are incorrect, should be number "${description}"`
                            );
                        }
                        break;
                    default:
                }
                payload.push({
                    group,
                    name,
                    value
                });
            }
        }

        dispatch(setState("settingsSaveFetching", true));
        const { data: apiResponse } = await Api().put(`/admin/settings`, payload);
        dispatch(setState("settingsSaveFetching", false));

        if (apiResponse.error) {
            triggerMessage(apiResponse.message, "error");
        }

        if (apiResponse.message === "ok") {
            triggerMessage("Settings saved", "success");
        }
    } catch (e) {
        triggerMessage(e.message, "error");
        dispatch(setState("settingsSaveFetching", false));
    }
};

export const updateItem = (group, name, value) => async dispatch => {
    dispatch({
        type: SETTINGS_UPDATE_ITEM,
        payload: {
            group,
            name,
            value
        }
    });
};
