import React from "react";
import { Grid, Tab, Tabs, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageTitle from "../../components/PageTitle";
import ScriptReport from "../../components/ScriptReport";
import AppTranslation from "../../components/AppTranslation";
import AccountantReport from "../../components/AccountantReport";
import PopupMessage from "../../components/PopupMessage";
import CheckRenewEmail from "../../components/CheckRenewEmail";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    title: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },
    subtitle: {
        fontSize: 20
    },
    header: {
        marginBottom: 15
    },
    tabs: {
        paddingBottom: 15
    }
}));

const DashBoardMisc = () => {
    const [tab, settab] = React.useState(0);
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={0}
                justify="space-between"
                className={classes.header}
                alignContent="center"
            >
                <Grid item>
                    <PageTitle title="Misc" />
                </Grid>
            </Grid>

            <Grid container justify="space-between" alignContent="center">
                <Grid item>
                    <Tabs
                        className={classes.tabs}
                        value={tab}
                        onChange={(e, value) => settab(value)}
                    >
                        <Tab label="Pop-up message" />
                        <Tab label="Script reports" />
                        <Tab label="App translations" />
                        <Tab label="Accountant report" />
                        <Tab label="Renew email" />
                    </Tabs>
                </Grid>
            </Grid>

            <Paper>
                {tab === 0 && <PopupMessage />}
                {tab === 1 && <ScriptReport />}
                {tab === 2 && <AppTranslation />}
                {tab === 3 && <AccountantReport />}
                {tab === 4 && <CheckRenewEmail />}
            </Paper>
        </div>
    );
};

export default DashBoardMisc;
