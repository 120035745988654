import Api from "../services/api";
import { setStateWithKey } from "../actions/state";
import { SET_WIDGET, CUSTOM_FILTER_PREFIX } from "../constants/widgets";

export const loadWidget = (name, force = false) => async (dispatch, getState) => {
    try {
        const isLoading = getState().state.widgets[name];
        // const data = getState().widgets[name];

        // collect custom filters
        const filterString = window.localStorage.getItem(`${CUSTOM_FILTER_PREFIX}${name}`) || "{}";
        const filterJson = JSON.parse(filterString);
        const customFilters = Object.keys(filterJson).reduce((acc, filterName) => {
            // other
            if (filterJson[filterName]) {
                acc[filterName] = filterJson[filterName];
            }

            // check box case
            if (filterJson[filterName] === "0") {
                delete acc[filterName];
            }

            return acc;
        }, {});

        if (!isLoading) {
            dispatch(setStateWithKey("widgets", name, true));
            const { data: apiResponse } = await Api().get(
                `/admin/widgets/${name}${
                    Object.keys(customFilters).length > 0
                        ? "?" + new URLSearchParams(customFilters).toString()
                        : ""
                }`
            );
            dispatch(setStateWithKey("widgets", name, false));

            if (apiResponse.message === "ok") {
                dispatch({
                    type: SET_WIDGET,
                    payload: {
                        name,
                        data: apiResponse.data
                    }
                });
            }
        }
    } catch (e) {
        dispatch(setStateWithKey("widgets", name, false));
    }
};
