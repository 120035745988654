import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as quizActions from "../../actions/quiz";
import Typography from "@material-ui/core/Typography";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageTitle from "../../components/PageTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { green } from "@material-ui/core/colors";
import QuizList from "../../components/QuizList";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    content: {
        padding: theme.spacing(2)
    },
    spinnerWrapper: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: "2"
    },
    header: {
        marginBottom: 15
    },
    add: {
        backgroundColor: green[500],
        color: "white",
        width: 200
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    addNewBtnWrapper: {
        display: "flex",
        justifyContent: "flex-end"
    }
}));

const DashBoardQuizzes = ({ push, quizActions }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {false && (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress size={60} />
                </div>
            )}
            <Grid container spacing={0} justify="space-between" className={classes.header}>
                <Grid item xs={6}>
                    <PageTitle title="Quizzes" />
                    <Typography color="textSecondary">
                        {process.env.REACT_APP_PROJECT_NAME} quizzes. To start a new quiz you have
                        to implemnt its support on frontend.
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.addNewBtnWrapper}>
                        <Button
                            className={classes.add}
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                quizActions.reset();
                                push("/dashboard/quiz/edit/add");
                            }}
                        >
                            Add new quiz
                            <ThumbsUpDownIcon className={classes.rightIcon} />
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <QuizList />
        </div>
    );
};

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        quizActions: bindActionCreators(quizActions, dispatch),
        push: (url) => {
            dispatch(push(url));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashBoardQuizzes));
