import { useEffect, useRef } from "react";

/** hook keeps prev prop or state value */
export function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}
