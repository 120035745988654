import { tokenRequest } from "../actions/user";
import { LOGOUT_USER } from "../constants/user";
import { eraseCookie } from "./cookie";
import { push } from "connected-react-router";

const Auth = (() => {
    let isAuthenticated = false;
    let dispatch = null;
    let getState = null;

    const init = (
        dispatchFn,
        getStateFn
    ) => {
        dispatch = dispatchFn;
        getState = getStateFn;
    };

    const checkToken = () => {
        if (isAuthenticated === false && !getState().user.id) {
            dispatch(tokenRequest());
        }
    };

    const logout = () => {
        isAuthenticated = false;
        eraseCookie("token");
        dispatch({
            type: LOGOUT_USER
        });
        dispatch(push("/login"));
    };

    return {
        isAuthenticated,
        init,
        checkToken,
        logout
    };
})();

export default Auth;