import Api from "../services/api";
import { setState } from "../actions/state";
import {
    DISCOUNTS_PAGE_LIMIT,
    DISCOUNT_FORM_SET,
    DISCOUNT_FROM_SET_FIELD,
    DISCOUNT_FORM_RESET,
    DISCOUNT_TYPE_GIFT,
    DISCOUNT_TYPE_DISCOUNT_SUBSCRIPTION,
    DISCOUNT_FORM_SET_INVITE_CODE_CONDITION
} from "../constants/discount";
import { triggerMessage } from "../utils/events";
import { SET_DISCOUNTS } from "../constants/discount";
import { push } from "connected-react-router";

export const loadDiscounts = (page = 0, limit = DISCOUNTS_PAGE_LIMIT) => async (
    dispatch,
    getState
) => {
    try {
        dispatch(setState("discountsFetching", true));
        const { data: apiResponse } = await Api().get(
            `/admin/discount?limit=${limit}&page=${page}`
        );
        dispatch(setState("discountsFetching", false));

        if (apiResponse.message === "ok") {
            dispatch({
                type: SET_DISCOUNTS,
                payload: apiResponse.data
            });
        }
    } catch (e) {
        dispatch(setState("discountsFetching", false));
    }
};

export const loadDiscount = id => async (dispatch, getState) => {
    dispatch(setState("loadDiscountFetching", true));
    try {
        const { data: apiResponse } = await Api().get(`/admin/discount/${id}`);
        dispatch(setState("loadDiscountFetching", false));

        if (apiResponse.message === "ok") {
            dispatch({
                type: DISCOUNT_FORM_SET,
                payload: apiResponse.data
            });
        } else {
            triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
        }
    } catch (e) {
        dispatch(setState("loadDiscountFetching", false));
        const error = (e.response && e.response.data && e.response.data.message) || "";
        triggerMessage(error, "error");
    }
};

const processDiscountForm = (form = {}) => {
    switch (form.type) {
        case DISCOUNT_TYPE_GIFT:
            delete form.subjects; // remove sub type subjects
            break;
        case DISCOUNT_TYPE_DISCOUNT_SUBSCRIPTION:
            form = {
                type: DISCOUNT_TYPE_DISCOUNT_SUBSCRIPTION,
                subjects: form.subjects
            };
            break;
        default:
    }

    return form;
};

// let's use discount form
export const updateDiscount = () => async (dispatch, getState) => {
    dispatch(setState("saveDiscountFetching", true));
    let form = getState().discountForm ? getState().discountForm.data : {};
    form = processDiscountForm(form);

    try {
        if (!form.offer.type) {
            throw new Error("Discount type is required");
        }
        if (!form.id) {
            throw new Error("Id is required");
        }
        if (!form.user_invite_code_condition_id) {
            throw new Error("User invite code condition is required");
        }
        const { data: apiResponse } = await Api().put(`/admin/discount/${form.id}`, form);
        dispatch(setState("saveDiscountFetching", false));

        if (apiResponse.message === "ok") {
            triggerMessage("Discount condition has been updated", "success");
        } else {
            triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
        }
    } catch (e) {
        dispatch(setState("saveDiscountFetching", false));
        let errors =
            (e.response &&
                e.response.data &&
                e.response.data.data &&
                e.response.data.data.errors) ||
            "";

        if (!errors) {
            errors = e.message;
        }
        triggerMessage(errors, "error");
    }
};

export const addDiscount = () => async (dispatch, getState) => {
    dispatch(setState("saveDiscountFetching", true));
    let form = getState().discountForm ? getState().discountForm.data : {};
    form = processDiscountForm(form);
    delete form.id;

    try {
        if (!form.offer.type) {
            throw new Error("Discount type is required");
        }
        if (!form.user_invite_code_condition_id) {
            throw new Error("User invite code condition is required");
        }

        const { data: apiResponse } = await Api().post("/admin/discount", form);
        dispatch(setState("saveDiscountFetching", false));

        if (apiResponse.message === "ok") {
            triggerMessage("New discount condition has been added", "success");
            dispatch({
                type: DISCOUNT_FORM_RESET
            });
            dispatch(push(`/dashboard/discount-conditions/edit/${apiResponse.data.id}`));
        } else {
            triggerMessage(`Something wrong ${apiResponse.message}`, "warning");
        }
    } catch (e) {
        dispatch(setState("saveDiscountFetching", false));
        let errors =
            (e.response &&
                e.response.data &&
                e.response.data.data &&
                e.response.data.data.errors) ||
            "";

        if (!errors) {
            errors = e.message;
        }
        triggerMessage(errors, "error");
    }
};

export const discountFormSetField = (field, value) => async dispatch => {
    dispatch({
        type: DISCOUNT_FROM_SET_FIELD,
        payload: { field, value }
    });
};

export const discountFormReset = () => async dispatch => {
    dispatch({
        type: DISCOUNT_FORM_RESET
    });
};
export const setInviteCodeDiscountCondition = discount => async dispatch => {
    dispatch({
        type: DISCOUNT_FORM_SET_INVITE_CODE_CONDITION,
        payload: discount
    });
};
